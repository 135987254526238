import React from "react";
import { useHistory } from "react-router";
import styled from "styled-components";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { Stack } from "~/component/atoms/layout/Stack";
import { TutorialLine } from "../TutorialLine";

export const CompleteLineRegister: React.FC = () => {
  const history = useHistory();
  const onClick = () => {
    history.push("/register_my_library");
  };
  return (
    <_Wrapper>
      <TutorialLine />
      <_ModalWrapper>
        <_ModalContainer>
          <_CheckMark>✅</_CheckMark>
          <Stack rowGap="32px">
            <Stack rowGap="16px">
              <_Title>LINEの連携が完了しました</_Title>
              <_Message>
                LINEアカウントの連携が完了しました。
                <br />
                メッセージや通知などをお送りします。
              </_Message>
            </Stack>
            <PrimaryButton onClick={onClick} noMarginTop>
              次へ進む
            </PrimaryButton>
          </Stack>
        </_ModalContainer>
      </_ModalWrapper>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: relative;
  height: 100vh;
  max-height: 100svh;
  overflow: hidden;
`;

const _ModalWrapper = styled.div`
  position: absolute;
  z-index: ${({ theme }) => theme.zIndex.modal};
  top: 0;
  bottom: 0;
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-color: ${({ theme }) => theme.colors.base.overlay};
  padding: 0 32px;

  display: flex;
  justify-content: center;
  align-items: center;
`;

const _ModalContainer = styled.div`
  position: relative;
  margin: auto;
  padding: 48px 16px;
  width: 100%;
  max-width: 640px;

  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
`;

const _CheckMark = styled.div`
  position: absolute;
  top: -26px;
  left: calc(50% - 26px);
  font-size: 52px;
`;

const _Title = styled.p`
  font-size: ${({ theme }) => theme.fontSize.XXL};
  font-weight: 700;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.tex.t800};
`;

const _Message = styled.p`
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  color: ${({ theme }) => theme.colors.tex.t500};
`;
