import React from "react";
import { useHistory } from "react-router-dom";
import { EditRecommendFrequencyTemplate } from ".";
import {
  RecommendFrequency,
  useChangeRecommendFrequencyMutation,
} from "~/generated/graphql";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";

type EditRecommendFrequencyContainerProps = {
  match: { params: { id: string } };
};

export const EditRecommendFrequencyContainer: React.FC<
  EditRecommendFrequencyContainerProps
> = ({ match }) => {
  const userId = Number(match.params.id);
  const { currentUser, refetch } = React.useContext(CurrentUserContext);
  const [recommendFrequency, setRecommendFrequency] =
    React.useState<RecommendFrequency>(
      currentUser?.children.get.find((child) => {
        return child.id === userId;
      })?.recommendFrequency || RecommendFrequency.Basic
    );
  const [openModal, setOpenModal] = React.useState(false);
  const [modalMessage, setModalMessage] = React.useState("");

  const [changeRecommendFrequency] = useChangeRecommendFrequencyMutation({
    onCompleted() {
      refetch();
      setModalMessage("おすすめ冊数が変更されました");
      setOpenModal(true);
    },
  });
  const history = useHistory();

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    changeRecommendFrequency({
      variables: {
        userID: userId,
        frequency: recommendFrequency,
      },
    });
  };

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    event.preventDefault();
    setRecommendFrequency(event.target.value as RecommendFrequency);
  };

  if (
    currentUser &&
    !currentUser?.children.get.find((child) => {
      return child.id === userId;
    })
  ) {
    history.push("/booklist");
  }
  return (
    <EditRecommendFrequencyTemplate
      recommendFrequency={recommendFrequency}
      onClick={handleClick}
      onChange={handleChange}
      handleBack={() => history.goBack()}
      openModal={openModal}
      setOpenModal={setOpenModal}
      modalMessage={modalMessage}
    />
  );
};
