import { ConfigType } from "./type";
import { env } from "../src/utils/env";

export const devConf: ConfigType = {
  ENV: "development",

  PAGE_PREFIX: "/",
  API_HOST: "http://localhost:8083/",
  SUBSCRIPTIONS_API_HOST: "http://localhost:3010",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 2,

  ACCESS_TOKEN_HEADER: null,

  CERTIFICATE_TOKEN_HEADER: "X-Certificate-Token",

  USER_APP: "http://localhost:3002/",
  PARENT_APP: "http://localhost:3003/",
  PAY_JP_PUBLIC_KEY: "pk_test_fbc214054ea5cd7458cf6c38",

  GA_MEASUREMENT_ID: `${env("GA_MEASUREMENT_ID")}`,
  RECAPTCHA_KEY: "6Lep5fgZAAAAAMNZ3yT_nLUqGRtxZ6CMPRWuIuvm",
  GOOGLE_TAG_MANAGER_ID: `${env("GOOGLE_TAG_MANAGER_ID")}`,
  SENTRY_DSN:
    "https://2ce807d4c2cf45a8b59c835f0cd9937f@o967000.ingest.sentry.io/4504483415916544",

  AUTH0_DOMAIN: env("AUTH0_DOMAIN") || "yondemy-local.jp.auth0.com",
  AUTH0_CLIENT_ID: env("AUTH0_CLIENT_ID") || "zMzQptPn4UTNpCLB9EkjIt267YmkJBNk",
  AUTH0_AUDIENCE: env("AUTH0_AUDIENCE") || "http://localhost:8083",
  NOTIFICATION_LINE_LINK: "https://lin.ee/V8FZFoF",
};
