import { RecommendFrequency } from "~/generated/graphql";

type ListContents = {
  emoji: string;
  name: string;
  bookListMax: number;
  border: number;
  lead: string;
  sub: string;
};

const constList: { [key in RecommendFrequency]: ListContents } = {
  LITTLE: {
    emoji: "🍵",
    name: "じっくりコース",
    bookListMax: 5,
    border: 3,
    lead: "一冊一冊を大切に読むお子さんにおすすめ！",
    sub: "特に長い本を読む場合おすすめです。",
  },
  BASIC: {
    emoji: "🍜",
    name: "どんどんコース",
    bookListMax: 8,
    border: 5,
    lead: "ヨンデミー先生イチオシのコースです！",
    sub: "最も人気の高いコースです。",
  },
  MANY: {
    emoji: "🍰",
    name: "たっぷりコース",
    bookListMax: 10,
    border: 6,
    lead: "おうちに本をたっぷり置いておきましょう！",
    sub: "",
  },
  ENOUGH: {
    emoji: "🥙",
    name: "まとめてコース",
    bookListMax: 15,
    border: 11,
    lead: "まとめてたくさん読むお子さんにおすすめ！",
    sub: "",
  },
  BULK: {
    emoji: "🍇",
    name: "どさっとコース",
    bookListMax: 20,
    border: 16,
    lead: "なかなか図書館に行けない方におすすめです。",
    sub: "",
  },
};

export default constList;
