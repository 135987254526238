import * as React from "react";
import styled from "styled-components";
import { ListText } from "../../atoms/text";

type InfoListProps = {
  list: {
    label: string,
    value: React.ReactNode
  }[];
}
export const InfoList: React.VFC<InfoListProps> = ({ list }) => {
  return (
    <table style={{width: "100%"}}>
      <tbody>
        {
          list.map((e, index) => {
            return (
              <tr key={index}>
                <LabelTd>
                  <ListText.Label>
                    {e.label}
                  </ListText.Label>
                </LabelTd>
                <ValueTd>
                  <ListText.Value>
                    {e.value}
                  </ListText.Value>
                </ValueTd>
              </tr>
            );
          })
        }
      </tbody>
    </table>
  );
};

const Td = styled.td`
  padding: 5px 0;
`;

const LabelTd = styled(Td)`
  width: 40%;
  padding-right: 2%;
`;

const ValueTd = styled(Td)`
  width: 58%;
`;