import * as React from "react";
import { Input } from ".";
import { Icon } from "../../icon";
import styled from "styled-components";

type InputProps = Parameters<typeof Input>[0];

type PasswordInputProps = Omit<InputProps, "type" | "endAdornment">;

// eslint-disable-next-line react/display-name
export const PasswordInput = React.forwardRef<
  HTMLInputElement,
  PasswordInputProps
>((props, ref) => {
  const [type, setType] = React.useState<InputProps["type"]>("password");
  const handleClick = () => {
    setType((prev) => (prev === "password" ? "text" : "password"));
  };
  return (
    <Input
      {...props}
      type={type}
      ref={ref}
      endAdornment={
        <_IconWrapper onClick={handleClick} disabled={props.disabled}>
          <Icon name={type === "password" ? "eye" : "eyeSlash"} />
        </_IconWrapper>
      }
    />
  );
});

const _IconWrapper = styled.div<{ disabled?: boolean }>`
  cursor: pointer;
  pointer-events: ${({ disabled }) => (disabled ? "none" : "inherit")}} ;

  svg {
    color: ${({ theme, disabled }) =>
      disabled
        ? theme.colors.semantic.text.placeholder
        : theme.colors.semantic.text.label};
    height: 16px;
    width: 16px;
  }
`;
