import React, { VFC } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { ColorTheme, theme } from "~/component/theme";

// NOTE: アプリケーションで使用するアイコン一覧
export const fontawesomeIcons = {
  question: {
    prefix: "fas",
    iconName: "question-circle",
  },
  // pencil: {
  //   prefix: "fas",
  //   iconName: "pencil-alt",
  // },
  arrowLeft: {
    prefix: "fas",
    iconName: "arrow-left",
  },
  arrowRight: {
    prefix: "fas",
    iconName: "arrow-right",
  },
  // book: {
  //   prefix: "fas",
  //   iconName: "book",
  // },
  // bookmark: {
  //   prefix: "fas",
  //   iconName: "bookmark",
  // },
  check: {
    prefix: "fas",
    iconName: "check",
  },
  // clipboard: {
  //   prefix: "fas",
  //   iconName: "clipboard-list",
  // },
  clipboardCheck: {
    prefix: "fas",
    iconName: "clipboard-check",
  },
  // crown: {
  //   prefix: "fas",
  //   iconName: "crown",
  // },
  // file: {
  //   prefix: "fas",
  //   iconName: "file",
  // },
  // microphone: {
  //   prefix: "fas",
  //   iconName: "microphone",
  // },
  // plus: {
  //   prefix: "fas",
  //   iconName: "plus",
  // },
  // skull: {
  //   prefix: "fas",
  //   iconName: "skull-crossbones",
  // },
  // search: {
  //   prefix: "fas",
  //   iconName: "search",
  // },
  // camera: {
  //   prefix: "fas",
  //   iconName: "camera",
  // },
  copy: {
    prefix: "fas",
    iconName: "copy",
  },
  // cancel: {
  //   prefix: "fas",
  //   iconName: "times",
  // },
  bell: {
    prefix: "fas",
    iconName: "bell",
  },
  close: {
    prefix: "fas",
    iconName: "times-circle",
  },
  chevronRight: {
    prefix: "fas",
    iconName: "chevron-right",
  },
  // chevronArrowDown: {
  //   prefix: "fas",
  //   iconName: "chevron-down",
  // },
  // medal: {
  //   prefix: "fas",
  //   iconName: "medal",
  // },
  // dottedLine: {
  //   prefix: "fas",
  //   iconName: "ellipsis-h",
  // },
  cog: {
    prefix: "fas",
    iconName: "cog",
  },
  // heart: {
  //   prefix: "fas",
  //   iconName: "heart",
  // },
  // infoCircle: {
  //   prefix: "fas",
  //   iconName: "info-circle",
  // },
  // star: {
  //   prefix: "fas",
  //   iconName: "star",
  // },
  trash: {
    prefix: "fas",
    iconName: "trash",
  },
  exclamation: {
    prefix: "fas",
    iconName: "exclamation",
  },
  exclamationCircle: {
    prefix: "fas",
    iconName: "exclamation-circle",
  },
  // externalLink: {
  //   prefix: "fas",
  //   iconName: "external-link-alt",
  // },
  // envelope: {
  //   prefix: "fas",
  //   iconName: "envelope",
  // },
  // exclamationTriangle: {
  //   prefix: "fas",
  //   iconName: "exclamation-triangle",
  // },
  // volumeHigh: {
  //   prefix: "fas",
  //   iconName: "volume-up",
  // },
  // volumeLow: {
  //   prefix: "fas",
  //   iconName: "volume-down",
  // },
  // circleArrowLeft: {
  //   prefix: "fas",
  //   iconName: "arrow-alt-circle-left",
  // },
  // bars: {
  //   prefix: "fas",
  //   iconName: "bars",
  // },
  // comment: {
  //   prefix: "fas",
  //   iconName: "comment",
  // },
  // cloudArrowButton: {
  //   prefix: "fas",
  //   iconName: "cloud-arrow-down",
  // },
  // print: {
  //   prefix: "fas",
  //   iconName: "print",
  // },
  clone: {
    prefix: "fas",
    iconName: "clone",
  },
  // share: {
  //   prefix: "fas",
  //   iconName: "share-nodes",
  // },
  commentDots: {
    prefix: "fas",
    iconName: "comment-dots",
  },
  thumbsUp: {
    prefix: "fas",
    iconName: "thumbs-up",
  },
  // gear: {
  //   prefix: "fas",
  //   iconName: "gear",
  // },
  home: {
    prefix: "fas",
    iconName: "home",
  },
  book: {
    prefix: "fas",
    iconName: "book",
  },
  caretDown: {
    prefix: "fas",
    iconName: "caret-down",
  },
  caretUp: {
    prefix: "fas",
    iconName: "caret-up",
  },
  eye: {
    prefix: "fas",
    iconName: "eye",
  },
  eyeSlash: {
    prefix: "fas",
    iconName: "eye-slash",
  },
} as const;

export const customIconPaths = {
  present: "/img/icon/present.svg",
  openedPresent: "/img/icon/opened-present.svg",
};

type InlineCustomIconType = "ribbon";

const icons = {
  ...fontawesomeIcons,
  ...customIconPaths,
};

export type IconType = keyof typeof icons | InlineCustomIconType;

export type IconProps = {
  /**
   * アイコン名
   */
  name: IconType;
  /**
   * FontAwesomeIconのsvg要素に適用するCSS
   */
  style?: React.CSSProperties;
  /**
   * カラーテーマ
   */
  color?: ColorTheme;
  /**
   * Iconがクリックされたときに発火
   */
  onClick?: (
    event: React.MouseEvent<SVGSVGElement, MouseEvent>
  ) => void | Promise<void>;
};

export const IconColorList: Record<ColorTheme, string> = {
  pri: theme.colors.pri.p500,
  sec: theme.colors.sec.s500,
  violet: theme.colors.violet.v500,
  green: theme.colors.green.g500,
  red: theme.colors.red.r500,
  sky: theme.colors.sky.s500,
  gray: theme.colors.tex.t200,
  tex: theme.colors.tex.t500,
  white: theme.colors.base.white,
  lightGray: theme.colors.tex.t100,
};

export const Icon: VFC<IconProps> = ({
  name,
  style,
  color = "pri",
  onClick,
}) => {
  if (fontawesomeIcons[name]) {
    return (
      <StyledIcon
        style={style}
        icon={fontawesomeIcons[name]}
        color={color}
        onClick={onClick}
      />
    );
  }
  return <></>;
};

const StyledIcon = styled(FontAwesomeIcon)<Pick<IconProps, "color">>`
  width: 1em;
  height: 1em;
  display: flex;
  align-items: center;
  color: ${({ color }) =>
    color ? IconColorList[color] : IconColorList["pri"]};
`;
