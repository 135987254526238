import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { BookListViewerTemplate } from "./BookListViewerTemplate";
import { LoadingPage } from "~/component/templates/loading";
import {
  RecommendFrequency,
  useUpdateRecommendFrequencyMutation,
  useGetNowBookListQuery,
  useUpdateProcureStatusMutation,
  ProcureStatus,
  useGetWantReadBooksQuery,
  useRemoveWantReadBookMutation,
  useGetSuitableYlQuery,
} from "../../generated/graphql";
import { usePreference } from "~/store/preference/usePreference";
// import { entryPageOfRemoveRecommendVar } from "../RemoveRecommend";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { WantReadBookViewerTemplate } from "./wantReadBookListViewerTemplate";
import { RemoveWantReadBookModalProps } from "./wantReadBookListViewerTemplate/RemoveWantReadBookModal";

const recommendOrder: Record<ProcureStatus, number> = {
  UNTOUCHED: 1,
  RESERVED: 2,
  PURCHASED: 3,
};

const LIST_LENGTH = 10;

type BookListViewerProps = {
  match: { params: { id: string } };
};

export type BookshelfType = "recommend" | "wantReadBook";

export const BOOKLIST_USER_SESSIONSTORAGE_KEY = "booklist_user_key";

export const BookListViewer: React.FC<BookListViewerProps> = ({ match }) => {
  const userId = Number(match.params.id);
  // タブ切り替え時に元々見ていたユーザーに戻るようにする
  sessionStorage.setItem(BOOKLIST_USER_SESSIONSTORAGE_KEY, String(userId));
  const [recommendBegin, setRecommendBegin] = React.useState<number>(0);
  const [wantReadBookBegin, setWantReadBookBegin] = React.useState<number>(0);
  const [currentTab, setCurrentTab] =
    React.useState<BookshelfType>("recommend");
  const history = useHistory();
  const [newRecommendIdList, setNewRecommendIdList] = React.useState<number[]>(
    []
  );
  const [removeWantReadBookId, setRemoveWantReadBookId] = useState<
    number | null
  >(null);
  const { preference, savePreference } = usePreference();
  const { currentUser, refetch: refetchContext } =
    React.useContext(CurrentUserContext);
  const currentChild =
    currentUser?.children.get[
      currentUser?.children.get.findIndex((child) => child.id === userId)
    ];

  const handleBack = (): void => {
    history.push("/");
  };

  const {
    loading: suitableYlLoading,
    error: suitableYlError,
    data: suitableYlData,
  } = useGetSuitableYlQuery({
    variables: {
      userID: userId,
    },
    fetchPolicy: "network-only",
  });

  const {
    loading: recommendLoading,
    error: recommendError,
    data: recommendData,
    refetch: refetchRecommend,
  } = useGetNowBookListQuery({
    variables: {
      userID: userId,
      begin: recommendBegin,
      end: recommendBegin + LIST_LENGTH,
    },
    fetchPolicy: "network-only",
    onCompleted: (recommendData) => {
      savePreference(
        {
          booklistLatestRecommendId: Math.max(
            ...recommendData.getRecommendsInBookShelf.get.map(
              (recommend) => recommend.id
            )
          ),
        },
        {
          updateCurrentValue: false,
        }
      );
    },
  });

  const {
    loading: wantReadBookLoading,
    error: wantReadBookError,
    data: wantReadBookData,
    refetch: refetchWantReadBook,
  } = useGetWantReadBooksQuery({
    variables: {
      userID: userId,
      begin: wantReadBookBegin,
      end: wantReadBookBegin + LIST_LENGTH,
    },
    fetchPolicy: "network-only",
  });

  const [removeWantReadBookMutation, { loading: removeWantReadBookLoading }] =
    useRemoveWantReadBookMutation({
      variables: { id: removeWantReadBookId || 0 },
      onCompleted: () => {
        refetchWantReadBook();
        setRemoveWantReadBookId(null);
      },
    });

  React.useEffect(() => {
    if (recommendData && preference) {
      const storagerecommendData = JSON.parse(
        preference?.newRecommendIdList || "{}"
      );
      let _newRecommendIdList: number[] = [];
      if (Array.isArray(storagerecommendData)) {
        _newRecommendIdList = storagerecommendData.filter(
          (v) => typeof v === "number"
        );
      }

      const latestRecommendId = Math.max(
        ...recommendData.getRecommendsInBookShelf.get.map(
          (recommend) => recommend.id
        )
      );

      if (!preference?.booklistLatestRecommendId) {
        _newRecommendIdList = _newRecommendIdList.concat(
          recommendData.getRecommendsInBookShelf.get.map(
            (recommend) => recommend.id
          )
        );
      } else if (preference.booklistLatestRecommendId < latestRecommendId) {
        _newRecommendIdList = _newRecommendIdList.concat(
          recommendData.getRecommendsInBookShelf.get
            .map(
              (recommend) =>
                preference.booklistLatestRecommendId &&
                recommend.id > preference.booklistLatestRecommendId &&
                recommend.id
            )
            .filter((v) => v) as number[]
        );
      }

      setNewRecommendIdList(_newRecommendIdList);
      savePreference({
        newRecommendIdList: JSON.stringify(_newRecommendIdList),
      });
    }
    // NOTE: preferenceやsavePreferenceを入れると無限ループしてしまうので回避
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [recommendData]);

  const [openChangeRFrequencyToast, setOpenChangeRFrequencyToast] =
    useState(false);
  const [changeRecommendFrequency] = useUpdateRecommendFrequencyMutation({
    onCompleted() {
      refetchRecommend();
      refetchContext();
      setOpenChangeRFrequencyToast(true);
    },
  });
  const [updateProcureStatus] = useUpdateProcureStatusMutation({
    onCompleted() {
      refetchRecommend();
      refetchWantReadBook();
    },
  });

  const handleChangeRecommendFrequency = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    event.preventDefault();
    changeRecommendFrequency({
      variables: {
        userID: userId,
        frequency: event.target.value as RecommendFrequency,
      },
    });
  };

  const handleEraseNewTag = (recommendId: number): void => {
    const _newRecommendIdList = newRecommendIdList.filter(
      (recommend) => recommend !== recommendId
    );
    setNewRecommendIdList(_newRecommendIdList);
    savePreference({
      newRecommendIdList: JSON.stringify(_newRecommendIdList),
    });
  };

  const removeWantReadBookModalProps: RemoveWantReadBookModalProps =
    React.useMemo(() => {
      return {
        isOpen: removeWantReadBookId !== null,
        onClickReturn: () => setRemoveWantReadBookId(null),
        onClickRemove: () =>
          new Promise((resolve) =>
            removeWantReadBookMutation()
              .then(() => resolve(true))
              .catch(() => resolve(false))
          ),
        loading: removeWantReadBookLoading,
      };
    }, [
      removeWantReadBookId,
      removeWantReadBookMutation,
      removeWantReadBookLoading,
    ]);

  if (recommendLoading || wantReadBookLoading || suitableYlLoading) {
    return <LoadingPage />;
  }
  if (recommendError || wantReadBookError || suitableYlError) {
    return (
      <>
        Error!{" "}
        {recommendError?.message ||
          wantReadBookError?.message ||
          suitableYlError?.message}
      </>
    );
  }
  if (!recommendData || !wantReadBookData || !suitableYlData) {
    return null;
  }

  return (
    <>
      {currentTab === "recommend" ? (
        <BookListViewerTemplate
          handleBack={handleBack}
          currentCount={recommendBegin}
          items={recommendData.getRecommendsInBookShelf.get
            .map((recommend, index) => {
              const {
                isbn,
                title,
                imageUrl,
                affiliateLink,
                author,
                publisher,
              } = recommend.book;
              const isNew = newRecommendIdList.includes(recommend.id);
              return {
                recommendId: recommend.id,
                num: index + 1,
                total: recommendData.getRecommendsInBookShelf.count,
                isbn: isbn,
                title: title ? title : "",
                imageUrl: imageUrl ?? "",
                affiliateLink: affiliateLink || undefined,
                author: author ?? "",
                publisher: publisher ?? "",
                availableBookType: recommend.availableBookType,
                myLibraryName: currentUser?.myLibrary?.libraryName,
                isNew: isNew,
                procureStatusManager: {
                  procureStatus: recommend.procureStatus,
                  updateProcureStatus: (procureStatus: ProcureStatus) =>
                    new Promise<boolean>((resolve) => {
                      updateProcureStatus({
                        variables: {
                          recommendID: recommend.id,
                          procureStatus: procureStatus,
                        },
                      }).then((res) => {
                        if (res.errors) {
                          resolve(false);
                        }
                        resolve(true);
                      });
                    }),
                },
                handleEraseNewTag: handleEraseNewTag,
                handleRemoveItem: (e: React.MouseEvent) => {
                  e.preventDefault();
                  const searchParams = new URLSearchParams({
                    title: title ? title : "",
                    ISBN: isbn,
                  });

                  history.push(
                    `/remove_recommend/${
                      recommend.id
                    }?${searchParams.toString()}`
                  );
                },
                onClickDetail: () => history.push(`/recommend/${recommend.id}`),
              };
            })
            .sort(
              (a, b) =>
                recommendOrder[a.procureStatusManager.procureStatus] -
                recommendOrder[b.procureStatusManager.procureStatus]
            )}
          itemTotal={recommendData.getRecommendsInBookShelf.count}
          itemsPerPage={LIST_LENGTH}
          onPageChange={setRecommendBegin}
          recommendFrequency={currentChild?.recommendFrequency}
          onChangeRecommendFrequency={handleChangeRecommendFrequency}
          openToastChangeRFreq={openChangeRFrequencyToast}
          setOpenToastChangeRFreq={setOpenChangeRFrequencyToast}
          myLibraryName={currentUser?.myLibrary?.libraryName}
          currentUserId={userId}
          changeCurrentTab={(value: BookshelfType) => setCurrentTab(value)}
          suitableYl={suitableYlData?.getSuitableYL}
        />
      ) : (
        <WantReadBookViewerTemplate
          handleBack={handleBack}
          currentCount={wantReadBookBegin}
          items={wantReadBookData.getWantReadBooks.get.map(
            (wantReadBook, index) => {
              const {
                isbn,
                title,
                imageUrl,
                affiliateLink,
                author,
                publisher,
              } = wantReadBook.book;
              return {
                recommendId: wantReadBook.id,
                num: index + 1,
                total: wantReadBookData.getWantReadBooks.count,
                isbn: isbn,
                title: title ? title : "",
                imageUrl: imageUrl ?? "",
                affiliateLink: affiliateLink || undefined,
                author: author ?? "",
                publisher: publisher ?? "",
                myLibraryName: currentUser?.myLibrary?.libraryName,
                isNew: false,
                updateProcureStatus: (procureStatus: ProcureStatus) =>
                  new Promise<boolean>((resolve) => {
                    updateProcureStatus({
                      variables: {
                        recommendID: wantReadBook.id,
                        procureStatus: procureStatus,
                      },
                    }).then((res) => {
                      if (res.errors) {
                        resolve(false);
                      }
                      resolve(true);
                    });
                  }),
                handleEraseNewTag: handleEraseNewTag,
                handleRemoveItem: () =>
                  setRemoveWantReadBookId(wantReadBook.id),
                onClickDetail: () =>
                  history.push(`/wantreadbook/${wantReadBook.id}`),
              };
            }
          )}
          itemTotal={wantReadBookData.getWantReadBooks.count}
          itemsPerPage={LIST_LENGTH}
          onPageChange={setWantReadBookBegin}
          currentUserId={userId}
          changeCurrentTab={(value: BookshelfType) => setCurrentTab(value)}
          removeWantReadBookModalProps={removeWantReadBookModalProps}
        />
      )}
    </>
  );
};
