import React from "react";

export const options = [
  [
    "とても好きだと思う",
    "好きだと思う",
    "好きではないと思う",
    "苦手意識があると思う",
    "嫌いだと思う",
  ],
  [
    "本への苦手意識を克服して欲しい",
    "声かけや読み聞かせに頼らず、自分から本を読むようになって欲しい",
    "簡単な本や図鑑 / 漫画以外も読むようになってほしい",
    "本を読むのが習慣になって欲しい",
    "本を通して、自分の気持ちを表現できるようになってほしい",
    "本に接する時間が増えてほしい",
  ],
];

export const questions = [
  {
    id: "1",
    question: [
      {
        id: "1",
        type: "text",
        body: "お子さんのニックネームを教えてください",
        annotation: "※アプリで使われるニックネームとは異なります",
      },
    ],
  },
  {
    id: "2",
    question: [
      {
        id: "2",
        type: "select",
        body: (name: string): string =>
          name + "さんは1週間で何日読書していますか？",
        options: [
          "選択してください",
          "週1日",
          "週2日",
          "週3日",
          "週4日",
          "週5日",
          "週6日",
          "週7日",
        ],
      },
      {
        id: "3",
        type: "select",
        body: (name: string): string =>
          name + "さんは1日あたり何時間読書していますか？",
        options: [
          "選択してください",
          "0時間",
          "1時間",
          "2時間",
          "3時間",
          "4時間",
          "5時間",
          "6時間以上",
        ],
      },
    ],
  },
  {
    id: "3",
    question: [
      {
        id: "4",
        type: "radio",
        body: (name: string): string =>
          name + "さんは読書にどんな印象を持っていますか？",
        options: options[0],
      },
    ],
  },
  {
    id: "4",
    question: [
      {
        id: "5",
        type: "radio",
        body: "ヨンデミーを利用したい理由、ヨンデミーを通じてお子さんにどうなって欲しいかを教えてください。",
        options: options[1],
      },
    ],
  },
  { id: "5", question: [{ id: "6", type: "result" }] },
];

export type ResultType =
  | "NoSelfControl"
  | "HateToRead"
  | "GoodButNoHabit"
  | "NotGoodAtReading"
  | "UnbalancedReading"
  | "NeedToBeCared"
  | "UnableToExpress"
  | "NoHabitYet";

type ResultsType = {
  childTypeTitle: Record<ResultType, string>;
  childTypeBody: Record<ResultType, JSX.Element>;
  everydayLesson: Record<ResultType, JSX.Element>;
  reasonForImportance: Record<ResultType, JSX.Element>;
};

export const results = (name: string): ResultsType => {
  return {
    childTypeTitle: {
      NoSelfControl: "他律読書型",
      HateToRead: "読まず嫌い型",
      GoodButNoHabit: "未習慣化型",
      NotGoodAtReading: "苦手意識型",
      UnbalancedReading: "偏読型",
      NeedToBeCared: "未習慣化型",
      UnableToExpress: "未自己表現型",
      NoHabitYet: "未習慣化型",
    },
    childTypeBody: {
      NoSelfControl: (
        <span>
          読書に良い印象はありつつも、声かけや読み聞かせが必要な{name}
          さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),
      HateToRead: (
        <span>
          読書に良い印象はありつつも、読む本が偏っている{name}
          さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),
      GoodButNoHabit: (
        <span>
          読書に良い印象はありつつも、読書習慣があるとは言えない{name}
          さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),
      NotGoodAtReading: (
        <span>
          読書に苦手意識を持ち、なかなか積極的に本を手に取らない{name}
          さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),

      UnbalancedReading: (
        <span>
          読書にネガティブな印象を持ちつつも、好きな本はたくさん読むことができる
          {name}さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),
      NeedToBeCared: (
        <span>
          読書にネガティブな印象を持ちつつも、ケアすると本を読む{name}
          さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),
      UnableToExpress: (
        <span>
          これから読書を通じて語彙や表現を習得し、自分の気持ちを表現できるようになっていきたい
          {name}さんのヨンデミー活用の鍵、
          <br />
          それは「ミニレッスン」です！
        </span>
      ),
      NoHabitYet: (
        <span>
          {name}
          さんのようなケースで、ヨンデミーを通してどう成長するかのモデルケースをお示しします。
        </span>
      ),
    },
    everydayLesson: {
      NoSelfControl: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}
            さんが声かけや読み聞かせに頼らず本を読むようになるための秘訣です🙌
          </span>
        </span>
      ),
      HateToRead: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}さんの読む本の幅を拡げるための秘訣です🙌
          </span>
        </span>
      ),
      GoodButNoHabit: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}さんが読書習慣をつけるための秘訣です🙌
          </span>
        </span>
      ),
      NotGoodAtReading: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}
            さんが声読書への苦手意識を払拭し、自分から本を手に取るようになるための秘訣です🙌
          </span>
        </span>
      ),
      UnbalancedReading: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}
            さんの本へのイメージを変え、読む本の幅を拡げるための秘訣です🙌
          </span>
        </span>
      ),
      NeedToBeCared: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}さんが読書習慣をつけるための秘訣です🙌
          </span>
        </span>
      ),
      UnableToExpress: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}さんが自分の気持ちを表現できるようになるための秘訣です🙌
          </span>
        </span>
      ),
      NoHabitYet: (
        <span>
          結論から申し上げますと、
          <br />
          ミニレッスンを必ず毎日受けることが
          <br />
          <span style={{ fontWeight: "bold" }}>
            {name}さんが読書習慣をつけるための秘訣です🙌
          </span>
        </span>
      ),
    },
    reasonForImportance: {
      NoSelfControl: (
        <span>
          {name}
          さんが声かけや読み聞かせに頼らず本を読むようになるためには、読書の「習慣化」が非常に重要です😊
          <br />
          一度「毎日本を読む習慣」がつけば声かけをされずとも自分から本を手にとるようになりますし、だんだんと1人で読む力もついていきます。
        </span>
      ),
      HateToRead: (
        <span>
          {name}
          さんの読む本を拡げるためには、読書の「習慣化」が非常に重要です😊
          <br />
          まずは「毎日本を読む習慣」をつけて「読む量」を確保することで、徐々に「興味の芽」に出会えるようになり、これまで読んでこなかった本にも興味が持てるようになります。
        </span>
      ),
      GoodButNoHabit: (
        <span>
          {name}
          さんが読書を習慣化するためには、ヨンデミーに毎日欠かさず触れることで習慣を途切れさせないことが非常に重要です😊
        </span>
      ),
      NotGoodAtReading: (
        <span>
          {name}
          さんが読書への苦手意識を払拭し、自分から本を手に取るようになるためには、まず「本」に習慣的に触れることが非常に重要です😊
          <br />
          読まずとも本と触れる機会を増やすことで読書への抵抗感を減らし、次第に本を読む日を増やすことができます！
        </span>
      ),
      UnbalancedReading: (
        <span>
          {name}
          さんの読む本の幅を拡げるためには、読書の「習慣化」が非常に重要です😊
          <br />
          まずは本のイメージを変えつつ「毎日本を読む習慣」をつけて「読む量」を確保することで、徐々に「興味の芽」に出会えるようになり、これまで読んでこなかった本にも興味が持てるようになります。
        </span>
      ),
      NeedToBeCared: (
        <span>
          {name}
          さんが読書を習慣化するためには、ヨンデミーに毎日欠かさず触れることで習慣を途切れさせないことが非常に重要です😊
        </span>
      ),
      UnableToExpress: (
        <span>
          {name}
          さんが自分の気持ちを表現できるようになるためには、読書の「習慣化」が非常に重要です😊
          <br />
          まずは「毎日本を読む習慣」をつけて「読む量」を確保することで、{name}
          さんの使える「語彙・表現」が増え、自分の気持ちを表現することが負担ではなくなってきます。
        </span>
      ),
      NoHabitYet: (
        <span>
          {name}
          さんが声かけや読み聞かせに頼らず本を読むようになるためには、読書の「習慣化」が非常に重要です😊
        </span>
      ),
    },
  };
};

/* export const results = [
  {
    type: 1,
    body: [
      {
        title: (name: string): string => `{name}さんは-他律読書型-です！`,
        description: (name: string): string =>
          `読書に良い印象はありつつも、声かけや読み聞かせが必要な{name}さんのヨンデミー活用の鍵、それは「ミニレッスン」です！`,
         classNameはGoogleOptimizeで特定の要素を操作する際の識別子として付けています
        className: "result-1-1",
      },
      {
        title: "まずはミニレッスンを",
        description:
          "ミニレッスンを受けてヨンデミー先生の出すクイズに答えることで、自然と本へのイメージが変わります。また、ヨンデミーに毎日触れることで、読書のことを毎日意識するようになり、その後読書の習慣化に繋がります。",
        className: "result-1-2",
      },
      {
        title: "ミニレッスンに慣れたら、本を読むように",
        description:
          "子ども1人1人のレベルと好みに合わせたお薦めの本が送られてきます。毎日同じ時刻（夜寝る前が多い）に本を読みましょう。",
        className: "result-1-3",
      },
      {
        title: "使っているうちに習慣化する",
        description:
          "バッジがもらえたり、ヨンデミーの他の受講生の子が出した感想が読めます。",
        className: "result-1-4",
      },
    ],
  },
  {
    type: 2,
    body: [
      {
        title: "お子さんは-読まず嫌い型-ですね！",
        description:
          "{name}さんのようなケースで、ヨンデミーを通してどう成長するかのモデルケースをお示しします。",
        className: "result-2-1",
      },
      {
        title: "まずはミニレッスンを",
        description:
          "ミニレッスンを受けてヨンデミー先生の出すクイズに答えることで、自然と本へのイメージが変わります。また、ヨンデミーに毎日触れることで、読書のことを毎日意識するようになり、その後読書の習慣化に繋がります。",
        className: "result-2-2",
      },
      {
        title: "選書を受け取りましょう",
        description:
          "ヨンデミーの選書は、回数を重ねるごとにお子さんの好みに合わせるようになります。",
        className: "result-2-3",
      },
      {
        title: "バラエティ豊かな選書",
        description:
          "普段保護者さまが選ばれないような本や、ポピュラーではない本がおすすめされます。新しい本との出会いは、お子さんの世界を広げてくれること間違いなしです。",
        className: "result-2-4",
      },
      {
        title: "慣れてきたら、だんだんレベルが上がっていきます",
        description:
          "本が簡単に読めるようになってきたら、だんだん難しめの本がお薦めされるようになります。",
        className: "result-2-5",
      },
    ],
  },
  {
    type: 3,
    body: [
      {
        title: "お子さんは-未習慣化型-ですね！",
        description:
          "{name}さんのようなケースで、ヨンデミーを通してどう成長するかのモデルケースをお示しします。",
        className: "result-3-1",
      },
      {
        title: "まずはミニレッスンを",
        description:
          "ミニレッスンを受けてヨンデミー先生の出すクイズに答えることで、自然と本へのイメージが変わります。また、ヨンデミーに毎日触れることで、読書のことを毎日意識するようになり、その後読書の習慣化に繋がります。",
        className: "result-3-2",
      },
      {
        title: "ミニレッスンに慣れたら、本を読むように",
        description:
          "子ども1人1人のレベルと好みに合わせたお薦めの本が送られてきます。毎日同じ時刻（夜寝る前が多い）に本を読みましょう。",
        className: "result-3-3",
      },
      {
        title: "使っているうちにだんだん習慣化",
        description:
          "ヨンデミーの他の受講生の子が出した感想を読むことや、ヨンデミー先生とのコミュニケーションがモチベーションになって、習慣化につながります。",
        className: "result-3-4",
      },
    ],
  },
  {
    type: 4,
    body: [
      {
        title: "お子さんは-苦手意識型-ですね！",
        description:
          "{name}さんのようなケースで、ヨンデミーを通してどう成長するかのモデルケースをお示しします。",
        className: "result-4-1",
      },
      {
        title: "まずはミニレッスンを",
        description:
          "ミニレッスンを受けてヨンデミー先生の出すクイズに答えることで、自然と本へのイメージが変わります。また、ヨンデミーに毎日触れることで、読書のことを毎日意識するようになり、その後読書の習慣化に繋がります。",
        className: "result-4-2",
      },
      {
        title: "ミニレッスンに慣れたら、本を読むように",
        description:
          "子ども1人1人のレベルと好みに合わせたお薦めの本が送られてきます。毎日同じ時刻（夜寝る前が多い）に本を読みましょう。",
        className: "result-4-3",
      },
      {
        title: "最初は声かけが肝心",
        description: "最初の数日保護者さんに声かけを頑張って頂ければああ",
        className: "result-4-4",
      },
    ],
  },
  {
    type: 5,
    body: [
      {
        title: "お子さんは-偏読型-ですね！",
        description:
          "{name}さんのようなケースで、ヨンデミーを通してどう成長するかのモデルケースをお示しします。",
        className: "result-5-1",
      },
      {
        title: "まずはミニレッスンを",
        description:
          "ミニレッスンを受けてヨンデミー先生の出すクイズに答えることで、自然と本へのイメージが変わります。また、ヨンデミーに毎日触れることで、読書のことを毎日意識するようになり、その後読書の習慣化に繋がります。",
        className: "result-5-2",
      },
      {
        title: "難しい本は一旦待って",
        description:
          "お子さんのレベルに合った本を推測しながら、本をお薦めします。何回も感想を出してもらう中で選書は最適化され、お子さんにもっと合った本をお薦めされるようになっていきます。",
        className: "result-5-3",
      },
      {
        title: "レベル判定をしましょう",
        description:
          "実は親が進める本が合ってないのは漢字とか文章レベルの問題かも、ヨンデミーのアンケートでお子さんのYLを診断して読んでみましょう",
        className: "result-5-4",
      },
    ],
  },
  {
    type: 6,
    body: [
      {
        title: "お子さんは-未習慣化型-ですね！",
        description:
          "{name}さんのようなケースで、ヨンデミーを通してどう成長するかのモデルケースをお示しします。",
        className: "result-6-1",
      },
      {
        title: "まずは焦らずミニレッスンを",
        description:
          "ミニレッスンを受けてヨンデミー先生の出すクイズに答えることで、自然と本へのイメージが変わります。また、ヨンデミーに毎日触れることで、読書のことを毎日意識するようになり、その後読書の習慣化に繋がります。",
        className: "result-6-2",
      },
      {
        title: "難しい本は一旦待って",
        description:
          "お子さんのレベルに合った本を推測しながら、本をお薦めします。何回も感想を出してもらう中で選書は最適化され、お子さんにもっと合った本をお薦めされるようになっていきます。",
        className: "result-6-3",
      },
      {
        title: "レベル判定をしましょう",
        description:
          "実は親が進める本が合ってないのは漢字とか文章レベルの問題かも、ヨンデミーのアンケートでお子さんのYLを診断して読んでみましょう",
        className: "result-6-4",
      },
    ],
  },
]; */
