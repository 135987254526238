import { ButtonProps } from "../ui/Dialog/types";
import { MessageProps } from "../ui/Message";
import { SelectQuestionProps } from "../ui/Dialog/SelectQuestion";
import { BookLikeability } from "~/generated/graphql";
import React from "react";
import { YLCheckType } from "../types";

export const allImages = [
  "/img/sensei/standing.svg",
  "/img/sensei/smile.svg",
  "/img/sensei/reading.svg",
] as const;
type ImageSrc = typeof allImages[number];

type Flow = {
  imgSrc?: ImageSrc;
  messageProps?: MessageProps;
  selectQuestion?: SelectQuestionProps;
  inputQuestion?: PartialInputQuestionProps;
};

type TmpInputQuestionProps = {
  onClick: (e: React.MouseEvent) => void;
};

type PartialInputQuestionProps = {
  buttonProps: ButtonProps;
  imgSrc: ImageSrc;
  messageProps?: MessageProps;
};

export const getFlows = (
  nickname: string,
  confirmNameButtons: ButtonProps[],
  bookLikeabiltyButtons: ButtonProps[],
  ylCheckTypeButtons: ButtonProps[],
  tmpInputQuestionProps: TmpInputQuestionProps,
  isHateBook: boolean
): Flow[] => [
  {
    imgSrc: "/img/sensei/standing.svg",
    messageProps: {
      message:
        "《まぼろしのとしょかん》 に\nようこそ! ヨンデミー先生です😊\nとしょかんの おせわをしているよ📚",
      speaker: "ヨンデミー先生",
    },
  },
  {
    imgSrc: "/img/sensei/standing.svg",
    messageProps: {
      message: "まずは、きみのなまえを\nおしえてほしいな！",
      speaker: "ヨンデミー先生",
    },
  },
  {
    inputQuestion: {
      messageProps: {
        message:
          "ニックネームをおしえてね。\nあとから かえることもできるよ。\n16字まで かけるよ。",
        speaker: "ヨンデミー先生",
        showTriangle: false,
      },
      imgSrc: "/img/sensei/standing.svg",
      buttonProps: {
        onClick: tmpInputQuestionProps.onClick,
        label: "これにきめる",
      },
    },
  },
  {
    selectQuestion: {
      messageProps: {
        speaker: "ヨンデミー先生",
        message: `${nickname}さんと よんでいいかな？`,
      },
      imgSrc: "/img/sensei/standing.svg",
      buttons: confirmNameButtons,
    },
  },
  {
    imgSrc: "/img/sensei/smile.svg",
    messageProps: {
      message: "すてきな なまえ！\nよろしくね😆✨",
      speaker: "ヨンデミー先生",
    },
  },
  {
    imgSrc: "/img/sensei/reading.svg",
    messageProps: {
      message: `${nickname}さんのことを\nしりたいな！`,
      speaker: "ヨンデミー先生",
    },
  },
  {
    selectQuestion: {
      messageProps: {
        message: `${nickname}さんは、\nほんって、すき？📚`,
        speaker: "ヨンデミー先生",
      },
      imgSrc: "/img/sensei/standing.svg",
      buttons: bookLikeabiltyButtons,
    },
  },
  {
    imgSrc: "/img/sensei/smile.svg",
    messageProps: {
      message: isHateBook
        ? `おしえてくれて ありがとう。\nそれじゃあ、\n${nickname}さんに ほんが すきになって\nもらえるように がんばるね！`
        : "そうなんだね😆\nヨンデミーせんせいも、ほんが\nだいすきだよ！",
      speaker: "ヨンデミー先生",
    },
  },
  {
    imgSrc: "/img/sensei/standing.svg",
    messageProps: {
      message:
        "ヨンデミーせんせいは、\nとっておきの ほんを\nたくさん しっているんだ！",
      speaker: "ヨンデミー先生",
    },
  },
  {
    imgSrc: "/img/sensei/standing.svg",
    messageProps: {
      message: `${nickname}さんに ぴったりの ほんを\nえらびたいな✨\nだから${nickname}さんのこと\nおしえてね😊`,
      speaker: "ヨンデミー先生",
    },
  },
  {
    selectQuestion: {
      messageProps: {
        message: `どっちにする？\nくわしめ にすると、\nさらに ぴったりな ほんを\nおすすめできるよ💪`,
        speaker: "ヨンデミー先生",
      },
      imgSrc: "/img/sensei/standing.svg",
      buttons: ylCheckTypeButtons,
    },
  },
  {
    imgSrc: "/img/sensei/standing.svg",
    messageProps: {
      message: "わかった！\nそれじゃあ、いっしょに\nおはなししよう！",
      speaker: "ヨンデミー先生",
    },
  },
];

export const confirmNameButtons = (
  toNext: () => void,
  toPrev: () => void
): ButtonProps[] => [
  {
    label: "うん！",
    onClick: () => toNext(),
  },
  {
    label: "やっぱりなおす",
    onClick: () => toPrev(),
  },
];

export const bookLikeabiltyButtons = (
  handleClick: (bookLikeability: BookLikeability) => void
): ButtonProps[] => [
  {
    label: "とてもすき",
    onClick: () => {
      handleClick(BookLikeability.VeryLike);
    },
  },
  {
    label: "すき",
    onClick: () => {
      handleClick(BookLikeability.Like);
    },
  },
  {
    label: "すきではない",
    onClick: () => {
      handleClick(BookLikeability.NotLike);
    },
  },
  {
    label: "にがて",
    onClick: () => {
      handleClick(BookLikeability.VeryNotLike);
    },
  },
];

export const ylCheckTypeButtons = (
  handleClick: (ylCheckType: YLCheckType) => void
): ButtonProps[] => [
  {
    label: "😙みじかめがいい",
    onClick: () => {
      handleClick("Simple");
    },
  },
  {
    label: "🧐くわしめがいい",
    onClick: () => {
      handleClick("Detail");
    },
  },
];

export const assetImages = [
  "/img/world_view_onboarding/bright-table.png",
  "/img/world_view_onboarding/bright-bookshelf.png",
  "/img/message-box-top.png",
  "/img/message-box-bottom.png",
];
