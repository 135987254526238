import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { Select } from "~/component/atoms/select";
import { Row } from "~/component/atoms/layout/Row";
import { Text } from "~/component/atoms/text/Text";
import { IconButton } from "~/component/molecules/button/IconButton";
import { PageTitle } from "~/component/molecules/text/PageTitle";
import { RegisterMyLibraryGuide } from "../RegisterMyLibraryGuide";
import { BookBuyLinkList } from "../BookListViewer";
import { BookListViewerItemProps } from "../BookListViewer/BookListViewerItem";
import { NotifyTextToast } from "~/component/organisms/toasts/NotifyTextToast";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { RecommendFrequency } from "~/generated/graphql";
import frequencyList from "../const";
import { BottomNavbar } from "~/component/molecules/BottomNavbar";
import { Stack } from "~/component/atoms/layout/Stack";
import { Icon } from "~/component/atoms/icon";
import { theme } from "~/component/theme";
import { config } from "../../../../config";
import { BookshelfTab } from "../BookshelfTab";
import { BookshelfType } from "../container";

export type BookListViewerTemplateProps = {
  currentCount: number;
  items: BookListViewerItemProps[];
  itemTotal: number;
  itemsPerPage: number;
  onPageChange: (currentCount: number) => void;
  handleBack: () => void;
  recommendFrequency?: RecommendFrequency;
  onChangeRecommendFrequency: (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => void;
  openToastChangeRFreq: boolean;
  setOpenToastChangeRFreq: (b: boolean) => void;
  myLibraryName?: string;
  currentUserId: number;
  changeCurrentTab: (value: BookshelfType) => void;
  suitableYl?: number;
};

export const BookListViewerTemplate: FC<BookListViewerTemplateProps> = ({
  currentCount,
  items,
  itemTotal,
  itemsPerPage,
  onPageChange,
  handleBack,
  recommendFrequency,
  openToastChangeRFreq,
  setOpenToastChangeRFreq,
  myLibraryName,
  currentUserId,
  changeCurrentTab,
  suitableYl,
}) => {
  const history = useHistory();
  const { currentUser } = React.useContext(CurrentUserContext);
  return (
    <>
      <NavWrapper>
        <IconButton
          onClick={handleBack}
          color={"pri"}
          name={"arrowLeft"}
          fontSize={"XXXL"}
          disabled={false}
        />
        <PageTitle>ほんだな</PageTitle>
        <div></div>
      </NavWrapper>
      <TopWrapper>
        {currentUser?.children.get && currentUser?.children.get.length > 0 && (
          <Select
            defaultValue={currentUserId}
            onChange={(event) =>
              history.push(`/booklist/${event.target.value}`)
            }
          >
            {currentUser.children.get.map((child) => {
              return (
                <option value={child.id} key={child.id}>
                  {child.nickname}
                </option>
              );
            })}
          </Select>
        )}
        <BookshelfTab value="recommend" onChange={changeCurrentTab} />
        <PrintingContainer>
          {recommendFrequency && (
            <_RecommendFrequencyWrapper>
              <Text fontSize="XS" lineHeight="MD" fontColor="tex.t400" keepAll>
                おすすめ冊数
              </Text>
              <Row
                columnGap="0"
                alignItems="baseline"
                justifyContent="flex-start"
              >
                <Text
                  fontSize="XL"
                  lineHeight="MD"
                  fontColor="tex.t700"
                  bold
                  keepAll
                >
                  {frequencyList[recommendFrequency].bookListMax}
                </Text>
                <Text
                  fontSize="XXS"
                  lineHeight="MD"
                  fontColor="tex.t400"
                  keepAll
                >
                  冊
                </Text>
              </Row>
              <_HorizontalLine />
              <_HorizontalLine />
              <Text fontSize="XS" lineHeight="MD" fontColor="tex.t400" keepAll>
                次の選書まであと
              </Text>
              <Row
                columnGap="0"
                alignItems="baseline"
                justifyContent="flex-start"
              >
                <Text
                  fontSize="XL"
                  lineHeight="MD"
                  fontColor="tex.t700"
                  bold
                  keepAll
                >
                  {itemTotal - frequencyList[recommendFrequency].border > 0
                    ? itemTotal - frequencyList[recommendFrequency].border
                    : 0}
                </Text>
                <Text
                  fontSize="XS"
                  lineHeight="MD"
                  fontColor="tex.t400"
                  keepAll
                >
                  冊読もう
                </Text>
              </Row>
              <_HorizontalLine />
              <_HorizontalLine />
              <div
                onClick={() =>
                  history.push(`/edit_recommend_frequency/${currentUserId}`)
                }
                style={{ cursor: "pointer" }}
              >
                <Text fontSize="XS" lineHeight="MD" fontColor="tex.t400">
                  現在のコース
                </Text>
              </div>
              <_SelectCourseWrapper
                justifyContent="space-between"
                onClick={() =>
                  history.push(`/edit_recommend_frequency/${currentUserId}`)
                }
              >
                <Text
                  fontSize="MD"
                  lineHeight="MD"
                  fontColor="tex.t400"
                  keepAll
                >
                  {frequencyList[recommendFrequency].name + "コース"}
                </Text>
                <Icon
                  name="chevronRight"
                  color="sec"
                  style={{ width: "12px", height: "12px" }}
                />
              </_SelectCourseWrapper>
              <_HorizontalLine />
              <_HorizontalLine />
              {suitableYl && (
                <>
                  <Text
                    fontSize="XS"
                    lineHeight="MD"
                    fontColor="tex.t400"
                    keepAll
                  >
                    適切なYL
                  </Text>
                  <Row
                    columnGap="0"
                    alignItems="baseline"
                    justifyContent="flex-start"
                  >
                    <Text
                      fontSize="XL"
                      lineHeight="MD"
                      fontColor="tex.t700"
                      bold
                      keepAll
                    >
                      {suitableYl}
                    </Text>
                  </Row>
                  <_HorizontalLine />
                  <_HorizontalLine />
                </>
              )}
            </_RecommendFrequencyWrapper>
          )}
          <Stack rowGap="8px">
            <Text fontSize="XS" fontColor="tex.t300" lineHeight="MD">
              YL（ヨンデミーレベル）が合っていないとき、新しいジャンルを試したいとき、選書を調整する診断を受けることができます！
            </Text>
            <Row justifyContent="flex-start" columnGap="8px">
              <_RequestAnchor
                href={
                  config.USER_APP +
                  `set_user/${currentUserId}?path=/book_select_adjustment?entry_page=parent_book_list_viewer`
                }
                rel="noopener noreferer"
                target="_blank"
              >
                <Icon
                  name="commentDots"
                  style={{ color: theme.colors.primitive.blue.b400 }}
                />
                <Text
                  fontColor="sec.s400"
                  fontSize="SM"
                  lineHeight="MD"
                  style={{ textDecoration: "underline" }}
                >
                  選書を調整する
                </Text>
              </_RequestAnchor>
            </Row>
          </Stack>
        </PrintingContainer>
        {!myLibraryName && <RegisterMyLibraryGuide />}
        <BookBuyLinkList
          currentCount={currentCount}
          items={items}
          itemTotal={itemTotal}
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
          renderEmptyComponent={EmptyComponent}
        />
      </TopWrapper>
      <NotifyTextToast
        isOpen={openToastChangeRFreq}
        setIsOpen={setOpenToastChangeRFreq}
        text={"おすすめ冊数が変更されました"}
      />
      <BottomNavbar
        currentTab={1}
        tabItems={[
          { page: 0, title: "ホーム", iconName: "home" },
          { page: 1, title: "本棚", iconName: "book" },
        ]}
        onChangeTab={(tabNumber: number) => {
          switch (tabNumber) {
            case 0:
              history.push("/");
              return;
            case 1:
              return;
            default:
              return;
          }
        }}
      />
    </>
  );
};

const EmptyDiv = styled.div`
  width: 100%;
  padding: 20px;
`;

const EmptyText = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t400};
`;

const EmptyComponent = (): JSX.Element => {
  return (
    <EmptyDiv>
      <EmptyText fontSize={"MD"} lineHeight={"MD"}>
        おすすめの本が届くのをお待ちください！
      </EmptyText>
    </EmptyDiv>
  );
};

const _RecommendFrequencyWrapper = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 16px;
  align-items: baseline;
  justify-items: space-between;
`;

const NavWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  display: flex;
  width: 100%;
  position: fixed;
  top: -1px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 5%;
  z-index: ${({ theme }) => theme.zIndex.navbar};
  border-bottom: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const TopWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 80px 16px;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: 80px 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    padding: 80px 20%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    padding: 80px 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;

const PrintingContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
  padding: 16px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 16px;
`;

const _HorizontalLine = styled.div`
  width: 100%;
  border-top: 1px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const _RequestAnchor = styled.a`
  border: none;
  background-color: inherit;
  display: flex;
  column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const _SelectCourseWrapper = styled(Row)`
  cursor: pointer;
`;
