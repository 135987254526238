import { css } from "styled-components";

export const Interpolation = {
  Border: css`
    border: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  `,
  BorderBottom: css`
    border-bottom: 2px solid ${({ theme }) => theme.colors.base.lightGray};
  `,
  Form: css<{ error: boolean }>`
    border: 2px solid
      ${({ theme, error }) =>
        error ? theme.colors.red.r300 : theme.colors.base.gray};
    background-color: ${({ theme }) => theme.colors.base.white};
    &:active,
    :focus {
      border: 2px solid ${({ theme }) => theme.colors.pri.p400};
      transition: border 100ms linear;
    }
    &:disabled {
      background-color: ${({ theme }) => theme.colors.base.lightGray};
      &:active,
      :focus {
        border: 2px solid ${({ theme }) => theme.colors.base.gray};
        transition: none;
      }
    }
    &::placeholder {
      color: ${({ theme }) => theme.colors.tex.t100};
    }
  `,
};

export const ButtonInterpolation = {
  Square: css`
    width: 84px;
    height: 84px;
  `,
  RoundedCorner: css`
    height: 32px;
    padding: 0;
  `,
};

export const VisuallyHiddenInterpolation = css`
  position: absolute;
  top: -1px;
  left: 0;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;
