import React, { VFC } from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { Text, TextProps } from "~/component/atoms/text/Text";
import { ColorTheme } from "~/component/theme";

export type EmojiButtonProps = TextProps & {
  onClick: (event: React.MouseEvent) => void;
  color: ColorTheme;
  disabled?: boolean;
  text: string;
  emoji: string;
};

export const EmojiButton: VFC<EmojiButtonProps> = ({
  onClick,
  disabled = false,
  color,
  text,
  emoji,
  ...textProps
}) => {
  return (
    <PrimaryButton
      color={color}
      onClick={onClick}
      disabled={disabled}
      noMarginTop
    >
      <Left>
        <Emoji>{emoji}</Emoji>
      </Left>
      <Right>
        <InnerText {...textProps}>{text}</InnerText>
      </Right>
    </PrimaryButton>
  );
};

const Left = styled.div`
  width: 25%;
  padding-left: 16px;
`;

const Right = styled.div`
  width: 75%;
  padding-right: 16px;
`;

const InnerText = styled(Text)`
  color: ${({ theme }) => theme.colors.base.white};
  text-align: left;
`;

const Emoji = styled.p`
  font-size: ${({ theme }) => theme.fontSize.XXL};
`;
