import * as React from "react";
import Header from "../organisms/common/header";
import { MiddleWrapper } from "./wrapper";
import { PageTitle } from "../atoms/heading/page_title";
import { ContentsContainer, WhiteBox, FirstHeadContainer } from "./box";
import { PlainText } from "../atoms/text";
import { BreadCrumb, Crumb } from "../atoms/bread_crumb";
import { ContentsTitleCard } from "../organisms/common/contents_title_card";

export const NewsTemplate: React.VFC = () => {
  return (
    <>
      <Header />
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <BreadCrumb>
              <Crumb.FirstList>
                <Crumb.Link to={"/"}>トップ</Crumb.Link>
              </Crumb.FirstList>
              <Crumb.List>
                <Crumb.Para>お知らせ</Crumb.Para>
              </Crumb.List>
            </BreadCrumb>
            <PageTitle>お知らせ</PageTitle>
          </FirstHeadContainer>
          <WhiteBox>
            <PlainText>
              ヨンデミーに関して更新やお知らせなどを掲載致します。
            </PlainText>
          </WhiteBox>
        </ContentsContainer>
        <ContentsContainer>
          <ContentsTitleCard
            title={"決済がクレジットカードでできるようになりました"}
          >
            これまでは料金の支払いが銀行振り込みのみでしたが、クレジットカードでもお支払いいただけるようになりました。
          </ContentsTitleCard>
        </ContentsContainer>
      </MiddleWrapper>
    </>
  );
};
