import styled from "styled-components";

export const TextAnchor = styled.a`
  font-size: 14px;
  margin: 0;
  color: ${(props) => props.theme.colors.tex.t700};
  opacity: 0.9;
  text-decoration: underline;
  transition: all 0.1s;
  &:hover, &:active, &:focus {
    text-decoration: none;
    color: ${(props) => props.theme.colors.tex.t700};
    opacity: 1;
  }
`;