import * as React from "react";
import styled from "styled-components";
import { WhiteBox } from "../../templates/box";

type ContentsTitleCardProps = {
  title: string;
  children: React.ReactNode;
  className?: string;
};

export const ContentsTitleCard: React.VFC<ContentsTitleCardProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <WhiteBox className={className}>
      <Upper>{title}</Upper>
      <Downer>{children}</Downer>
    </WhiteBox>
  );
};

const Upper = styled.div`
  width: 100%;
  color: ${(props) => props.theme.colors.pri.p500};
  border-bottom: 0.5px solid ${(props) => props.theme.colors.tex.t100};
  font-size: 17px;
  line-height: 1.4;
  font-weight: bold;
  padding-bottom: 14px;
  margin-bottom: 18px;
`;

const Downer = styled.div`
  width: 100%;
`;
