import * as React from "react";
import styled from "styled-components";
import { ProgressBar } from "~/component/atoms/ProgressBar";

type Props = {
  progress: number;
  onBack?: () => void;
};

export const SurveyHeader: React.FC<Props> = ({ progress, onBack }) => {
  return (
    <_Container>
      {onBack && <BackArrow onClick={onBack} />}

      <ProgressBar progress={progress} />
    </_Container>
  );
};

const _Container = styled.div`
  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;

  height: 24px;

  .header-progress-bar {
    flex-grow: 1;
  }
`;

const _BackArrowButton = styled.button`
  border: none;
  background-color: transparent;
`;

type BackArrowProps = Omit<JSX.IntrinsicElements["button"], "ref">;

const BackArrow: React.FC<BackArrowProps> = (props) => (
  <_BackArrowButton {...props}>
    <svg
      width="26"
      height="30"
      viewBox="0 0 26 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1261_1674)">
        <path
          d="M14.9442 26.0801L13.6558 27.3808C13.1103 27.9316 12.2281 27.9316 11.6884 27.3808L0.406222 15.9961C-0.139314 15.4453 -0.139314 14.5547 0.406222 14.0098L11.6884 2.61913C12.2339 2.06834 13.1161 2.06834 13.6558 2.61913L14.9442 3.91991C15.4955 4.47655 15.4839 5.38475 14.921 5.92967L7.92766 12.6562H24.6071C25.379 12.6562 26 13.2832 26 14.0625V15.9375C26 16.7168 25.379 17.3437 24.6071 17.3437H7.92766L14.921 24.0703C15.4897 24.6152 15.5013 25.5234 14.9442 26.0801Z"
          fill="#0068C9"
        />
      </g>
      <defs>
        <clipPath id="clip0_1261_1674">
          <rect width="26" height="30" fill="white" />
        </clipPath>
      </defs>
    </svg>
  </_BackArrowButton>
);
