import { yupResolver } from "@hookform/resolvers/yup";
import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import * as yup from "yup";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { DeprecatedInput } from "~/component/atoms/deprecatedInput";
import { Row } from "~/component/atoms/layout/Row";
import { Stack } from "~/component/atoms/layout/Stack";
import { Text } from "~/component/atoms/text/Text";
import { PortalFooter } from "~/component/organisms/common/footer";
import { SurveyHeader } from "~/component/organisms/survey/SurveyHeader";
import { NicknameMaxLength } from "../../../../util/constant";
import {
  Validation_RequireCharType,
  Validation_RestrictCharType,
  Validation_StringLengthBySegmenter,
} from "../../../../util/validation";
import { SignupResponse } from "./container";
import { ErrorMessage } from "~/component/molecules/text/ErrorMessage";
import { TextButton } from "~/component/molecules/button/TextButton";
import { CustomerSupportContactTemplate } from "../CustomerSupportContact";

type FormValues = {
  password: string;
  nickname: string;
};

type Props = {
  email: string;
  onSubmit: (values: {
    nickname: string;
    password: string;
  }) => Promise<SignupResponse>;
};

const resolver = yupResolver(
  yup.object().shape({
    nickname: yup
      .string()
      .required("ニックネームを入力してください")
      .test(
        "characters",
        `${NicknameMaxLength}文字以下で入力してください`,
        (string) => {
          if (!string) {
            return false;
          }
          return Validation_StringLengthBySegmenter(
            string,
            1,
            NicknameMaxLength
          );
        }
      ),
    password: yup
      .string()
      .required("パスワードを入力してください")
      .max(32, "パスワードは32文字以下で入力してください")
      .min(8, "パスワードは8文字以上で入力してください")
      .test(
        "Validation_RestrictCharType",
        "英数字、アンダースコア以外の文字を含めないでください",
        (string) => {
          if (!string) {
            return false;
          }
          return Validation_RestrictCharType(string, true, true, true, true);
        }
      )
      .test(
        "Validation_RequireCharType",
        "小文字、大文字、数字をすべて含む文字列にしてください",
        (string) => {
          if (!string) {
            return false;
          }
          return Validation_RequireCharType(string, true, true, true);
        }
      ),
  })
);

export const Signup: React.FC<Props> = ({ email, onSubmit }: Props) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm<FormValues>({
    defaultValues: {
      nickname: "",
      password: "",
    },
    resolver,
    mode: "onBlur",
  });

  const [submitting, setSubmitting] = React.useState<boolean>(false);
  const [showPassword, setShowPassword] = React.useState<boolean>(false);
  const [showError, setShowError] = React.useState<boolean>(false);

  const [isShowCSPage, setIsShowCSPage] = React.useState<boolean>(false);

  return (
    <>
      {isShowCSPage ? (
        <CustomerSupportContactTemplate
          entryPage="signup"
          onBack={() => setIsShowCSPage(false)}
        />
      ) : (
        <_Wrapper>
          <Stack rowGap="32px">
            <SurveyHeader progress={0} />
            <_MainTitle>保護者さまの情報</_MainTitle>
            <_FormWrapper>
              <Stack rowGap="32px">
                <Stack rowGap="12px">
                  <Stack rowGap="0">
                    <_ItemName>保護者さまメールアドレス</_ItemName>
                  </Stack>
                  <Text fontSize="SM" lineHeight="EQ">
                    {email}
                  </Text>
                </Stack>
                <Stack rowGap="12px">
                  <Stack rowGap="0">
                    <_ItemName>保護者さまのニックネーム</_ItemName>
                  </Stack>
                  <Controller
                    control={control}
                    name="nickname"
                    render={({ field }) => {
                      return (
                        <DeprecatedInput size="lg" type="text" {...field} />
                      );
                    }}
                  />
                  {errors.nickname && (
                    <ErrorMessage>{errors.nickname.message}</ErrorMessage>
                  )}
                </Stack>
                <Stack rowGap="12px">
                  <Stack rowGap="4px">
                    <_ItemName>パスワード</_ItemName>
                    <_SubText>
                      ・8文字以上にしてください
                      <br />
                      ・アルファベット小文字、大文字、数字をそれぞれ1文字以上含んでください
                    </_SubText>
                  </Stack>
                  <Controller
                    control={control}
                    name="password"
                    render={({ field }) => {
                      return (
                        <DeprecatedInput
                          size="lg"
                          type={showPassword ? "text" : "password"}
                          autoComplete="new-password"
                          {...field}
                        />
                      );
                    }}
                  />
                  {errors.password && (
                    <ErrorMessage>{errors.password.message}</ErrorMessage>
                  )}
                  <_ShowPassword>
                    <div
                      className="toggle"
                      onClick={() => setShowPassword((s) => !s)}
                    >
                      {showPassword
                        ? "パスワードを非表示にする"
                        : "パスワードを表示する"}
                    </div>
                  </_ShowPassword>
                </Stack>
                <PrimaryButton
                  disabled={submitting || !isValid}
                  noMarginTop
                  onClick={handleSubmit((values) => {
                    setSubmitting(true);
                    onSubmit({ ...values })
                      .then((res) => {
                        res !== "success" && setShowError(true);
                      })
                      .finally(() => {
                        setSubmitting(false);
                      });
                  })}
                >
                  <_ButtonText>アカウントを作成</_ButtonText>
                </PrimaryButton>
                {showError && (
                  <Stack rowGap="16px" alignItems="flex-start">
                    <ErrorMessage alignItems="flex-start">
                      エラーが発生しました。時間をおいてもう一度お試しください。
                      <br />
                      何度か試してもログインできない場合はお手数ですがお問い合わせください。
                    </ErrorMessage>
                    <div style={{ marginLeft: "20px" }}>
                      <TextButton
                        fontSize="XS"
                        lineHeight="EQ"
                        text="お問い合わせはこちら"
                        fontColor="semantic.secondary.main"
                        onClick={() => {
                          setShowError(false);
                          setIsShowCSPage(true);
                        }}
                      />
                    </div>
                  </Stack>
                )}
              </Stack>
            </_FormWrapper>
          </Stack>
        </_Wrapper>
      )}

      <PortalFooter />
    </>
  );
};

const _ButtonText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.LG};
`;

const _ItemName = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
`;

const _ShowPassword = styled.div`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;
  display: flex;
  justify-content: flex-end;

  div.toggle {
    cursor: pointer;
    user-select: none;
    &:hover {
      opacity: 0.5;
    }
  }
`;

const _SubText = styled.div`
  font-weight: 400;
  color: ${({ theme }) => theme.colors.tex.t300};
  font-size: ${({ theme }) => theme.fontSize.XXS};
  line-height: 170%;
`;

const _FormWrapper = styled.div`
  padding: 32px 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;
`;

const _Wrapper = styled.div`
  padding: 30px 20px;
  margin: 0 auto;
  min-width: 300px;
  max-width: 560px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 300px;
`;

const _MainTitle = styled(Row)`
  font-weight: 700;
  color: ${({ theme }) => theme.colors.tex.t800};
  font-size: ${({ theme }) => theme.fontSize.XXL};
  line-height: 170%;
`;
