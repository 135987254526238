import React from "react";
import { useHistory, useParams } from "react-router";
import { RecommendDetailTemplate } from "~/component/templates/RecommendDetail";
import { LoadingPage } from "~/component/templates/loading";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { useGetRecommendByIdQuery } from "~/generated/graphql";
import { useCopyToClipboard } from "~/store/bookViewer/useCopyToClipboard";

export const RecommendDetail: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { copyToClipboardPopup, handleCopyToClipboard } = useCopyToClipboard();
  const { currentUser } = React.useContext(CurrentUserContext);
  const history = useHistory();

  const { loading, error, data } = useGetRecommendByIdQuery({
    variables: {
      id: Number(id),
    },
    fetchPolicy: "no-cache",
  });

  const handleRemoveRecommend = React.useCallback(() => {
    const book = data?.getRecommendByID.book;
    if (!book) {
      return;
    }
    const searchParams = new URLSearchParams({
      title: book.title ? book.title : "",
      ISBN: book.isbn,
    });

    history.push(`/remove_recommend/${id}?${searchParams.toString()}`);
  }, [data, history, id]);

  if (loading) {
    return <LoadingPage />;
  }
  if (error) {
    return <>Error! {error.message}</>;
  }
  if (!data) {
    return null;
  }

  return (
    <>
      {copyToClipboardPopup}
      <RecommendDetailTemplate
        bookInfoProps={{ book: data.getRecommendByID.book }}
        handleRemoveRecommend={handleRemoveRecommend}
        handleCopyISBN={handleCopyToClipboard}
        recommendComment={data.getRecommendByID.recommendComment}
        myLibraryName={currentUser?.myLibrary?.libraryName}
      />
    </>
  );
};
