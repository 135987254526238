import * as React from "react";
import { useHistory } from "react-router";
import {
  CalilLibrary,
  useSearchLibraryQuery,
  useCreateMyLibraryMutation,
} from "~/generated/graphql";
import { RegisterMyLibrary } from ".";
import { ConfirmSkipRegisterModalProps } from "./ConfirmSkipRegisterModal";

export const RegisterMyLibraryContainer: React.FC = () => {
  const [pref, setPref] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");

  const { refetch: onSearchLibrary } = useSearchLibraryQuery({
    skip: !pref || !city,
    variables: { pref: pref, city: city },
    onCompleted: (res) => {
      setLibraryList(res.searchLibrary);
    },
  });

  const [libraryList, setLibraryList] = React.useState<CalilLibrary[]>([]);

  const [create, { loading }] = useCreateMyLibraryMutation();

  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const confirmSkipRegisterModalProps: ConfirmSkipRegisterModalProps & {
    setIsModalOpen: (isModalOpen: boolean) => void;
  } = {
    onBackToRegister: () => confirmSkipRegisterModalProps.setIsModalOpen(false),
    onSkip: () => history.push("/register_first_user"),
    isOpen: isModalOpen,
    setIsModalOpen: setIsModalOpen,
  };

  return (
    <RegisterMyLibrary
      pref={pref}
      setPref={setPref}
      city={city}
      setCity={setCity}
      libraryList={libraryList}
      onClearLibraryList={() => setLibraryList([])}
      onSearchLibrary={() => {
        onSearchLibrary();
      }}
      onSelectLibrary={(params) => {
        return new Promise((resolve) => {
          create({
            variables: {
              calilLibraryAreaID: params.calilLibraryAreaID,
              calilLibraryKey: params.calilLibraryKey,
            },
          })
            .then((res) => {
              if (res.errors) {
                resolve(false);
              } else {
                resolve(true);
              }
            })
            .catch(() => resolve(false));
        });
      }}
      onNext={() => history.push("/register_first_user")}
      loading={loading}
      submitButtonText={"えらんだ図書館を登録して次へ"}
      confirmSkipRegisterModalProps={confirmSkipRegisterModalProps}
    />
  );
};
