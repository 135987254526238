import type { Browser } from "detect-browser";
import { detect } from "detect-browser";

export const getBrowser = (): Browser | null => {
  const browser = detect();
  if (browser && browser.type === "browser") {
    return browser.name;
  }
  return null;
};

export const isMobile = (): boolean => {
  const ua = navigator.userAgent;
  return (
    // NOTE: iPadのsafariでMacOS判定されてPWAチュートリアルが出ないのを避けるためにmaxTouchPointsの判定を入れている
    /Android|webOS|iPhone|iPad|iPod/i.test(ua) || navigator.maxTouchPoints > 0
  );
};
