import React from "react";
import styled from "styled-components";
import { createPortal } from "react-dom";
import runes2 from "runes2";

export type MessageProps = {
  message: string;
  speaker?: string;
  showTriangle?: boolean;
  onNext?: () => void;
};

export const Message: React.FC<MessageProps> = ({
  message,
  speaker,
  showTriangle = true,
  onNext,
}) => {
  const [_message, setMessage] = React.useState<string>("");
  const [count, setCount] = React.useState<number>(0);
  const [completed, setCompleted] = React.useState<boolean>(false);
  const [showOverLay, setShowOverLay] = React.useState<boolean>(true);
  const splitMessage = runes2(message);

  React.useEffect(() => {
    setCount(0);
    setMessage("");
    setCompleted(false);
    setShowOverLay(true);
  }, [message]);

  React.useEffect(() => {
    if (count >= splitMessage.length) {
      return;
    }
    const TimeoutID = window.setTimeout(() => {
      const newMessage = _message + splitMessage[count];
      setMessage(newMessage);
      if (splitMessage.length === count + 1) {
        setCompleted(true);
        if (!onNext) {
          setShowOverLay(false);
        }
      }
      setCount((count) => count + 1);
    }, 50);

    return () => clearTimeout(TimeoutID);
  }, [count, message, splitMessage, _message, onNext]);

  const handleClickOverlay = React.useCallback(() => {
    if (completed && !onNext) {
      return;
    }
    if (!completed) {
      setCount(splitMessage.length);
      setMessage(message);
      setCompleted(true);
      if (!onNext) {
        setShowOverLay(false);
      }
    } else {
      onNext && onNext();
    }
  }, [completed, splitMessage, message, onNext]);

  return (
    <_Wrapper noMargin={!speaker}>
      <_MiddleBox>
        <_SideImg src="/img/message-box-top.png" />
        <_MessageWrapper>
          <_MessageText>{_message}</_MessageText>
          {showTriangle && (
            <_TriangleWrapper>
              <_Triangle />
            </_TriangleWrapper>
          )}
        </_MessageWrapper>
        <_SideImg src="/img/message-box-bottom.png" />
      </_MiddleBox>
      {speaker && <_SpeakerWrapper>{speaker}</_SpeakerWrapper>}
      {showOverLay &&
        createPortal(<_Overlay onClick={handleClickOverlay} />, document.body)}
    </_Wrapper>
  );
};

const _Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const _Wrapper = styled.div<{ noMargin: boolean }>`
  margin: ${({ noMargin }) => (noMargin ? "0" : "5px 10px 0 10px")};
  width: 340px;
  height: 209px;
  position: relative;
`;

const _SpeakerWrapper = styled.div`
  position: absolute;
  top: 0px;
  left: -10px;

  background-color: ${({ theme }) => theme.colors.sec.s400};
  font-size: ${({ theme }) => theme.fontSize.SM};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.base.white};

  padding: 8px 16px;
  border-radius: 20px;
  transform: rotate(-5.49deg);
`;

const _SideImg = styled.img`
  position: relative;
  width: 339px;
  height: auto;
`;

const _MiddleBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const _MessageWrapper = styled.div`
  width: 329px;
  height: 184px;
  margin: -12px 0;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 20px;

  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 8px;
`;

const _MessageText = styled.p`
  font-family: "M PLUS 1 Code";
  height: 100%;
  color: black;
  font-size: ${({ theme }) => theme.fontSize.MD};
  font-weight: 700;
  line-height: 160%;
  white-space: pre-wrap;
`;

const _TriangleWrapper = styled.div`
  animation: 2s ease-in-out fadeInOut infinite;

  @keyframes fadeInOut {
    from {
      opacity: 0;
    }
    10% {
      opacity: 1;
    }
    90% {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }
`;

const _Triangle: React.FC = () => (
  <svg
    width="17"
    height="18"
    viewBox="0 0 17 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.1564 7.28687C16.447 8.06437 16.447 9.93563 15.1564 10.7131L3.78207 17.5656C2.44909 18.3687 0.749997 17.4087 0.749997 15.8525L0.749998 2.14751C0.749998 0.591308 2.44909 -0.368683 3.78208 0.434377L15.1564 7.28687Z"
      fill="#F3AE36"
    />
  </svg>
);
