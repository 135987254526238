import *  as React from "react";
import styled from "styled-components";

export const ButtonContainer: React.VFC<{children: React.ReactNode, isShown?: boolean}> = ({ children, isShown=true }) => {
  return (
    <Con isShown={isShown}>
      <Inner>
        {children}
      </Inner>
    </Con>
  );
};

type ConProps = {
  isShown: boolean;
}

const Con = styled.div<ConProps>`
  display: ${({ isShown }) => isShown ? "flex" : "none" };
  justify-content: center;
  margin: 20px 0;
`;

const Inner = styled.div`
  width: 100%;
  @media screen and (min-width: 750px) {
    width: 50%;
  }
`;

export const AnnotationBelowButton = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row-reverse;
  margin-top: 12px;
`;