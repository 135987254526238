import React from "react";
import { QuitEnqueteTemplate } from "../component/templates/quit_enquete";

export const QuitEnquete: React.VFC = () => {
  React.useEffect(() => {
    const scriptChat = document.createElement("script");
    scriptChat.type = "text/javascript";

    const text = document.createTextNode(`
      (function(w, d) { w.CollectId = "60f3f4e794689f24ee2c23c0"; var h = d.head || d.getElementsByTagName("head")[0]; var s = d.createElement("script"); s.setAttribute("type", "text/javascript"); s.async=true; s.setAttribute("src", "https://collectcdn.com/launcher.js"); h.appendChild(s); })(window, document);
    `);

    scriptChat.appendChild(text);
    const head = document.getElementsByTagName("head")[0] as HTMLElement;
    head.appendChild(scriptChat);
  }, []);
  return <QuitEnqueteTemplate />;
};
