import * as React from "react";
import { useParams } from "react-router-dom";
import { Signup } from ".";
import { useVerifyTokenQuery, useSignupMutation } from "~/generated/graphql";
import { Loading } from "~/component/atoms/progress_indicator/loading";
import { Auth0Context } from "~/contexts/Auth0Context";

export type SignupResponse = "success" | "signupError" | "loginError";

export const SignupContainer: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const [signup] = useSignupMutation();
  const { login } = React.useContext(Auth0Context);

  const handleSubmit = React.useCallback(
    ({ nickname, password }: { nickname: string; password: string }) => {
      return new Promise<SignupResponse>((resolve) => {
        signup({
          variables: { token: token, nickname: nickname, password: password },
        })
          .then((res) => {
            if (res.errors) {
              resolve("signupError");
            } else if (res.data?.signup.email) {
              login(res.data.signup.email, password, {
                onLoginError: () => {
                  // TODO: 必要に応じてエラー処理
                  resolve("loginError");
                },
                onLoginSuccess: () => {
                  resolve("success");
                },
                redirectUri: `${window.location.origin}/line_tutorial`,
              });
            }
          })
          .catch(() => {
            resolve("signupError");
          });
      });
    },
    [token, signup, login]
  );

  const { data, loading, error } = useVerifyTokenQuery({
    variables: { token: token },
  });

  // Tokenがない場合は意図しないrequestなのでnullを返す
  if (!token) {
    return null;
  }

  if (loading || !data) return <Loading />;

  if (error)
    return <div>エラーが発生しました。再度お申し込みを行ってください。</div>;
  else if (!data?.verifyToken) {
    return <div>エラーが発生しました。再度お申し込みを行ってください。</div>;
  }

  return <Signup email={data.verifyToken} onSubmit={handleSubmit} />;
};
