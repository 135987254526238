import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { theme } from "~/component/theme";
import {
  CONTENT_HEIGHT,
  CONTENT_WIDTH,
  useScale,
} from "~/pages/WorldViewOnboarding/ui/constants";
import { BookColor } from "../..";

type Color = {
  bookColor: string;
  backgroundColor: string;
};

const ColorTable: Record<BookColor, Color> = {
  green: {
    bookColor: theme.colors.green.g400,
    backgroundColor: theme.colors.green.g300,
  },
  pri: {
    bookColor: theme.colors.pri.p400,
    backgroundColor: theme.colors.pri.p300,
  },
  red: {
    bookColor: theme.colors.red.r400,
    backgroundColor: theme.colors.red.r300,
  },
  sky: {
    bookColor: theme.colors.sky.s400,
    backgroundColor: theme.colors.sky.s300,
  },
  sec: {
    bookColor: theme.colors.sec.s400,
    backgroundColor: theme.colors.sec.s300,
  },
  violet: {
    bookColor: theme.colors.violet.v400,
    backgroundColor: theme.colors.violet.v300,
  },
};

export type BookInformationProps = {
  color: BookColor;
  bookNumber: number;
  onShowDetail: () => void;
};

export const BookInformation: React.FC<BookInformationProps> = ({
  color,
  bookNumber,
  onShowDetail,
}) => {
  const scale = useScale();
  return (
    <_BackgroundImg>
      <_Wrapper>
        <_Container style={{ scale: `${scale}` }}>
          <_BookInformationWrapper>
            <_HeaderWrapper>
              <_HeaderBackground
                backgroundColor={ColorTable[color].backgroundColor}
              />
              <_HeaderImg bookColor={ColorTable[color].bookColor}>
                ？
              </_HeaderImg>
              <_Ribbon />
            </_HeaderWrapper>
            <_ContentWrapper>
              <_BookNumber>
                <span className="large">{bookNumber}</span>
                さつ目/{Object.keys(ColorTable).length}
              </_BookNumber>
              <_MessageWrapper>
                <_MessageText>
                  ？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？？
                  <br />
                  ？？？？？？？？？？？？？？？？？？？？？？？？？？
                  <br />
                  ？？？？？
                  <br />
                  ？？？？？？？？？？
                </_MessageText>
                <_SenseiText>ヨンデミー先生より</_SenseiText>
                <_RibbonWrapper>
                  <_RibbonIcon />
                </_RibbonWrapper>
              </_MessageWrapper>
              {/*TODO: 偽のボタンに変更する(onClickをpropsから外す) */}
              <PrimaryButton onClick={() => onShowDetail()} noMarginTop>
                くわしく見る
              </PrimaryButton>
            </_ContentWrapper>
          </_BookInformationWrapper>
        </_Container>
      </_Wrapper>
    </_BackgroundImg>
  );
};

const _BackgroundImg = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-image: url("/img/world_view_onboarding/bright-bookshelf.png");
  background-repeat: repeat-x;
  background-size: contain;
`;

const _Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.base.overlay};
`;

const _Container = styled.div`
  position: relative;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  margin: 0 auto;
  overflow: scroll;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  transform-origin: top center;
`;

const _BookInformationWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  width: 100%;
  border-radius: 16px;
  background-image: linear-gradient(
      0deg,
      transparent calc(100% - 1px),
      #f0f0f0 calc(100% - 1px)
    ),
    linear-gradient(
      90deg,
      transparent calc(100% - 1px),
      #f0f0f0 calc(100% - 1px)
    );
  background-size: 25px 25px;
  background-repeat: repeat;
  background-position: center center;
  padding-bottom: 54px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const _HeaderWrapper = styled.div`
  position: relative;
  overflow: hidden;
  height: 160px;
  table-layout: fixed;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
`;

const _HeaderBackground = styled.div<{
  backgroundColor: string;
}>`
  position: absolute;
  background-color: ${({ backgroundColor }) => backgroundColor};
  filter: blur(16px);
  height: 200%;
  width: 100%;
  left: 0;
  right: 0;
  top: -50%;
`;

const _HeaderImg = styled.div<{ bookColor: string }>`
  position: absolute;
  background-color: ${({ bookColor }) => bookColor};
  left: calc(50% - 54px);
  top: 10%;
  height: 128px;
  aspect-ratio: 1 / 1.126;
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);

  display: flex;
  justify-content: center;
  align-items: center;

  color: ${({ theme }) => theme.colors.base.white};
  font-weight: 700;
  font-size: 70px;
`;

const _ContentWrapper = styled.div`
  padding: 0 20px;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
`;

const _MessageWrapper = styled.div`
  position: relative;
  background-color: ${({ theme }) => theme.colors.base.white};
  border: 3px solid;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  padding: 16px 12px;
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  margin-bottom: 8px;
`;

const _MessageText = styled.p`
  color: ${({ theme }) => theme.colors.tex.t500};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  font-weight: 700;
  text-align: center;
  word-wrap: break-word;
`;

const _SenseiText = styled.p`
  color: ${({ theme }) => theme.colors.tex.t500};
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;
  text-align: center;
`;

const _RibbonIcon: React.FC = () => (
  <svg
    width="22"
    height="12"
    viewBox="0 0 22 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9322 0.486119L12.95 2.20666C12.3514 1.87526 11.6784 1.70141 10.9943 1.70141C10.3101 1.70141 9.63716 1.87526 9.03861 2.20666L6.05633 0.486119C5.51217 0.169254 4.89403 0.00156934 4.26434 1.0962e-05C3.63465 -0.00154742 3.01569 0.163078 2.46997 0.477245C1.92425 0.791413 1.47109 1.24399 1.15623 1.78932C0.841382 2.33464 0.675993 2.9534 0.67676 3.58309V8.41209C0.677472 9.04139 0.843576 9.65945 1.15844 10.2043C1.4733 10.7492 1.92586 11.2017 2.47074 11.5166C3.01563 11.8314 3.6337 11.9975 4.26301 11.9982C4.89232 11.9989 5.51075 11.8342 6.05633 11.5205L9.49741 9.53617C10.4638 9.93324 11.5477 9.93324 12.5141 9.53617L15.9552 11.5205C16.4999 11.835 17.1178 12.0004 17.7468 12C18.3758 11.9996 18.9936 11.8334 19.5379 11.5182C20.0822 11.203 20.5338 10.75 20.8472 10.2047C21.1607 9.65936 21.3249 9.04106 21.3233 8.41209V3.58309C21.3229 2.95509 21.1572 2.33825 20.8429 1.79454C20.5287 1.25082 20.0769 0.799369 19.5329 0.485541C18.9889 0.171714 18.372 0.00656112 17.744 0.00666182C17.1159 0.00676252 16.4991 0.172117 15.9552 0.486119"
      fill="#EB5757"
    />
  </svg>
);

const _RibbonWrapper = styled.div`
  position: absolute;
  bottom: -8px;
  left: calc(50% - 10px);
`;

const _Ribbon = styled.div`
  position: absolute;
  right: 25px;
  top: -25px;
  height: 120px;
  width: 20px;
  background-color: ${({ theme }) => theme.colors.red.r400};
  transform: rotate(-45deg);
`;

const _BookNumber = styled.p`
  color: ${({ theme }) => theme.colors.red.r400};
  font-size: ${({ theme }) => theme.fontSize.MD};

  .large {
    font-size: ${({ theme }) => theme.fontSize.XXXL};
    font-weight: 700;
  }
`;
