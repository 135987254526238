import React, { FC } from "react";
import styled from "styled-components";

export type BookshelfImageProps = {
  url?: string;
  alt: string;
};

export const BookshelfImage: FC<BookshelfImageProps> = ({
  url,
  alt,
}: BookshelfImageProps) => {
  if (!url) {
    return (
      <NoImage>
        <NoImageText>{alt}</NoImageText>
        <NoImageContainer>
          {alt.length % 4 === 0 ? ( // sleepingよりreadingの比率を多くしたい
            <NoImgStyleSleeping
              src={"/img/sensei/sticker_sleeping.png"}
              alt={alt}
            />
          ) : (
            <NoImgStyleReading
              src={"/img/sensei/sticker_reading.png"}
              alt={alt}
            />
          )}
        </NoImageContainer>
      </NoImage>
    );
  }
  return <ImgStyle src={url} alt={alt} />;
};

const ImgStyle = styled.img`
  width: 100%;
  max-width: 180px;
  @media screen and (max-width: 400px) {
    max-width: 140px;
  }
  height: 100%;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  object-fit: contain;
`;

const NoImageText = styled.div`
  line-height: 1.4;
  color: ${(props) => props.theme.colors.semantic.layout.backgroundBox};
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  word-break: break-all;
  @media screen and (max-width: 400px) {
    font-size: 12px;
  }
`;

const NoImgStyleReading = styled.img`
  width: 40%;
  max-width: 60px;
  height: 100%;
`;

const NoImgStyleSleeping = styled.img`
  width: 52%;
  max-width: 80px;
  height: 100%;
`;

const NoImageContainer = styled.div`
  padding-top: 15px;
  display: flex;
  justify-content: flex-end;
`;

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  max-width: 240px;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  height: 100%;
  min-height: 130px;
  padding: 10px;
  background-color: ${(props) => props.theme.colors.semantic.secondary.main};
`;
