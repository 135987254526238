import React from "react";
import styled from "styled-components";
import { CONTENT_HEIGHT, CONTENT_WIDTH, useScale } from "../../ui/constants";
import { OpenBoxAnimation } from "./OpenBox";
import { SearchBookAnimation } from "./SearchBook";

export type BookSelectionAnimationProps = {
  onNext: () => void;
};

type Flow = "SearchBook" | "OpenBox";

export const BookSelectionAnimation: React.FC<BookSelectionAnimationProps> = ({
  onNext,
}) => {
  const scale = useScale();
  const [flow, setFlow] = React.useState<Flow>("SearchBook");
  return (
    <_Wrapper>
      <_Container style={{ scale: `${scale}` }}>
        {flow === "SearchBook" && (
          <SearchBookAnimation onNext={() => setFlow("OpenBox")} />
        )}
        {flow === "OpenBox" && <OpenBoxAnimation onNext={onNext} />}
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-color: #292f24;
`;

const _Container = styled.div`
  margin: 0 auto;
  position: relative;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  transform-origin: top center;
`;
