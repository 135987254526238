import styled from "styled-components";

export const OrangeSpanText = styled.span`
  color: ${(props) => props.theme.colors.pri.p500};
`;

export const RedSpanText = styled.span`
  color: ${(props) => props.theme.colors.red.r300};
`;

export const GraySpanText = styled.span`
  color: ${(props) => props.theme.colors.tex.t300};
`;