import React from "react";
import styled, { css } from "styled-components";
import { Icon, IconType } from "~/component/atoms/icon";
import { IconTextButton } from "~/component/molecules/button/IconTextButton";
import { ColorTheme } from "~/component/theme";

export type ToastProps = {
  icon: IconType;
  children: React.ReactNode;
  stickPlace: "top" | "bottom";
  onClick?: (event: React.MouseEvent) => void;
  buttonLabel?: string;
  color?: ColorTheme;
};

export const Toast: React.VFC<
  Omit<ToastProps, "isOpen" | "setIsOpen"> & {
    handleClick?: (e: React.MouseEvent) => void;
  }
> = ({ icon, children, stickPlace, onClick, buttonLabel, color = "pri" }) => {
  return (
    <Container stickPlace={stickPlace} color={color}>
      <Box>
        <Icon name={icon} style={{ marginRight: "12px" }} color={color} />
        {children}
      </Box>
      {onClick && (
        <Box>
          <IconTextButton
            iconPlace={"pre"}
            color={color}
            text={buttonLabel}
            onClick={onClick}
            disabled={false}
            name={"close"}
            fontSize={"XS"}
            lineHeight={"EQ"}
          />
        </Box>
      )}
    </Container>
  );
};

const Container = styled.div<Pick<ToastProps, "stickPlace" | "color">>`
  width: 90%;
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  background-color: ${({ theme }) => theme.colors.base.white};
  box-shadow: 0px 2px 8px 2px rgba(0, 0, 0, 0.1);
  border: 3px solid;
  border-color: ${({ color, theme }) => {
    const { base, pri, sec, green, red, sky, violet } = theme.colors;
    switch (color) {
      case "pri":
        return pri.p400;
      case "sec":
        return sec.s400;
      case "gray":
        return base.gray;
      case "green":
        return green.g400;
      case "red":
        return red.r400;
      case "sky":
        return sky.s400;
      case "violet":
        return violet.v400;
      case "white":
        return base.white;
      default:
        return pri.p400;
    }
  }};
  padding: 10px;
  max-width: 500px;
  position: fixed;
  z-index: ${({ theme }) => theme.zIndex.popup};
  display: flex;
  flex-direction: column;
  gap: 12px;
  ${({ stickPlace }) => {
    if (stickPlace === "top") {
      return css`
        top: 5%;
        left: 50%;
        transform: translate(-50%, 5%);
        -webkit-transform: translate(-50%, 5%);
      `;
    } else {
      return css`
        bottom: 2%;
        left: 50%;
        transform: translate(-50%, -2%);
        -webkit-transform: translate(-50%, -2%);
      `;
    }
  }}
`;

const Box = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;
