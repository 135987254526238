import React from "react";
import styled from "styled-components";
import { DetailedFirstRecommendEnqueteDifficulty } from "~/generated/graphql";
import { ReadAllSentence } from "../../ui/ReadAllSentence";
import { SelectDifficulty } from "../../ui/SelectDifficulty";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";
import { PriorPassageContent } from "../const";
import { PageType } from "..";

export type PriorCheckProps = {
  passageContent: PriorPassageContent;
  pageType: PageType;
  setPageType: (pageType: PageType) => void;
  handleBack: () => void;
  onSelectDifficulty: (
    difficulty: DetailedFirstRecommendEnqueteDifficulty
  ) => void;
};

export const PriorCheck: React.FC<PriorCheckProps> = ({
  passageContent,
  pageType,
  setPageType,
  handleBack,
  onSelectDifficulty,
}) => {
  const content = React.useMemo<React.ReactNode>(() => {
    switch (pageType) {
      case "readAll":
        return (
          <_LibraryWrapper>
            <YLEnqueteBackNavbar withBlur={true} onBackButton={handleBack} />
            <ReadAllSentence
              sentence={passageContent}
              onNext={() => {
                setPageType("selectDifficulty");
              }}
            />
          </_LibraryWrapper>
        );
      case "selectDifficulty":
        return (
          <_LibraryWrapper>
            <YLEnqueteBackNavbar
              withBlur={true}
              onBackButton={() => setPageType("readAll")}
            />
            <SelectDifficulty
              sentence={passageContent}
              onSelect={onSelectDifficulty}
            />
          </_LibraryWrapper>
        );
    }
  }, [handleBack, onSelectDifficulty, pageType, setPageType, passageContent]);

  return <>{content}</>;
};

const _LibraryWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  overflow: hidden;
  background-image: url("/img/world_view_onboarding/bright-bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  position: relative;

  ::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.45);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;
