import React from "react";

export const ExclamationTriangle: React.FC = () => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4232 11.0257C14.8772 11.8126 14.3074 12.7962 13.4007 12.7962H1.59791C0.689444 12.7962 0.122237 11.8111 0.575324 11.0257L6.47677 0.793522C6.93097 0.00626394 8.06855 0.00769041 8.52194 0.793522L14.4232 11.0257ZM7.49935 8.91021C6.87452 8.91021 6.36799 9.41674 6.36799 10.0416C6.36799 10.6664 6.87452 11.1729 7.49935 11.1729C8.12419 11.1729 8.63072 10.6664 8.63072 10.0416C8.63072 9.41674 8.12419 8.91021 7.49935 8.91021ZM6.42522 4.84354L6.60767 8.18845C6.6162 8.34497 6.74562 8.4675 6.90236 8.4675H8.09635C8.25309 8.4675 8.38251 8.34497 8.39104 8.18845L8.57349 4.84354C8.58271 4.67447 8.4481 4.53232 8.27879 4.53232H6.71989C6.55058 4.53232 6.416 4.67447 6.42522 4.84354Z"
      fill="white"
    />
  </svg>
);
