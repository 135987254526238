import React from "react";
import styled, { useTheme } from "styled-components";
import { Icon } from "~/component/atoms/icon";
import { Text } from "~/component/atoms/text/Text";

export type ErrorMessageProps = {
  children: React.ReactNode;
  alignItems?: "flex-start" | "center";
  style?: React.CSSProperties;
};

export const ErrorMessage: React.FC<ErrorMessageProps> = ({
  children,
  style,
  alignItems = "center",
}) => {
  const theme = useTheme();
  return (
    <Wrapper style={style} alignItems={alignItems}>
      <IconContainer>
        <Icon
          style={{
            color: theme.colors.red.r400,
            width: theme.fontSize.MD,
            height: theme.fontSize.MD,
          }}
          name={"exclamationCircle"}
        />
      </IconContainer>
      <Message fontSize={"XS"} lineHeight={"MD"}>
        {children}
      </Message>
    </Wrapper>
  );
};

const Wrapper = styled.div<{
  alignItems: "flex-start" | "center";
}>`
  width: 100%;
  display: flex;
  align-items: ${({ alignItems }) => alignItems};
`;

const IconContainer = styled.div`
  height: ${({ theme }) => theme.fontSize.MD};
  margin-right: 8px;
  display: flex;
  align-items: center;
`;

const Message = styled(Text)`
  color: ${({ theme }) => theme.colors.red.r400};
`;
