import React from "react";
import styled from "styled-components";
import { CONTENT_HEIGHT, CONTENT_WIDTH } from "../constants";
import { Message, MessageProps } from "../Message";

export type ConversationProps = {
  imgSrc: string;
  messageProps?: MessageProps;
  dialog?: React.ReactNode;
  overlay?: boolean;
  scale?: number;
  onNext?: () => void;
};

export const Conversation: React.FC<ConversationProps> = ({
  imgSrc,
  messageProps,
  dialog,
  overlay,
  scale = 1.0,
  onNext,
}) => {
  return (
    <_Wrapper pointer={Boolean(onNext)}>
      {overlay && <_Overlay />}
      <_Container style={{ scale: `${scale}` }}>
        <_Images overlay={overlay}>
          <_SenseiImage src={imgSrc} height="auto" />
          <_TableImage src="/img/world_view_onboarding/bright-table.png" />
        </_Images>
        {dialog}
        {!dialog && messageProps && (
          <_MessageWrapper>
            <Message
              message={messageProps.message}
              speaker={messageProps.speaker}
              onNext={onNext}
            />
          </_MessageWrapper>
        )}
      </_Container>
    </_Wrapper>
  );
};

const _Overlay = styled.div`
  position: absolute;
  min-width: 100vw;
  min-height: 100vh;
  top: 0;
  background-color: ${({ theme }) => theme.colors.base.overlay};
`;

const _MessageWrapper = styled.div`
  position: absolute;
  bottom: 15%;
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _Wrapper = styled.div<{ pointer: boolean }>`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  background-image: url("/img/world_view_onboarding/bright-bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  display: flex;
  justify-content: center;
  align-items: center;
  :hover {
    cursor: ${({ pointer }) => (pointer ? "pointer" : "default")};
  }
`;

const _Container = styled.div`
  position: relative;

  transform-origin: top 50%;

  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`;

const _Images = styled.div<{ overlay?: boolean }>`
  filter: ${({ overlay }) => (overlay ? "brightness(0.5)" : "none")};

  text-align: center;
`;

const _SenseiImage = styled.img`
  width: calc(${CONTENT_WIDTH}px * 0.66);
  margin-bottom: -30%;
`;

const _TableImage = styled.img`
  width: ${CONTENT_WIDTH}px;
`;
