import styled, {
  css,
  DefaultTheme,
  FlattenInterpolation,
  ThemeProps,
} from "styled-components";
import { AllColorTheme } from "~/component/color";
import { FontLineHeight, FontSize } from "~/component/theme";
import get from "lodash/get";
export type TextProps = {
  /**
   * 太字か
   */
  bold?: boolean;
  /**
   * フォントサイズ
   */
  fontSize: FontSize;
  /**
   * 行の高さ
   */
  lineHeight: FontLineHeight;
  /**
   * 改行しないようにする場合true
   */
  keepAll?: boolean;
  /**
   * テキストのカラー
   */
  fontColor?: AllColorTheme;
  /*
   * 改行コードで改行するとき等に追加
   */
  whiteSpace?: "pre-wrap" | "pre-line";
  /**
   * className
   * deprecated: styleはoverrideできないようにする
   */
  className?: string;
};

export const TextInterpolation = (
  props: TextProps
): FlattenInterpolation<ThemeProps<DefaultTheme>> => {
  return css`
    font-family: inherit;
    font-weight: ${props.bold ? "bold" : "normal"};
    word-break: ${props.keepAll ? "keep-all" : "normal"};
    white-space: ${props.whiteSpace ? props.whiteSpace : "unset"};
    word-wrap: break-word;
    ${({ theme }) => {
      return css`
        color: ${props.fontColor
          ? get(theme.colors, props.fontColor)
          : theme.colors.tex.t700};
        font-size: ${theme.fontSize[props.fontSize]};
        line-height: ${theme.lineHeight[props.lineHeight]};
      `;
    }}
  `;
};

export const Text = styled.p<TextProps>`
  ${TextInterpolation}
`;
