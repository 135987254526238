import styled, { css } from "styled-components";

type RowGap =
  | "0"
  | "4px"
  | "6px"
  | "8px"
  | "10px"
  | "12px"
  | "16px"
  | "20px"
  | "24px"
  | "28px"
  | "32px"
  | "36px"
  | "48px";

type AlignItems = "center" | "flex-start" | "flex-end";

export const Stack = styled.div<{ rowGap?: RowGap; alignItems?: AlignItems }>`
  display: flex;
  row-gap: ${({ rowGap = "16px" }) => rowGap};
  flex-direction: column;
  ${({ alignItems }) =>
    alignItems &&
    css`
      align-items: ${alignItems || "center"};
    `};
`;
