import * as React from "react";
import { MiddleWrapper as BaseMiddleWrapper } from "~/component/templates/wrapper";
import { ContentsContainer } from "./box";
import styled from "styled-components";

type RegisteredTemplateProps = {
  ContentCon: React.ReactNode;
};

export const OnboardingTemplate: React.VFC<RegisteredTemplateProps> = ({
  ContentCon,
}) => {
  return (
    <OnboardingTemplateCon>
      <MiddleWrapper>
        <ContentsContainer>{ContentCon}</ContentsContainer>
      </MiddleWrapper>
    </OnboardingTemplateCon>
  );
};

const OnboardingTemplateCon = styled.div`
  min-height: 100vh;
  padding-bottom: 50px;
`;

const MiddleWrapper = styled(BaseMiddleWrapper)`
  min-height: 100vh;
  padding-bottom: 50px;
`;
