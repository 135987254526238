import React, { ReactNode } from "react";
import styled, { css } from "styled-components";
import { ColorTheme } from "~/component/theme";
import { useSnd } from "~/utils/hooks/useSnd";

export type FlatButtonProps = {
  /**
   * ボタンの役割を示す.`Outline`はサブで用いることが多い.
   */
  variant: "Normal" | "Outline";
  /**
   * ボタンの形状
   */
  shape: "Normal" | "RoundedCorner";
  /**
   * 配色
   */
  color: ColorTheme;
  /**
   * moleculesで内側の要素は制御
   */
  children: React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
  borderWidth?: "1px";
  trackingLabel?: ReactNode;
};

export const FlatButton: React.FC<FlatButtonProps> = ({
  variant,
  shape,
  color,
  children,
  style,
  className,
  onClick,
  borderWidth,
  disabled = false,
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("TAP");
    onClick(event);
  };

  return (
    <ButtonCon disabled={disabled}>
      <StyledButton
        variant={variant}
        shape={shape}
        color={color}
        style={style}
        onClick={handleClick}
        disabled={disabled}
        className={className}
        borderWidth={borderWidth}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const ButtonCon = styled.div<Pick<FlatButtonProps, "disabled">>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  width: 100%;
`;

const StyledButton = styled.button<
  Pick<
    FlatButtonProps,
    "variant" | "shape" | "color" | "disabled" | "borderWidth"
  >
>`
  ${({ theme, variant, shape, color, disabled, borderWidth }) => {
    return css`
      width: 100%;
      border-radius: ${theme.borderRadius.XS};
      ${shape === "RoundedCorner" && RoundedCornerInterpolation}
      padding: 4px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      cursor: ${!disabled && "pointer"};
      font-family: inherit;
      font-weight: bold;
      text-align: center;
      text-decoration: none;
      line-height: 160%;
      background-color: ${() => {
        if (variant === "Outline") {
          return theme.colors.base.white;
        } else {
          switch (color) {
            case "pri":
              return theme.colors.pri.p400;
            case "sec":
              return theme.colors.sec.s400;
            case "violet":
              return theme.colors.violet.v400;
            case "red":
              return theme.colors.red.r400;
            case "green":
              return theme.colors.green.g400;
            case "sky":
              return theme.colors.sky.s400;
            case "gray":
              return theme.colors.base.lightGray;
            case "white":
              return theme.colors.base.white;
            default:
              return theme.colors.pri.p400;
          }
        }
      }};
      border-width: ${variant === "Outline"
        ? borderWidth
          ? borderWidth
          : "2px"
        : "0"};
      border-style: solid;
      border-color: ${() => {
        switch (color) {
          case "pri":
            return theme.colors.pri.p500;
          case "sec":
            return theme.colors.sec.s500;
          case "violet":
            return theme.colors.violet.v500;
          case "green":
            return theme.colors.green.g500;
          case "red":
            return theme.colors.red.r500;
          case "sky":
            return theme.colors.sky.s500;
          case "gray":
            return theme.colors.base.gray;
          case "white":
            return theme.colors.base.white;
          default:
            return theme.colors.pri.p500;
        }
      }};
      &:hover,
      &:focus {
        outline: none;
        background-color: ${() => {
          if (variant === "Outline") {
            return theme.colors.base.white;
          } else {
            switch (color) {
              case "pri":
                return disabled ? theme.colors.pri.p400 : theme.colors.pri.p500;
              case "sec":
                return disabled ? theme.colors.sec.s400 : theme.colors.sec.s500;
              case "violet":
                return disabled
                  ? theme.colors.violet.v400
                  : theme.colors.violet.v500;
              case "green":
                return disabled
                  ? theme.colors.green.g400
                  : theme.colors.green.g500;
              case "red":
                return disabled ? theme.colors.red.r400 : theme.colors.red.r500;
              case "sky":
                return disabled ? theme.colors.sky.s400 : theme.colors.sky.s500;
              case "gray":
                return disabled
                  ? theme.colors.base.lightGray
                  : theme.colors.base.gray;
              case "white":
                return disabled
                  ? theme.colors.base.lightGray
                  : theme.colors.base.white;
              default:
                return disabled ? theme.colors.pri.p400 : theme.colors.pri.p500;
            }
          }
        }};
        &:active {
          outline: none;
          background-color: ${() => {
            if (!disabled) {
              switch (color) {
                case "pri":
                  return theme.colors.pri.p500;
                case "sec":
                  return theme.colors.sec.s500;
                case "violet":
                  return theme.colors.violet.v500;
                case "green":
                  return theme.colors.green.g500;
                case "red":
                  return theme.colors.red.r500;
                case "sky":
                  return theme.colors.sky.s500;
                case "gray":
                  return theme.colors.base.gray;
                case "white":
                  return theme.colors.base.white;
                default:
                  return theme.colors.pri.p500;
              }
            }
          }};
          ${variant === "Outline" && OutlineInterpolation}
        }
      }
    `;
  }}
`;

const RoundedCornerInterpolation = css`
  height: 36px;
  border-radius: 18px;
`;

const OutlineInterpolation = css`
  & p,
  & svg {
    color: ${({ theme }) =>
      theme.colors.base
        .white} !important; //背景色がついた時に子要素の色が白になるように
  }
`;
