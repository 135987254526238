import React from "react";
import styled from "styled-components";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";
import { LoadingIndicator } from "../loadingIndicator";

const SubmitInput = styled.button`
  display: block;
  height: 56px;
  padding: 20px 0;
  border: none;
  background-color: ${({ theme }) => theme.colors.pri.p500};
  opacity: 0.9;
  border-radius: 28px;
  color: white;
  text-align: center;
  font-size: 15px;
  outline: none;

  &:disabled {
    background-color: ${({ theme }) => theme.colors.pri.p200};
    pointer-events: none;
  }

  &:hover,
  &:active,
  &:focus {
    opacity: 1;
    cursor: pointer;
  }
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 485px;
  }
`;

type SubmitButtonProps = {
  text: string;
  loading?: boolean;
  disabled?: boolean;
};

export const SubmitButton = ({
  text,
  loading = false,
  disabled = false,
}: SubmitButtonProps): React.ReactElement => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick: () => {
      return;
    },
    label: text,
  });
  return (
    <SubmitInput
      type="submit"
      disabled={disabled || loading}
      onClick={onClickWithTracking}
    >
      {loading ? <LoadingIndicator size="s" /> : text}
    </SubmitInput>
  );
};
