import React, { VFC } from "react";
import styled, { useTheme } from "styled-components";
import { FlatButton } from "~/component/atoms/button/FlatButton";
import { Icon } from "~/component/atoms/icon";
import { Text, TextProps } from "~/component/atoms/text/Text";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/component/atoms/button/tag_changer";
import { ColorTheme } from "~/component/theme";

export type PrimaryFlatButtonProps = TextProps & {
  /**
   * クリック時の関数
   */
  onClick: () => void;
  /**
   * クリック可能か
   */
  disabled?: boolean;
  /**
   * ボタンの配色
   */
  color: ColorTheme;
  /**
   * ボタン内のテキスト
   */
  text: string;
  /**
   * 矢印の有無
   */
  withArrow?: boolean;
  /**
   * className
   */
  className?: string;
  /**
   * スタイル
   */
  style?: React.CSSProperties;
};

export const PrimaryFlatButton: VFC<PrimaryFlatButtonProps> = ({
  onClick,
  disabled,
  color,
  text,
  withArrow = false,
  className,
  style,
  ...textProps
}) => {
  const theme = useTheme();
  const { fontSize } = textProps;
  return (
    <FlatButton
      color={color}
      onClick={onClick}
      shape={"Normal"}
      variant={"Normal"}
      disabled={disabled}
      className={className}
      style={style}
    >
      <LeftSide></LeftSide>
      <Center>
        <InnerText {...textProps}>{text}</InnerText>
      </Center>
      <RightSide>
        {withArrow && (
          <Icon
            name={"arrowRight"}
            style={{
              color: theme.colors.base.white,
              height: theme.fontSize[fontSize],
              width: "auto",
            }}
          />
        )}
      </RightSide>
    </FlatButton>
  );
};

export type PrimaryFlatLinkProps = Omit<PrimaryFlatButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof PrimaryFlatButtonProps>;

export const PrimaryFlatLink: VFC<PrimaryFlatLinkProps> = ({
  color,
  text,
  disabled,
  withArrow,
  fontSize,
  lineHeight,
  bold,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <PrimaryFlatButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Linkerize>
);

export type PrimaryFlatAnchorProps = Omit<PrimaryFlatButtonProps, "onClick"> &
  Omit<AnchorizeProps, keyof PrimaryFlatButtonProps>;

export const PrimaryFlatAnchor: VFC<PrimaryFlatAnchorProps> = ({
  color,
  text,
  disabled,
  withArrow,
  fontSize,
  lineHeight,
  bold,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <PrimaryFlatButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Anchorize>
);

const LeftSide = styled.div`
  width: 10%;
`;

const Center = styled.div`
  width: 80%;
`;

const RightSide = styled.div`
  width: 10%;
`;

const InnerText = styled(Text)`
  color: ${({ theme }) => theme.colors.base.white};
  text-align: center;
`;
