import * as React from "react";
import { Route, Switch } from "react-router-dom";

import { Login } from "./login";
import { CheckMail } from "./pages/check_mail";
import { CertificateFailed } from "./pages/error/certificate_failed";
import { Forget } from "./pages/forget";
import { LoginForLine } from "./pages/login_for_line";
import { Onboarding } from "./pages/onboarding";
import { SignupContainer } from "./pages/Signup/container";
import { CustomerSupportContact } from "./pages/CustomerSupportContact/container";

export const NotAuthorized = (): React.ReactElement => {
  return (
    <>
      <Switch>
        <Route exact path="/forget" component={Forget} />
        <Route exact path="/check_mail" component={CheckMail} />
        <Route
          exact
          path="/error/certificate_failed"
          component={CertificateFailed}
        />
        <Route exact path="/login_for_line" component={LoginForLine} />
        <Route exact path="/onboarding" component={Onboarding} />

        <Route exact path="/signup/:token" component={SignupContainer} />
        <Route exact path="/cs_contact" component={CustomerSupportContact} />
        <Login />
      </Switch>
    </>
  );
};
