import React from "react";
import styled from "styled-components";
import { BaseModal } from "~/component/atoms/wrappers/BaseModal";
import { Text } from "~/component/atoms/text/Text";
import { Stack } from "~/component/atoms/layout/Stack";
import { PrimaryButton } from "~/component/atoms/button/primary_button";

export type ConfirmSkipRegisterModalProps = {
  onBackToRegister: () => void;
  isOpen: boolean;
  onSkip: () => void;
};

export const ConfirmSkipRegisterModal: React.FC<
  ConfirmSkipRegisterModalProps
> = ({ onBackToRegister, isOpen, onSkip }) => {
  return (
    <BaseModal open={isOpen} onClose={onBackToRegister}>
      <_ModalWrapper>
        <Stack rowGap="12px">
          <Text fontColor="tex.t700" fontSize="MD" lineHeight="MD" bold>
            本当にスキップしますか？
          </Text>
          <Text fontColor="tex.t700" fontSize="SM" lineHeight="MD">
            図書館登録すると、その
            <span style={{ fontWeight: "bold" }}>
              図書館にある本を中心におすすめ
            </span>
            します！
            <br />
            そのため、本を入手しやすくなります。
            <br />
            <br /> お子さんが読みたい！と思った
            <span style={{ fontWeight: "bold" }}>タイミングを逃さない</span>
            ためにも、本の用意をスムーズに行うことが重要です。
            <br />
            <br />
            ぜひ、図書館を登録してください。
          </Text>
          <div style={{ marginBottom: "5px" }}>
            <PrimaryButton color="pri" onClick={onBackToRegister} noMarginTop>
              <Text
                lineHeight="EQ"
                fontSize="LG"
                bold
                fontColor="semantic.layout.backgroundBox"
              >
                図書館を登録する
              </Text>
            </PrimaryButton>
          </div>

          <SkipButtonContainer onClick={onSkip}>
            <Text lineHeight="MD" fontSize="XS">
              スキップする
            </Text>
          </SkipButtonContainer>
        </Stack>
      </_ModalWrapper>
    </BaseModal>
  );
};

const _ModalWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};

  border-radius: ${({ theme }) => theme.borderRadius.MD};
`;

const SkipButtonContainer = styled.div`
  width: 72px;
  height: 19px;
  color: gray;
  text-align: center;
  margin: 0 auto;
  cursor: pointer;
`;
