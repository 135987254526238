import { SampleDifficultyPerception } from "~/generated/graphql";
import { PassageYL, YLAndSentenceOrder, passageYLs } from "./const";

type SentenceLevel = "easy" | "middle" | "difficult";

export type SentenceLevelRecord = Record<SentenceLevel, PassageYL>;

const roundToPassageYL = (value: number): PassageYL => {
  const passageYL = passageYLs.find((yl) => {
    return yl + 5 > value && value >= yl;
  });
  if (!passageYL) {
    if (value < 15) {
      return 15;
    } else {
      return 50;
    }
  }

  return passageYL;
};

const getPerceptionRecord = (
  suitableYl: number
): Record<SampleDifficultyPerception, () => SentenceLevelRecord> => {
  return {
    EASY: () => {
      const easyLevel = Math.min(suitableYl, 40);
      const difficultLevel = Math.min(suitableYl + 20, 50);
      const middleLevel = (easyLevel + difficultLevel) / 2;
      return {
        easy: roundToPassageYL(easyLevel),
        middle: roundToPassageYL(middleLevel),
        difficult: roundToPassageYL(difficultLevel),
      };
    },
    DIFFICULT: () => {
      const easyLevel = Math.max(15, suitableYl - 20);
      const difficultLevel = Math.max(suitableYl, 25);
      const middleLevel = (easyLevel + difficultLevel) / 2;
      return {
        easy: roundToPassageYL(easyLevel),
        middle: roundToPassageYL(middleLevel),
        difficult: roundToPassageYL(difficultLevel),
      };
    },
  };
};

type GetYlReassesmentEnqueteProps = {
  ylAndSentenceOrderList: YLAndSentenceOrder[];
  sentenceLevelRecord: SentenceLevelRecord;
};

export const getDetailYLEnquete = (
  suitableYl: 25 | 30,
  currentDifficultyPerception: SampleDifficultyPerception
): GetYlReassesmentEnqueteProps => {
  const perceptionRecord = getPerceptionRecord(suitableYl);
  const sentenceLevelAndPassageYlRecord: SentenceLevelRecord =
    perceptionRecord[currentDifficultyPerception]();

  const ylAndSentenceOrderList: YLAndSentenceOrder[] = [
    { passageYL: sentenceLevelAndPassageYlRecord.easy, sentenceOrder: "first" },
    {
      passageYL: sentenceLevelAndPassageYlRecord.difficult,
      sentenceOrder: "first",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.easy,
      sentenceOrder: "second",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.middle,
      sentenceOrder: "first",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.difficult,
      sentenceOrder: "second",
    },
    {
      passageYL: sentenceLevelAndPassageYlRecord.middle,
      sentenceOrder: "second",
    },
  ];

  return {
    ylAndSentenceOrderList: ylAndSentenceOrderList,
    sentenceLevelRecord: sentenceLevelAndPassageYlRecord,
  };
};
