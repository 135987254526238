import React from "react";
import styled from "styled-components";
import { theme } from "~/component/theme";

export const Title = styled.h2`
  font-size: ${({ theme }) => theme.fontSize.XL};
  color: ${({ theme }) => theme.colors.tex.t800};
  font-weight: 700;
`;

export const SectionTitle = styled.h3`
  position: relative;
  padding-left: 16px;
  font-weight: 700;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.MD};
  margin: 0;
  line-height: 160%;

  &::before {
    position: absolute;
    content: "";
    top: 0;
    bottom: 0;
    left: 0;
    width: 6px;
    border-radius: 10px;
    border-color: ${({ theme }) => theme.colors.pri.p400};
    background-color: ${({ theme }) => theme.colors.pri.p400};
    margin-right: ${({ theme }) => theme.fontSize.XS};
  }
`;

const _CheckTextWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  align-items: center;
`;

const _CheckText = styled.h4`
  color: ${({ theme }) => theme.colors.tex.t600};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  font-weight: 700;
`;

const _Check: React.FC = () => (
  <svg
    width="25"
    height="19"
    viewBox="0 0 25 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.49112 18.4553L0.366116 10.3303C-0.122018 9.84216 -0.122018 9.05071 0.366116 8.56252L2.13384 6.79475C2.62198 6.30657 3.41348 6.30657 3.90161 6.79475L9.375 12.2681L21.0984 0.544751C21.5865 0.0566162 22.378 0.0566162 22.8662 0.544751L24.6339 2.31252C25.122 2.80066 25.122 3.59211 24.6339 4.0803L10.2589 18.4553C9.77071 18.9435 8.97925 18.9435 8.49112 18.4553Z"
      fill={theme.colors.pri.p400}
    />
  </svg>
);

type CheckTextProps = {
  children: React.ReactNode;
};

export const CheckText: React.FC<CheckTextProps> = ({ children }) => {
  return (
    <_CheckTextWrapper>
      <_Check />
      <_CheckText>{children}</_CheckText>
    </_CheckTextWrapper>
  );
};

export const PlainText = styled.p`
  font-size: ${({ theme }) => theme.fontSize.SM};
  color: ${({ theme }) => theme.colors.tex.t600};
  line-height: 200%;

  b {
    font-weight: 700;
  }

  .underline {
    font-weight: 700;
    text-decoration-line: underline;
  }

  .emphasized {
    font-weight: 700;
    color: ${({ theme }) => theme.colors.pri.p600};
  }
`;

export const AnnotationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  padding: 24px;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
`;

export const AnnotationTitle = styled.h5`
  font-size: ${({ theme }) => theme.fontSize.XS};
  color: ${({ theme }) => theme.colors.tex.t600};
  line-height: 160%;
  font-weight: 700;

  padding-bottom: 8px;
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.lightGray};
`;

export const AnnotationText = styled(PlainText)`
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;
`;

export const Img = styled.img`
  width: 100%;
`;
