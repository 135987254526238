import * as React from "react";
import styled, { css } from "styled-components";
import { Form as BootstrapFrom } from "react-bootstrap";
import { FInput } from "~/component/form_style";
import { useQuestion, Value } from "../useQuestion";
import { useAnswer } from "../useAnswer";

const { Control } = BootstrapFrom;

type Props = {
  item: Value;
  answer: ReturnType<typeof useAnswer>;
  index: number;
  question: ReturnType<typeof useQuestion>;
};

export const Form: React.FC<Props> = ({ item, answer, index, question }) => (
  <Content key={item.id}>
    {item.body && (
      <Text>
        {typeof item.body === "string"
          ? item.body
          : item.body(answer.answers[0].body[0].value)}
      </Text>
    )}
    <Annotation>{item.annotation ?? ""}</Annotation>
    <FormCon>
      {item.type === "text" && (
        <FInput
          type="text"
          value={answer?.currentAnswer?.body[index].value ?? ""}
          onChange={(e) => {
            const _answer = answer.currentAnswer
              ? {
                  ...answer.currentAnswer,
                  body: answer.currentAnswer.body.map((v) =>
                    v.id === item.id
                      ? {
                          ...v,
                          name: e.target.value,
                          value: e.target.value,
                        }
                      : v
                  ),
                }
              : {
                  questionId: question.current.id,
                  name: e.target.value,
                  body: [{ id: item.id, value: e.target.value }],
                };
            const pos = answer.answers.findIndex(
              (item) => item.questionId === question.current.id
            );
            answer.setAnswers((prev) => {
              if (pos === -1) {
                return [...prev, _answer];
              } else {
                prev[pos] = _answer;
                return [...prev];
              }
            });
          }}
          isError={false}
        />
      )}
      {item.type === "select" && (
        <StyledControl
          as="select"
          defaultValue={answer?.currentAnswer?.body[index]?.value ?? ""}
          onChange={(e) => {
            const _answer = answer.currentAnswer
              ? {
                  ...answer.currentAnswer,
                  body: answer.currentAnswer.body.find((v) => v.id === item.id)
                    ? answer.currentAnswer.body.map((v) =>
                        v.id === item.id
                          ? { id: item.id, value: e.target.value }
                          : v
                      )
                    : [
                        ...answer.currentAnswer.body,
                        { id: item.id, value: e.target.value },
                      ],
                }
              : {
                  questionId: question.current.id,
                  body: [{ id: item.id, value: e.target.value }],
                };
            const pos = answer.answers.findIndex(
              (item) => item.questionId === question.current.id
            );
            answer.setAnswers((prev) => {
              if (pos === -1) {
                return [...prev, _answer];
              } else {
                prev[pos] = _answer;
                return [...prev];
              }
            });
          }}
        >
          {item.options?.map((option) => (
            <option key={option} value={option}>
              {option}
            </option>
          ))}
        </StyledControl>
      )}
      {item.type === "radio" &&
        item.options?.map((option) => (
          <Radio key={option}>
            <Label>
              <input
                type="radio"
                value={option ?? ""}
                checked={false}
                onChange={(e) => {
                  const _answer = answer.currentAnswer
                    ? {
                        ...answer.currentAnswer,
                        body: answer.currentAnswer.body.map((v) =>
                          v.id === item.id ? { ...v, value: e.target.value } : v
                        ),
                      }
                    : {
                        questionId: question.current.id,
                        body: [{ id: item.id, value: e.target.value }],
                      };
                  const pos = answer.answers.findIndex(
                    (item) => item.questionId === question.current.id
                  );
                  answer.setAnswers((prev) => {
                    if (pos === -1) {
                      return [...prev, _answer];
                    } else {
                      prev[pos] = _answer;
                      return [...prev];
                    }
                  });
                }}
              />
              <div>
                <Circle
                  checked={answer.currentAnswer?.body[index]?.value === option}
                />
              </div>
              <Option>{option}</Option>
            </Label>
          </Radio>
        ))}
    </FormCon>
  </Content>
);

const Content = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  margin-top: 24px;
`;

const Text = styled.span`
  font-size: 14px;
  display: block;
  line-height: 1.4;
`;

const Annotation = styled.span`
  font-size: 12px;
  line-height: 1.4;
`;

const FormCon = styled.form`
  margin-top: 16px;
`;

const StyledControl = styled(Control)`
  width: 100%;
  padding: 12px 12px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-top: 8px;
`;

const Radio = styled.div`
  display: flex;
  align-items: center;
  margin-top: 8px;
`;

const Label = styled.label`
  width: 100%;
  display: flex;
  align-items: center;
`;

const Circle = styled.span<{ checked: boolean }>`
  position: relative;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-right: 12px;
  vertical-align: middle;
  background-color: inherit;
  border: 2px solid #666;
  border-radius: 24px;
  ${({ checked }) =>
    checked
      ? css`
          ::after {
            content: "";
            display: block;
            position: absolute;
            top: 3px;
            left: 3px;
            width: 10px;
            height: 10px;
            background-color: #666;
            border-radius: 12px;
          }
        `
      : ""}
`;

const Option = styled.span`
  display: inline-block;
  line-height: 1.4;
`;
