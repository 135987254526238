import * as React from "react";
import styled from "styled-components";
import { useAnswer } from "../useAnswer";
import { useQuestion } from "../useQuestion";
import { Result } from "./Result";
import { Form } from "./Form";

type Props = {
  question: ReturnType<typeof useQuestion>;
  answer: ReturnType<typeof useAnswer>;
};

export const Steps: React.VFC<Props> = ({ question, answer }) => (
  <>
    {question.current.id === "1" && (
      <Content>
        <Title>受講前アンケート</Title>
        <Description>
          お子さんの状況、保護者さまがヨンデミーに期待されるものを教えてください。
        </Description>
      </Content>
    )}
    {question.current.id !== "5" ? (
      question.current.question.map((item, index) => {
        return (
          <Form
            key={item.id}
            item={item}
            question={question}
            answer={answer}
            index={index}
          />
        );
      })
    ) : (
      <Result answer={answer.answers} question={question} />
    )}
  </>
);

const Content = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  margin-top: 24px;
`;

const Title = styled.h1`
  font-size: 24px;
  font-weight: bold;
  line-height: 1.4;
  color: ${({ theme }) => theme.colors.sec.s400};
`;

const Description = styled.p`
  font-size: 14px;
  line-height: 1.4;
`;
