import * as React from "react";
import Header from "~/component/organisms/common/header";
import { useGetFirstBookRecommendEnqueteResultQuery } from "~/generated/graphql";
import { EnqueteResult } from ".";
import { useApolloClient } from "@apollo/client";

type Props = {
  match: { params: { id: string } };
};

export const DiagnosticReportContainer: React.FC<Props> = ({
  match: {
    params: { id },
  },
}) => {
  const client = useApolloClient();
  const { data } = useGetFirstBookRecommendEnqueteResultQuery({
    client: client,
    variables: { userID: parseInt(id) },
    onError: (error) => {
      if (error.graphQLErrors.some((e) => e.extensions?.code === "Not Found")) {
        // TODO: エラー表示 or TOPに戻す
        console.error("result not found");
      }
    },
    onCompleted: () => {
      // getFirstBookRecommendEnqueteResultが存在した場合のみイベントを発生させる
      window.gtag("event", "view_recommend_report", {
        event_category: "report",
        event_label: "first",
        value: 1,
      });
    },
  });

  if (!data?.getFirstBookRecommendEnqueteResult) {
    return <></>;
  }

  return (
    <EnqueteResult
      header={<Header />}
      result={data.getFirstBookRecommendEnqueteResult}
      userId={parseInt(id)}
    />
  );
};
