import auth0 from "auth0-js";
import { config } from "config";

const auth0Domain = config.AUTH0_DOMAIN;
const auth0ClientId = config.AUTH0_CLIENT_ID;
const auth0Audience = config.AUTH0_AUDIENCE;

export const auth0RedirectUri = `${window.location.origin}`;

export const auth0WebAuth = new auth0.WebAuth({
  clientID: auth0ClientId,
  scope: "openid profile email",
  domain: auth0Domain,
  redirectUri: auth0RedirectUri,
  responseType: "token id_token",
  audience: auth0Audience,
});
export const Auth0IDPasswordLoginConnectionRealm =
  "Username-Password-Authentication";
