import React from "react";
import styled from "styled-components";
import { useAudio } from "~/utils/hooks/useAudio";

const ImgSrcList: string[] = [
  "/img/world_view_onboarding/book_selection/search1.jpg",
  "/img/world_view_onboarding/book_selection/search3.jpg",
  "/img/world_view_onboarding/book_selection/search5.jpg",
  "/img/world_view_onboarding/book_selection/search6.jpg",
  "/img/world_view_onboarding/book_selection/search2.jpg",
  "/img/world_view_onboarding/book_selection/search4.jpg",
];

export type SearchBookAnimationProps = {
  onNext: () => void;
};

export const SearchBookAnimation: React.FC<SearchBookAnimationProps> = ({
  onNext,
}) => {
  const [index, setIndex] = React.useState<number>(0);
  const [loaded, setLoaded] = React.useState<Record<string, boolean>>({});
  const { play } = useAudio({ preload: ["searchBook", "light"] });

  const imgSrc = React.useMemo(() => {
    return ImgSrcList[index];
  }, [index]);

  const allImagesLoaded =
    Object.keys(loaded).length > 0 &&
    Object.keys(loaded).length ===
      Object.values(loaded).filter((v) => v).length;

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    ImgSrcList.forEach((src) => {
      const img = document.createElement("img");
      img.onload = () => {
        setLoaded((l) => ({ ...l, [src]: true }));
      };
      img.src = src;
      setLoaded((l) => ({ ...l, [src]: false }));
      ref.current && ref.current.appendChild(img);
    });
  }, []);

  React.useEffect(() => {
    if (allImagesLoaded) {
      setTimeout(() => {
        if (index === ImgSrcList.length - 1) {
          onNext();
        } else {
          setIndex((i) => i + 1);
        }
      }, 700);
      play("searchBook");
    }
  }, [index, allImagesLoaded, play]);

  return <>{allImagesLoaded && <_SearchBookImg src={imgSrc} />}</>;
};

const _SearchBookImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`;
