import React from "react";
import { useHistory } from "react-router";
import { YondemyStory } from ".";

export const YondemyStoryPage: React.FC = () => {
  const history = useHistory();

  const onCompleted = () => {
    history.push("/");
  };

  return <YondemyStory onCompleted={onCompleted} />;
};
