import React from "react";
import { Toast, ToastProps } from "../Toast";

export type NotifyToastProps = Pick<
  ToastProps,
  "children" | "stickPlace" | "icon" | "color"
> & {
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
};

/**
 * NotifyTextToastの拡張版
 */
export const NotifyToast: React.FC<NotifyToastProps> = ({
  children,
  stickPlace,
  icon,
  isOpen,
  setIsOpen,
  color,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(false);
  };
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(setIsOpen.bind(null, false), 4000);  
    }
  }, [isOpen]);
  if (isOpen) {
    return (
      <Toast
        icon={icon}
        stickPlace={stickPlace}
        onClick={handleClick}
        buttonLabel={"とじる"}
        color={color}
      >
        {children}
      </Toast>
    );
  } else {
    return <></>;
  }
};
