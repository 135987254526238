import * as React from "react";
import { EnqueteForRecommendTemplate } from "../component/templates/enquete_for_recommend";

type EnqueteForRecommendProps = {
  match: { params: { id: number } };
};
export const EnqueteForRecommend: React.VFC<EnqueteForRecommendProps> = ({
  match,
}) => {
  return <EnqueteForRecommendTemplate userId={match.params.id} />;
};
