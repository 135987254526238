import styled, { css } from "styled-components";

type ColumnGap = "0" | "4px" | "8px" | "10px" | "16px";

type Props = {
  columnGap?: ColumnGap;
  justifyContent?:
    | "center"
    | "flex-start"
    | "flex-end"
    | "space-between"
    | "space-evenly";
  alignItems?: "baseline";
  flexWrap?: "wrap";
};

export const Row = styled.div<Props>`
  display: flex;
  flex-direction: row;
  width: 100%;
  column-gap: ${({ columnGap = "8px" }) => columnGap};
  ${({ justifyContent }) =>
    justifyContent &&
    css`
      justify-content: ${justifyContent};
    `};
  ${({ alignItems }) =>
    css`
      align-items: ${alignItems || "center"};
    `};

  ${({ flexWrap }) =>
    flexWrap &&
    css`
      flex-wrap: ${flexWrap};
    `};
`;
