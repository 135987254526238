import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Time: any;
};

export type AnswerdBookGenres = {
  __typename?: 'AnswerdBookGenres';
  mostFavoriteGenre: Scalars['String'];
  favoriteGenres?: Maybe<Scalars['String']>;
};

export enum AvailableBookType {
  Available = 'AVAILABLE',
  Ordered = 'ORDERED',
  Unavailable = 'UNAVAILABLE',
  Unknown = 'UNKNOWN'
}

export type Book = {
  __typename?: 'Book';
  isbn: Scalars['String'];
  yl?: Maybe<Scalars['Int']>;
  publisher?: Maybe<Scalars['String']>;
  pageCount?: Maybe<Scalars['Int']>;
  author?: Maybe<Scalars['String']>;
  painter?: Maybe<Scalars['String']>;
  wordCount?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  published?: Maybe<Scalars['Time']>;
  synopsis?: Maybe<Scalars['String']>;
  imageUrl?: Maybe<Scalars['String']>;
  impression?: Maybe<Scalars['String']>;
  affiliateLink?: Maybe<Scalars['String']>;
};

export enum BookLikeability {
  VeryLike = 'VERY_LIKE',
  Like = 'LIKE',
  NotLike = 'NOT_LIKE',
  VeryNotLike = 'VERY_NOT_LIKE'
}

export type CalilLibrary = {
  __typename?: 'CalilLibrary';
  calilLibraryAreaID: Scalars['String'];
  calilLibraryKey: Scalars['String'];
  libraryName: Scalars['String'];
};

export type ChangeCreditCardInput = {
  payjpToken: Scalars['String'];
  subscriptionId: Scalars['ID'];
};

export type CreateSubscriptionInput = {
  planId: Scalars['ID'];
  userId: Scalars['Int'];
  payjpToken: Scalars['String'];
};


export type DetailedFirstRecommendEnqueteDifficulties = {
  first: DetailedFirstRecommendEnqueteDifficulty;
  second: DetailedFirstRecommendEnqueteDifficulty;
};

export enum DetailedFirstRecommendEnqueteDifficulty {
  VeryEasy = 'VERY_EASY',
  Easy = 'EASY',
  Normal = 'NORMAL',
  Difficult = 'DIFFICULT',
  VeryDifficult = 'VERY_DIFFICULT'
}

export type DetailedFirstRecommendEnqueteInput = {
  bookLikeability: BookLikeability;
  mostFavoriteBookGenre?: Maybe<FavoriteBookGenre>;
  favoriteBookGenres?: Maybe<Array<FavoriteBookGenre>>;
  sampleDifficultyPerception: SampleDifficultyPerception;
  suitableYL: Scalars['Int'];
  yl15difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl20difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl25difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl30difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl35difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl40difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl45difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  yl50difficulties?: Maybe<DetailedFirstRecommendEnqueteDifficulties>;
  version: DetailedFirstRecommendEnqueteVersion;
};

export enum DetailedFirstRecommendEnqueteVersion {
  Version_1 = 'VERSION_1'
}

export enum DifficultyYl {
  VeryEasy = 'VERY_EASY',
  Easy = 'EASY',
  Normal = 'NORMAL',
  Difficult = 'DIFFICULT',
  VeryDifficult = 'VERY_DIFFICULT'
}

export type ErrorUserNumExceeded = {
  __typename?: 'ErrorUserNumExceeded';
  currentUserNum: Scalars['Int'];
  message: Scalars['String'];
};

export enum FavoriteBookGenre {
  Doubutsu = 'DOUBUTSU',
  Bouken = 'BOUKEN',
  Mahou = 'MAHOU',
  Tomodachi = 'TOMODACHI',
  Obake = 'OBAKE',
  Tatakau = 'TATAKAU',
  Waraeru = 'WARAERU',
  Wakuwaku = 'WAKUWAKU',
  Kandou = 'KANDOU',
  Hokkori = 'HOKKORI'
}

export type FirstBookRecommendEnqueteResult = {
  __typename?: 'FirstBookRecommendEnqueteResult';
  nickname: Scalars['String'];
  charNum: Scalars['Int'];
  yl: Scalars['Int'];
  answerdBookGenres: AnswerdBookGenres;
};

export type Mutation = {
  __typename?: 'Mutation';
  cancelSubscription: Scalars['Boolean'];
  changeCreditCard: Scalars['Boolean'];
  connectLine: Scalars['String'];
  createMyLibrary: CalilLibrary;
  createSubscription: Scalars['Boolean'];
  editUser: User;
  recreateSubscription: Scalars['Boolean'];
  registerAdditionalUser: RegisterAdditionalUserResult;
  registerFirstUser: User;
  removeRecommend: Scalars['Int'];
  removeWantReadBook: Ok;
  signup: SignupResult;
  updateProcureStatus: Ok;
  updateRecommendFrequency: Scalars['Int'];
  upsertMyLibrary?: Maybe<CalilLibrary>;
};


export type MutationCancelSubscriptionArgs = {
  input: UpdateSubscriptionInput;
};


export type MutationChangeCreditCardArgs = {
  input: ChangeCreditCardInput;
};


export type MutationConnectLineArgs = {
  connectionUrlId: Scalars['String'];
};


export type MutationCreateMyLibraryArgs = {
  calilLibraryAreaID: Scalars['String'];
  calilLibraryKey: Scalars['String'];
};


export type MutationCreateSubscriptionArgs = {
  input: CreateSubscriptionInput;
};


export type MutationEditUserArgs = {
  id: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
};


export type MutationRecreateSubscriptionArgs = {
  input: RecreateSubscriptionInput;
};


export type MutationRegisterAdditionalUserArgs = {
  input: RegisterAdditionalUserInput;
};


export type MutationRegisterFirstUserArgs = {
  birthday: Scalars['Time'];
};


export type MutationRemoveRecommendArgs = {
  recommendID: Scalars['Int'];
  removeReason: RemoveReason;
};


export type MutationRemoveWantReadBookArgs = {
  input: RemoveWantReadBookInput;
};


export type MutationSignupArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
  nickname: Scalars['String'];
};


export type MutationUpdateProcureStatusArgs = {
  recommendID: Scalars['Int'];
  procureStatus: ProcureStatus;
};


export type MutationUpdateRecommendFrequencyArgs = {
  userID: Scalars['Int'];
  frequency: RecommendFrequency;
};


export type MutationUpsertMyLibraryArgs = {
  calilLibraryAreaID?: Maybe<Scalars['String']>;
  calilLibraryKey?: Maybe<Scalars['String']>;
};

export type Ok = {
  __typename?: 'OK';
  state: OkState;
};

export enum OkState {
  Success = 'SUCCESS'
}

export enum OrderOfRecommend {
  Finishtime = 'FINISHTIME',
  Recommendtime = 'RECOMMENDTIME'
}

export type Parent = {
  __typename?: 'Parent';
  id: Scalars['Int'];
  email: Scalars['String'];
  nickname: Scalars['String'];
  children: UserList;
  canAddChild: Scalars['Boolean'];
  lineRegistered: Scalars['Boolean'];
  myLibrary?: Maybe<CalilLibrary>;
};

export enum ProcureStatus {
  Untouched = 'UNTOUCHED',
  Reserved = 'RESERVED',
  Purchased = 'PURCHASED'
}

export type Query = {
  __typename?: 'Query';
  getEmailByCertificateToken: Scalars['String'];
  getFirstBookRecommendEnqueteResult?: Maybe<FirstBookRecommendEnqueteResult>;
  getRecommendByID: Recommend;
  getRecommendsInBookShelf: RecommendList;
  getSuitableYL?: Maybe<Scalars['Int']>;
  getWantReadBookByID: WantReadBook;
  getWantReadBooks: WantReadBookList;
  isValidUserNickname: Scalars['Boolean'];
  me: Parent;
  parent: SubscriptionParent;
  ping: Scalars['String'];
  searchLibrary: Array<CalilLibrary>;
  user: UserPresenter;
  verifyToken: Scalars['String'];
};


export type QueryGetEmailByCertificateTokenArgs = {
  token: Scalars['String'];
};


export type QueryGetFirstBookRecommendEnqueteResultArgs = {
  userID: Scalars['Int'];
};


export type QueryGetRecommendByIdArgs = {
  recommendID: Scalars['Int'];
};


export type QueryGetRecommendsInBookShelfArgs = {
  userID: Scalars['Int'];
};


export type QueryGetSuitableYlArgs = {
  userID: Scalars['Int'];
};


export type QueryGetWantReadBookByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetWantReadBooksArgs = {
  userID: Scalars['Int'];
};


export type QueryIsValidUserNicknameArgs = {
  userNickname: Scalars['String'];
};


export type QuerySearchLibraryArgs = {
  pref: Scalars['String'];
  city: Scalars['String'];
};


export type QueryUserArgs = {
  userId: Scalars['Int'];
};


export type QueryVerifyTokenArgs = {
  token: Scalars['String'];
};

export enum ReadStatus {
  Reading = 'READING',
  End = 'END',
  Quit = 'QUIT'
}

export enum ReadStatusForPostReadLog {
  Reading = 'READING',
  End = 'END'
}

export type Recommend = {
  __typename?: 'Recommend';
  id: Scalars['Int'];
  whenRecommend: Scalars['Time'];
  whenDone?: Maybe<Scalars['Time']>;
  book: Book;
  recommendComment: Scalars['String'];
  availableBookType: AvailableBookType;
  self: Scalars['Boolean'];
  procureStatus: ProcureStatus;
  recommendStatus: RecommendStatus;
};

export enum RecommendFrequency {
  Little = 'LITTLE',
  Basic = 'BASIC',
  Many = 'MANY',
  Enough = 'ENOUGH',
  Bulk = 'BULK'
}

export type RecommendList = {
  __typename?: 'RecommendList';
  count: Scalars['Int'];
  get: Array<Recommend>;
};


export type RecommendListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
  order: OrderOfRecommend;
};

export enum RecommendStatus {
  Default = 'DEFAULT',
  Reading = 'READING',
  Ended = 'ENDED',
  Quit = 'QUIT',
  Discarded = 'DISCARDED'
}

export type RecreateSubscriptionInput = {
  userId: Scalars['Int'];
  payjpToken: Scalars['String'];
};

export type RegisterAdditionalUserInput = {
  userNickname: Scalars['String'];
  birthday: Scalars['Time'];
  simpleEnqueteInput?: Maybe<SimpleFirstRecommendEnqueteInput>;
  detailedEnqueteInput?: Maybe<DetailedFirstRecommendEnqueteInput>;
};

export type RegisterAdditionalUserResult = User | ErrorUserNumExceeded;

export enum RemoveReason {
  TooDifficult = 'TOO_DIFFICULT',
  TooEasy = 'TOO_EASY',
  NotInteresting = 'NOT_INTERESTING',
  CannotGet = 'CANNOT_GET',
  HaveRead = 'HAVE_READ'
}

export type RemoveWantReadBookInput = {
  id: Scalars['Int'];
};

export enum SampleDifficultyPerception {
  Easy = 'EASY',
  Difficult = 'DIFFICULT'
}

export type SignupResult = {
  __typename?: 'SignupResult';
  email: Scalars['String'];
};

export type SimpleFirstRecommendEnqueteInput = {
  bookLikeability: BookLikeability;
  mostFavoriteBookGenre?: Maybe<FavoriteBookGenre>;
  favoriteBookGenres?: Maybe<Array<FavoriteBookGenre>>;
  difficultyYL15?: Maybe<DifficultyYl>;
  difficultyYL20?: Maybe<DifficultyYl>;
  difficultyYL25?: Maybe<DifficultyYl>;
  difficultyYL30?: Maybe<DifficultyYl>;
  difficultyYL35?: Maybe<DifficultyYl>;
  difficultyYL40?: Maybe<DifficultyYl>;
  difficultyYL45?: Maybe<DifficultyYl>;
  difficultyYL50?: Maybe<DifficultyYl>;
};

export enum StringMatchType {
  Exact = 'EXACT',
  PartialAnd = 'PARTIAL_AND',
  PartialOr = 'PARTIAL_OR'
}

export type StringSearch = {
  type?: Maybe<StringMatchType>;
  strs: Array<Scalars['String']>;
};

export type SubscriptionParent = {
  __typename?: 'SubscriptionParent';
  id: Scalars['Int'];
  email: Scalars['String'];
  children: Array<UserPresenter>;
};


export enum TimeRange {
  All = 'ALL'
}

export type UpdateSubscriptionInput = {
  id: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  id: Scalars['Int'];
  createdAt: Scalars['Time'];
  nickname: Scalars['String'];
  birthday: Scalars['Time'];
  userIconType: Scalars['Int'];
  recommendFrequency: RecommendFrequency;
};

export type UserList = {
  __typename?: 'UserList';
  count: Scalars['Int'];
  /** if `end` is null, it imply that this query should return exact one User. */
  get: Array<User>;
};


export type UserListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export enum UserPlan {
  Premium = 'PREMIUM',
  Basic = 'BASIC',
  Light = 'LIGHT',
  Invalidation = 'INVALIDATION'
}

export type UserPresenter = {
  __typename?: 'UserPresenter';
  id: Scalars['Int'];
  parentId: Scalars['Int'];
  nickname: Scalars['String'];
  birthday: Scalars['Time'];
  userPlan: Scalars['Int'];
  createdAt?: Maybe<Scalars['Time']>;
  customerCardNumber?: Maybe<Scalars['String']>;
  subscriptionId: Scalars['ID'];
  subscriptionStatus: Scalars['String'];
  subscriptionCurrentPeriodStartsAt?: Maybe<Scalars['Time']>;
  subscriptionCurrentPeriodEndsAt?: Maybe<Scalars['Time']>;
  subscriptionTrialStartsAt?: Maybe<Scalars['Time']>;
  subscriptionTrialEndsAt?: Maybe<Scalars['Time']>;
  subscriptionCreatedAt?: Maybe<Scalars['Time']>;
  subscriptionDeletedAt?: Maybe<Scalars['Time']>;
  subscriptionPausedAt?: Maybe<Scalars['Time']>;
  subscriptionRemainingTrialDays?: Maybe<Scalars['Int']>;
  planId: Scalars['ID'];
  planAmount: Scalars['Int'];
  planTrialDays: Scalars['Int'];
  planCurrency: Scalars['String'];
  planDuration: Scalars['String'];
  planDurationLength: Scalars['Int'];
  planName: Scalars['String'];
  planPrimary: Scalars['Boolean'];
};

export type WantReadBook = {
  __typename?: 'WantReadBook';
  id: Scalars['Int'];
  userID: Scalars['Int'];
  book: Book;
};

export type WantReadBookList = {
  __typename?: 'WantReadBookList';
  count: Scalars['Int'];
  get: Array<WantReadBook>;
};


export type WantReadBookListGetArgs = {
  begin?: Maybe<Scalars['Int']>;
  end?: Maybe<Scalars['Int']>;
};

export type NotificationLineInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type NotificationLineInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Parent' }
    & Pick<Parent, 'nickname' | 'lineRegistered'>
  ) }
);

export type CurrentUserFragment = (
  { __typename?: 'Parent' }
  & Pick<Parent, 'id' | 'email' | 'nickname' | 'canAddChild' | 'lineRegistered'>
  & { children: (
    { __typename?: 'UserList' }
    & Pick<UserList, 'count'>
    & { get: Array<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'nickname' | 'recommendFrequency'>
    )> }
  ), myLibrary?: Maybe<(
    { __typename?: 'CalilLibrary' }
    & Pick<CalilLibrary, 'libraryName'>
  )> }
);

export type GetCurrentUserQueryVariables = Exact<{
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Parent' }
    & CurrentUserFragment
  ) }
);

export type GetNowBookListQueryVariables = Exact<{
  userID: Scalars['Int'];
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetNowBookListQuery = (
  { __typename?: 'Query' }
  & { getRecommendsInBookShelf: (
    { __typename?: 'RecommendList' }
    & Pick<RecommendList, 'count'>
    & { get: Array<(
      { __typename?: 'Recommend' }
      & Pick<Recommend, 'id' | 'recommendComment' | 'availableBookType' | 'whenRecommend' | 'procureStatus'>
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'isbn' | 'yl' | 'title' | 'wordCount' | 'publisher' | 'author' | 'synopsis' | 'imageUrl' | 'affiliateLink' | 'published'>
      ) }
    )> }
  ) }
);

export type GetWantReadBooksQueryVariables = Exact<{
  userID: Scalars['Int'];
  begin: Scalars['Int'];
  end: Scalars['Int'];
}>;


export type GetWantReadBooksQuery = (
  { __typename?: 'Query' }
  & { getWantReadBooks: (
    { __typename?: 'WantReadBookList' }
    & Pick<WantReadBookList, 'count'>
    & { get: Array<(
      { __typename?: 'WantReadBook' }
      & Pick<WantReadBook, 'id'>
      & { book: (
        { __typename?: 'Book' }
        & Pick<Book, 'isbn' | 'yl' | 'title' | 'wordCount' | 'publisher' | 'author' | 'synopsis' | 'imageUrl' | 'affiliateLink' | 'published'>
      ) }
    )> }
  ) }
);

export type GetSuitableYlQueryVariables = Exact<{
  userID: Scalars['Int'];
}>;


export type GetSuitableYlQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getSuitableYL'>
);

export type UpdateProcureStatusMutationVariables = Exact<{
  recommendID: Scalars['Int'];
  procureStatus: ProcureStatus;
}>;


export type UpdateProcureStatusMutation = (
  { __typename?: 'Mutation' }
  & { updateProcureStatus: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type UpdateRecommendFrequencyMutationVariables = Exact<{
  userID: Scalars['Int'];
  frequency: RecommendFrequency;
}>;


export type UpdateRecommendFrequencyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRecommendFrequency'>
);

export type RemoveWantReadBookMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveWantReadBookMutation = (
  { __typename?: 'Mutation' }
  & { removeWantReadBook: (
    { __typename?: 'OK' }
    & Pick<Ok, 'state'>
  ) }
);

export type ChangeRecommendFrequencyMutationVariables = Exact<{
  userID: Scalars['Int'];
  frequency: RecommendFrequency;
}>;


export type ChangeRecommendFrequencyMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'updateRecommendFrequency'>
);

export type GetCurrentRecommendFrequencyQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentRecommendFrequencyQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Parent' }
    & { children: (
      { __typename?: 'UserList' }
      & { get: Array<(
        { __typename?: 'User' }
        & Pick<User, 'recommendFrequency'>
      )> }
    ) }
  ) }
);

export type GetFirstBookRecommendEnqueteResultQueryVariables = Exact<{
  userID: Scalars['Int'];
}>;


export type GetFirstBookRecommendEnqueteResultQuery = (
  { __typename?: 'Query' }
  & { getFirstBookRecommendEnqueteResult?: Maybe<(
    { __typename?: 'FirstBookRecommendEnqueteResult' }
    & Pick<FirstBookRecommendEnqueteResult, 'nickname' | 'charNum' | 'yl'>
    & { answerdBookGenres: (
      { __typename?: 'AnswerdBookGenres' }
      & Pick<AnswerdBookGenres, 'mostFavoriteGenre' | 'favoriteGenres'>
    ) }
  )> }
);

export type GetRecommendByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetRecommendByIdQuery = (
  { __typename?: 'Query' }
  & { getRecommendByID: (
    { __typename?: 'Recommend' }
    & Pick<Recommend, 'id' | 'whenRecommend' | 'whenDone' | 'recommendStatus' | 'recommendComment' | 'self'>
    & { book: (
      { __typename?: 'Book' }
      & Pick<Book, 'isbn' | 'yl' | 'publisher' | 'pageCount' | 'author' | 'painter' | 'wordCount' | 'title' | 'published' | 'synopsis' | 'imageUrl'>
    ) }
  ) }
);

export type SearchLibraryQueryVariables = Exact<{
  pref: Scalars['String'];
  city: Scalars['String'];
}>;


export type SearchLibraryQuery = (
  { __typename?: 'Query' }
  & { searchLibrary: Array<(
    { __typename?: 'CalilLibrary' }
    & Pick<CalilLibrary, 'calilLibraryAreaID' | 'calilLibraryKey' | 'libraryName'>
  )> }
);

export type UpsertMyLibraryMutationVariables = Exact<{
  calilLibraryAreaID: Scalars['String'];
  calilLibraryKey: Scalars['String'];
}>;


export type UpsertMyLibraryMutation = (
  { __typename?: 'Mutation' }
  & { upsertMyLibrary?: Maybe<(
    { __typename?: 'CalilLibrary' }
    & Pick<CalilLibrary, 'calilLibraryAreaID' | 'calilLibraryKey' | 'libraryName'>
  )> }
);

export type CreateMyLibraryMutationVariables = Exact<{
  calilLibraryAreaID: Scalars['String'];
  calilLibraryKey: Scalars['String'];
}>;


export type CreateMyLibraryMutation = (
  { __typename?: 'Mutation' }
  & { createMyLibrary: (
    { __typename?: 'CalilLibrary' }
    & Pick<CalilLibrary, 'calilLibraryAreaID' | 'calilLibraryKey' | 'libraryName'>
  ) }
);

export type RegisterFirstUserMutationVariables = Exact<{
  birthday: Scalars['Time'];
}>;


export type RegisterFirstUserMutation = (
  { __typename?: 'Mutation' }
  & { registerFirstUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type RegisterAdditionalUserMutationVariables = Exact<{
  input: RegisterAdditionalUserInput;
}>;


export type RegisterAdditionalUserMutation = (
  { __typename?: 'Mutation' }
  & { registerAdditionalUser: (
    { __typename: 'User' }
    & Pick<User, 'id'>
  ) | (
    { __typename: 'ErrorUserNumExceeded' }
    & Pick<ErrorUserNumExceeded, 'currentUserNum' | 'message'>
  ) }
);

export type RegisterUserInfoQueryVariables = Exact<{ [key: string]: never; }>;


export type RegisterUserInfoQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Parent' }
    & Pick<Parent, 'email' | 'nickname'>
    & { children: (
      { __typename?: 'UserList' }
      & { get: Array<(
        { __typename?: 'User' }
        & Pick<User, 'id'>
      )> }
    ) }
  ) }
);

export type RemoveRecommendMutationVariables = Exact<{
  recommendID: Scalars['Int'];
  removeReason: RemoveReason;
}>;


export type RemoveRecommendMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeRecommend'>
);

export type VerifyTokenQueryVariables = Exact<{
  token: Scalars['String'];
}>;


export type VerifyTokenQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'verifyToken'>
);

export type SignupMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
  nickname: Scalars['String'];
}>;


export type SignupMutation = (
  { __typename?: 'Mutation' }
  & { signup: (
    { __typename?: 'SignupResult' }
    & Pick<SignupResult, 'email'>
  ) }
);

export type GetWantReadBookByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetWantReadBookByIdQuery = (
  { __typename?: 'Query' }
  & { getWantReadBookByID: (
    { __typename?: 'WantReadBook' }
    & Pick<WantReadBook, 'id' | 'userID'>
    & { book: (
      { __typename?: 'Book' }
      & Pick<Book, 'isbn' | 'yl' | 'publisher' | 'pageCount' | 'author' | 'painter' | 'wordCount' | 'title' | 'published' | 'synopsis' | 'imageUrl'>
    ) }
  ) }
);

export type IsValidUserNicknameQueryVariables = Exact<{
  userNickname: Scalars['String'];
}>;


export type IsValidUserNicknameQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'isValidUserNickname'>
);

export type UserEditQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type UserEditQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'UserPresenter' }
    & Pick<UserPresenter, 'id' | 'parentId' | 'nickname' | 'birthday'>
  ) }
);

export type EditUserMutationVariables = Exact<{
  id: Scalars['Int'];
  nickname?: Maybe<Scalars['String']>;
}>;


export type EditUserMutation = (
  { __typename?: 'Mutation' }
  & { editUser: (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  ) }
);

export type ConnectLineMutationVariables = Exact<{
  connectionUrlId: Scalars['String'];
}>;


export type ConnectLineMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'connectLine'>
);

export type ParentProfileQueryVariables = Exact<{ [key: string]: never; }>;


export type ParentProfileQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Parent' }
    & Pick<Parent, 'email' | 'nickname'>
  ) }
);

export type RegisteredQueryVariables = Exact<{ [key: string]: never; }>;


export type RegisteredQuery = (
  { __typename?: 'Query' }
  & { me: (
    { __typename?: 'Parent' }
    & Pick<Parent, 'nickname'>
  ) }
);

export type SubscriptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type SubscriptionsQuery = (
  { __typename?: 'Query' }
  & { parent: (
    { __typename?: 'SubscriptionParent' }
    & Pick<SubscriptionParent, 'id' | 'email'>
    & { children: Array<(
      { __typename?: 'UserPresenter' }
      & Pick<UserPresenter, 'id' | 'nickname' | 'createdAt' | 'subscriptionStatus' | 'subscriptionCurrentPeriodStartsAt' | 'subscriptionCurrentPeriodEndsAt' | 'subscriptionTrialStartsAt' | 'subscriptionTrialEndsAt' | 'subscriptionRemainingTrialDays' | 'planName' | 'planAmount' | 'planCurrency'>
    )> }
  ) }
);

export type SubscriptionShowQueryVariables = Exact<{
  userId: Scalars['Int'];
}>;


export type SubscriptionShowQuery = (
  { __typename?: 'Query' }
  & { user: (
    { __typename?: 'UserPresenter' }
    & Pick<UserPresenter, 'id' | 'parentId' | 'nickname' | 'birthday' | 'customerCardNumber' | 'subscriptionId' | 'subscriptionStatus' | 'subscriptionCurrentPeriodStartsAt' | 'subscriptionCurrentPeriodEndsAt' | 'subscriptionTrialStartsAt' | 'subscriptionTrialEndsAt' | 'subscriptionRemainingTrialDays' | 'subscriptionCreatedAt' | 'subscriptionDeletedAt' | 'subscriptionPausedAt' | 'planId' | 'planName' | 'planAmount' | 'planCurrency' | 'planPrimary'>
  ), parent: (
    { __typename?: 'SubscriptionParent' }
    & { children: Array<(
      { __typename?: 'UserPresenter' }
      & Pick<UserPresenter, 'id' | 'nickname' | 'customerCardNumber' | 'subscriptionId' | 'subscriptionStatus' | 'subscriptionCurrentPeriodStartsAt' | 'subscriptionCurrentPeriodEndsAt' | 'subscriptionTrialStartsAt' | 'subscriptionTrialEndsAt' | 'subscriptionRemainingTrialDays' | 'subscriptionCreatedAt' | 'planId' | 'planName' | 'planAmount' | 'planCurrency' | 'planPrimary'>
    )> }
  ) }
);

export type CreateSubscriptionMutationVariables = Exact<{
  planId: Scalars['ID'];
  userId: Scalars['Int'];
  payjpToken: Scalars['String'];
}>;


export type CreateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createSubscription'>
);

export type RecreateSubscriptionMutationVariables = Exact<{
  userId: Scalars['Int'];
  payjpToken: Scalars['String'];
}>;


export type RecreateSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'recreateSubscription'>
);

export type CancelSubscriptionMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelSubscriptionMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'cancelSubscription'>
);

export type ChangeCreditCardMutationVariables = Exact<{
  payjpToken: Scalars['String'];
  subscriptionId: Scalars['ID'];
}>;


export type ChangeCreditCardMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changeCreditCard'>
);

export const CurrentUserFragmentDoc = gql`
    fragment CurrentUser on Parent {
  id
  email
  nickname
  children {
    get(begin: $begin, end: $end) {
      id
      nickname
      recommendFrequency
    }
    count
  }
  canAddChild
  lineRegistered
  myLibrary {
    libraryName
  }
}
    `;
export const NotificationLineInfoDocument = gql`
    query NotificationLineInfo {
  me {
    nickname
    lineRegistered
  }
}
    `;

/**
 * __useNotificationLineInfoQuery__
 *
 * To run a query within a React component, call `useNotificationLineInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useNotificationLineInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useNotificationLineInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useNotificationLineInfoQuery(baseOptions?: Apollo.QueryHookOptions<NotificationLineInfoQuery, NotificationLineInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<NotificationLineInfoQuery, NotificationLineInfoQueryVariables>(NotificationLineInfoDocument, options);
      }
export function useNotificationLineInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<NotificationLineInfoQuery, NotificationLineInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<NotificationLineInfoQuery, NotificationLineInfoQueryVariables>(NotificationLineInfoDocument, options);
        }
export type NotificationLineInfoQueryHookResult = ReturnType<typeof useNotificationLineInfoQuery>;
export type NotificationLineInfoLazyQueryHookResult = ReturnType<typeof useNotificationLineInfoLazyQuery>;
export type NotificationLineInfoQueryResult = Apollo.QueryResult<NotificationLineInfoQuery, NotificationLineInfoQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser($begin: Int!, $end: Int!) {
  me {
    ...CurrentUser
  }
}
    ${CurrentUserFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetNowBookListDocument = gql`
    query GetNowBookList($userID: Int!, $begin: Int!, $end: Int!) {
  getRecommendsInBookShelf(userID: $userID) {
    count
    get(begin: $begin, end: $end, order: RECOMMENDTIME) {
      id
      recommendComment
      availableBookType
      whenRecommend
      procureStatus
      book {
        isbn
        yl
        title
        wordCount
        publisher
        author
        synopsis
        imageUrl
        affiliateLink
        published
      }
    }
  }
}
    `;

/**
 * __useGetNowBookListQuery__
 *
 * To run a query within a React component, call `useGetNowBookListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNowBookListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNowBookListQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetNowBookListQuery(baseOptions: Apollo.QueryHookOptions<GetNowBookListQuery, GetNowBookListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNowBookListQuery, GetNowBookListQueryVariables>(GetNowBookListDocument, options);
      }
export function useGetNowBookListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNowBookListQuery, GetNowBookListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNowBookListQuery, GetNowBookListQueryVariables>(GetNowBookListDocument, options);
        }
export type GetNowBookListQueryHookResult = ReturnType<typeof useGetNowBookListQuery>;
export type GetNowBookListLazyQueryHookResult = ReturnType<typeof useGetNowBookListLazyQuery>;
export type GetNowBookListQueryResult = Apollo.QueryResult<GetNowBookListQuery, GetNowBookListQueryVariables>;
export const GetWantReadBooksDocument = gql`
    query getWantReadBooks($userID: Int!, $begin: Int!, $end: Int!) {
  getWantReadBooks(userID: $userID) {
    count
    get(begin: $begin, end: $end) {
      id
      book {
        isbn
        yl
        title
        wordCount
        publisher
        author
        synopsis
        imageUrl
        affiliateLink
        published
      }
    }
  }
}
    `;

/**
 * __useGetWantReadBooksQuery__
 *
 * To run a query within a React component, call `useGetWantReadBooksQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWantReadBooksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWantReadBooksQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *      begin: // value for 'begin'
 *      end: // value for 'end'
 *   },
 * });
 */
export function useGetWantReadBooksQuery(baseOptions: Apollo.QueryHookOptions<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>(GetWantReadBooksDocument, options);
      }
export function useGetWantReadBooksLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>(GetWantReadBooksDocument, options);
        }
export type GetWantReadBooksQueryHookResult = ReturnType<typeof useGetWantReadBooksQuery>;
export type GetWantReadBooksLazyQueryHookResult = ReturnType<typeof useGetWantReadBooksLazyQuery>;
export type GetWantReadBooksQueryResult = Apollo.QueryResult<GetWantReadBooksQuery, GetWantReadBooksQueryVariables>;
export const GetSuitableYlDocument = gql`
    query GetSuitableYL($userID: Int!) {
  getSuitableYL(userID: $userID)
}
    `;

/**
 * __useGetSuitableYlQuery__
 *
 * To run a query within a React component, call `useGetSuitableYlQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSuitableYlQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSuitableYlQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetSuitableYlQuery(baseOptions: Apollo.QueryHookOptions<GetSuitableYlQuery, GetSuitableYlQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSuitableYlQuery, GetSuitableYlQueryVariables>(GetSuitableYlDocument, options);
      }
export function useGetSuitableYlLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSuitableYlQuery, GetSuitableYlQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSuitableYlQuery, GetSuitableYlQueryVariables>(GetSuitableYlDocument, options);
        }
export type GetSuitableYlQueryHookResult = ReturnType<typeof useGetSuitableYlQuery>;
export type GetSuitableYlLazyQueryHookResult = ReturnType<typeof useGetSuitableYlLazyQuery>;
export type GetSuitableYlQueryResult = Apollo.QueryResult<GetSuitableYlQuery, GetSuitableYlQueryVariables>;
export const UpdateProcureStatusDocument = gql`
    mutation updateProcureStatus($recommendID: Int!, $procureStatus: ProcureStatus!) {
  updateProcureStatus(recommendID: $recommendID, procureStatus: $procureStatus) {
    state
  }
}
    `;
export type UpdateProcureStatusMutationFn = Apollo.MutationFunction<UpdateProcureStatusMutation, UpdateProcureStatusMutationVariables>;

/**
 * __useUpdateProcureStatusMutation__
 *
 * To run a mutation, you first call `useUpdateProcureStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProcureStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProcureStatusMutation, { data, loading, error }] = useUpdateProcureStatusMutation({
 *   variables: {
 *      recommendID: // value for 'recommendID'
 *      procureStatus: // value for 'procureStatus'
 *   },
 * });
 */
export function useUpdateProcureStatusMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProcureStatusMutation, UpdateProcureStatusMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProcureStatusMutation, UpdateProcureStatusMutationVariables>(UpdateProcureStatusDocument, options);
      }
export type UpdateProcureStatusMutationHookResult = ReturnType<typeof useUpdateProcureStatusMutation>;
export type UpdateProcureStatusMutationResult = Apollo.MutationResult<UpdateProcureStatusMutation>;
export type UpdateProcureStatusMutationOptions = Apollo.BaseMutationOptions<UpdateProcureStatusMutation, UpdateProcureStatusMutationVariables>;
export const UpdateRecommendFrequencyDocument = gql`
    mutation updateRecommendFrequency($userID: Int!, $frequency: RecommendFrequency!) {
  updateRecommendFrequency(userID: $userID, frequency: $frequency)
}
    `;
export type UpdateRecommendFrequencyMutationFn = Apollo.MutationFunction<UpdateRecommendFrequencyMutation, UpdateRecommendFrequencyMutationVariables>;

/**
 * __useUpdateRecommendFrequencyMutation__
 *
 * To run a mutation, you first call `useUpdateRecommendFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRecommendFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRecommendFrequencyMutation, { data, loading, error }] = useUpdateRecommendFrequencyMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useUpdateRecommendFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateRecommendFrequencyMutation, UpdateRecommendFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateRecommendFrequencyMutation, UpdateRecommendFrequencyMutationVariables>(UpdateRecommendFrequencyDocument, options);
      }
export type UpdateRecommendFrequencyMutationHookResult = ReturnType<typeof useUpdateRecommendFrequencyMutation>;
export type UpdateRecommendFrequencyMutationResult = Apollo.MutationResult<UpdateRecommendFrequencyMutation>;
export type UpdateRecommendFrequencyMutationOptions = Apollo.BaseMutationOptions<UpdateRecommendFrequencyMutation, UpdateRecommendFrequencyMutationVariables>;
export const RemoveWantReadBookDocument = gql`
    mutation removeWantReadBook($id: Int!) {
  removeWantReadBook(input: {id: $id}) {
    state
  }
}
    `;
export type RemoveWantReadBookMutationFn = Apollo.MutationFunction<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>;

/**
 * __useRemoveWantReadBookMutation__
 *
 * To run a mutation, you first call `useRemoveWantReadBookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveWantReadBookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeWantReadBookMutation, { data, loading, error }] = useRemoveWantReadBookMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveWantReadBookMutation(baseOptions?: Apollo.MutationHookOptions<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>(RemoveWantReadBookDocument, options);
      }
export type RemoveWantReadBookMutationHookResult = ReturnType<typeof useRemoveWantReadBookMutation>;
export type RemoveWantReadBookMutationResult = Apollo.MutationResult<RemoveWantReadBookMutation>;
export type RemoveWantReadBookMutationOptions = Apollo.BaseMutationOptions<RemoveWantReadBookMutation, RemoveWantReadBookMutationVariables>;
export const ChangeRecommendFrequencyDocument = gql`
    mutation ChangeRecommendFrequency($userID: Int!, $frequency: RecommendFrequency!) {
  updateRecommendFrequency(userID: $userID, frequency: $frequency)
}
    `;
export type ChangeRecommendFrequencyMutationFn = Apollo.MutationFunction<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>;

/**
 * __useChangeRecommendFrequencyMutation__
 *
 * To run a mutation, you first call `useChangeRecommendFrequencyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeRecommendFrequencyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeRecommendFrequencyMutation, { data, loading, error }] = useChangeRecommendFrequencyMutation({
 *   variables: {
 *      userID: // value for 'userID'
 *      frequency: // value for 'frequency'
 *   },
 * });
 */
export function useChangeRecommendFrequencyMutation(baseOptions?: Apollo.MutationHookOptions<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>(ChangeRecommendFrequencyDocument, options);
      }
export type ChangeRecommendFrequencyMutationHookResult = ReturnType<typeof useChangeRecommendFrequencyMutation>;
export type ChangeRecommendFrequencyMutationResult = Apollo.MutationResult<ChangeRecommendFrequencyMutation>;
export type ChangeRecommendFrequencyMutationOptions = Apollo.BaseMutationOptions<ChangeRecommendFrequencyMutation, ChangeRecommendFrequencyMutationVariables>;
export const GetCurrentRecommendFrequencyDocument = gql`
    query GetCurrentRecommendFrequency {
  me {
    children {
      get {
        recommendFrequency
      }
    }
  }
}
    `;

/**
 * __useGetCurrentRecommendFrequencyQuery__
 *
 * To run a query within a React component, call `useGetCurrentRecommendFrequencyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentRecommendFrequencyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentRecommendFrequencyQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentRecommendFrequencyQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>(GetCurrentRecommendFrequencyDocument, options);
      }
export function useGetCurrentRecommendFrequencyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>(GetCurrentRecommendFrequencyDocument, options);
        }
export type GetCurrentRecommendFrequencyQueryHookResult = ReturnType<typeof useGetCurrentRecommendFrequencyQuery>;
export type GetCurrentRecommendFrequencyLazyQueryHookResult = ReturnType<typeof useGetCurrentRecommendFrequencyLazyQuery>;
export type GetCurrentRecommendFrequencyQueryResult = Apollo.QueryResult<GetCurrentRecommendFrequencyQuery, GetCurrentRecommendFrequencyQueryVariables>;
export const GetFirstBookRecommendEnqueteResultDocument = gql`
    query getFirstBookRecommendEnqueteResult($userID: Int!) {
  getFirstBookRecommendEnqueteResult(userID: $userID) {
    nickname
    charNum
    yl
    answerdBookGenres {
      mostFavoriteGenre
      favoriteGenres
    }
  }
}
    `;

/**
 * __useGetFirstBookRecommendEnqueteResultQuery__
 *
 * To run a query within a React component, call `useGetFirstBookRecommendEnqueteResultQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFirstBookRecommendEnqueteResultQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFirstBookRecommendEnqueteResultQuery({
 *   variables: {
 *      userID: // value for 'userID'
 *   },
 * });
 */
export function useGetFirstBookRecommendEnqueteResultQuery(baseOptions: Apollo.QueryHookOptions<GetFirstBookRecommendEnqueteResultQuery, GetFirstBookRecommendEnqueteResultQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFirstBookRecommendEnqueteResultQuery, GetFirstBookRecommendEnqueteResultQueryVariables>(GetFirstBookRecommendEnqueteResultDocument, options);
      }
export function useGetFirstBookRecommendEnqueteResultLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFirstBookRecommendEnqueteResultQuery, GetFirstBookRecommendEnqueteResultQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFirstBookRecommendEnqueteResultQuery, GetFirstBookRecommendEnqueteResultQueryVariables>(GetFirstBookRecommendEnqueteResultDocument, options);
        }
export type GetFirstBookRecommendEnqueteResultQueryHookResult = ReturnType<typeof useGetFirstBookRecommendEnqueteResultQuery>;
export type GetFirstBookRecommendEnqueteResultLazyQueryHookResult = ReturnType<typeof useGetFirstBookRecommendEnqueteResultLazyQuery>;
export type GetFirstBookRecommendEnqueteResultQueryResult = Apollo.QueryResult<GetFirstBookRecommendEnqueteResultQuery, GetFirstBookRecommendEnqueteResultQueryVariables>;
export const GetRecommendByIdDocument = gql`
    query GetRecommendByID($id: Int!) {
  getRecommendByID(recommendID: $id) {
    id
    whenRecommend
    whenDone
    recommendStatus
    recommendComment
    self
    book {
      isbn
      yl
      publisher
      pageCount
      author
      painter
      wordCount
      title
      published
      synopsis
      imageUrl
    }
  }
}
    `;

/**
 * __useGetRecommendByIdQuery__
 *
 * To run a query within a React component, call `useGetRecommendByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRecommendByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRecommendByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetRecommendByIdQuery(baseOptions: Apollo.QueryHookOptions<GetRecommendByIdQuery, GetRecommendByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetRecommendByIdQuery, GetRecommendByIdQueryVariables>(GetRecommendByIdDocument, options);
      }
export function useGetRecommendByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetRecommendByIdQuery, GetRecommendByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetRecommendByIdQuery, GetRecommendByIdQueryVariables>(GetRecommendByIdDocument, options);
        }
export type GetRecommendByIdQueryHookResult = ReturnType<typeof useGetRecommendByIdQuery>;
export type GetRecommendByIdLazyQueryHookResult = ReturnType<typeof useGetRecommendByIdLazyQuery>;
export type GetRecommendByIdQueryResult = Apollo.QueryResult<GetRecommendByIdQuery, GetRecommendByIdQueryVariables>;
export const SearchLibraryDocument = gql`
    query searchLibrary($pref: String!, $city: String!) {
  searchLibrary(pref: $pref, city: $city) {
    calilLibraryAreaID
    calilLibraryKey
    libraryName
  }
}
    `;

/**
 * __useSearchLibraryQuery__
 *
 * To run a query within a React component, call `useSearchLibraryQuery` and pass it any options that fit your needs.
 * When your component renders, `useSearchLibraryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchLibraryQuery({
 *   variables: {
 *      pref: // value for 'pref'
 *      city: // value for 'city'
 *   },
 * });
 */
export function useSearchLibraryQuery(baseOptions: Apollo.QueryHookOptions<SearchLibraryQuery, SearchLibraryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SearchLibraryQuery, SearchLibraryQueryVariables>(SearchLibraryDocument, options);
      }
export function useSearchLibraryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SearchLibraryQuery, SearchLibraryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SearchLibraryQuery, SearchLibraryQueryVariables>(SearchLibraryDocument, options);
        }
export type SearchLibraryQueryHookResult = ReturnType<typeof useSearchLibraryQuery>;
export type SearchLibraryLazyQueryHookResult = ReturnType<typeof useSearchLibraryLazyQuery>;
export type SearchLibraryQueryResult = Apollo.QueryResult<SearchLibraryQuery, SearchLibraryQueryVariables>;
export const UpsertMyLibraryDocument = gql`
    mutation upsertMyLibrary($calilLibraryAreaID: String!, $calilLibraryKey: String!) {
  upsertMyLibrary(
    calilLibraryAreaID: $calilLibraryAreaID
    calilLibraryKey: $calilLibraryKey
  ) {
    calilLibraryAreaID
    calilLibraryKey
    libraryName
  }
}
    `;
export type UpsertMyLibraryMutationFn = Apollo.MutationFunction<UpsertMyLibraryMutation, UpsertMyLibraryMutationVariables>;

/**
 * __useUpsertMyLibraryMutation__
 *
 * To run a mutation, you first call `useUpsertMyLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpsertMyLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [upsertMyLibraryMutation, { data, loading, error }] = useUpsertMyLibraryMutation({
 *   variables: {
 *      calilLibraryAreaID: // value for 'calilLibraryAreaID'
 *      calilLibraryKey: // value for 'calilLibraryKey'
 *   },
 * });
 */
export function useUpsertMyLibraryMutation(baseOptions?: Apollo.MutationHookOptions<UpsertMyLibraryMutation, UpsertMyLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpsertMyLibraryMutation, UpsertMyLibraryMutationVariables>(UpsertMyLibraryDocument, options);
      }
export type UpsertMyLibraryMutationHookResult = ReturnType<typeof useUpsertMyLibraryMutation>;
export type UpsertMyLibraryMutationResult = Apollo.MutationResult<UpsertMyLibraryMutation>;
export type UpsertMyLibraryMutationOptions = Apollo.BaseMutationOptions<UpsertMyLibraryMutation, UpsertMyLibraryMutationVariables>;
export const CreateMyLibraryDocument = gql`
    mutation createMyLibrary($calilLibraryAreaID: String!, $calilLibraryKey: String!) {
  createMyLibrary(
    calilLibraryAreaID: $calilLibraryAreaID
    calilLibraryKey: $calilLibraryKey
  ) {
    calilLibraryAreaID
    calilLibraryKey
    libraryName
  }
}
    `;
export type CreateMyLibraryMutationFn = Apollo.MutationFunction<CreateMyLibraryMutation, CreateMyLibraryMutationVariables>;

/**
 * __useCreateMyLibraryMutation__
 *
 * To run a mutation, you first call `useCreateMyLibraryMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateMyLibraryMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createMyLibraryMutation, { data, loading, error }] = useCreateMyLibraryMutation({
 *   variables: {
 *      calilLibraryAreaID: // value for 'calilLibraryAreaID'
 *      calilLibraryKey: // value for 'calilLibraryKey'
 *   },
 * });
 */
export function useCreateMyLibraryMutation(baseOptions?: Apollo.MutationHookOptions<CreateMyLibraryMutation, CreateMyLibraryMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateMyLibraryMutation, CreateMyLibraryMutationVariables>(CreateMyLibraryDocument, options);
      }
export type CreateMyLibraryMutationHookResult = ReturnType<typeof useCreateMyLibraryMutation>;
export type CreateMyLibraryMutationResult = Apollo.MutationResult<CreateMyLibraryMutation>;
export type CreateMyLibraryMutationOptions = Apollo.BaseMutationOptions<CreateMyLibraryMutation, CreateMyLibraryMutationVariables>;
export const RegisterFirstUserDocument = gql`
    mutation RegisterFirstUser($birthday: Time!) {
  registerFirstUser(birthday: $birthday) {
    id
  }
}
    `;
export type RegisterFirstUserMutationFn = Apollo.MutationFunction<RegisterFirstUserMutation, RegisterFirstUserMutationVariables>;

/**
 * __useRegisterFirstUserMutation__
 *
 * To run a mutation, you first call `useRegisterFirstUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterFirstUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerFirstUserMutation, { data, loading, error }] = useRegisterFirstUserMutation({
 *   variables: {
 *      birthday: // value for 'birthday'
 *   },
 * });
 */
export function useRegisterFirstUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterFirstUserMutation, RegisterFirstUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterFirstUserMutation, RegisterFirstUserMutationVariables>(RegisterFirstUserDocument, options);
      }
export type RegisterFirstUserMutationHookResult = ReturnType<typeof useRegisterFirstUserMutation>;
export type RegisterFirstUserMutationResult = Apollo.MutationResult<RegisterFirstUserMutation>;
export type RegisterFirstUserMutationOptions = Apollo.BaseMutationOptions<RegisterFirstUserMutation, RegisterFirstUserMutationVariables>;
export const RegisterAdditionalUserDocument = gql`
    mutation RegisterAdditionalUser($input: RegisterAdditionalUserInput!) {
  registerAdditionalUser(input: $input) {
    __typename
    ... on User {
      id
    }
    ... on ErrorUserNumExceeded {
      currentUserNum
      message
    }
  }
}
    `;
export type RegisterAdditionalUserMutationFn = Apollo.MutationFunction<RegisterAdditionalUserMutation, RegisterAdditionalUserMutationVariables>;

/**
 * __useRegisterAdditionalUserMutation__
 *
 * To run a mutation, you first call `useRegisterAdditionalUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterAdditionalUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerAdditionalUserMutation, { data, loading, error }] = useRegisterAdditionalUserMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterAdditionalUserMutation(baseOptions?: Apollo.MutationHookOptions<RegisterAdditionalUserMutation, RegisterAdditionalUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RegisterAdditionalUserMutation, RegisterAdditionalUserMutationVariables>(RegisterAdditionalUserDocument, options);
      }
export type RegisterAdditionalUserMutationHookResult = ReturnType<typeof useRegisterAdditionalUserMutation>;
export type RegisterAdditionalUserMutationResult = Apollo.MutationResult<RegisterAdditionalUserMutation>;
export type RegisterAdditionalUserMutationOptions = Apollo.BaseMutationOptions<RegisterAdditionalUserMutation, RegisterAdditionalUserMutationVariables>;
export const RegisterUserInfoDocument = gql`
    query RegisterUserInfo {
  me {
    email
    nickname
    children {
      get(begin: 0, end: 5) {
        id
      }
    }
  }
}
    `;

/**
 * __useRegisterUserInfoQuery__
 *
 * To run a query within a React component, call `useRegisterUserInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisterUserInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisterUserInfoQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisterUserInfoQuery(baseOptions?: Apollo.QueryHookOptions<RegisterUserInfoQuery, RegisterUserInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisterUserInfoQuery, RegisterUserInfoQueryVariables>(RegisterUserInfoDocument, options);
      }
export function useRegisterUserInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisterUserInfoQuery, RegisterUserInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisterUserInfoQuery, RegisterUserInfoQueryVariables>(RegisterUserInfoDocument, options);
        }
export type RegisterUserInfoQueryHookResult = ReturnType<typeof useRegisterUserInfoQuery>;
export type RegisterUserInfoLazyQueryHookResult = ReturnType<typeof useRegisterUserInfoLazyQuery>;
export type RegisterUserInfoQueryResult = Apollo.QueryResult<RegisterUserInfoQuery, RegisterUserInfoQueryVariables>;
export const RemoveRecommendDocument = gql`
    mutation RemoveRecommend($recommendID: Int!, $removeReason: RemoveReason!) {
  removeRecommend(recommendID: $recommendID, removeReason: $removeReason)
}
    `;
export type RemoveRecommendMutationFn = Apollo.MutationFunction<RemoveRecommendMutation, RemoveRecommendMutationVariables>;

/**
 * __useRemoveRecommendMutation__
 *
 * To run a mutation, you first call `useRemoveRecommendMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRecommendMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRecommendMutation, { data, loading, error }] = useRemoveRecommendMutation({
 *   variables: {
 *      recommendID: // value for 'recommendID'
 *      removeReason: // value for 'removeReason'
 *   },
 * });
 */
export function useRemoveRecommendMutation(baseOptions?: Apollo.MutationHookOptions<RemoveRecommendMutation, RemoveRecommendMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveRecommendMutation, RemoveRecommendMutationVariables>(RemoveRecommendDocument, options);
      }
export type RemoveRecommendMutationHookResult = ReturnType<typeof useRemoveRecommendMutation>;
export type RemoveRecommendMutationResult = Apollo.MutationResult<RemoveRecommendMutation>;
export type RemoveRecommendMutationOptions = Apollo.BaseMutationOptions<RemoveRecommendMutation, RemoveRecommendMutationVariables>;
export const VerifyTokenDocument = gql`
    query verifyToken($token: String!) {
  verifyToken(token: $token)
}
    `;

/**
 * __useVerifyTokenQuery__
 *
 * To run a query within a React component, call `useVerifyTokenQuery` and pass it any options that fit your needs.
 * When your component renders, `useVerifyTokenQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useVerifyTokenQuery({
 *   variables: {
 *      token: // value for 'token'
 *   },
 * });
 */
export function useVerifyTokenQuery(baseOptions: Apollo.QueryHookOptions<VerifyTokenQuery, VerifyTokenQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(VerifyTokenDocument, options);
      }
export function useVerifyTokenLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<VerifyTokenQuery, VerifyTokenQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<VerifyTokenQuery, VerifyTokenQueryVariables>(VerifyTokenDocument, options);
        }
export type VerifyTokenQueryHookResult = ReturnType<typeof useVerifyTokenQuery>;
export type VerifyTokenLazyQueryHookResult = ReturnType<typeof useVerifyTokenLazyQuery>;
export type VerifyTokenQueryResult = Apollo.QueryResult<VerifyTokenQuery, VerifyTokenQueryVariables>;
export const SignupDocument = gql`
    mutation signup($token: String!, $password: String!, $nickname: String!) {
  signup(token: $token, password: $password, nickname: $nickname) {
    email
  }
}
    `;
export type SignupMutationFn = Apollo.MutationFunction<SignupMutation, SignupMutationVariables>;

/**
 * __useSignupMutation__
 *
 * To run a mutation, you first call `useSignupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signupMutation, { data, loading, error }] = useSignupMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useSignupMutation(baseOptions?: Apollo.MutationHookOptions<SignupMutation, SignupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignupMutation, SignupMutationVariables>(SignupDocument, options);
      }
export type SignupMutationHookResult = ReturnType<typeof useSignupMutation>;
export type SignupMutationResult = Apollo.MutationResult<SignupMutation>;
export type SignupMutationOptions = Apollo.BaseMutationOptions<SignupMutation, SignupMutationVariables>;
export const GetWantReadBookByIdDocument = gql`
    query GetWantReadBookByID($id: Int!) {
  getWantReadBookByID(id: $id) {
    id
    userID
    book {
      isbn
      yl
      publisher
      pageCount
      author
      painter
      wordCount
      title
      published
      synopsis
      imageUrl
    }
  }
}
    `;

/**
 * __useGetWantReadBookByIdQuery__
 *
 * To run a query within a React component, call `useGetWantReadBookByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetWantReadBookByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetWantReadBookByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetWantReadBookByIdQuery(baseOptions: Apollo.QueryHookOptions<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>(GetWantReadBookByIdDocument, options);
      }
export function useGetWantReadBookByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>(GetWantReadBookByIdDocument, options);
        }
export type GetWantReadBookByIdQueryHookResult = ReturnType<typeof useGetWantReadBookByIdQuery>;
export type GetWantReadBookByIdLazyQueryHookResult = ReturnType<typeof useGetWantReadBookByIdLazyQuery>;
export type GetWantReadBookByIdQueryResult = Apollo.QueryResult<GetWantReadBookByIdQuery, GetWantReadBookByIdQueryVariables>;
export const IsValidUserNicknameDocument = gql`
    query isValidUserNickname($userNickname: String!) {
  isValidUserNickname(userNickname: $userNickname)
}
    `;

/**
 * __useIsValidUserNicknameQuery__
 *
 * To run a query within a React component, call `useIsValidUserNicknameQuery` and pass it any options that fit your needs.
 * When your component renders, `useIsValidUserNicknameQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useIsValidUserNicknameQuery({
 *   variables: {
 *      userNickname: // value for 'userNickname'
 *   },
 * });
 */
export function useIsValidUserNicknameQuery(baseOptions: Apollo.QueryHookOptions<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>(IsValidUserNicknameDocument, options);
      }
export function useIsValidUserNicknameLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>(IsValidUserNicknameDocument, options);
        }
export type IsValidUserNicknameQueryHookResult = ReturnType<typeof useIsValidUserNicknameQuery>;
export type IsValidUserNicknameLazyQueryHookResult = ReturnType<typeof useIsValidUserNicknameLazyQuery>;
export type IsValidUserNicknameQueryResult = Apollo.QueryResult<IsValidUserNicknameQuery, IsValidUserNicknameQueryVariables>;
export const UserEditDocument = gql`
    query userEdit($userId: Int!) {
  user(userId: $userId) {
    id
    parentId
    nickname
    birthday
  }
}
    `;

/**
 * __useUserEditQuery__
 *
 * To run a query within a React component, call `useUserEditQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserEditQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserEditQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserEditQuery(baseOptions: Apollo.QueryHookOptions<UserEditQuery, UserEditQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserEditQuery, UserEditQueryVariables>(UserEditDocument, options);
      }
export function useUserEditLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserEditQuery, UserEditQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserEditQuery, UserEditQueryVariables>(UserEditDocument, options);
        }
export type UserEditQueryHookResult = ReturnType<typeof useUserEditQuery>;
export type UserEditLazyQueryHookResult = ReturnType<typeof useUserEditLazyQuery>;
export type UserEditQueryResult = Apollo.QueryResult<UserEditQuery, UserEditQueryVariables>;
export const EditUserDocument = gql`
    mutation editUser($id: Int!, $nickname: String) {
  editUser(id: $id, nickname: $nickname) {
    id
  }
}
    `;
export type EditUserMutationFn = Apollo.MutationFunction<EditUserMutation, EditUserMutationVariables>;

/**
 * __useEditUserMutation__
 *
 * To run a mutation, you first call `useEditUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editUserMutation, { data, loading, error }] = useEditUserMutation({
 *   variables: {
 *      id: // value for 'id'
 *      nickname: // value for 'nickname'
 *   },
 * });
 */
export function useEditUserMutation(baseOptions?: Apollo.MutationHookOptions<EditUserMutation, EditUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditUserMutation, EditUserMutationVariables>(EditUserDocument, options);
      }
export type EditUserMutationHookResult = ReturnType<typeof useEditUserMutation>;
export type EditUserMutationResult = Apollo.MutationResult<EditUserMutation>;
export type EditUserMutationOptions = Apollo.BaseMutationOptions<EditUserMutation, EditUserMutationVariables>;
export const ConnectLineDocument = gql`
    mutation ConnectLine($connectionUrlId: String!) {
  connectLine(connectionUrlId: $connectionUrlId)
}
    `;
export type ConnectLineMutationFn = Apollo.MutationFunction<ConnectLineMutation, ConnectLineMutationVariables>;

/**
 * __useConnectLineMutation__
 *
 * To run a mutation, you first call `useConnectLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useConnectLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [connectLineMutation, { data, loading, error }] = useConnectLineMutation({
 *   variables: {
 *      connectionUrlId: // value for 'connectionUrlId'
 *   },
 * });
 */
export function useConnectLineMutation(baseOptions?: Apollo.MutationHookOptions<ConnectLineMutation, ConnectLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ConnectLineMutation, ConnectLineMutationVariables>(ConnectLineDocument, options);
      }
export type ConnectLineMutationHookResult = ReturnType<typeof useConnectLineMutation>;
export type ConnectLineMutationResult = Apollo.MutationResult<ConnectLineMutation>;
export type ConnectLineMutationOptions = Apollo.BaseMutationOptions<ConnectLineMutation, ConnectLineMutationVariables>;
export const ParentProfileDocument = gql`
    query parentProfile {
  me {
    email
    nickname
  }
}
    `;

/**
 * __useParentProfileQuery__
 *
 * To run a query within a React component, call `useParentProfileQuery` and pass it any options that fit your needs.
 * When your component renders, `useParentProfileQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useParentProfileQuery({
 *   variables: {
 *   },
 * });
 */
export function useParentProfileQuery(baseOptions?: Apollo.QueryHookOptions<ParentProfileQuery, ParentProfileQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ParentProfileQuery, ParentProfileQueryVariables>(ParentProfileDocument, options);
      }
export function useParentProfileLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ParentProfileQuery, ParentProfileQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ParentProfileQuery, ParentProfileQueryVariables>(ParentProfileDocument, options);
        }
export type ParentProfileQueryHookResult = ReturnType<typeof useParentProfileQuery>;
export type ParentProfileLazyQueryHookResult = ReturnType<typeof useParentProfileLazyQuery>;
export type ParentProfileQueryResult = Apollo.QueryResult<ParentProfileQuery, ParentProfileQueryVariables>;
export const RegisteredDocument = gql`
    query Registered {
  me {
    nickname
  }
}
    `;

/**
 * __useRegisteredQuery__
 *
 * To run a query within a React component, call `useRegisteredQuery` and pass it any options that fit your needs.
 * When your component renders, `useRegisteredQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRegisteredQuery({
 *   variables: {
 *   },
 * });
 */
export function useRegisteredQuery(baseOptions?: Apollo.QueryHookOptions<RegisteredQuery, RegisteredQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RegisteredQuery, RegisteredQueryVariables>(RegisteredDocument, options);
      }
export function useRegisteredLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RegisteredQuery, RegisteredQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RegisteredQuery, RegisteredQueryVariables>(RegisteredDocument, options);
        }
export type RegisteredQueryHookResult = ReturnType<typeof useRegisteredQuery>;
export type RegisteredLazyQueryHookResult = ReturnType<typeof useRegisteredLazyQuery>;
export type RegisteredQueryResult = Apollo.QueryResult<RegisteredQuery, RegisteredQueryVariables>;
export const SubscriptionsDocument = gql`
    query Subscriptions {
  parent {
    id
    email
    children {
      id
      nickname
      createdAt
      subscriptionStatus
      subscriptionCurrentPeriodStartsAt
      subscriptionCurrentPeriodEndsAt
      subscriptionTrialStartsAt
      subscriptionTrialEndsAt
      subscriptionRemainingTrialDays
      planName
      planAmount
      planCurrency
    }
  }
}
    `;

/**
 * __useSubscriptionsQuery__
 *
 * To run a query within a React component, call `useSubscriptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionsQuery({
 *   variables: {
 *   },
 * });
 */
export function useSubscriptionsQuery(baseOptions?: Apollo.QueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
      }
export function useSubscriptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionsQuery, SubscriptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionsQuery, SubscriptionsQueryVariables>(SubscriptionsDocument, options);
        }
export type SubscriptionsQueryHookResult = ReturnType<typeof useSubscriptionsQuery>;
export type SubscriptionsLazyQueryHookResult = ReturnType<typeof useSubscriptionsLazyQuery>;
export type SubscriptionsQueryResult = Apollo.QueryResult<SubscriptionsQuery, SubscriptionsQueryVariables>;
export const SubscriptionShowDocument = gql`
    query SubscriptionShow($userId: Int!) {
  user(userId: $userId) {
    id
    parentId
    nickname
    birthday
    customerCardNumber
    subscriptionId
    subscriptionStatus
    subscriptionCurrentPeriodStartsAt
    subscriptionCurrentPeriodEndsAt
    subscriptionTrialStartsAt
    subscriptionTrialEndsAt
    subscriptionRemainingTrialDays
    subscriptionCreatedAt
    subscriptionDeletedAt
    subscriptionPausedAt
    planId
    planName
    planAmount
    planCurrency
    planPrimary
  }
  parent {
    children {
      id
      nickname
      customerCardNumber
      subscriptionId
      subscriptionStatus
      subscriptionCurrentPeriodStartsAt
      subscriptionCurrentPeriodEndsAt
      subscriptionTrialStartsAt
      subscriptionTrialEndsAt
      subscriptionRemainingTrialDays
      subscriptionCreatedAt
      planId
      planName
      planAmount
      planCurrency
      planPrimary
    }
  }
}
    `;

/**
 * __useSubscriptionShowQuery__
 *
 * To run a query within a React component, call `useSubscriptionShowQuery` and pass it any options that fit your needs.
 * When your component renders, `useSubscriptionShowQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSubscriptionShowQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSubscriptionShowQuery(baseOptions: Apollo.QueryHookOptions<SubscriptionShowQuery, SubscriptionShowQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SubscriptionShowQuery, SubscriptionShowQueryVariables>(SubscriptionShowDocument, options);
      }
export function useSubscriptionShowLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SubscriptionShowQuery, SubscriptionShowQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SubscriptionShowQuery, SubscriptionShowQueryVariables>(SubscriptionShowDocument, options);
        }
export type SubscriptionShowQueryHookResult = ReturnType<typeof useSubscriptionShowQuery>;
export type SubscriptionShowLazyQueryHookResult = ReturnType<typeof useSubscriptionShowLazyQuery>;
export type SubscriptionShowQueryResult = Apollo.QueryResult<SubscriptionShowQuery, SubscriptionShowQueryVariables>;
export const CreateSubscriptionDocument = gql`
    mutation createSubscription($planId: ID!, $userId: Int!, $payjpToken: String!) {
  createSubscription(
    input: {planId: $planId, userId: $userId, payjpToken: $payjpToken}
  )
}
    `;
export type CreateSubscriptionMutationFn = Apollo.MutationFunction<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;

/**
 * __useCreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useCreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSubscriptionMutation, { data, loading, error }] = useCreateSubscriptionMutation({
 *   variables: {
 *      planId: // value for 'planId'
 *      userId: // value for 'userId'
 *      payjpToken: // value for 'payjpToken'
 *   },
 * });
 */
export function useCreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>(CreateSubscriptionDocument, options);
      }
export type CreateSubscriptionMutationHookResult = ReturnType<typeof useCreateSubscriptionMutation>;
export type CreateSubscriptionMutationResult = Apollo.MutationResult<CreateSubscriptionMutation>;
export type CreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<CreateSubscriptionMutation, CreateSubscriptionMutationVariables>;
export const RecreateSubscriptionDocument = gql`
    mutation recreateSubscription($userId: Int!, $payjpToken: String!) {
  recreateSubscription(input: {userId: $userId, payjpToken: $payjpToken})
}
    `;
export type RecreateSubscriptionMutationFn = Apollo.MutationFunction<RecreateSubscriptionMutation, RecreateSubscriptionMutationVariables>;

/**
 * __useRecreateSubscriptionMutation__
 *
 * To run a mutation, you first call `useRecreateSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRecreateSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [recreateSubscriptionMutation, { data, loading, error }] = useRecreateSubscriptionMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      payjpToken: // value for 'payjpToken'
 *   },
 * });
 */
export function useRecreateSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<RecreateSubscriptionMutation, RecreateSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RecreateSubscriptionMutation, RecreateSubscriptionMutationVariables>(RecreateSubscriptionDocument, options);
      }
export type RecreateSubscriptionMutationHookResult = ReturnType<typeof useRecreateSubscriptionMutation>;
export type RecreateSubscriptionMutationResult = Apollo.MutationResult<RecreateSubscriptionMutation>;
export type RecreateSubscriptionMutationOptions = Apollo.BaseMutationOptions<RecreateSubscriptionMutation, RecreateSubscriptionMutationVariables>;
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription($id: ID!) {
  cancelSubscription(input: {id: $id})
}
    `;
export type CancelSubscriptionMutationFn = Apollo.MutationFunction<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;

/**
 * __useCancelSubscriptionMutation__
 *
 * To run a mutation, you first call `useCancelSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelSubscriptionMutation, { data, loading, error }] = useCancelSubscriptionMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument, options);
      }
export type CancelSubscriptionMutationHookResult = ReturnType<typeof useCancelSubscriptionMutation>;
export type CancelSubscriptionMutationResult = Apollo.MutationResult<CancelSubscriptionMutation>;
export type CancelSubscriptionMutationOptions = Apollo.BaseMutationOptions<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>;
export const ChangeCreditCardDocument = gql`
    mutation changeCreditCard($payjpToken: String!, $subscriptionId: ID!) {
  changeCreditCard(
    input: {payjpToken: $payjpToken, subscriptionId: $subscriptionId}
  )
}
    `;
export type ChangeCreditCardMutationFn = Apollo.MutationFunction<ChangeCreditCardMutation, ChangeCreditCardMutationVariables>;

/**
 * __useChangeCreditCardMutation__
 *
 * To run a mutation, you first call `useChangeCreditCardMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangeCreditCardMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changeCreditCardMutation, { data, loading, error }] = useChangeCreditCardMutation({
 *   variables: {
 *      payjpToken: // value for 'payjpToken'
 *      subscriptionId: // value for 'subscriptionId'
 *   },
 * });
 */
export function useChangeCreditCardMutation(baseOptions?: Apollo.MutationHookOptions<ChangeCreditCardMutation, ChangeCreditCardMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangeCreditCardMutation, ChangeCreditCardMutationVariables>(ChangeCreditCardDocument, options);
      }
export type ChangeCreditCardMutationHookResult = ReturnType<typeof useChangeCreditCardMutation>;
export type ChangeCreditCardMutationResult = Apollo.MutationResult<ChangeCreditCardMutation>;
export type ChangeCreditCardMutationOptions = Apollo.BaseMutationOptions<ChangeCreditCardMutation, ChangeCreditCardMutationVariables>;