import * as React from "react";
import { BookSelection } from "./";
import { RegisterAdditionalUserModal } from "./flows/BookInformation/RegisterAdditionalUserModal";

type BookSelectionContainerProps = {
  onNext: () => void;
};

export const BookSelectionContainer: React.FC<BookSelectionContainerProps> = ({
  onNext,
}) => {
  const [modalOpen, setModalOpen] = React.useState<boolean>(false);
  return (
    <>
      {modalOpen && <RegisterAdditionalUserModal onNext={onNext} />}
      <BookSelection onShowModal={() => setModalOpen(true)} />
    </>
  );
};
