import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  InMemoryCache,
  Operation,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import * as React from "react";
import { Auth0Context } from "~/contexts/Auth0Context";

import { onError } from "@apollo/link-error";

import { config } from "config";

import * as Sentry from "@sentry/react";
import { Kind } from "graphql";
import { sentryErrorTrackingID } from "~/utils/sentry";

type Props = {
  children: React.ReactNode;
};

const ERROR_TRACKING_ID_HEADER = "X-Error-Tracking-ID";

const findMutation = (operation: Operation) => {
  return operation.query.definitions.find(
    (def) =>
      def.kind === Kind.OPERATION_DEFINITION && def.operation === "mutation"
  );
};

export const CustomApolloProvider: React.FC<Props> = ({ children }) => {
  const { getAccessToken, logout, isAuthenticated } =
    React.useContext(Auth0Context);

  const [apolloClient, subscriptionsClient] = React.useMemo(() => {
    const forceLogout = () => {
      // TODO: snackbarでフィードバックするなど
      window.setTimeout(() => {
        logout && logout();
      }, 3000); // フィードバックを表示してから3秒後にログアウトする
    };

    const getToken = () => {
      return new Promise((resolve, reject) => {
        getAccessToken()
          .then((token) => {
            resolve(token.token);
          })
          .catch((e) => {
            forceLogout();
            reject(e);
          });
      });
    };

    const httpLink = createHttpLink({
      uri: config.API_HOST + "graphql",
      fetchOptions: {
        mode: "cors",
      },
      credentials: "include",
    });

    const subscriptionsHttpLink = createHttpLink({
      uri: config.SUBSCRIPTIONS_API_HOST + "/subscriptions/graphql",
      fetchOptions: {
        mode: "cors",
      },
      credentials: "include",
    });

    const headerLink = setContext(async (request, context) => {
      const headers = context.headers || {};

      const withoutSession = [
        "sendPasswordResetRequest",
        "setPasswordByEmail",
        "verifyToken",
      ];
      const withSession =
        request.query.definitions.some(
          (d) =>
            d.kind === "OperationDefinition" &&
            d.selectionSet.selections.some(
              (s) =>
                s.kind === "Field" &&
                withoutSession.every((n) => n !== s.name.value)
            )
        ) ||
        request.query.definitions.some(
          (d) => d.kind === "OperationDefinition" && d.operation === "mutation"
        );

      if (withSession && isAuthenticated) {
        const token = await getToken();

        Object.assign(headers, {
          authorization: `Bearer ${token}`,
        });
      }

      return {
        headers: {
          ...headers,
          [ERROR_TRACKING_ID_HEADER]: sentryErrorTrackingID,
        },
      };
    });

    const errorLink = onError(
      ({ networkError, graphQLErrors, operation, forward }) => {
        const hasMutation = findMutation(operation);
        if (networkError) {
          const repeat = operation.getContext().repeat ?? 0;
          if (!hasMutation && repeat + 1 < config.REQUEST_REPEAT) {
            operation.setContext({
              repeat: repeat === undefined ? 1 : repeat + 1,
            });
            return forward(operation);
          } else {
            return;
          }
        }

        if (graphQLErrors) {
          for (const err of graphQLErrors) {
            if (
              err.extensions === undefined ||
              err.extensions.tmp_error === undefined ||
              typeof err.extensions.tmp_error !== "string"
            ) {
              continue;
            }
            Sentry.withScope((scope) => {
              scope.setExtra("エラー詳細", JSON.stringify(err, null, 4));
              scope.setExtra("operationName", operation.operationName);
              scope.setExtra(
                "variables",
                JSON.stringify(operation.variables, null, 4)
              );
              scope.setLevel("error");
              Sentry.captureMessage(
                `GraphQL Error: ${operation.operationName} - ${err.message}`
              );
            });

            if (err.message == "temporary_error") {
              const repeat = operation.getContext().repeat ?? 0;
              if (!hasMutation && repeat + 1 < config.REQUEST_REPEAT) {
                operation.setContext({
                  repeat: repeat === undefined ? 1 : repeat + 1,
                });
                return forward(operation);
              } else {
                return;
              }
            }
          }
        }
      }
    );

    const client = new ApolloClient({
      cache: new InMemoryCache({
        typePolicies: {
          Parent: {
            merge(existing, incoming, { mergeObjects }) {
              return mergeObjects(existing, incoming);
            },
          },
        },
      }),
      link: ApolloLink.from([errorLink, headerLink, httpLink]),
    });

    const subscriptionsClient = new ApolloClient({
      cache: new InMemoryCache(),
      link: ApolloLink.from([errorLink, headerLink, subscriptionsHttpLink]),
    });

    return [client, subscriptionsClient];
  }, [getAccessToken, logout]);

  return (
    <SubscriptionsClientContext.Provider value={{ subscriptionsClient }}>
      <ApolloProvider client={apolloClient}>{children}</ApolloProvider>
    </SubscriptionsClientContext.Provider>
  );
};

type SubscriptionsClientContextValue = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  subscriptionsClient?: ApolloClient<any>;
};

export const SubscriptionsClientContext =
  React.createContext<SubscriptionsClientContextValue>({});
