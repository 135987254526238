import { RecommendFrequency } from "~/generated/graphql";

type ListContents = {
  name: string;
  bookListMax: number;
  border: number;
};

const constList: { [key in RecommendFrequency]: ListContents } = {
  LITTLE: {
    name: "じっくり",
    bookListMax: 5,
    border: 3,
  },
  BASIC: {
    name: "どんどん",
    bookListMax: 8,
    border: 5,
  },
  MANY: {
    name: "たっぷり",
    bookListMax: 10,
    border: 6,
  },
  ENOUGH: {
    name: "まとめて",
    bookListMax: 15,
    border: 11,
  },
  BULK: {
    name: "どさっと",
    bookListMax: 20,
    border: 16,
  },
};

export default constList;
