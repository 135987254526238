import React, {
  createContext,
  FC,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from "react";
import { detect } from "detect-browser";
import { isMobile } from "~/utils/detectBrowser";
import { SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY } from "~/pages/PwaTutorial/PwaTutorialList";

type UserChoice = "accepted" | "dismissed";

interface BeforeInstallPromptEvent extends Event {
  readonly platforms: string[];
  readonly userChoice: Promise<{
    outcome: UserChoice;
    platform: string;
  }>;
  prompt(): Promise<void>;
}

declare global {
  interface WindowEventMap {
    beforeinstallprompt: BeforeInstallPromptEvent;
  }

  interface Navigator {
    getInstalledRelatedApps: () => Promise<{ platform: string; url: string }[]>;
    standalone?: boolean;
  }
}

export type A2HSContextValues = {
  installed: boolean;
  installable: boolean;
  userChoice?: UserChoice;
  standalone?: boolean;
  installApp: () => void;
  supported?: boolean;
  showPwaTutorial: boolean;
  setShowPwaTutorial: (showPwaTutorial: boolean) => void;
};

export const A2HSContext = createContext<A2HSContextValues>({
  installed: false,
  installable: false,
  installApp: () => {
    return;
  },
  showPwaTutorial: false,
  setShowPwaTutorial: () => {
    return;
  },
});

type Props = {
  children: ReactNode;
};

export const A2HSProvider: FC<Props> = ({ children }) => {
  const [installed, setInstalled] = useState<boolean>(false);
  const [installable, setInstallable] = useState<boolean>(false);
  const [promptEvent, setPromptEvent] = useState<BeforeInstallPromptEvent>();
  const [userChoice, setUserChoice] = useState<UserChoice>();
  const [supported, setSupported] = useState<boolean>(false);
  const [showPwaTutorial, setShowPwaTutorial] = useState<boolean>(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: BeforeInstallPromptEvent) => {
      e.preventDefault();
      setPromptEvent(e);
      setInstallable(true);
      setUserChoice(undefined);
    };
    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    window.addEventListener("appinstalled", () => {
      setInstalled(true);
    });
    if ("serviceWorker" in navigator) {
      const browser = detect();
      // iOSではbeforeinstallpromptが発火しないのでサポート対象外とする
      if (browser?.os === "iOS") {
        setSupported(false);
      } else {
        setSupported(true);
      }
    }
    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  useEffect(() => {
    let _showPwaTutorialLocalStorage: boolean = false;
    try {
      _showPwaTutorialLocalStorage =
        !localStorage.getItem(SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY) ||
        JSON.parse(
          localStorage.getItem(SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY) as string
        ) === false;
    } catch (e) {
      console.error(e);
    }

    const browser = detect();

    // NOTE: browser.osはiPadのsafariでMac OSになる場合がある
    if (
      isMobile() === false ||
      !_showPwaTutorialLocalStorage ||
      !(
        browser?.os === "iOS" ||
        browser?.os === "Android OS" ||
        browser?.os === "Mac OS"
      )
    ) {
      return;
    } else if (
      !(
        window.matchMedia("(display-mode: standalone)").matches ||
        navigator.standalone
      ) &&
      !(browser?.os === "Android OS" && !installable)
    ) {
      setShowPwaTutorial(true);
    }
  }, [installable]);

  const installApp = useCallback(() => {
    if (!promptEvent) {
      return;
    }

    promptEvent.prompt();
    promptEvent.userChoice.then((choiceResult) => {
      setPromptEvent(undefined);
      setUserChoice(choiceResult.outcome);
    });
  }, [promptEvent]);

  return (
    <A2HSContext.Provider
      value={{
        installed,
        installable,
        installApp,
        userChoice,
        supported,
        standalone:
          window.matchMedia("(display-mode: standalone)").matches ||
          navigator.standalone,
        showPwaTutorial,
        setShowPwaTutorial,
      }}
    >
      {children}
    </A2HSContext.Provider>
  );
};
