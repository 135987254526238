import React from "react";
import {
  DetailedFirstRecommendEnqueteDifficulty,
  SampleDifficultyPerception,
} from "~/generated/graphql";
import { PriorCheck } from "./PriorCheck";
import { FollowingCheck } from "./FollowingCheck";
import {
  PassageYL,
  SentenceOrder,
  YLAndSentenceOrder,
  priorPassage15,
  priorPassage20,
  priorPassage30,
} from "./const";
import { Conversation } from "../../ui/Conversation";
import { useScale } from "../../ui/constants";
import { getDetailYLEnquete } from "./getDetailYLEnquete";
import { YLEnqueteBackNavbar } from "../ui/YLEnqueteBackNavbar";

export type DetailedYLCheckProps = {
  onNext: () => void;
  onResetDetailDifficulty: () => void;
  onSelectDetailDifficulty: (
    level: PassageYL,
    difficulty: DetailedFirstRecommendEnqueteDifficulty,
    order?: SentenceOrder
  ) => void;
  setDetailSampleDifficultyPerception: (
    detailSampleDifficultyPerception: SampleDifficultyPerception
  ) => void;
  setDetailSuitableYL: (detailSuitableYL: PassageYL) => void;
  onBack: () => void;
};

const YL15 = 15;

export type PageType = "readAll" | "selectDifficulty";

type YLCheckFlow =
  | "Conversation"
  | "PriorCheck20"
  | "PriorCheck15"
  | "PriorCheck30"
  | "FollowingCheck";

export const DetailedYLCheck: React.FC<DetailedYLCheckProps> = ({
  onNext,
  onResetDetailDifficulty,
  onSelectDetailDifficulty,
  setDetailSampleDifficultyPerception,
  setDetailSuitableYL,
  onBack,
}) => {
  const [flow, setFlow] = React.useState<YLCheckFlow>("Conversation");
  const [pageType, setPageType] = React.useState<PageType>("readAll");
  const scale = useScale();

  const [ylAndSentenceOrderArr, setYLAndSentenceOrderArr] = React.useState<
    YLAndSentenceOrder[]
  >([]);

  const onSelectDifficulty20 = (
    difficulty: DetailedFirstRecommendEnqueteDifficulty
  ) => {
    // ちょっとむずかしいを選んだ場合
    if (difficulty === DetailedFirstRecommendEnqueteDifficulty.Difficult) {
      setPageType("readAll");
      setFlow("PriorCheck15");
      return;
    }

    // むずかしいを選んだ場合
    if (difficulty === DetailedFirstRecommendEnqueteDifficulty.VeryDifficult) {
      setDetailSuitableYL(YL15);
      setDetailSampleDifficultyPerception(SampleDifficultyPerception.Difficult);
      onNext();
      return;
    }

    setPageType("readAll");
    setFlow("PriorCheck30");
  };

  const onSelectDifficulty15 = (
    difficulty: DetailedFirstRecommendEnqueteDifficulty
  ) => {
    onSelectDetailDifficulty(YL15, difficulty);
    setDetailSuitableYL(YL15);
    setDetailSampleDifficultyPerception(SampleDifficultyPerception.Easy);
    onNext();
  };

  const onSelectDifficulty30 = (
    difficultySecond: DetailedFirstRecommendEnqueteDifficulty
  ) => {
    setYLAndSentenceOrderArr(getYLAndSentenceOrderArr(difficultySecond));
    setPageType("readAll");
    onResetDetailDifficulty(); // 戻ったときに影響がないようにリセットする
    setFlow("FollowingCheck");

    if (
      difficultySecond === DetailedFirstRecommendEnqueteDifficulty.VeryEasy ||
      difficultySecond === DetailedFirstRecommendEnqueteDifficulty.Easy
    ) {
      setDetailSuitableYL(30);
      setDetailSampleDifficultyPerception(SampleDifficultyPerception.Easy);
    } else if (
      difficultySecond === DetailedFirstRecommendEnqueteDifficulty.Normal
    ) {
      setDetailSuitableYL(25);
      setDetailSampleDifficultyPerception(SampleDifficultyPerception.Easy);
    } else if (
      difficultySecond === DetailedFirstRecommendEnqueteDifficulty.Difficult
    ) {
      setDetailSuitableYL(30);
      setDetailSampleDifficultyPerception(SampleDifficultyPerception.Difficult);
    } else {
      setDetailSuitableYL(25);
      setDetailSampleDifficultyPerception(SampleDifficultyPerception.Difficult);
    }
  };

  const handleBackToConversation = () => {
    setFlow("Conversation");
  };

  const handleBackToPriorCheck = () => {
    setPageType("selectDifficulty");
    setFlow("PriorCheck20");
  };

  const handleBackToPriorCheck30 = () => {
    setYLAndSentenceOrderArr([]);
    setPageType("selectDifficulty");
    setFlow("PriorCheck30");
  };

  if (flow === "Conversation") {
    return (
      <>
        <YLEnqueteBackNavbar withBlur={true} onBackButton={onBack} />
        <Conversation
          imgSrc={"/img/sensei/standing.svg"}
          messageProps={{
            message:
              "つぎは、パッとぶんしょうを\nみて、かんたんか むずかしいか\nおしえてね！",
            speaker: "ヨンデミー先生",
          }}
          scale={scale}
          onNext={() => {
            onResetDetailDifficulty();
            setFlow("PriorCheck20");
          }}
        />
      </>
    );
  } else if (flow === "PriorCheck20") {
    return (
      <PriorCheck
        passageContent={priorPassage20}
        onSelectDifficulty={onSelectDifficulty20}
        pageType={pageType}
        setPageType={setPageType}
        handleBack={handleBackToConversation}
      />
    );
  } else if (flow === "PriorCheck30") {
    return (
      <PriorCheck
        passageContent={priorPassage30}
        onSelectDifficulty={onSelectDifficulty30}
        pageType={pageType}
        setPageType={setPageType}
        handleBack={handleBackToPriorCheck}
      />
    );
  } else if (flow === "PriorCheck15") {
    return (
      <PriorCheck
        passageContent={priorPassage15}
        onSelectDifficulty={onSelectDifficulty15}
        pageType={pageType}
        setPageType={setPageType}
        handleBack={handleBackToPriorCheck}
      />
    );
  } else {
    return (
      <FollowingCheck
        ylAndSentenceOrderArr={ylAndSentenceOrderArr}
        onSelectDetailDifficulty={onSelectDetailDifficulty}
        onNext={onNext}
        pageType={pageType}
        setPageType={setPageType}
        handleBack={handleBackToPriorCheck30}
      />
    );
  }
};

const getYLAndSentenceOrderArr = (
  _second: DetailedFirstRecommendEnqueteDifficulty
): YLAndSentenceOrder[] => {
  if (
    _second === DetailedFirstRecommendEnqueteDifficulty.VeryEasy ||
    _second === DetailedFirstRecommendEnqueteDifficulty.Easy
  ) {
    return getDetailYLEnquete(30, SampleDifficultyPerception.Easy)
      .ylAndSentenceOrderList;
  } else if (_second === DetailedFirstRecommendEnqueteDifficulty.Normal) {
    return getDetailYLEnquete(25, SampleDifficultyPerception.Easy)
      .ylAndSentenceOrderList;
  } else if (_second === DetailedFirstRecommendEnqueteDifficulty.Difficult) {
    return getDetailYLEnquete(30, SampleDifficultyPerception.Difficult)
      .ylAndSentenceOrderList;
  } else {
    return getDetailYLEnquete(25, SampleDifficultyPerception.Difficult)
      .ylAndSentenceOrderList;
  }
};
