import { ApolloError } from "@apollo/client";
import { createContext } from "react";
import {
  CurrentUserFragment,
  useGetCurrentUserQuery,
} from "~/generated/graphql";
import React from "react";
import { setUser } from "@sentry/react";
import { config } from "config";

type CurrentUser = CurrentUserFragment;

type CurrentUserContextValue = {
  currentUser?: CurrentUser;
  loading: boolean;
  refetch: () => void;
  error?: ApolloError;
};

const defaultValue: CurrentUserContextValue = {
  loading: false,
  refetch: () => {
    return;
  },
};

export const CurrentUserContext =
  createContext<CurrentUserContextValue>(defaultValue);

export const CurrentUserProvider: React.FC = ({ children }) => {
  const { refetch, data, loading, error } = useGetCurrentUserQuery({
    variables: { begin: 0, end: 10 },
    onCompleted: (res) => {
      setUser({
        id: `${res.me.id}`,
      });

      window.setTimeout(() => {
        if (typeof window?.clarity === "function") {
          window.clarity("identify", `${res.me.id}`);
          window.clarity("set", "env", config.ENV);
          window.clarity("set", "user_id", `${res.me.id}`);
        }
      }, 3000);
    },
  });

  return (
    <CurrentUserContext.Provider
      value={{
        currentUser: data?.me,
        loading,
        refetch: () => {
          refetch();
        },
        error,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
