import * as React from "react";
import { useHistory } from "react-router-dom";
import { useSubscriptionShowQuery } from "../generated/graphql";
import { Loading } from "../component/atoms/progress_indicator/loading";
import { ChildTemplate } from "../component/templates/child";
import { PopupWrapper } from "../component/templates/wrapper";
import { IndicateMutationState } from "../component/organisms/modal/indicate_mutation_state";
import { SubscriptionsClientContext } from "~/contexts/CustomApolloProvider";

type ChildProps = {
  match: { params: { id: string } };
  location: { state?: { redirectMessage?: string } };
};

const Child: React.VFC<ChildProps> = ({ match, location }) => {
  const history = useHistory();
  const { subscriptionsClient } = React.useContext(SubscriptionsClientContext);
  const { loading, error, data, refetch } = useSubscriptionShowQuery({
    client: subscriptionsClient,
    variables: { userId: parseInt(match.params.id) },
  });

  React.useEffect(() => {
    if (location.state?.redirectMessage) {
      setMutationMessage(location.state?.redirectMessage || "");
      setMutationState("success");
      history.replace({ ...history.location, state: null });
    }
  }, []);

  const [mutationState, setMutationState] = React.useState("idle");
  const [mutationMessage, setMutationMessage] = React.useState("");
  if (loading || !data) return <Loading />;
  if (error) return <>エラーです。リロードしてください</>;
  return (
    <>
      <ChildTemplate
        user={data.user}
        users={data.parent.children}
        refetchData={refetch}
      />
      {/* リダイレクト時に表示するメッセージ */}
      <PopupWrapper>
        <IndicateMutationState
          displayedMessage={mutationMessage}
          mutationState={mutationState}
          setMutationState={setMutationState}
        />
      </PopupWrapper>
    </>
  );
};

export default Child;
