import React from "react";
import styled from "styled-components";
import Modal from "react-modal";
import { theme } from "../../theme";

const content = {
  backgroundColor: theme.colors.base.white,
  border: "none",
  borderRadius: 20,
  padding: 20,
  position: "relative",
  bottom: "80px",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: theme.zIndex.modal,
};
const spContent = {
  ...content,
  width: "80%",
};
const pcContent = {
  ...content,
  width: "500px",
};
const getModalStyle = (): { overlay; content } => {
  const viewportWidth = document.documentElement.clientWidth;
  return {
    overlay: {
      backgroundColor: "rgba(0,0,0,0.7)",
      zIndex: theme.zIndex.modal,
    },
    //ビューポート の幅によってボタンにつけるcssを変える
    //普通はmedia queryで行うところだが、inline-CSSなのでjsで
    content: viewportWidth < 625 ? spContent : pcContent,
  };
};

type Props = {
  title: string;
  children: React.ReactNode;
  isOpen: boolean;
  onClose: (e: React.MouseEvent) => void;
};

/**
 * @deprecated src/components/atoms/wrappers/BaseModalを使用してください
 */
export const BaseModal: React.FC<Props> = ({
  children,
  title,
  isOpen,
  onClose,
}: Props): React.ReactElement => {
  return (
    <Div>
      <Modal
        isOpen={isOpen}
        ariaHideApp={false}
        onRequestClose={onClose}
        contentLabel={title}
        style={getModalStyle()}
        className={"react-modal"}
      >
        {children}
      </Modal>
    </Div>
  );
};

const Div = styled.div`
  .ReactModal__Content {
    width: 500px;
    position: relative;
    bottom: 80px;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: ${(props) => props.theme.zIndex.modal};
    @media screen and (max-width: 625px) {
      width: 80%;
    }
    &:focus {
      outline: none;
    }
  }
`;
