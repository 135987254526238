import * as React from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { results, options, ResultType } from "../data";
import { useQuestion } from "../useQuestion";

/* const getTitle = (title: string, className: string) => {
  const titleArr = title.split("-");
  return titleArr.length === 3 ? (
    <>
      <Span className={`${className}-title-1`}>{titleArr[0]}</Span>
      <Type className={`${className}-title-2`}>{titleArr[1]}</Type>
      <Span className={`${className}-title-3`}>{titleArr[2]}</Span>
    </>
  ) : (
    <>
      <Span className={`${className}-title`}>{titleArr[0]}</Span>
    </>
  );
}; */

type Answer = {
  questionId: string;
  body: {
    id: string;
    value: string;
  }[];
}[];

type Props = {
  answer: Answer;
  question: ReturnType<typeof useQuestion>;
};

const getIndex = (answers: Answer): ResultType | "Error" => {
  const thirdAnswer = answers.find((item) => item.questionId === "3")?.body[0]
    .value;
  const fourthAnswer = answers.find((item) => item.questionId === "4")?.body[0]
    .value;

  const like = thirdAnswer === options[0][0] || thirdAnswer === options[0][1];
  const unlike =
    thirdAnswer === options[0][2] ||
    thirdAnswer === options[0][3] ||
    thirdAnswer === options[0][4];

  if (like && fourthAnswer === options[1][0]) {
    return "Error";
  }

  if (like && fourthAnswer === options[1][1]) {
    return "NoSelfControl";
  }

  if (like && fourthAnswer === options[1][2]) {
    return "HateToRead";
  }

  if (
    like &&
    (fourthAnswer === options[1][3] || fourthAnswer === options[1][5])
  ) {
    return "GoodButNoHabit";
  }

  if (fourthAnswer === options[1][4]) {
    return "UnableToExpress";
  }

  if (
    unlike &&
    (fourthAnswer === options[1][0] || fourthAnswer === options[1][1])
  ) {
    return "NotGoodAtReading";
  }

  if (unlike && fourthAnswer === options[1][2]) {
    return "UnbalancedReading";
  }

  if (
    unlike &&
    (fourthAnswer === options[1][3] || fourthAnswer === options[1][5])
  ) {
    return "NeedToBeCared";
  }

  return "Error";
};

export const Result: React.FC<Props> = ({ answer, question }) => {
  //ユーザーの名前
  const name = answer[0].body[0].value;
  const index = getIndex(answer);
  const { childTypeTitle, childTypeBody, everydayLesson, reasonForImportance } =
    results(name);
  if (index === "Error") {
    return (
      <>
        <Content>
          <Title>診断結果</Title>
        </Content>
        <Content>
          <Description>
            入力内容に間違いはありませんか？
            <br />
            「読書の印象」が「好き」、「お子さんにどんな風になって欲しいか」が「苦手意識を克服して欲しい」を回答されています。
          </Description>
        </Content>
        <PrimaryButton onClick={() => question.move(2)}>
          回答に戻る
        </PrimaryButton>
      </>
    );
  } else {
    return (
      <>
        <Content>
          <Title>診断結果</Title>
        </Content>
        <Content>
          <Span>{name}さんは</Span>
          <Type>{childTypeTitle[index]}</Type>
          <Span>ですね！</Span>
          <Divider />
          <Description>{childTypeBody[index]}</Description>
        </Content>
        <Content>
          <Span>ミニレッスンって何？</Span>
          <Divider />
          <ImageContainer>
            <MinilessonImage src="img/tutorial/minilesson.png" />
          </ImageContainer>
          <Description>
            1日1回、お子さん用アプリにて
            <br />
            ヨンデミー先生から送られてくる
            <br />
            クイズ仕立てのチャット型レッスンです🎁
            <br />
            <br />
            本の楽しみ方・感想の書き方など
            <BlueBig>1回2~3分で</BlueBig>
            回答できるボリュームとなっています✨
          </Description>
        </Content>
        <Content>
          <Span>保護者さまへのお願い</Span>
          <Divider />
          <Description>
            {everydayLesson[index]}
            <br />
            毎日本を読む必要はありません👍
            <br />
            しかし、本を読んでいなくても、
            <br />
            必ず1日1度アプリを開き、
            <br />
            ミニレッスンを受講することが大切です！
            <br />
            <br />
            それではここから、
            <br />
            なぜミニレッスンがそれほど重要なのか、
            <br />
            についてお話しします👀✨
          </Description>
        </Content>
        <Content>
          <Span>ミニレッスンはなぜ重要？</Span>
          <Divider />
          <Description>
            {reasonForImportance[index]}
            <br />
            一方で、忙しい日々の中で、
            <br />
            本を読む時間を取れない日もあることでしょう。
            <br />
            そんなときに大切なのが、
            <br />
            本を思い出す時間を取ることです🤔💭
            <br />
            <br />
            そこでミニレッスン！
            <br />
            <br />
            例え読まずとも、
            <br />
            毎日本を思い出す機会があることで、
            <br />
            生活の中に「本」が
            <br />
            当たり前に馴染んでいきます📚
            <br />
            <br />
            本を身近なものに感じることが、
            <br />
            習慣化への第一歩です！🙌
            <br />
          </Description>
        </Content>
        <Content>
          <Span>明日から3日間、必ず毎日ミニレッスンを受けてください！</Span>
          <Divider />
          <Description>
            まずは<BlueBig>3日間だけ</BlueBig>でも構いませんので、 お子さんに
            <BlueBig>必ず毎日</BlueBig>ミニレッスンを受けてもらってください。
          </Description>
          <ImageContainer>
            <CalendarImage src="img/tutorial/calendar.png" />
          </ImageContainer>
        </Content>
        <Content>
          <Span>本を読んでいない日でも受けてください！</Span>
          <Divider />
          <Description>
            明日からの3日間、本は読んでいなくてもヨンデミーのアプリを立ち上げてください。
            <br />
            <br />
            毎日欠かさず準備運動をすることで、
            <br />
            <BlueBig>2〜3分のレッスン</BlueBig>
            でも、その後のお子さんの成長度合いが全く変わります！
            <br />
          </Description>
          <ImageContainer>
            <GraphImage src="img/tutorial/graph.png" />
          </ImageContainer>
        </Content>
        <Content>
          <Span>お子さんへのお声がけをお願いします！</Span>
          <Divider />
          <Description>
            お子さんがミニレッスンを忘れていましたら、
            ぜひお声がけしてあげてください！
          </Description>
          <ImageContainer>
            <ApproachImage
              src="img/tutorial/apploach.png"
              style={{ width: "" }}
            />
          </ImageContainer>
        </Content>
        {/* {results[getIndex(answer)].body.map((item) => (
          <Content key={item.title}>
            {getTitle(item.title, item.className)}
            <Divider />
            <Description className={`${item.className}-description`}>
              {item.description}
            </Description>
          </Content>
        ))} */}
      </>
    );
  }
};

const Content = styled.div`
  width: 100%;
  border-radius: 16px;
  background-color: #fff;
  padding: 24px;
  margin-top: 24px;
`;

const Title = styled.h1`
  display: block;
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  line-height: 1.6;
`;

const Divider = styled.div`
  width: 100%;
  height: 2px;
  background-color: ${({ theme }) => theme.colors.base.gray};
  margin-top: 16px;
`;

const Description = styled.p`
  margin-top: 16px;
  font-size: 14px;
  line-height: 1.6;
  letter-spacing: 1px;
`;

const Span = styled.span`
  font-weight: bold;
  color: ${({ theme }) => theme.colors.sec.s400};
`;

const BlueBig = styled.span`
  font-weight: bold;
  font-size: 16px;
  color: ${({ theme }) => theme.colors.sec.s400};
`;

const Type = styled.span`
  font-size: 24px;
  font-weight: bold;
  color: ${({ theme }) => theme.colors.sec.s400};
  line-height: 1.6;
`;

const MinilessonImage = styled.img`
  width: 60%;
  @media screen and (min-width: 768px) {
    width: 30%;
  }
`;

const ImageContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 16px;
`;

const CalendarImage = styled.img`
  width: 80%;
  @media screen and (min-width: 768px) {
    width: 30%;
  }
`;

const GraphImage = styled.img`
  width: 60%;
  @media screen and (min-width: 768px) {
    width: 30%;
  }
`;

const ApproachImage = styled.img`
  width: 30%;
  @media screen and (min-width: 768px) {
    width: 15%;
  }
`;
