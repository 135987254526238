import React from "react";
import styled from "styled-components";
import { useLocation } from "react-router-dom";
import { SurveyWrapper } from "~/component/organisms/survey/SurveyWrapper";
import { SurveyHeader } from "~/component/organisms/survey/SurveyHeader";
import { Stack } from "~/component/atoms/layout/Stack";
import { config } from "../../../config";
import { ADDITIONAL_USER_DATA } from "../WorldViewOnboarding/registerAdditionalUserContainer";
import { PrimaryButton } from "~/component/atoms/button/primary_button";

export const CompleteRegister: React.FC = () => {
  const { state } = useLocation<{ userId: number }>();

  const handleClickLogin = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (state?.userId) {
        window.location.href = `${config.USER_APP}/set_user/${state.userId}`;
      } else {
        window.location.href = `${config.USER_APP}/set_user`;
      }
    },
    [state]
  );

  React.useEffect(() => {
    localStorage.removeItem(ADDITIONAL_USER_DATA);
  }, []);

  return (
    <SurveyWrapper>
      <Stack rowGap="32px">
        <SurveyHeader progress={100} />
        <Stack rowGap="48px">
          <_Title>アカウント登録が完了しました！</_Title>
          <_ContentWrapper>
            <_Message>
              アカウント登録が完了しました。
              <br />
              アプリにすすんでください。
            </_Message>
            <_SenseiImg src="/img/sensei/smile.svg" />
          </_ContentWrapper>
          <PrimaryButton onClick={handleClickLogin}>ログイン</PrimaryButton>
        </Stack>
      </Stack>
    </SurveyWrapper>
  );
};

const _Title = styled.h1`
  color: ${({ theme }) => theme.colors.tex.t900};
  font-size: ${({ theme }) => theme.fontSize.XL};
  font-weight: 700;
`;

const _ContentWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 48px 16px;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  align-items: center;
  border-radius: 16px;
`;

const _Message = styled.p`
  color: ${({ theme }) => theme.colors.tex.t900};
  font-size: ${({ theme }) => theme.fontSize.MD};
  line-height: 160%;
`;

const _SenseiImg = styled.img`
  width: 92px;
  height: 137px;
`;
