import React, { useState } from "react";
import moment from "moment-timezone";
import { useHistory } from "react-router-dom";

import { useUserEditQuery, useEditUserMutation } from "../generated/graphql";
import { OnErrorSetMessage, ValidateNickname } from "../../../util/validation";

import { RegisterWrapper, ErrorMessage } from "../component/form_style";
import {
  FLabel,
  FsubLabel,
  FInput,
  FlexCon,
  InputCon3,
} from "../component/form_style";
import { SubmitButton } from "../component/atoms/button/misc_button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUserEdit } from "@fortawesome/free-solid-svg-icons";
import { IndicateMutationState } from "../component/organisms/modal/indicate_mutation_state";

import { UserProps } from "../utils/subscription_config";
import { PopupWrapper } from "../component/templates/wrapper";
import Header from "../component/organisms/common/header";
import { PortalFooter } from "../component/organisms/common/footer";
import { FirstHeadContainer, WhiteBox } from "../component/templates/box";
import { PageTitle } from "../component/atoms/heading/page_title";
import { BreadCrumb, Crumb } from "../component/atoms/bread_crumb";
import { SubscriptionsClientContext } from "~/contexts/CustomApolloProvider";
import { Loading } from "~/component/atoms/progress_indicator/loading";

export const EditUser = (props: {
  match: { params: { id: string } };
}): React.ReactElement => {
  const { match } = props;
  const { subscriptionsClient } = React.useContext(SubscriptionsClientContext);

  const { loading, error, data, refetch } = useUserEditQuery({
    client: subscriptionsClient,
    variables: { userId: parseInt(match.params.id) },
  });

  {
    /* TODO: Translate below */
  }
  if (loading) return <Loading />;
  if (error) return <>fail to fetch info</>;
  if (!data) return <Loading />;

  return <EditUserContent user={data.user} refetchData={refetch} />;
};

const EditUserContent = (props: {
  user: UserProps;
  refetchData: () => void;
}): React.ReactElement => {
  const history = useHistory();
  const { user, refetchData } = props;
  const [mutationState, setMutationState] = useState("idle");
  const [mutationMessage, setMutationMessage] = useState("");

  // inputs
  const [nickname, setNickname] = useState(user.nickname);

  // errors
  const [nicknameError, setNicknameError] = useState("");

  const birthday = moment(user.birthday);

  const [updateUser, { loading }] = useEditUserMutation({
    async onCompleted() {
      await refetchData();

      history.push({
        pathname: `/child/${user.id}`,
        state: { redirectMessage: "保存しました" },
      });
    },

    onError(e) {
      console.error(e?.message);
      setMutationMessage("保存に失敗しました");
      setMutationState("fail");
    },
  });

  const validate = () => {
    let valid = true;

    if (nickname != null) {
      OnErrorSetMessage(ValidateNickname(nickname), (message: string) => {
        setNicknameError(message);
        valid = false;
      });
    }

    return valid;
  };

  const handleSubmit = (e): void => {
    e.preventDefault();

    if (!loading && validate() && user.id && nickname) {
      const userParams = { id: user.id, nickname: nickname };

      updateUser({ variables: userParams });
    }
  };

  return (
    <>
      <Header />
      <section
        className="white-back"
        id="register-childname"
        style={{ padding: "0px" }}
      >
        <RegisterWrapper>
          <FirstHeadContainer>
            <BreadCrumb>
              <Crumb.FirstList>
                <Crumb.Link to={"/"}>トップ</Crumb.Link>
              </Crumb.FirstList>
              <Crumb.List>
                <Crumb.Link to={"/child/" + String(user.id)}>
                  {user.nickname}さん
                </Crumb.Link>
              </Crumb.List>
              <Crumb.List>
                <Crumb.Para>お子さん情報の変更</Crumb.Para>
              </Crumb.List>
            </BreadCrumb>
            <PageTitle>
              <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
              お子さん情報の変更
            </PageTitle>
          </FirstHeadContainer>
          <WhiteBox>
            <form onSubmit={handleSubmit}>
              <div className="form-group">
                <FLabel>お子さんの誕生日</FLabel>
                <FlexCon>
                  <InputCon3>
                    <FsubLabel>年</FsubLabel>
                    <FInput
                      type="text"
                      value={birthday.format("YYYY")}
                      disabled
                      isError={false}
                    />
                  </InputCon3>
                  <InputCon3>
                    <FsubLabel>月</FsubLabel>
                    <FInput
                      type="text"
                      value={birthday.format("MM")}
                      disabled
                      isError={false}
                    />
                  </InputCon3>
                  <InputCon3>
                    <FsubLabel>日</FsubLabel>
                    <FInput
                      type="text"
                      value={birthday.format("DD")}
                      disabled
                      isError={false}
                    />
                  </InputCon3>
                </FlexCon>
              </div>

              <div className="form-group">
                <FLabel>ニックネーム</FLabel>
                <FInput
                  type="text"
                  value={nickname}
                  onChange={(e) => {
                    setNickname(e.target.value);
                    setNicknameError("");
                  }}
                  isError={nicknameError !== ""}
                />
                <ErrorMessage>{nicknameError}</ErrorMessage>
              </div>
              <div className="d-flex justify-content-center">
                <SubmitButton text="保存して完了" />
              </div>
            </form>
          </WhiteBox>
        </RegisterWrapper>
        <PopupWrapper>
          <IndicateMutationState
            setMutationState={setMutationState}
            mutationState={mutationState}
            displayedMessage={mutationMessage}
          />
        </PopupWrapper>
      </section>
      <PortalFooter />
    </>
  );
};
