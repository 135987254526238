import { useState, Dispatch, SetStateAction } from "react";

type ReturnValue = {
  answers: {
    questionId: string;
    body: {
      id: string;
      value: string;
    }[];
  }[];
  setAnswers: Dispatch<
    SetStateAction<
      {
        questionId: string;
        body: {
          id: string;
          value: string;
        }[];
      }[]
    >
  >;
  currentAnswer?: {
    questionId: string;
    body: {
      id: string;
      value: string;
    }[];
  };
};

export const useAnswer = ({
  questionId,
}: {
  questionId: string;
}): ReturnValue => {
  const [answers, setAnswers] = useState<
    {
      questionId: string;
      body: { id: string; value: string }[];
    }[]
  >([]);

  console.log({ answers });

  const currentAnswer = answers.find((item) => item.questionId === questionId);

  return {
    answers,
    setAnswers,
    currentAnswer,
  };
};
