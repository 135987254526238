import React from "react";
import styled from "styled-components";
import {
  ActOnSpecialCommercialTransactionsLink,
  PrivacyPolicyLink,
  TermsOfServiceLink,
} from "../../../../../util/constant";

const Links = styled.div`
  margin: 0 auto;
  padding: 40px 0 80px 0;
  width: 160px;
`;
const ExternalLink = styled.a`
  color: white;
  margin-bottom: 13px;
  font-size: 13px;
  display: block;
  &:hover,
  &:active,
  &:focus {
    text-decoration: underline;
    color: white;
  }
`;
const P = styled.p`
  color: white;
  font-size: 10px;
  text-align: center;
`;
const LogoWrap = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;
const LogoCon = styled.div`
  width: 60px;
`;

export const PortalFooter = (): React.ReactElement => {
  const year = String(new Date().getFullYear());
  const yearRange = "2020" === year ? year : "2020 - " + year;

  return (
    <footer className="blue-back">
      <Links>
        <ExternalLink href={TermsOfServiceLink}>利用規約</ExternalLink>
        <ExternalLink href={ActOnSpecialCommercialTransactionsLink}>
          特定商取引法に関する表示
        </ExternalLink>
        <ExternalLink href={PrivacyPolicyLink}>
          プライバシーポリシー
        </ExternalLink>
        <div
          style={{ borderBottom: "0.5px solid white", marginBottom: "13px" }}
        ></div>
        <P>©︎株式会社Yondemy, {yearRange}</P>
        <LogoWrap>
          <LogoCon>
            <img src="/img/logo_w.png" alt="ロゴ" style={{ width: "100%" }} />
          </LogoCon>
        </LogoWrap>
      </Links>
    </footer>
  );
};
