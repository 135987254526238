import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowLeft,
  faArrowRight,
  faBell,
  faBook,
  faCaretDown,
  faCaretUp,
  faCheck,
  faChevronRight,
  faClipboardCheck,
  faClone,
  faCog,
  faCommentDots,
  faCopy,
  faExclamation,
  faExclamationCircle,
  faEye,
  faEyeSlash,
  faHome,
  faQuestionCircle,
  faThumbsUp,
  faTimesCircle,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
export const initFontAwesomeIcon = (): void => {
  library.add(
    faArrowLeft,
    faArrowRight,
    faBell,
    faBook,
    faCaretDown,
    faCaretUp,
    faCheck,
    faChevronRight,
    faClipboardCheck,
    faClone,
    faCog,
    faCommentDots,
    faCopy,
    faExclamation,
    faExclamationCircle,
    faEye,
    faEyeSlash,
    faHome,
    faQuestionCircle,
    faThumbsUp,
    faTimesCircle,
    faTrash
  );
};
