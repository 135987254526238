import { useEffect } from "react";
import Snd from "snd-lib";
import { KitKinds } from "snd-lib/dist/constant";
import { SoundKeys } from "snd-lib/dist/types";

const snd = new Snd();
type SoundKey = keyof SoundKeys;

type LoadingStatus = "loading" | "loaded";
const loadingStatus: Record<string, LoadingStatus> = {};

//NOTE: アプリのデフォルト値と同じ
const VOLUME = 0.75;

export const useSnd = (
  kit: KitKinds = Snd.KITS.SND01
): {
  play: (key: SoundKey) => void;
} => {
  useEffect(() => {
    if (loadingStatus[kit]) {
      return;
    }

    loadingStatus[kit] = "loading";
    snd.load(kit).then(() => {
      loadingStatus[kit] = "loaded";
    });
  }, [kit]);

  const play = (key: SoundKey) => {
    try {
      snd.play(Snd.SOUNDS[key], { volume: VOLUME });
    } catch (err) {
      console.error(err);
    }
  };

  return { play };
};
