import React, { VFC } from "react";
import styled from "styled-components";
import { Text } from "~/component/atoms/text/Text";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { IconText } from "~/component/molecules/text/IconText";
import { useHistory } from "react-router";
import { Row } from "~/component/atoms/layout/Row";
import { Icon } from "~/component/atoms/icon";
import { theme } from "~/component/theme";

export const RegisterMyLibraryGuide: VFC = () => {
  const history = useHistory();
  const onClickNextRequest = () => {
    window.gtag("event", "go-to-register-my-library", {
      event_category: "booklist",
      value: 1,
    });
    history.push("/change_library");
  };
  return (
    <Div>
      <IconText
        iconPlace="pre"
        fontSize="MD"
        lineHeight="MD"
        color="tex"
        icon="clipboardCheck"
        text="オンラインで簡単予約"
        bold
      />
      <Text fontSize="SM" lineHeight="MD" fontColor="tex.t700">
        利用する図書館を登録すると、その図書館で貸出や取寄ができるかをひとめで確認できます
      </Text>
      <PrimaryButton onClick={onClickNextRequest}>
        <Row justifyContent="space-between">
          <div></div>
          <Text fontSize="MD" lineHeight="EQ" bold fontColor="base.white">
            利用する図書館を登録する
          </Text>
          <Icon
            name={"arrowRight"}
            style={{
              color: theme.colors.base.white,
            }}
          />
        </Row>
      </PrimaryButton>
    </Div>
  );
};

const Div = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  border: 3px dashed ${({ theme }) => theme.colors.tex.t200};
  padding: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  align-items: flex-start;
`;
