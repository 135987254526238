import React, { VFC } from "react";
import styled from "styled-components";
import { LoadingIndicator } from "../atoms/loadingIndicator";

export const LoadingPage: VFC = () => {
  return (
    <Wrapper>
      <LoadingIndicator />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
`;
