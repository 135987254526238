import React from "react";
import styled from "styled-components";
import { CONTENT_HEIGHT, CONTENT_WIDTH, useScale } from "../../constants";
import { Conversation } from "../../Conversation";
import { Message, MessageProps } from "../../Message";
import {
  FavoriteGenreButton,
  FavoriteGenreButtonProps,
} from "./FavoriteGenreButton";
import { NavigationButton } from "./NavigationButton";

export type MultiSelectQuestionProps = {
  messageProps: MessageProps;
  buttons: FavoriteGenreButtonProps[];
  onNext: (e: React.MouseEvent) => void;
  imgSrc: string;
  disabled: boolean;
};

export const MultiSelectQuestion: React.FC<MultiSelectQuestionProps> = ({
  messageProps,
  buttons,
  onNext,
  imgSrc,
  disabled,
}) => {
  const scale = useScale();
  const Dialog: React.ReactNode = React.useMemo(() => {
    return (
      <_Wrapper>
        <_Container>
          <_MessageWrapper>
            <Message
              message={messageProps.message}
              speaker={messageProps.speaker}
              showTriangle={false}
            />
          </_MessageWrapper>
          <_ButtonGrid>
            {buttons.map((button, index) => (
              <_ButtonWrapper key={index}>
                <FavoriteGenreButton
                  onClick={button.onClick}
                  disabled={button.disabled}
                  selected={button.selected}
                  label={button.label}
                >
                  {button.label}
                </FavoriteGenreButton>
              </_ButtonWrapper>
            ))}
          </_ButtonGrid>
          <_NavigationButtonWrapper>
            <NavigationButton onClick={onNext} disabled={disabled}>
              すすむ
            </NavigationButton>
          </_NavigationButtonWrapper>
        </_Container>
      </_Wrapper>
    );
  }, [messageProps, buttons]);

  return <Conversation imgSrc={imgSrc} dialog={Dialog} overlay scale={scale} />;
};

const _Wrapper = styled.div`
  position: absolute;
  top: 0;
`;

const _Container = styled.div`
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  margin: 0 auto;
  overflow: scroll;

  display: flex;
  flex-direction: column;
  row-gap: 56px;
  justify-content: flex-end;

  padding-bottom: 80px;
  box-sizing: border-box;
`;

const _MessageWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ButtonGrid = styled.div`
  width: 100%;
  padding: 0 16px;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 16px;
  column-gap: 20px;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
`;

const _NavigationButtonWrapper = styled.div`
  width: 100%;
  padding: 0 16px;
`;
