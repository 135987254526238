import styled from "styled-components";

export const PlainText = styled.p`
  width: 100%;
  color: ${(props) => props.theme.colors.tex.t600};
  line-height: 170%;
  font-size: 14px;
  font-family: inherit;
  margin: 0;
  text-align: justify;
  word-wrap: break-word;
`;

export const ListText = {
  Label: styled(PlainText)`
    font-size: 14px;
    color: ${(props) => props.theme.colors.tex.t300};
    text-align: left;
  `,
  Value: styled(PlainText)`
    font-size: 16px;
    font-weight: bold;
    color: ${(props) => props.theme.colors.tex.t700};
    text-align: left;
  `,
  ValueSm: styled(PlainText)`
    font-size: 14px;
    color: ${(props) => props.theme.colors.tex.t700};
    text-align: left;
  `,
};
