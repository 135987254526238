import * as React from "react";
import {
  UserProps,
  SUBSCRIPTION_CANCELED,
  SUBSCRIPTION_ACTIVE,
  SUBSCRIPTION_PAUSED,
  SUBSCRIPTION_EXPIRED,
  SUBSCRIPTION_TRIAL,
  formatDate,
  MONTH_DAY_FORMAT,
  buildSubscriptionStatus,
} from "../../../utils/subscription_config";
import { WhiteBox } from "../../templates/box";
import { InfoList } from "../../molecules/list/info_list";
import ChangeCreditCard from "./change_credit_card";

const CardNumber: React.VFC<{ user: UserProps; canBeChanged?: boolean }> = ({
  user,
  canBeChanged = false,
}) => {
  return (
    <>
      <span>{user.customerCardNumber}</span>
      {canBeChanged && <ChangeCreditCard user={user} />}
    </>
  );
};

const getOneDayBefore = (date: Date) => {
  const _date = new Date(date);
  _date.setDate(_date.getDate() - 1);
  return formatDate(_date.toUTCString(), MONTH_DAY_FORMAT);
};

export const PaymentInfoList: React.VFC<{ user: UserProps }> = ({ user }) => {
  const buildPaymentItems = () => {
    const subscriptionExists = !!user.subscriptionCurrentPeriodStartsAt;

    if (user.subscriptionStatus === SUBSCRIPTION_TRIAL) {
      let items = [
        {
          label: "受講料/月",
          value: String(user.planAmount) + "円",
        },
        {
          label: "ステータス",
          value: buildSubscriptionStatus(user.subscriptionStatus),
        },
        {
          label: "無料期間残り",
          value: `${user.subscriptionRemainingTrialDays} 日`,
        },
        {
          label: "無料期間",
          value: `~ ${
            user.subscriptionTrialEndsAt &&
            getOneDayBefore(new Date(user.subscriptionTrialEndsAt))
          }`,
        },
      ];

      if (subscriptionExists) {
        items = [
          {
            label: "カード番号",
            value: <CardNumber user={user} canBeChanged={true} />,
          },
          ...items,
          {
            label: "次期受講料のお支払日",
            value: user.subscriptionCurrentPeriodEndsAt
              ? formatDate(user.subscriptionCurrentPeriodEndsAt)
              : "",
          },
        ];
      }

      return items;
    } else if (user.subscriptionStatus === SUBSCRIPTION_EXPIRED) {
      let items = [
        {
          label: "受講料/月",
          value: String(user.planAmount) + "円",
        },
        {
          label: "ステータス",
          value: buildSubscriptionStatus(user.subscriptionStatus),
        },
      ];

      if (subscriptionExists) {
        /* when expired status don't show card number
        items = [
          { label: "カード番号", value: <CardNumber user={user} canBeChanged={false} /> },
          ...items,
        ];
        */
      } else {
        items = [
          ...items,
          {
            label: "無料期間残り",
            value: `${user.subscriptionRemainingTrialDays} 日`,
          },
          {
            label: "無料期間",
            value: `~ ${
              user.subscriptionTrialEndsAt
                ? getOneDayBefore(new Date(user.subscriptionTrialEndsAt))
                : ""
            }`,
          },
        ];
      }

      return items;
    } else if (user.subscriptionStatus === SUBSCRIPTION_ACTIVE) {
      return [
        {
          label: "カード番号",
          value: <CardNumber user={user} canBeChanged={true} />,
        },
        {
          label: "ステータス",
          value: buildSubscriptionStatus(user.subscriptionStatus),
        },
        {
          label: "受講料/月",
          value: String(user.planAmount) + "円",
        },
        {
          label: "次回決済日",
          value: user.subscriptionCurrentPeriodEndsAt
            ? formatDate(user.subscriptionCurrentPeriodEndsAt)
            : "",
        },
      ];
    } else if (user.subscriptionStatus === SUBSCRIPTION_CANCELED) {
      return [
        {
          label: "カード番号",
          value: <CardNumber user={user} canBeChanged={false} />,
        },
        {
          label: "受講料/月",
          value: String(user.planAmount) + "円",
        },
        {
          label: "ステータス",
          value: buildSubscriptionStatus(user.subscriptionStatus),
        },
        {
          label: "ご利用可能期間",
          value: `${
            user.subscriptionCurrentPeriodStartsAt
              ? formatDate(
                  user.subscriptionCurrentPeriodStartsAt,
                  MONTH_DAY_FORMAT
                )
              : ""
          } ~ ${
            user.subscriptionCurrentPeriodEndsAt
              ? getOneDayBefore(new Date(user.subscriptionCurrentPeriodEndsAt))
              : ""
          }`,
        },
      ];
    } else if (user.subscriptionStatus === SUBSCRIPTION_PAUSED) {
      return [
        {
          label: "カード番号",
          value: <CardNumber user={user} canBeChanged={false} />,
        },
        {
          label: "受講料/月",
          value: String(user.planAmount) + "円",
        },
        {
          label: "ステータス",
          value: buildSubscriptionStatus(user.subscriptionStatus),
        },
        {
          label: "ご利用可能期間",
          value: `${
            user.subscriptionCurrentPeriodStartsAt
              ? formatDate(
                  user.subscriptionCurrentPeriodStartsAt,
                  MONTH_DAY_FORMAT
                )
              : ""
          } ~ ${
            user.subscriptionCurrentPeriodEndsAt
              ? getOneDayBefore(new Date(user.subscriptionCurrentPeriodEndsAt))
              : ""
          }`,
        },
      ];
    } else {
      return [];
    }
  };
  return (
    <WhiteBox>
      <InfoList list={buildPaymentItems()} />
    </WhiteBox>
  );
};
