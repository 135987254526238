import { ConfigType } from "./type";

export const epic1Conf: ConfigType = {
  ENV: "epic1",

  PAGE_PREFIX: "/",
  API_HOST: "https://api.parent.epic1.alnair.gotoloop.dev/",
  SUBSCRIPTIONS_API_HOST:
    "https://subscriptions.parent.epic1.alnair.gotoloop.dev",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 2,

  ACCESS_TOKEN_HEADER: "X-Access-Token",

  CERTIFICATE_TOKEN_HEADER: "X-Certificate-Token",

  USER_APP: "https://user.epic1.alnair.gotoloop.dev/",
  PARENT_APP: "https://parent.epic1.alnair.gotoloop.dev/",
  PAY_JP_PUBLIC_KEY: "pk_test_b42384bc5023b616b8144cc8",

  GA_MEASUREMENT_ID: null,
  RECAPTCHA_KEY: "6LdkzRwhAAAAAIsd16Pwp0cEH9CeaVH_pLSpv6yv",
  GOOGLE_TAG_MANAGER_ID: "GTM-NS4MWRX",
  SENTRY_DSN:
    "https://2ce807d4c2cf45a8b59c835f0cd9937f@o967000.ingest.sentry.io/4504483415916544",

  AUTH0_DOMAIN: "yondemy-epic1.jp.auth0.com",
  AUTH0_CLIENT_ID: "cNGtej7D4bsLJ7B9hw7NxICigkLxHCg5",
  AUTH0_AUDIENCE: "https://api.parent.epic1.alnair.gotoloop.dev",
  NOTIFICATION_LINE_LINK: "https://lin.ee/kXtAxmi",
};
