import React from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/component/atoms/button/primary_button";

export type RegisterAdditionalUserModalProps = {
  onNext: () => void;
};

export const RegisterAdditionalUserModal: React.FC<
  RegisterAdditionalUserModalProps
> = ({ onNext }) => {
  return (
    <_ModalWrapper>
      <_ModalContainer>
        <_ModalMessage>
          ここからは、
          <br />
          アカウントとうろくがひつようです！
          <br />
          ほごしゃのかたに わたしてね
        </_ModalMessage>
        <_ButtonWrapper>
          <PrimaryButton onClick={onNext} noMarginTop>
            アカウント登録する
          </PrimaryButton>
        </_ButtonWrapper>
        <_AnnotationMessage>※5分程度で終わる簡単な登録です</_AnnotationMessage>
      </_ModalContainer>
    </_ModalWrapper>
  );
};

const _ModalWrapper = styled.div`
  position: absolute;
  width: 100vw;
  height: 100vh;
  top: 0;

  display: flex;
  align-items: flex-end;

  z-index: 100;

  background-color: ${({ theme }) => theme.colors.base.overlay};
`;

const _ModalContainer = styled.div`
  width: 100%;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  border: 3px solid;
  border-color: ${({ theme }) => theme.colors.base.lightGray};
  background-color: ${({ theme }) => theme.colors.base.white};

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 20px;
  padding: 40px 0;
`;

const _ModalMessage = styled.p`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.LG};
  color: ${({ theme }) => theme.colors.tex.t700};
  line-height: 160%;
  text-align: left;
`;

const _ButtonWrapper = styled.div`
  width: 100%;
  padding: 0 20px;
  max-width: 480px;
`;

const _AnnotationMessage = styled.p`
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;
  text-align: center;
  color: ${({ theme }) => theme.colors.tex.t300};
`;
