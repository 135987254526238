import React from "react";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { useAudio } from "~/utils/hooks/useAudio";
import { useSnd } from "~/utils/hooks/useSnd";

type PrimaryButtonWithSound = Parameters<typeof PrimaryButton>[0];

export const PrimaryButtonWithSound: React.FC<PrimaryButtonWithSound> = (
  props
) => {
  const { play } = useSnd();
  const { play: initialzeAudio } = useAudio({ preload: ["initializeAudio"] });
  const children = props.children;
  const onClick = (e: React.MouseEvent) => {
    play("BUTTON");
    initialzeAudio("initializeAudio");
    props.onClick(e);
  };
  return (
    <PrimaryButton {...props} onClick={onClick}>
      {children}
    </PrimaryButton>
  );
};
