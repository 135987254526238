import * as React from "react";
import * as ReactDOM from "react-dom";
import { BrowserRouter, useLocation } from "react-router-dom";
import { config } from "../config";
import { App } from "./app";
import { NotAuthorized } from "./not_authorized";
import { ThemeProvider } from "styled-components";
import { theme } from "./component/theme";
import { initGtag } from "./utils/googleAnalytics";
import { setupGoogleTagManager } from "~/utils/googleTagManager";
import { useMaintenance } from "./utils/hooks/useMaintenance";
import { MaintenancePage } from "./pages/Maintenance";
import "~/utils/sentry";
import "~/utils/serviceWorker";
import { CurrentUserProvider } from "./contexts/CurrentUserContext";
import { CustomApolloProvider } from "./contexts/CustomApolloProvider";
import { Auth0Context, Auth0Provider } from "./contexts/Auth0Context";
import { initFontAwesomeIcon } from "./utils/fontAwesomeIcon";
import { A2HSProvider } from "./contexts/A2HSContext";
import { LoadingPage } from "./component/templates/loading";

initGtag();
setupGoogleTagManager();
initFontAwesomeIcon();

const Main = (): React.ReactElement => {
  const { isAuthenticated, isLoading } = React.useContext(Auth0Context);

  const { isMaintenanceMode, endTime } = useMaintenance();

  const { pathname } = useLocation();
  React.useEffect(() => {
    document.body.scrollTo(0, 0);
  }, [pathname]);

  if (isMaintenanceMode) {
    return (
      <ThemeProvider theme={theme}>
        <MaintenancePage endTime={endTime} />
      </ThemeProvider>
    );
  }

  if (isLoading) {
    return (
      <ThemeProvider theme={theme}>
        <LoadingPage />
      </ThemeProvider>
    );
  }

  if (isAuthenticated) {
    return (
      <CurrentUserProvider>
        <App />
      </CurrentUserProvider>
    );
  } else {
    return <NotAuthorized />;
  }
};

ReactDOM.render(
  <A2HSProvider>
    <Auth0Provider>
      <CustomApolloProvider>
        <BrowserRouter basename={config.PAGE_PREFIX}>
          <ThemeProvider theme={theme}>
            <Main />
          </ThemeProvider>
        </BrowserRouter>
      </CustomApolloProvider>
    </Auth0Provider>
  </A2HSProvider>,

  document.getElementById("main")
);
