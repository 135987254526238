import React from "react";
import styled, { css } from "styled-components";
import { useSnd } from "~/utils/hooks/useSnd";

export type SelectProps = React.SelectHTMLAttributes<HTMLSelectElement> & {
  error?: boolean;
  children: React.ReactNode;
  bold?: boolean;
};

export const Select: React.FC<SelectProps> = (props) => {
  const { children, error, onChange, bold, ...selectProps } = props;
  const { play } = useSnd();
  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onChange && onChange(event);
    play("SELECT");
  };
  return (
    <Container>
      <StyledSelect
        {...selectProps}
        onChange={handleChange}
        error={error}
        bold={bold}
      >
        {children}
      </StyledSelect>
      <DropdownTarget
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.98486 9.74365L0.344238 3.10303C-0.114746 2.64404 -0.114746 1.90186 0.344238 1.44775L1.44775 0.344238C1.90674 -0.114746 2.64893 -0.114746 3.10303 0.344238L7.81006 5.05127L12.5171 0.344238C12.9761 -0.114746 13.7183 -0.114746 14.1724 0.344238L15.2759 1.44775C15.7349 1.90674 15.7349 2.64893 15.2759 3.10303L8.63525 9.74365C8.18604 10.2026 7.44385 10.2026 6.98486 9.74365V9.74365Z" />
      </DropdownTarget>
    </Container>
  );
};

const StyledSelect = styled.select<{ error?: boolean; bold?: boolean }>`
  ${({ theme, error, bold }) => {
    const { colors, fontSize } = theme;
    return css<Pick<SelectProps, "disabled" | "error">>`
      -webkit-appearance: none;
      border: 2px solid
        ${({ theme, error }) =>
          error ? theme.colors.red.r300 : theme.colors.base.gray};
      background-color: ${({ theme }) => theme.colors.base.white};

      width: 100%;
      padding: 16px;
      font-size: ${fontSize.MD};
      line-height: 100%;
      color: ${colors.tex.t700};
      border-radius: 16px;
      font-family: inherit;
      font-weight: ${bold ? 700 : 400};

      &:active,
      :focus {
        outline: none;
        border: 2px solid ${({ theme }) => theme.colors.pri.p400};
        transition: border 100ms linear;
      }
      &:disabled {
        background-color: ${({ theme }) => theme.colors.base.lightGray};
        &:active,
        :focus {
          border: 2px solid ${({ theme }) => theme.colors.base.gray};
          transition: none;
        }
      }
      &::placeholder {
        color: ${({ theme }) => theme.colors.tex.t100};
      }
      &:focus {
        + svg {
          path {
            fill: ${colors.pri.p400};
            transition: all 100ms linear;
          }
        }
      }
      ${error &&
      css`
        + svg {
          path {
            fill: ${colors.red.r300};
          }
        }
      `}
    `;
  }}
`;

const Container = styled.div`
  position: relative;
`;

const DropdownTarget = styled.svg`
  position: absolute;
  right: 16px;
  top: 42%;
  path {
    fill: ${({ theme }) => theme.colors.base.gray};
  }
`;
