import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import {
  LargeButtonTemplateProps,
  LargeButtonTemplate,
  ButtonColors,
} from "./large_button_template";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";

type TransitionButtonProps = {
  children: React.ReactNode;
  // TODO: RouteListにする
  to: string;
  target?: string;
  rel?: string;
  color?: ButtonColors;
  disabled?: boolean;
};

export const TransitionButton: React.VFC<TransitionButtonProps> = ({
  children,
  to,
  target,
  rel,
  color = "orange",
  disabled = false,
}) => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick: () => {
      return;
    },
    label: children,
  });
  const getTo = (): string => {
    if (disabled) {
      return "#";
    } else {
      return to;
    }
  };
  return (
    <LinkContainer
      to={getTo()}
      target={target}
      rel={rel}
      disabled={disabled}
      color={color}
      onClick={onClickWithTracking}
    >
      {children}
    </LinkContainer>
  );
};

const LinkContainer = styled(Link)<LargeButtonTemplateProps>`
  ${LargeButtonTemplate};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.base.white};
  }
`;
