import * as React from "react";
import { useHistory } from "react-router";
import {
  useRegisterUserInfoQuery,
  useRegisterFirstUserMutation,
} from "~/generated/graphql";
import { FormValues, RegisterUser } from ".";

export const RegisterFirstUserContainer: React.FC = () => {
  const [isError, setIsError] = React.useState(false);

  const [registerFirstUser, { loading: registerFirstUserLoading }] =
    useRegisterFirstUserMutation({
      onError: () => {
        setIsError(true);
      },
    });

  const history = useHistory();

  const { loading, error, data } = useRegisterUserInfoQuery({
    fetchPolicy: "no-cache",
  });

  const handleSubmit = React.useCallback(
    (values: FormValues): Promise<boolean> => {
      return new Promise((resolve) => {
        setIsError(false);
        registerFirstUser({
          variables: {
            birthday: new Date(
              Number(values.birthYear),
              Number(values.birthMonth) - 1,
              Number(values.birthDay)
            ).toISOString(),
          },
        })
          .then((res) => {
            if (res.data?.registerFirstUser.id) {
              resolve(true);
              history.push("/complete_register", {
                userId: res.data.registerFirstUser.id,
              });
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(true);
          });
      });
    },
    [registerFirstUser, history]
  );

  React.useEffect(() => {
    if (data && data.me.children.get.length > 0) {
      history.push("/complete_register");
    }
  }, [data, history]);

  if (loading || !data) {
    return <>loading...</>;
  }
  if (error) {
    return <>fail to fetch info.</>;
  }

  return (
    <RegisterUser
      needProgress={true}
      onSubmit={handleSubmit}
      loading={registerFirstUserLoading}
      isError={isError}
    />
  );
};
