import styled from "styled-components";

export const MiddleWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  padding: 0 6%;
  @media screen and (min-width: 600px) {
    padding: 0 10%;
  }
  @media screen and (min-width: 1024px) {
    padding: 0 20%;
  }
`;

export const PopupWrapper = styled.div`
  width: 85%;
  max-width: 500px;
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 5%);
  -webkit-transform: translate(-50%, 5%);
  z-index: ${props => props.theme.zIndex.popup};
`;