import * as React from "react";
import { Controller, useForm } from "react-hook-form";
import styled from "styled-components";
import { LargeButton } from "~/component/atoms/button/Button";
import { Row } from "~/component/atoms/layout/Row";
import { Stack } from "~/component/atoms/layout/Stack";
import { PortalFooter } from "~/component/organisms/common/footer";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Form } from "react-bootstrap";
import { SurveyHeader } from "~/component/organisms/survey/SurveyHeader";
import { ErrorUserNumExceeded } from "~/generated/graphql";
import { Text } from "~/component/atoms/text/Text";
import { BaseModal } from "~/component/atoms/wrappers/BaseModal";
import { LoadingIndicator } from "~/component/atoms/loadingIndicator";

export type FormValues = {
  birthYear: string;
  birthMonth: string;
  birthDay: string;
};

type Props = {
  onSubmit: (values: FormValues) => Promise<boolean>;
  loading?: boolean;
  isError?: boolean;
  userNumExceededErr?: ErrorUserNumExceeded;
  needProgress: boolean;
};

type Schema = {
  birthYear: string;
  birthMonth: string;
  birthDay: string;
};

const getSchema = (): yup.SchemaOf<Schema> => {
  return yup.object().shape({
    birthYear: yup.string().required("選択してください"),
    birthMonth: yup.string().required("選択してください"),
    birthDay: yup.string().required("選択してください"),
  });
};

export const RegisterUser: React.FC<Props> = ({
  onSubmit,
  loading,
  isError,
  userNumExceededErr,
  needProgress,
}: Props) => {
  const schema = getSchema();
  const [isModalOpen, setIsModalOpen] = React.useState<boolean>(false);

  const {
    getValues,
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    defaultValues: {
      birthDay: "",
      birthMonth: "",
      birthYear: "",
    },
    resolver: yupResolver(schema),
    mode: "onBlur",
  });

  return (
    <>
      <_Wrapper>
        <Stack rowGap="32px">
          {needProgress && <SurveyHeader progress={75} />}
          <Text
            fontSize={"XXL"}
            lineHeight={"MD"}
            bold
            fontColor="semantic.text.heading"
          >
            お子さんの生年月日を教えてください
          </Text>
          <_QuestionBox>
            <Stack rowGap="32px">
              <Stack rowGap="16px">
                <Text
                  fontSize={"SM"}
                  lineHeight={"MD"}
                  bold
                  fontColor="semantic.text.heading"
                >
                  お子さんの生年月日
                </Text>
                <Text
                  fontSize={"XS"}
                  lineHeight={"MD"}
                  fontColor="semantic.text.body"
                >
                  一度確定すると変更できません。ご注意ください。
                </Text>
                <_BirthDayInputContainer columnGap="10px">
                  <Stack rowGap="4px">
                    <Text
                      fontSize={"XXS"}
                      lineHeight={"LG"}
                      fontColor="semantic.text.heading"
                    >
                      年
                    </Text>
                    <Controller
                      control={control}
                      name="birthYear"
                      render={({ field }) => {
                        return (
                          <Form.Control as="select" {...field}>
                            {(() => {
                              const items: React.ReactElement[] = [];
                              items.push(
                                <option key={"empty"} value={""}>
                                  {"----"}
                                </option>
                              );
                              for (let i = 2024; i >= 1980; i--) {
                                items.push(
                                  <option key={i} value={`${i}`}>
                                    {i}
                                  </option>
                                );
                              }
                              return items;
                            })()}
                          </Form.Control>
                        );
                      }}
                    />
                    <_ErrorMessage>
                      <_AbsoluteErrorMessage>
                        {errors.birthYear?.message}
                      </_AbsoluteErrorMessage>
                    </_ErrorMessage>
                  </Stack>
                  <Stack rowGap="4px">
                    <Text
                      fontSize={"XXS"}
                      lineHeight={"LG"}
                      fontColor="semantic.text.heading"
                    >
                      月
                    </Text>
                    <Controller
                      control={control}
                      name="birthMonth"
                      render={({ field }) => {
                        return (
                          <Form.Control as="select" {...field}>
                            {(() => {
                              const items: React.ReactElement[] = [];
                              items.push(
                                <option key={"empty"} value={""}>
                                  {"--"}
                                </option>
                              );
                              for (let i = 1; i <= 12; i++) {
                                items.push(
                                  <option key={i} value={i}>
                                    {i}
                                  </option>
                                );
                              }
                              return items;
                            })()}
                          </Form.Control>
                        );
                      }}
                    />
                    <_ErrorMessage>
                      <_AbsoluteErrorMessage>
                        {errors.birthMonth?.message}
                      </_AbsoluteErrorMessage>
                    </_ErrorMessage>
                  </Stack>
                  <Stack rowGap="4px">
                    <Text
                      fontSize={"XXS"}
                      lineHeight={"LG"}
                      fontColor="semantic.text.heading"
                    >
                      日
                    </Text>{" "}
                    <Controller
                      control={control}
                      name="birthDay"
                      render={({ field }) => {
                        return (
                          <Form.Control as="select" {...field}>
                            {(() => {
                              const items: React.ReactElement[] = [];
                              items.push(
                                <option key={"empty"} value={""}>
                                  {"--"}
                                </option>
                              );
                              for (let i = 1; i <= 31; i++) {
                                items.push(
                                  <option key={i} value={i}>
                                    {i}
                                  </option>
                                );
                              }
                              return items;
                            })()}
                          </Form.Control>
                        );
                      }}
                    />
                    <_ErrorMessage>
                      <_AbsoluteErrorMessage>
                        {errors.birthDay?.message}
                      </_AbsoluteErrorMessage>
                    </_ErrorMessage>
                  </Stack>
                </_BirthDayInputContainer>
              </Stack>
              <Stack rowGap="8px">
                <LargeButton
                  disabled={!isValid || loading}
                  onClick={() => setIsModalOpen(true)}
                  style={{ maxWidth: "100%" }}
                >
                  <_ButtonText>確認する</_ButtonText>
                </LargeButton>

                {userNumExceededErr && (
                  <_ErrorMessage>
                    安全性の観点より、こちらからご登録いただけますひと家族におけるお子さんの上限を
                    {userNumExceededErr.currentUserNum}
                    名に設定しております。
                    <br />
                    大変お手数をおかけしますが、
                    {userNumExceededErr.currentUserNum + 1}
                    人目以降のお子さんのご登録をご希望の際は、以下のメールアドレスまでご連絡ください。
                    <br />
                    株式会社Yondemyカスタマーサクセスチーム
                    <br />
                    Mail: cs@yondemy.com
                  </_ErrorMessage>
                )}
                {isError && (
                  <_ErrorMessage>
                    申し込み時にエラーが発生しました。
                    <br />
                    お手数をおかけいたしますが再度お申し込みをお願いいたします。
                  </_ErrorMessage>
                )}
              </Stack>
            </Stack>
          </_QuestionBox>
        </Stack>
      </_Wrapper>
      <PortalFooter />

      <BaseModal open={isModalOpen} onClose={() => setIsModalOpen(false)}>
        <Stack rowGap="12px">
          <Stack rowGap="8px">
            <Text
              fontSize={"MD"}
              lineHeight={"EQ"}
              bold
              fontColor="semantic.text.heading"
            >
              お間違いないですか？
            </Text>
            <_BirthDate
              fontSize="LG"
              lineHeight="EQ"
              bold
              fontColor="semantic.text.heading"
            >
              {getValues("birthYear")}年{getValues("birthMonth")}月
              {getValues("birthDay")}日
            </_BirthDate>
            <Text
              fontSize={"SM"}
              lineHeight={"MD"}
              fontColor="semantic.text.body"
            >
              一度確定すると変更できません。
              <br />
              お子さんの誕生日が正しく入力されているか、確認してください。
            </Text>
          </Stack>

          {loading ? (
            <LoadingIndicator />
          ) : (
            <Stack rowGap="8px">
              <LargeButton
                onClick={handleSubmit((values) => {
                  onSubmit({ ...values });
                })}
                style={{ maxWidth: "100%" }}
                disabled={!isValid || loading}
              >
                確認して次へ
              </LargeButton>
              <LargeButton
                onClick={() => setIsModalOpen(false)}
                style={{ maxWidth: "100%" }}
                variant="blank"
              >
                書き直す
              </LargeButton>
            </Stack>
          )}
        </Stack>
      </BaseModal>
    </>
  );
};

const _BirthDayInputContainer = styled(Row)`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`;

const _ErrorMessage = styled.div`
  position: relative;

  font-weight: 400;
  color: ${({ theme }) => theme.colors.red.r500};
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;

  white-space: pre-wrap;
`;

const _AbsoluteErrorMessage = styled.div`
  position: absolute;
  top: 0;
`;

const _ButtonText = styled.span`
  font-size: ${({ theme }) => theme.fontSize.LG};
`;

const _Wrapper = styled.div`
  padding: 30px 20px;
  margin: 0 auto;
  min-width: 300px;
  max-width: 560px;
  width: 100%;
  box-sizing: border-box;
  padding-bottom: 300px;
`;

const _QuestionBox = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;

  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 16px;
  line-height: 160%;
`;

const _BirthDate = styled(Text)`
  width: 100%;
  height: 42px;
  background: ${({ theme }) => theme.colors.base.background};
  border-radius: 12px;

  align-content: center;
  text-align: center;
`;
