import React, { VFC } from "react";
import { Link, LinkProps } from "react-router-dom";
import styled, { css } from "styled-components";

type Part = {
  disabled?: boolean;
};

export type LinkerizeProps = LinkProps & Part;

export const Linkerize: VFC<LinkerizeProps & { children: React.ReactNode }> = ({
  disabled = false,
  children,
  to,
  ...linkProps
}) => {
  return (
    <L to={disabled || !to ? "/#" : to} {...linkProps}>
      {children}
    </L>
  );
};

export type AnchorizeProps = React.AnchorHTMLAttributes<HTMLAnchorElement> &
  Part;

export const Anchorize: VFC<AnchorizeProps & { children: React.ReactNode }> = ({
  disabled = false,
  href,
  children,
  ...anchorProps
}) => {
  return (
    <A
      disabled={disabled}
      href={disabled ? "" : href}
      target="_blank"
      rel="noopener noreferrer"
      {...anchorProps}
    >
      {children}
    </A>
  );
};

const L = styled(Link)`
  text-decoration: none;
`;

const A = styled.a<{ disabled: boolean }>`
  text-decoration: none;
  ${({ disabled }) =>
    disabled &&
    css`
      pointer-events: none;
    `}
`;
