import React from "react";
import { useHistory, useParams } from "react-router";
import { WantReadBookDetailTemplate } from "~/component/templates/WantReadBookDetail";
import { LoadingPage } from "~/component/templates/loading";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import {
  useGetWantReadBookByIdQuery,
  useRemoveWantReadBookMutation,
} from "~/generated/graphql";
import { useCopyToClipboard } from "~/store/bookViewer/useCopyToClipboard";
import { RemoveWantReadBookModal } from "../BookList/wantReadBookListViewerTemplate/RemoveWantReadBookModal";

export const WantReadBookDetail: React.FC = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { copyToClipboardPopup, handleCopyToClipboard } = useCopyToClipboard();
  const { currentUser } = React.useContext(CurrentUserContext);
  const [removeWantReadBookID, setRemoveWantReadBookID] = React.useState<
    number | null
  >(null);
  const history = useHistory();

  const {
    loading: getLoading,
    error,
    data,
  } = useGetWantReadBookByIdQuery({
    variables: {
      id: Number(id),
    },
    fetchPolicy: "no-cache",
  });

  const [removeWantReadBookMutation, { loading: removeLoading }] =
    useRemoveWantReadBookMutation({
      variables: { id: removeWantReadBookID || 0 },
      onCompleted: () => {
        setRemoveWantReadBookID(null);
        history.goBack();
      },
    });

  if (getLoading) {
    return <LoadingPage />;
  }
  if (error) {
    return <>Error! {error.message}</>;
  }
  if (!data) {
    return null;
  }

  return (
    <>
      {copyToClipboardPopup}
      <RemoveWantReadBookModal
        onClickReturn={() => setRemoveWantReadBookID(null)}
        onClickRemove={() =>
          new Promise(
            () =>
              new Promise((resolve) =>
                removeWantReadBookMutation()
                  .then(() => resolve(true))
                  .catch(() => resolve(false))
              )
          )
        }
        isOpen={removeWantReadBookID !== null}
        loading={removeLoading}
      />
      <WantReadBookDetailTemplate
        bookInfoProps={{ book: data.getWantReadBookByID.book }}
        handleRemoveWantReadBook={() =>
          setRemoveWantReadBookID(data.getWantReadBookByID.id)
        }
        handleCopyISBN={handleCopyToClipboard}
        myLibraryName={currentUser?.myLibrary?.libraryName}
      />
    </>
  );
};
