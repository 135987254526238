import React from "react";
import styled, { css } from "styled-components";
import { IconButton } from "~/component/molecules/button/IconButton";
import { AllColorTheme } from "~/component/color";
import { ColorTheme } from "~/component/theme";
import { IconType } from "~/component/atoms/icon";
import { PageTitle } from "~/component/molecules/text/PageTitle";
import { TextButton } from "~/component/molecules/button/TextButton";

type RightBlockProps = {
  onClick: (event: React.MouseEvent) => void;
} & (
  | {
      textProps: {
        textColor: AllColorTheme;
        text: string;
      };
    }
  | {
      iconProps: {
        icon: IconType;
        iconColor: ColorTheme;
      };
    }
);

type CenterBlockProps = {
  text: string;
};

type LeftBlockProps = {
  onClick: (event: React.MouseEvent) => void;
  arrowColor: ColorTheme;
};

export type TransparentBackNavbarProps = {
  withBlur: boolean;
  leftBlock?: LeftBlockProps;
  centerBlock?: CenterBlockProps;
  rightBlock?: RightBlockProps;
};

export const TransparentBackNavbar: React.FC<TransparentBackNavbarProps> = ({
  withBlur,
  leftBlock,
  centerBlock,
  rightBlock,
}) => {
  return (
    <_Container withBlur={withBlur}>
      {leftBlock ? (
        <IconButton
          onClick={leftBlock.onClick}
          color={leftBlock.arrowColor}
          name={"arrowLeft"}
          fontSize={"XXXL"}
          disabled={false}
        />
      ) : (
        <_DummyBlock />
      )}
      {centerBlock && <PageTitle>{centerBlock.text}</PageTitle>}
      {rightBlock ? (
        "textProps" in rightBlock ? (
          <TextButton
            fontSize="MD"
            fontColor={rightBlock.textProps.textColor}
            lineHeight="EQ"
            onClick={rightBlock.onClick}
            text={rightBlock.textProps.text}
          />
        ) : (
          <IconButton
            onClick={rightBlock.onClick}
            color={rightBlock.iconProps.iconColor}
            name={rightBlock.iconProps.icon}
            fontSize={"XXXL"}
            disabled={false}
          />
        )
      ) : (
        <_DummyBlock />
      )}
    </_Container>
  );
};

const _Container = styled.div<{ withBlur: boolean }>`
  width: 100%;

  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 5%;

  ${({ withBlur }) =>
    withBlur &&
    css`
      background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.85) 0%,
        rgba(0, 0, 0, 0) 100%
      );
    `}
`;

const _DummyBlock = styled.div`
  width: 20px;
`;
