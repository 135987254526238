import React, { useState } from "react";
import sortBy from "lodash/sortBy";
import moment from "moment-timezone";
import { ContentsContainer } from "../../templates/box";
import { IconTitle } from "../../atoms/heading/icon_title";
import { PlainText } from "../../atoms/text";
import {
  ButtonContainer,
  AnnotationBelowButton,
} from "../../templates/button_container";
import { HeadContainer } from "../../templates/box";
import {
  UserProps,
  SUBSCRIPTION_TRIAL,
  SUBSCRIPTION_ACTIVE,
  formatDate,
  MONTH_DAY_FORMAT,
  UsersProps,
} from "../../../utils/subscription_config";
import { PopupWrapper } from "../../templates/wrapper";
import { IndicateMutationState } from "../modal/indicate_mutation_state";
import { useCancelSubscriptionMutation } from "../../../generated/graphql";
import { ModalTransitionButton } from "../../atoms/button/modal_transition_button";
import { CancelModal } from "../modal/cancel_modal";
import { QuitGuideLink } from "../../../../../util/constant";
import { useHistory } from "react-router";
import { TextAnchor } from "../../atoms/text/text_anchor";
import { SubscriptionsClientContext } from "~/contexts/CustomApolloProvider";

type CancelWrapperProps = {
  user: UserProps;
  users: UsersProps;
  refetchData: () => void;
};

export const CancelWrapper: React.VFC<CancelWrapperProps> = ({
  user,
  users,
  refetchData,
}) => {
  const { subscriptionsClient } = React.useContext(SubscriptionsClientContext);

  const [cancelSubscription, { loading }] = useCancelSubscriptionMutation({
    client: subscriptionsClient,

    async onCompleted() {
      await refetchData();
      setMutationMessage(
        `ニックネーム:${user.nickname}の休会処理が完了しました`
      );
      setMutationState("success");
    },

    onError(e) {
      console.error("Server error:", e?.message);
      setMutationMessage("問題が発生しました");
      setMutationState("fail");
      setCancelModalIsOpen(false);
    },
  });
  const history = useHistory();

  const cancel = () => {
    if (user.subscriptionId) {
      cancelSubscription({
        variables: { id: user.subscriptionId },
      });
      history.push(
        `/quit_enquete?userId=${user.id}&birthday=${user.birthday}&subscriptionCreatedAt=${user.subscriptionCreatedAt}&childName=${user.nickname}`
      );
    }
  };

  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setCancelModalIsOpen(true);
  };
  const handleModalClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setCancelModalIsOpen(false);
  };

  const getPreviousOfPaymentDate = () => {
    if (
      user.subscriptionStatus === SUBSCRIPTION_TRIAL &&
      user.subscriptionTrialEndsAt
    ) {
      return formatDate(user.subscriptionTrialEndsAt, MONTH_DAY_FORMAT);
    } else if (
      user.subscriptionStatus === SUBSCRIPTION_ACTIVE &&
      user.subscriptionCurrentPeriodEndsAt
    ) {
      return formatDate(user.subscriptionCurrentPeriodEndsAt, MONTH_DAY_FORMAT);
    } else {
      return "次期受講料のお支払日の前日";
    }
  };

  const messageList = React.useMemo(() => {
    let _checkUser: UserProps | undefined = undefined;
    if (user.planPrimary) {
      const activeUsers = users.filter((u) => {
        return (
          !u.planPrimary &&
          (u.subscriptionStatus === SUBSCRIPTION_ACTIVE ||
            u.subscriptionStatus === SUBSCRIPTION_TRIAL)
        );
      });

      _checkUser = sortBy(activeUsers, (u) => {
        // return moment(u.createdAt);
        return moment(u.id); // createdAtという存在しないプロパティが使われていたので代わりにidを使用
      })[0];
    }

    if (_checkUser) {
      return buildMessages(user, _checkUser);
    } else {
      return [];
    }
  }, [users, user]);

  const [mutationState, setMutationState] = useState("idle");
  const [mutationMessage, setMutationMessage] = useState("");
  const [cancelModalIsOpen, setCancelModalIsOpen] = useState(false);
  const subscriptionExists = !!user.subscriptionCurrentPeriodStartsAt;

  const ableToCancel =
    subscriptionExists &&
    (user.subscriptionStatus === SUBSCRIPTION_TRIAL ||
      user.subscriptionStatus === SUBSCRIPTION_ACTIVE);

  return (
    <>
      <ContentsContainer>
        <HeadContainer>
          <IconTitle
            iconPath={"/img/chat-icon.svg"}
            title={"休会手続きについて"}
          />
          <PlainText>
            {ableToCancel
              ? `${getPreviousOfPaymentDate()}までに休会手続きをされた場合、次期受講料の請求は発生いたしません。`
              : "カード情報を入力しない場合、無料期間終了後に自動的に休会となります。"}
          </PlainText>
        </HeadContainer>
        <ButtonContainer>
          <ModalTransitionButton
            onClick={handleClick}
            color={"red"}
            disabled={!ableToCancel}
          >
            休会手続きをする
          </ModalTransitionButton>
          <AnnotationBelowButton>
            <TextAnchor
              href={QuitGuideLink}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              休会手続きについて
            </TextAnchor>
          </AnnotationBelowButton>
        </ButtonContainer>
      </ContentsContainer>
      <CancelModal
        isOpen={cancelModalIsOpen}
        onClose={handleModalClose}
        messageList={messageList}
        cancel={cancel}
        cancelProcessLoading={loading}
      />
      <PopupWrapper>
        <IndicateMutationState
          displayedMessage={mutationMessage}
          mutationState={mutationState}
          setMutationState={setMutationState}
        />
      </PopupWrapper>
    </>
  );
};

const buildMessages = (
  user: UserProps,
  userWillTransition: UserProps
): string[] => {
  const messages: string[] = [];
  if (user.subscriptionStatus === SUBSCRIPTION_TRIAL) {
    messages.push(
      `無料期間は${user.subscriptionRemainingTrialDays}日残っています。無料期間中は引き続きアプリを利用いただけます。`
    );
  } else if (
    user.subscriptionStatus === SUBSCRIPTION_ACTIVE &&
    user.subscriptionCurrentPeriodEndsAt
  ) {
    messages.push(
      `このアカウントは${formatDate(
        user.subscriptionCurrentPeriodEndsAt,
        MONTH_DAY_FORMAT
      )}までご利用いただけます。`
    );
  }

  if (userWillTransition) {
    messages.push(
      `休会により、${userWillTransition.nickname}様がごきょうだい様プランからレギュラープランに転換します。`
    );
  }
  return messages;
};
