import React from "react";
import { FirstSurvey } from "../types";
import { useScale } from "../ui/constants";
import { Conversation } from "../ui/Conversation";

export type SenseiDialog2Props = {
  onNext: () => void;
  firstSurvey: FirstSurvey;
};

export const SenseiDialog2: React.FC<SenseiDialog2Props> = ({
  onNext,
  firstSurvey,
}) => {
  const scale = useScale();

  const userNickname = firstSurvey.userNickname || "";
  return (
    <Conversation
      scale={scale}
      imgSrc={"/img/sensei/standing.svg"}
      messageProps={{
        speaker: "ヨンデミー先生",
        message: `ありがとう😆✨\nふむふむ......\n${userNickname}さんにぴったりのほんは...... !`,
      }}
      onNext={onNext}
    />
  );
};
