import { yupResolver } from "@hookform/resolvers/yup";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import * as yup from "yup";
import { BookLikeability } from "~/generated/graphql";
import { NicknameMaxLength } from "../../../../../util/constant";
import { Validation_StringLengthBySegmenter } from "../../../../../util/validation";
import { FirstSurvey, YLCheckType } from "../types";
import { useScale } from "../ui/constants";
import { Conversation } from "../ui/Conversation";
import { InputQuestion } from "../ui/Dialog/InputQuestion";
import { SelectQuestion } from "../ui/Dialog/SelectQuestion";
import { ImageLoader } from "../ui/ImageLoader";
import {
  allImages,
  assetImages,
  bookLikeabiltyButtons,
  ylCheckTypeButtons,
  confirmNameButtons,
  getFlows,
} from "./constant";
import { useNicknameValidation } from "./hook";
import { NavContainer } from "~/component/molecules/NavContainer";
import { TransparentBackNavbar } from "~/component/molecules/TransparentBackNavbar";

export type SenseiDialog1Props = {
  onNext: () => void;
  firstSurvey: FirstSurvey;
  updateFirstSurvey: (firstSurvey: FirstSurvey) => void;
  index: number;
  setIndex: React.Dispatch<React.SetStateAction<number>>;
  onBack: () => void;
};

type FormValues = {
  nickname: string;
};

const getSchema = (
  validationUserNickname: (nickname: string) => Promise<boolean>
): yup.SchemaOf<FormValues> => {
  return yup.object().shape({
    nickname: yup
      .string()
      .required("ニックネームをにゅうりょくしてね！")
      .test(
        "characters",
        `ニックネームは ${NicknameMaxLength}もじ までにしてね！`,
        (string) => {
          return Validation_StringLengthBySegmenter(
            string,
            1,
            NicknameMaxLength
          );
        }
      )
      .test(
        "validCharacter",
        "つかえない きごうや ことばが はいっているよ！",
        validationUserNickname
      ),
  });
};

export const SenseiDialog1: React.FC<SenseiDialog1Props> = ({
  onNext,
  firstSurvey,
  updateFirstSurvey,
  index,
  setIndex,
  onBack,
}) => {
  const [isHateBook, setIsHateBook] = React.useState<boolean>(false);
  const { validationUserNickname, loading } = useNicknameValidation();
  const schema = getSchema(validationUserNickname);
  const {
    control,
    handleSubmit,
    formState: { isValid, errors },
  } = useForm<FormValues>({
    mode: "onChange",
    defaultValues: {
      nickname: firstSurvey.userNickname || "",
    },
    resolver: yupResolver(schema),
  });

  const [flow, toNext] = React.useMemo(() => {
    const nickname = firstSurvey.userNickname || "";

    const _toNextPage = () => {
      onNext();
    };

    const _toNext = () => {
      setIndex((i) => i + 1);
    };

    const _toPrev = () => {
      setIndex((i) => i - 1);
    };

    const _updateBookLikeability = (bookLikeability: BookLikeability) => {
      setIsHateBook(
        bookLikeability === BookLikeability.VeryNotLike ||
          bookLikeability === BookLikeability.NotLike
      );

      updateFirstSurvey({ ...firstSurvey, bookLikeability: bookLikeability });
      _toNext();
    };

    const _updateYLCheckType = (ylCheckType: YLCheckType) => {
      updateFirstSurvey({ ...firstSurvey, ylCheckType: ylCheckType });
      _toNext();
    };

    const _tmpInputQuestionProps = {
      onClick: handleSubmit((values) => {
        _toNext();
        window.scrollTo({ top: 0 });
        updateFirstSurvey({ ...firstSurvey, userNickname: values.nickname });
      }),
    };

    const _flows = getFlows(
      nickname,
      confirmNameButtons(_toNext, _toPrev),
      bookLikeabiltyButtons(_updateBookLikeability),
      ylCheckTypeButtons(_updateYLCheckType),
      _tmpInputQuestionProps,
      isHateBook
    );

    const _toNextWithBranch = () => {
      if (index === _flows.length - 1) {
        _toNextPage();
      } else {
        _toNext();
      }
    };

    const _flow = _flows[index];
    return [_flow, _toNextWithBranch];
  }, [
    index,
    setIndex,
    firstSurvey,
    updateFirstSurvey,
    handleSubmit,
    onNext,
    isHateBook,
  ]);

  const scale = useScale();

  const [allImagesLoaded, setAllImagesLoaded] = useState<boolean>(false);
  if (!allImagesLoaded) {
    return (
      <ImageLoader
        images={[...allImages, ...assetImages]}
        onCompleted={() => setAllImagesLoaded(true)}
      />
    );
  }

  if (!flow) {
    return null;
  }

  const _handleBack = () => {
    if (index === 0) {
      onBack();
      return;
    }
    setIndex((i) => i - 1);
  };

  return (
    <>
      <NavContainer>
        <TransparentBackNavbar
          withBlur={true}
          leftBlock={
            index === 0
              ? undefined
              : { arrowColor: "pri", onClick: _handleBack }
          }
        />
      </NavContainer>
      {flow.messageProps && (
        <Conversation
          scale={scale}
          imgSrc={flow.imgSrc}
          messageProps={flow.messageProps}
          onNext={toNext}
        />
      )}
      {flow.selectQuestion && (
        <SelectQuestion scale={scale} {...flow.selectQuestion} />
      )}
      {flow.inputQuestion && (
        <Controller
          control={control}
          name="nickname"
          render={({ field: { value, onChange } }) => {
            return (
              <InputQuestion
                {...flow.inputQuestion}
                scale={scale}
                value={value}
                onChange={onChange}
                error={!isValid}
                errorMessage={errors?.nickname?.message}
                disabled={loading}
              />
            );
          }}
        />
      )}
    </>
  );
};
