import * as React from "react";
import styled from "styled-components";

export const TapHint: React.FC = () => {
  return (
    <_Container>
      タップしてつぎにすすめるよ
      <_TailWrapper>
        <_Tail />
      </_TailWrapper>
    </_Container>
  );
};

const _Container = styled.div`
  width: 240px;
  padding: 10px;
  background-color: white;
  position: relative;
  box-sizing: border-box;

  text-align: center;
  color: ${({ theme }) => theme.colors.pri.p500};
  font-size: ${({ theme }) => theme.fontSize.MD};
  font-family: "M PLUS 1 Code", sans-serif;
  font-weight: 700;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
  opacity: 0;

  animation: fadeIn 0.2s ease-in-out;
  animation-fill-mode: forwards;
  animation-delay: 0.5s;

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
`;

const _TailWrapper = styled.div`
  position: absolute;
  bottom: -9px;
  width: 100%;

  display: flex;
  justify-content: center;
`;
const _Tail: React.FC = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 10L0.937822 0.25L13.0622 0.249999L7 10Z" fill="white" />
  </svg>
);
