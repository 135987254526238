import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";

const soundMap = {
  initializeAudio: { src: "/audio/InitializeAudio.mp3", level: 0 },
  light: { src: "/audio/Light.mp3", level: 0.25 },
  searchBook: { src: "/audio/SearchBook.mp3", level: 0.06 },
};

type SoundKey = keyof typeof soundMap;

type UseAudioParams = {
  preload: SoundKey[];
};

type LoadingStatus = "loading" | "loaded";

const _buffers = makeVar<Record<string, AudioBuffer>>({});
const _loadingStatus = makeVar<Record<string, LoadingStatus>>({});

const AudioContext = window.AudioContext || window.webkitAudioContext;
const ctx = new AudioContext();

export const useAudio = ({
  preload,
}: UseAudioParams): {
  play: (key: SoundKey, delay?: number) => (() => void) | undefined;
} => {
  const loadingStatus = useReactiveVar(_loadingStatus);
  const buffers = useReactiveVar(_buffers);

  useEffect(() => {
    preload.forEach((p) => {
      if (loadingStatus[p] || !soundMap[p]) {
        return;
      }
      loadingStatus[p] = "loading";

      const src = soundMap[p].src;
      const request = new XMLHttpRequest();
      request.open("GET", src, true);
      request.responseType = "arraybuffer";
      request.onload = () => {
        const res = request.response;
        ctx.decodeAudioData(res, (buf) => {
          buffers[p] = buf;
          loadingStatus[p] = "loaded";
        });
      };

      request.send();
    });
  }, [preload]);

  const play = useCallback(
    (key: SoundKey, delay = 0) => {
      if (buffers[key]) {
        const source = ctx.createBufferSource();
        source.buffer = buffers[key];

        const gainNode = ctx.createGain();
        const level = soundMap[key]?.level || 1.0;
        const volume = level;
        gainNode.gain.value = volume;
        gainNode.connect(ctx.destination);

        source.connect(gainNode);
        if (typeof ctx.currentTime !== "number" || !isFinite(ctx.currentTime)) {
          return () => {
            console.error("fail to start audio device");
          };
        }
        source.start(ctx.currentTime + delay);

        return () => {
          source.stop(ctx.currentTime);
        };
      }
    },
    [buffers]
  );

  return { play };
};
