import { useState } from "react";
import { questions } from "./data";

export type Value = {
  id: string;
  type: string;
  body?: string | ((name: string) => string);
  options?: string[];
  annotation?: string;
};
type Question = {
  id: string;
  question: Value[];
};

type ReturnValue = {
  current: Question;
  next: () => void;
  back: () => void;
  move: (n: number) => void;
};

export const useQuestion = (): ReturnValue => {
  const [current, setCurrent] = useState<Question>(questions[0]);

  const next = () => {
    const currentIndex = questions.findIndex(
      (question) => question.id === current.id
    );
    const nextIndex = currentIndex + 1;
    const nextQuestion = questions[nextIndex];
    setCurrent(nextQuestion);
  };

  const back = () => {
    const currentIndex = questions.findIndex(
      (question) => question.id === current.id
    );
    const nextIndex = currentIndex - 1;
    const nextQuestion = questions[nextIndex];
    setCurrent(nextQuestion);
  };

  const move = (n: number) => {
    setCurrent(questions[n]);
  };

  return {
    current,
    next,
    back,
    move,
  };
};
