import * as React from "react";
import { FirstSurvey } from "~/pages/WorldViewOnboarding/types";
import { DifficultyYL, PassageYL } from "../WorldViewOnboarding/YLCheck/types";
import {
  useRegisterAdditionalUserMutation,
  ErrorUserNumExceeded,
  SampleDifficultyPerception,
  DetailedFirstRecommendEnqueteDifficulties,
  DetailedFirstRecommendEnqueteVersion,
} from "~/generated/graphql";
import { FormValues, RegisterUser } from ".";

type RegisterAdditionalUserContainerProps = {
  firstSurvey: FirstSurvey;
  difficulties: Record<PassageYL, DifficultyYL>;
  detailDifficulties: Record<
    PassageYL,
    DetailedFirstRecommendEnqueteDifficulties
  >;
  onNext: (userId: number) => void;
  sampleDifficultyPerception: SampleDifficultyPerception;
  suitableYL: PassageYL;
};

//2人目以降のお子さんの登録の際に、AuthorizedContainerの中で呼ばれる
export const RegisterAdditionalUserContainer: React.FC<
  RegisterAdditionalUserContainerProps
> = ({
  firstSurvey,
  difficulties,
  detailDifficulties,
  onNext,
  sampleDifficultyPerception,
  suitableYL,
}) => {
  const [isError, setIsError] = React.useState(false);
  const [userNumExceededErr, setUserNumExceededErr] = React.useState<
    ErrorUserNumExceeded | undefined
  >(undefined);

  const [registerAdditionalUser, { loading: regiserAdditionalUserLoading }] =
    useRegisterAdditionalUserMutation({
      onError: () => {
        setIsError(true);
      },
    });

  const handleSubmit = React.useCallback(
    (values: FormValues): Promise<boolean> => {
      return new Promise((resolve) => {
        setIsError(false);
        if (!firstSurvey.userNickname || !firstSurvey.bookLikeability) {
          resolve(false);
          setIsError(true);
          return;
        }
        registerAdditionalUser({
          variables: {
            input: {
              userNickname: firstSurvey.userNickname,
              birthday: new Date(
                Number(values.birthYear),
                Number(values.birthMonth) - 1,
                Number(values.birthDay)
              ).toISOString(),

              ...(firstSurvey.ylCheckType === "Simple"
                ? {
                    simpleEnqueteInput: {
                      bookLikeability: firstSurvey.bookLikeability,
                      mostFavoriteBookGenre:
                        firstSurvey.mostFavoriteBookGenre === "Omakase"
                          ? undefined
                          : firstSurvey.mostFavoriteBookGenre,
                      favoriteBookGenres: firstSurvey.favoriteBookGenres,
                      difficultyYL15: difficulties[15],
                      difficultyYL20: difficulties[20],
                      difficultyYL25: difficulties[25],
                      difficultyYL30: difficulties[30],
                      difficultyYL35: difficulties[35],
                      difficultyYL40: difficulties[40],
                      difficultyYL45: difficulties[45],
                      difficultyYL50: difficulties[50],
                    },
                  }
                : {
                    detailedEnqueteInput: {
                      bookLikeability: firstSurvey.bookLikeability,
                      mostFavoriteBookGenre:
                        firstSurvey.mostFavoriteBookGenre === "Omakase"
                          ? undefined
                          : firstSurvey.mostFavoriteBookGenre,
                      favoriteBookGenres: firstSurvey.favoriteBookGenres,
                      sampleDifficultyPerception: sampleDifficultyPerception,
                      suitableYL: suitableYL,
                      yl15difficulties: detailDifficulties[15],
                      yl20difficulties: detailDifficulties[20],
                      yl25difficulties: detailDifficulties[25],
                      yl30difficulties: detailDifficulties[30],
                      yl35difficulties: detailDifficulties[35],
                      yl40difficulties: detailDifficulties[40],
                      yl45difficulties: detailDifficulties[45],
                      yl50difficulties: detailDifficulties[50],
                      version: DetailedFirstRecommendEnqueteVersion.Version_1,
                    },
                  }),
            },
          },
        })
          .then((res) => {
            if (
              res.data?.registerAdditionalUser.__typename === "User" &&
              res.data.registerAdditionalUser.id
            ) {
              resolve(true);
              onNext(res.data.registerAdditionalUser.id);
            } else if (
              res.data?.registerAdditionalUser.__typename ===
              "ErrorUserNumExceeded"
            ) {
              setUserNumExceededErr(res.data.registerAdditionalUser);
              resolve(false);
            } else {
              resolve(false);
            }
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [
      registerAdditionalUser,
      difficulties,
      firstSurvey,
      onNext,
      detailDifficulties,
      sampleDifficultyPerception,
      suitableYL,
    ]
  );

  return (
    <RegisterUser
      needProgress={false}
      onSubmit={handleSubmit}
      loading={regiserAdditionalUserLoading}
      isError={isError}
      userNumExceededErr={userNumExceededErr}
    />
  );
};
