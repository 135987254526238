import React from "react";
import styled from "styled-components";
import { BaseModal } from "../../atoms/modal/base_modal";
import { ModalTransitionButton } from "../../atoms/button/modal_transition_button";

type CancelModalProps = {
  isOpen: boolean;
  onClose: (event: React.MouseEvent) => void;
  messageList: string[];
  cancel: () => void;
  cancelProcessLoading: boolean;
};

export const CancelModal: React.VFC<CancelModalProps> = ({
  isOpen,
  onClose,
  messageList,
  cancel,
  cancelProcessLoading,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    cancel();
    onClose(event);
  };
  const handleBack = (event: React.MouseEvent) => {
    event.preventDefault();
    onClose(event);
  };
  return (
    <BaseModal title={"cancel"} isOpen={isOpen} onClose={onClose}>
      <Header>
        <Heading>休会してもよろしいでしょうか？</Heading>
        <SubHeading>
          休会すると、次回決済日以降にアプリにログインできなくなります。
        </SubHeading>
      </Header>
      <Body>
        <ul style={{ listStyle: "none" }}>
          {messageList.map((message, i) => (
            <Message key={`${message}-${i}`}>{message}</Message>
          ))}
        </ul>
      </Body>
      <ButtonCon>
        <ModalTransitionButton
          onClick={handleClick}
          color={"red"}
          disabled={cancelProcessLoading}
        >
          {cancelProcessLoading ? "手続き中" : "休会する"}
        </ModalTransitionButton>
      </ButtonCon>
      <ButtonCon>
        <ModalTransitionButton
          onClick={handleBack}
          color={"gray"}
          disabled={cancelProcessLoading}
        >
          戻る
        </ModalTransitionButton>
      </ButtonCon>
    </BaseModal>
  );
};

const Header = styled.div`
  width: 100%;
  padding-bottom: 30px;
  border-bottom: 1px solid ${(props) => props.theme.colors.base.darkGray};
`;

const Body = styled.div`
  width: 100%;
  padding-top: 30px;
`;

const ButtonCon = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Heading = styled.h3`
  font-size: 18px;
  font-weight: bold;
  line-height: 170%;
  color: ${(props) => props.theme.colors.tex.t700};
`;

const SubHeading = styled.h4`
  font-size: 16px;
  font-weight: normal;
  line-height: 170%;
  color: ${(props) => props.theme.colors.tex.t300};
`;

const Message = styled.li`
  font-size: 14px;
  line-height: 160%;
  color: ${(props) => props.theme.colors.tex.t700};
`;
