import React, { VFC, HTMLAttributes } from "react";
import styled, { css } from "styled-components";
import {
  cogDuration,
  containerRotate,
  fillUnfillRotate,
  leftSpin,
  line1FadeInOut,
  line2FadeInOut,
  line3FadeInOut,
  line4FadeInOut,
  lineDuration,
  rightSpin,
  spinnerEasing,
} from "./loadingAnimation";

const VisuallyHiddenInterpolation = css`
  position: absolute;
  top: -1px;
  left: 0;
  width: 1px;
  height: 1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
`;

type Props = {
  size?: "s" | "m";
  text?: string;
  type?: "primary" | "light";
};

type ElementProps = Omit<HTMLAttributes<HTMLDivElement>, keyof Props>;

type LoadingProps = Props & ElementProps;

//smarthr-uiのUI要素を一部変更して使っています
//OSSなので問題ないと思いますが、問題があれば変更します
export const LoadingIndicator: VFC<LoadingProps> = ({
  size = "m",
  text = "",
  type = "primary",
  ...props
}) => {
  return (
    <Wrapper {...props}>
      <Spinner className={size}>
        {[...Array(4)].map((_, index) => (
          <Line className={`line${index + 1} ${type}`} key={index}>
            <Cog>
              <CogInner className="cogInner left"></CogInner>
            </Cog>
            <Ticker>
              <CogInner className="cogInner center"></CogInner>
            </Ticker>
            <Cog>
              <CogInner className="cogInner right"></CogInner>
            </Cog>
          </Line>
        ))}
      </Spinner>
      {text && <Text className={type}>{text}</Text>}
      <VisuallyHidden>Loading</VisuallyHidden>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: inline-block;
`;

const VisuallyHidden = styled.span`
  ${VisuallyHiddenInterpolation}
`;

const Spinner = styled.div`
  position: relative;
  animation: ${containerRotate} 1600ms linear infinite;
  margin: 0 auto;

  &.m {
    width: 48px;
    height: 48px;

    .cogInner {
      border-width: 4px;
    }
  }
  &.s {
    width: 24px;
    height: 24px;

    .cogInner {
      border-width: 2px;
    }
  }
`;

const Line = styled.div`
  ${({ theme }) => {
    return css`
      position: absolute;
      width: 100%;
      height: 100%;
      opacity: 0;

      &.line1 {
        /* stylelint-disable */
        animation: ${fillUnfillRotate} ${lineDuration} ${spinnerEasing} infinite
            both,
          ${line1FadeInOut} ${lineDuration} ${spinnerEasing} infinite both;
      }
      &.line2 {
        animation: ${fillUnfillRotate} ${lineDuration} ${spinnerEasing} infinite
            both,
          ${line2FadeInOut} ${lineDuration} ${spinnerEasing} infinite both;
      }
      &.line3 {
        animation: ${fillUnfillRotate} ${lineDuration} ${spinnerEasing} infinite
            both,
          ${line3FadeInOut} ${lineDuration} ${spinnerEasing} infinite both;
      }
      &.line4 {
        animation: ${fillUnfillRotate} ${lineDuration} ${spinnerEasing} infinite
            both,
          ${line4FadeInOut} ${lineDuration} ${spinnerEasing} infinite both;
      }
      /* stylelint-enable */

      &.primary {
        border-color: ${theme.colors.pri.p400};
      }
      &.light {
        border-color: ${theme.colors.base.white};
      }
    `;
  }}
`;

const Cog = styled.div`
  display: inline-block;
  position: relative;
  width: 50%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
`;

const CogInner = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 200%;
  box-sizing: border-box;
  height: 100%;
  border-style: solid;
  border-color: inherit;
  border-bottom-color: transparent;
  border-radius: 50%;
  animation: none;

  &.left {
    border-right-color: transparent;
    transform: rotate(129deg);
    animation: ${leftSpin} ${cogDuration} ${spinnerEasing} infinite both;
  }
  &.center {
    width: 1000%;
    left: -450%;
  }
  &.right {
    left: -100%;
    border-left-color: transparent;
    transform: rotate(-129deg);
    animation: ${rightSpin} ${cogDuration} ${spinnerEasing} infinite both;
  }
`;

const Ticker = styled.div`
  position: absolute;
  box-sizing: border-box;
  top: 0;
  left: 45%;
  width: 10%;
  height: 100%;
  overflow: hidden;
  border-color: inherit;
`;

const Text = styled.p`
  ${({ theme }) => {
    return css`
      margin-top: 12px;
      font-size: 1rem;
      text-align: center;

      &.primary {
        color: ${theme.colors.tex.t700};
      }
      &.light {
        color: ${theme.colors.base.white};
      }
    `;
  }}
`;
