import { ConfigType } from "./type";

export const stgConf: ConfigType = {
  ENV: "staging",

  PAGE_PREFIX: "/",
  API_HOST: "https://api.parent.alnair.stg.gotoloop.dev/",
  SUBSCRIPTIONS_API_HOST:
    "https://subscriptions.parent.alnair.stg.gotoloop.dev",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 4,

  ACCESS_TOKEN_HEADER: "X-Access-Token",

  CERTIFICATE_TOKEN_HEADER: "X-Certificate-Token",

  USER_APP: "https://user.alnair.stg.gotoloop.dev/",
  PARENT_APP: "https://parent.alnair.stg.gotoloop.dev/",
  PAY_JP_PUBLIC_KEY: "pk_test_b42384bc5023b616b8144cc8",

  GA_MEASUREMENT_ID: null,
  RECAPTCHA_KEY: "6Ld_F_4ZAAAAAIYIPlXBuDfx-6W63oGXup1WNR9O",
  GOOGLE_TAG_MANAGER_ID: null,
  SENTRY_DSN:
    "https://2ce807d4c2cf45a8b59c835f0cd9937f@o967000.ingest.sentry.io/4504483415916544",

  AUTH0_DOMAIN: "yondemy-stg.jp.auth0.com",
  AUTH0_CLIENT_ID: "8wxRuQqmU242ncgnw5L63CeVhCu4FE84",
  AUTH0_AUDIENCE: "https://api.parent.alnair.stg.gotoloop.dev",
  NOTIFICATION_LINE_LINK: "https://lin.ee/GXYjjXG",
};
