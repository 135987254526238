import * as React from "react";
import styled from "styled-components";

type InputProps = {
  size: "lg" | "sm";
  type?: "text" | "number" | "password";
  value: string | number;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  onBlur?: () => void;
  placeholder?: string;
  label?: string;
  annotation?: string;
  autoFocus?: boolean;
  unit?: string;
  error?: boolean;
  autoComplete?: string;
};

export const DeprecatedInput: React.VFC<InputProps> = ({
  size,
  type = "text",
  value,
  onChange,
  placeholder = "",
  label = "",
  annotation = "",
  autoFocus = false,
  unit,
  error = false,
  onBlur,
  autoComplete,
}) => {
   
  switch (size) {
    case "lg":
      return (
        <Con>
          <LabelCon>
            <Label>
              {label}
              <Annot>{annotation}</Annot>
              <BodyCon>
                <InputEl
                  autoFocus={autoFocus}
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={onChange}
                  error={error}
                  onBlur={onBlur}
                  autoComplete={autoComplete}
                />
                {unit && <Unit>{unit}</Unit>}
              </BodyCon>
            </Label>
          </LabelCon>
        </Con>
      );
    case "sm":
      return (
        <HalfCon>
          <LabelCon>
            <LabelSmall>
              {label}
              <BodyCon>
                <InputEl
                  autoFocus={autoFocus}
                  placeholder={placeholder}
                  type={type}
                  value={value}
                  onChange={onChange}
                  error={error}
                />
                {unit && <SmUnit>{unit}</SmUnit>}
              </BodyCon>
            </LabelSmall>
          </LabelCon>
        </HalfCon>
      );
    default:
      return <></>;
  }
};

type InputStyle = {
  error?: boolean;
};

const InputEl = styled.input<InputStyle>`
  width: 100%;
  line-height: 160%;
  color: ${(props) => props.theme.colors.tex.t700};
  font-size: 16px;
  font-family: inherit;
  border-radius: 8px;
  padding: 13px 11px;
  border: 1px solid
    ${(props) =>
      props.error ? props.theme.colors.red.r300 : props.theme.colors.tex.t200};
  transition: all 0.2s ease-out;
  outline: none;
  &:focus {
    border: 1px solid ${(props) => props.theme.colors.pri.p400};
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.tex.t200};
  }
`;

const BodyCon = styled.div`
  margin-top: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Unit = styled.p`
  font-size: 16px;
  color: ${(props) => props.theme.colors.tex.t500};
  margin-left: 12px;
  font-weight: normal;
`;

const SmUnit = styled(Unit)`
  font-size: 14px;
  margin-left: 7px;
`;

const Con = styled.div`
  width: 100%;
`;

const HalfCon = styled.div`
  width: 100%;
  margin: 5px 0;
`;

const LabelCon = styled.div`
  width: 100%;
`;

const Label = styled.label`
  width: 100%;
  font-size: 16px;
  font-family: inherit;
  font-weight: bold;
  color: ${(props) => props.theme.colors.tex.t500};
`;

const LabelSmall = styled.label`
  font-size: 14px;
  font-family: inherit;
  color: ${(props) => props.theme.colors.tex.t500};
`;

const Annot = styled.p`
  font-size: 14px;
  font-weight: normal;
  text-align: justify;
  color: ${(props) => props.theme.colors.tex.t400};
  line-height: 170%;
  font-family: inherit;
  margin: 5px 0;
`;
