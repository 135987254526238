import * as React from "react";

import Header from "../organisms/common/header";
import { MiddleWrapper, PopupWrapper } from "./wrapper";
import { ContentsContainer, HeadContainer, FirstHeadContainer } from "./box";
import { IconTitle } from "../atoms/heading/icon_title";
import {
  hasSubscription,
  hasExpiredSubscription,
  UserProps,
} from "../../utils/subscription_config";
import { PlainText } from "../atoms/text";
import { CancelWrapper } from "../organisms/child/cancel_wrapper";
import { PortalFooter } from "../organisms/common/footer";
import { UserInfoList } from "../organisms/child/user_info_list";
import { PaymentInfoList } from "../organisms/child/payment_info_list";
import Billing from "../organisms/child/billing";
import { BreadCrumb, Crumb } from "../atoms/bread_crumb";
import { PageTitle } from "../atoms/heading/page_title";
import { TransitionButton } from "../atoms/button/transition_button";
import { ButtonContainer } from "./button_container";
import { IndicateMutationState } from "../organisms/modal/indicate_mutation_state";

type ChildTemplateProps = {
  user: UserProps;
  users: UserProps[];
  refetchData: () => void;
};

export const ChildTemplate: React.VFC<ChildTemplateProps> = ({
  user,
  users,
  refetchData,
}) => {
  const [mutationState, setMutationState] = React.useState("idle");
  const [mutationMessage, setMutationMessage] = React.useState("");

  return (
    <>
      <Header />
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <BreadCrumb>
              <Crumb.FirstList>
                <Crumb.Link to={"/"}>トップ</Crumb.Link>
              </Crumb.FirstList>
              <Crumb.List>
                <Crumb.Para>{user.nickname}さん</Crumb.Para>
              </Crumb.List>
            </BreadCrumb>
            <PageTitle>{user.nickname}</PageTitle>
          </FirstHeadContainer>
          <UserInfoList user={user} />
          <ButtonContainer>
            <TransitionButton to={"/child/edit/" + user.id}>
              <span style={{ fontSize: "16px" }}>ニックネーム変更</span>
            </TransitionButton>
          </ButtonContainer>
        </ContentsContainer>
        <ContentsContainer>
          <HeadContainer>
            <IconTitle
              iconPath={"/img/lock-solid-icon.svg"}
              title={"決済情報"}
            />
            <PlainText>
              クレジットカードによる決済を行います。決済日までに決済情報の登録をお願い致します。
            </PlainText>
          </HeadContainer>
          <PaymentInfoList user={user} />

          {(!hasSubscription(user) || hasExpiredSubscription(user)) && (
            <Billing
              user={user}
              onSuccess={async () => {
                await refetchData();
                setMutationMessage("お支払情報登録が完了しました");
                setMutationState("success");
              }}
              onError={() => {
                setMutationMessage(
                  "処理に失敗しました。もう一度お試しください。"
                );
                setMutationState("fail");
              }}
            />
          )}
        </ContentsContainer>
        <CancelWrapper user={user} users={users} refetchData={refetchData} />
      </MiddleWrapper>
      <PortalFooter />

      <PopupWrapper>
        <IndicateMutationState
          displayedMessage={mutationMessage}
          mutationState={mutationState}
          setMutationState={setMutationState}
        />
      </PopupWrapper>
    </>
  );
};
