import * as React from "react";
import { useHistory } from "react-router-dom";
import {
  FLabel,
  FInput,
  RegisterWrapper,
  InputCon,
  ButtonCon,
  Title,
  ForgetButton,
  ForgetCon,
} from "~/component/form_style";
import { SubmitButton } from "~/component/atoms/button/misc_button";
import { ConnectLineModal } from "~/component/organisms/modal/connect_line_modal";
import { useObserver } from "mobx-react";
import { useConnectLineMutation } from "~/generated/graphql";
import { Auth0Context } from "~/contexts/Auth0Context";

export const LoginForLine = (): React.ReactElement => {
  const [id] = React.useState(() => {
    const LINE_CONNECT_ID_KEY = "line_connect_id";
    const query = new URLSearchParams(window.location.search);
    const id = query.get("id");
    if (id) {
      sessionStorage.setItem(LINE_CONNECT_ID_KEY, id);
    } else {
      return sessionStorage.getItem(LINE_CONNECT_ID_KEY);
    }

    return id;
  });
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [isConnected, setIsConnected] = React.useState(false);
  const [authorized, setAuthorized] = React.useState(false);
  const [connectLineMutation, { loading }] = useConnectLineMutation({
    onCompleted() {
      setIsConnected(true);
    },
    onError(e) {
      setIsConnected(false);
      console.error(e);
    },
  });

  const { isAuthenticated, login } = React.useContext(Auth0Context);

  useObserver(async () => {
    if (isAuthenticated && !isModalOpen && !isConnected && id) {
      setIsModalOpen(true);
      setAuthorized(true);

      await connectLineMutation({
        variables: {
          connectionUrlId: id,
        },
      });
    }
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    await login(email, password, {
      redirectUri: `${window.location.origin}/login_for_line`,
    });
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
    history.push("/register_my_library");
  };

  if (loading) {
    return <>Loading</>;
  }

  return (
    <section className="white-back" id="login" style={{ padding: "0px" }}>
      <RegisterWrapper>
        {authorized && (
          <ConnectLineModal
            isOpen={isModalOpen}
            isConnected={isConnected}
            onClose={handleCloseModal}
          />
        )}

        {!isAuthenticated && (
          <>
            <Title>保護者さま向けサイトログイン</Title>
            <form onSubmit={handleSubmit}>
              <InputCon>
                <FLabel>
                  メールアドレス
                  <FInput
                    type="text"
                    value={email}
                    onChange={(event) => setEmail(event.target.value)}
                    isError={false}
                  />
                </FLabel>
              </InputCon>
              <InputCon>
                <FLabel>
                  パスワード
                  <FInput
                    type="password"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                    isError={false}
                  />
                </FLabel>
              </InputCon>
              <ButtonCon>
                <SubmitButton text="ログイン" />
              </ButtonCon>
            </form>
            <form>
              <ForgetCon>
                <ForgetButton onClick={() => history.push("/forget")}>
                  パスワードをお忘れですか？
                </ForgetButton>
              </ForgetCon>
            </form>
          </>
        )}
      </RegisterWrapper>
    </section>
  );
};
