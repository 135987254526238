import React from "react";
import styled from "styled-components";
import { BaseModal } from "~/component/atoms/wrappers/BaseModal";
import { Text } from "~/component/atoms/text/Text";
import { Stack } from "~/component/atoms/layout/Stack";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { Loading } from "~/pages/TempRegister/loading";

export type RemoveWantReadBookModalProps = {
  onClickReturn: () => void;
  onClickRemove: (e: React.MouseEvent) => Promise<boolean>;
  isOpen: boolean;
  loading: boolean;
};

export const RemoveWantReadBookModal: React.FC<
  RemoveWantReadBookModalProps
> = ({ onClickRemove, onClickReturn, isOpen, loading }) => {
  return (
    <BaseModal open={isOpen} onClose={onClickReturn}>
      <_ModalWrapper>
        <Stack rowGap="24px">
          <Text fontColor="tex.t700" fontSize="LG" lineHeight="MD">
            選んだ本を読みま書架から消しますか？
          </Text>
          <Stack rowGap="16px">
            {loading ? (
              <Loading />
            ) : (
              <PrimaryButton color="red" onClick={onClickRemove} noMarginTop>
                <Text
                  lineHeight="EQ"
                  fontSize="LG"
                  bold
                  fontColor="semantic.layout.backgroundBox"
                >
                  消す
                </Text>
              </PrimaryButton>
            )}
            <PrimaryButton onClick={onClickReturn} noMarginTop color="gray">
              <Text
                lineHeight="EQ"
                fontSize="LG"
                bold
                fontColor="semantic.primary.minus1"
              >
                やっぱりやめる
              </Text>
            </PrimaryButton>
          </Stack>
        </Stack>
      </_ModalWrapper>
    </BaseModal>
  );
};

const _ModalWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.MD};
`;
