import React from "react";
import styled from "styled-components";
import { Text } from "~/component/atoms/text/Text";
import {
  PwaTutorialCardItem,
  PwaTutorialCardItemProps,
} from "../PwaTutorialCardItem";
import { CheckBoxWithLabel } from "~/component/molecules/checkboxWithLabel";
import { Stack } from "~/component/atoms/layout/Stack";
import { useHistory } from "react-router";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { A2HSContext } from "~/contexts/A2HSContext";

export type { PwaTutorialCardItemProps };

export type PwaTutorialListProps = {
  items: PwaTutorialCardItemProps[];
};

export const SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY =
  "skip_pwa_tutorial_localstorage_key";

export const PwaTutorialList: React.FC<PwaTutorialListProps> = ({ items }) => {
  const [isSkipFutureDisplay, setIsSkipFutureDisplay] =
    React.useState<boolean>(false);

  const history = useHistory();
  const { setShowPwaTutorial } = React.useContext(A2HSContext);

  React.useEffect(() => {
    let _isSkipFutureDisplay: string | null = null;
    try {
      _isSkipFutureDisplay = JSON.parse(
        localStorage.getItem(SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY) || ""
      );
    } catch (e) {
      console.error(e);
    }
    if (
      _isSkipFutureDisplay !== null &&
      typeof JSON.parse(_isSkipFutureDisplay) === "boolean"
    ) {
      setIsSkipFutureDisplay(JSON.parse(_isSkipFutureDisplay));
    }
  }, []);
  return (
    <Container>
      <TitleContainer>
        <SquareText fontSize="MD" lineHeight="MD" bold>
          保護者サイトを見る前に
        </SquareText>
        <Stack rowGap="12px">
          <PageTitle>保護者アプリをホームから開けるようにしましょう</PageTitle>
          <_Card>
            <_TutorialImg src="/img/pwa/pwa-tutorial.png" />
          </_Card>
          <Text fontSize="SM" lineHeight="MD" fontColor="tex.t500" bold>
            本の予約管理がスムーズにできるようになります。
          </Text>
        </Stack>
      </TitleContainer>
      <ItemsContainer>
        {items.map((item, index) => {
          return (
            <PwaTutorialCardItem
              key={index}
              {...item}
              isHideNext={items.length === index + 1}
            />
          );
        })}
      </ItemsContainer>
      <FooterWrapper>
        <Footer>
          <Description fontSize={"XS"} lineHeight={"MD"}>
            マイページからいつでも設定できます。
          </Description>
          <_Stack rowGap="12px">
            <FooterButton
              onClick={() => {
                history.push("/?skip_pwa=true");
                localStorage.setItem(
                  SKIP_PWA_TUTORIAL_LOCALSTORAGE_KEY,
                  String(isSkipFutureDisplay)
                );
                setShowPwaTutorial(false);
              }}
              color="gray"
              noMarginTop
            >
              <Text fontSize={"MD"} lineHeight={"EQ"} bold fontColor="pri.p400">
                スキップしてログイン
              </Text>
            </FooterButton>
            <CheckBoxWithLabel
              onClick={() => {
                setIsSkipFutureDisplay((v) => !v);
              }}
              isChecked={isSkipFutureDisplay}
              label="今後も表示しない"
              size="SM"
            />
          </_Stack>
        </Footer>
      </FooterWrapper>
    </Container>
  );
};

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  row-gap: 24px;
`;

const PageTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.LG};
  font-weight: 700;
  color: ${({ theme }) => theme.colors.sec.s400};
  text-align: left;
  line-height: 1.4;
`;

const TitleContainer = styled.div``;

const _Card = styled.div`
  max-width: 560px;
  background: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-radius: 12px;
  display: flex;
  justify-content: center;
`;

const _TutorialImg = styled.img`
  width: 100%;
`;

const Footer = styled.div`
  max-width: 500px;
  margin: 0 auto;
`;

const FooterWrapper = styled.div`
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-top: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const ItemsContainer = styled.div`
  padding-bottom: 136px;
`;

const Description = styled(Text)`
  margin-bottom: 8px;
`;

const FooterButton = styled(PrimaryButton)`
  padding: 16px;
`;

const SquareText = styled(Text)`
  width: fit-content;
  color: ${({ theme }) => theme.colors.base.white};
  background: ${({ theme }) => theme.colors.sec.s400};
  border-radius: 5px;
  padding: 8px;
  margin-bottom: 15px;
`;

const _Stack = styled(Stack)`
  width: 100%;
  align-items: center;
`;
