import React from "react";
import styled from "styled-components";
import { Text } from "~/component/atoms/text/Text";
import { Icon } from "~/component/atoms/icon";
import { Stack } from "~/component/atoms/layout/Stack";
import { TextAnchor } from "~/component/molecules/button/TextButton";
import { Heading1 } from "~/component/atoms/text/Heading1";
import { Heading2 } from "~/component/atoms/text/Heading2";
import { OfficialLineLink } from "../../../../util/constant";
import { LargeButton } from "~/component/atoms/button/Button";

export type CustomerSupportContactEntryPage = "login" | "signup";

const OnBackButtonStr: Record<CustomerSupportContactEntryPage, string> = {
  login: "ログイン画面にもどる",
  signup: "アカウント作成画面にもどる",
};

export type CustomerSupportContactTemplateProps = {
  onBack: () => void;
  entryPage: CustomerSupportContactEntryPage;
};

export const CustomerSupportContactTemplate: React.FC<
  CustomerSupportContactTemplateProps
> = ({ onBack, entryPage }) => {
  return (
    <_Wrapper>
      <Stack rowGap="32px" alignItems="center" style={{ width: "100%" }}>
        <Heading1
          fontSize="XL"
          lineHeight="EQ"
          fontColor="semantic.text.heading"
          bold
          style={{ width: "100%", textAlign: "left" }}
        >
          窓口からお問い合わせください
        </Heading1>
        <Stack rowGap="24px" style={{ width: "100%" }}>
          <_WhiteBox>
            <Stack rowGap="12px" alignItems="center" style={{ width: "100%" }}>
              <Stack
                rowGap="8px"
                alignItems="flex-start"
                style={{ width: "100%" }}
              >
                <Heading2
                  fontSize="LG"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  なんでも相談窓口
                </Heading2>
                <Text
                  fontSize="SM"
                  lineHeight="MD"
                  fontColor="semantic.text.body"
                >
                  LINEでお気軽にご相談いただけます。
                </Text>
              </Stack>
              <LargeButton
                onClick={() => {
                  window.open(OfficialLineLink);
                }}
                color="line"
              >
                LINEで友だち追加
              </LargeButton>
            </Stack>
          </_WhiteBox>
          <_WhiteBox>
            <Stack rowGap="12px" style={{ width: "100%" }}>
              <Stack rowGap="8px">
                <Heading2
                  fontSize="LG"
                  lineHeight="EQ"
                  fontColor="semantic.text.heading"
                  bold
                >
                  メール
                </Heading2>
                <Text
                  fontSize="SM"
                  lineHeight="MD"
                  fontColor="semantic.text.body"
                >
                  メールでご相談したい方はこちらのアドレスをご利用ください。
                </Text>
              </Stack>
              <TextAnchor
                href="mailto:cs@yondemy.com"
                lineHeight="MD"
                fontSize="SM"
                text="cs@yondemy.com"
                fontColor="semantic.secondary.main"
              />
            </Stack>
          </_WhiteBox>
        </Stack>
        {/* FIXME: ボタンのフォントサイズ修正 */}
        <LargeButton
          onClick={onBack}
          color="primary"
          leftIcon={<Icon name="arrowLeft" color="white" />}
        >
          {OnBackButtonStr[entryPage]}
        </LargeButton>
      </Stack>
    </_Wrapper>
  );
};

const _WhiteBox = styled.div`
  padding: ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
`;

const _Wrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.size.s4} 7% 0 7%;

  min-height: 100vh;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: ${({ theme }) => theme.size.s4} 10% 0 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    width: 100%;
    padding: ${({ theme }) => theme.size.s4} 20% 0 20%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 100%;
    padding: ${({ theme }) => theme.size.s4} 30% 0 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;
