import React, { ReactNode, VFC } from "react";
import styled from "styled-components";
import { Heading1 } from "~/component/atoms/text/Heading1";

export type PageTitleProps = {
  children: ReactNode;
  className?: string;
};

const PageTitleStyle = styled(Heading1)`
  text-align: center;
  color: ${({ theme }) => theme.colors.tex.t800};
`;

export const PageTitle: VFC<PageTitleProps> = ({
  children,
  className,
}: PageTitleProps) => (
  <PageTitleStyle
    fontSize={"LG"}
    lineHeight={"EQ"}
    bold={true}
    className={className}
  >
    {children}
  </PageTitleStyle>
);
