import React, { FC, useContext, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import styled from "styled-components";
import { TextButton } from "../../atoms/text/text_button";
import { ActionOutsideLinkButton } from "../../atoms/button/action_button";
import { config } from "../../../../config";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { Auth0Context } from "~/contexts/Auth0Context";

const Header: FC = () => {
  const [openMenu, setOpenMenu] = useState(false);
  const history = useHistory();
  const { logout } = useContext(Auth0Context);

  const handleLogout = () => logout();

  const { currentUser } = React.useContext(CurrentUserContext);
  const user_app_link: string =
    currentUser && currentUser.children.count > 1
      ? `${config.USER_APP}/set_user/`
      : config.USER_APP;

  return (
    <>
      <HeaderWrapper>
        <Menu src="/img/menu-icon.svg" onClick={() => setOpenMenu(true)} />
        <TitleWrapper to={"/"}>
          <SubTitle>保護者さま用サイト</SubTitle>
          <Title>ヨンデミー</Title>
        </TitleWrapper>
        <MenuContents>
          {currentUser?.canAddChild && (
            <TextButtonCon>
              <TextButton
                onClick={() => history.push("/register_additional_user")}
                color={"black"}
                style={{ fontSize: "13px" }}
              >
                きょうだい登録
              </TextButton>
            </TextButtonCon>
          )}

          <TextButtonCon>
            <ActionOutsideLinkButton href={user_app_link}>
              お子さんのアプリはこちら
            </ActionOutsideLinkButton>
          </TextButtonCon>
        </MenuContents>
        <ImgLink to={"/profile"}>
          <ProfileImg src={"/img/profile.svg"} alt={"profile-image"} />
        </ImgLink>
      </HeaderWrapper>
      {openMenu && (
        <>
          <ModalBackground onClick={() => setOpenMenu(false)} />
          <Modal>
            <RoundedButtonCon>
              <ActionOutsideLinkButton href={user_app_link}>
                お子さんのアプリはこちら
              </ActionOutsideLinkButton>
            </RoundedButtonCon>
            <SecondLinksWrapper>
              {currentUser?.canAddChild && (
                <FirstLink to={"/register_additional_user"}>
                  きょうだい登録
                </FirstLink>
              )}
              <SecondLink to={"/profile"}>
                保護者さまの登録情報・設定
              </SecondLink>
            </SecondLinksWrapper>
            <ThirdLinksWrapper>
              <LogoutButton onClick={handleLogout}>ログアウト</LogoutButton>
              <span style={{ marginBottom: "7px" }}>利用規約</span>
              <span>プライバシーポリシー</span>
            </ThirdLinksWrapper>
          </Modal>
        </>
      )}
    </>
  );
};

const HeaderWrapper = styled.div`
  position: fixed;
  background: ${(props) => props.theme.colors.base.white};
  display: flex;
  padding: 20px;
  border-bottom: 0.5px solid ${(props) => props.theme.colors.tex.t100};
  width: 100vw;
  justify-content: space-between;
  align-items: center;
  @media screen and (min-width: 768px) {
    padding: 20px 5%;
  }
  @media screen and (min-width: 1024px) {
    padding: 20px 10%;
  }
`;

const Menu = styled.img`
  margin-right: 19px;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const MenuContents = styled.div`
  width: 60%;
  display: flex;
  justify-content: flex-end;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

const TextButtonCon = styled.div`
  padding: 0 10px;
  display: flex;
  align-items: center;
`;

const RoundedButtonCon = styled.div`
  width: 100%;
  padding: 0 20px 23px 0;
`;

const TitleWrapper = styled(Link)`
  display: block;
  padding-top: 8px;
  margin-right: 70px;
`;

const SubTitle = styled.div`
  font-size: 15px;
  color: ${(props) => props.theme.colors.tex.t500};
  font-weight: bold;
  margin-bottom: 10px;
`;

const Title = styled.h1`
  font-size: 17px;
  font-weight: 700;
  color: #e8920a;
`;

const ProfileImg = styled.img`
  width: 100%;
`;

const ImgLink = styled(Link)`
  width: 42px;
  height: 42px;
  display: block;
`;

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: #000000;
  opacity: 0.4;
`;

const Modal = styled.div`
  height: 100vh;
  background: ${(props) => props.theme.colors.base.white};
  position: fixed;
  width: 50%;
  z-index: 100;
  top: 0;
  color: ${(props) => props.theme.colors.tex.t700};
  font-size: 14px;
  padding: 96px 0px 96px 20px;
`;

/* const ChildInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #f9c364;
  margin: 23px 20px 29px 0;
  padding-bottom: 15px;
`;

const ChildInfoLabel = styled(Link)`
  color: ${(props) => props.theme.colors.tex.t300};
  font-size: 11px;
  font-weight: bold;
  margin-bottom: 20px;
  &:hover, &:focus, &:active {
    color: ${(props) => props.theme.colors.tex.t300};
    text-decoration: underline;
  }
`; */

const SecondLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 138px;
  gap: 30px;
`;

const FirstLink = styled(Link)`
  color: ${(props) => props.theme.colors.tex.t600};
  line-height: 1.4;
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.tex.t800};
  }
`;

const SecondLink = styled(FirstLink)``;

/* const ChildInfo = styled(FirstLink)`
  margin: 0 0 16px 24px;
`; */

const ThirdLinksWrapper = styled.div`
  display: flex;
  flex-direction: column;
  font-weight: bold;
  font-size: 11px;
  color: #46739c;
`;

const LogoutButton = styled.span`
  font-size: 15px;
  color: ${(props) => props.theme.colors.tex.t500};
  margin-bottom: 35px;
`;

export default Header;
