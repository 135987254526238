import * as React from "react";
import { WhiteBox } from "../../templates/box";
import { InfoList } from "../../molecules/list/info_list";
import { UserProps, formatDate } from "../../../utils/subscription_config";

export const UserInfoList: React.VFC<{ user: UserProps }> = ({ user }) => {
  const userInfoItems = [
    { label: "ニックネーム", value: user.nickname },
    { label: "誕生日", value: user.birthday ? formatDate(user.birthday) : "" },
  ];
  return (
    <WhiteBox>
      <InfoList list={userInfoItems} />
    </WhiteBox>
  );
};
