import { ConfigType } from "./type";
import { devConf } from "./development";
import { epic0Conf } from "./epic0";
import { epic1Conf } from "./epic1";
import { prodConf } from "./production";
import { stgConf } from "./staging";
import { env } from "../src/utils/env";

const __MODE__ = env("BUILD_MODE");

let conf: ConfigType = devConf;
if (__MODE__ === "epic0") {
  conf = epic0Conf;
}
if (__MODE__ === "epic1") {
  conf = epic1Conf;
}
if (__MODE__ === "production") {
  conf = prodConf;
}
if (__MODE__ === "staging") {
  conf = stgConf;
}

export const config = conf;
