import React, { useState } from "react";
import styled from "styled-components";
import { DetailedFirstRecommendEnqueteDifficulty } from "~/generated/graphql";
import { HeartLine } from "~/pages/WorldViewOnboarding/ui/HeartLine";
import { ReadAllSentence } from "../../ui/ReadAllSentence";
import { SelectDifficulty } from "../../ui/SelectDifficulty";
import { YLEnqueteBackNavbar } from "../../ui/YLEnqueteBackNavbar";
import {
  YLAndSentenceOrder,
  FollowingPassageContent,
  passageDict,
  PassageYL,
  SentenceOrder,
} from "../const";
import { PageType } from "..";

export type FollowingCheckProps = {
  ylAndSentenceOrderArr: YLAndSentenceOrder[];
  onSelectDetailDifficulty: (
    level: PassageYL,
    difficulty: DetailedFirstRecommendEnqueteDifficulty,
    order?: SentenceOrder
  ) => void;
  onNext: () => void;
  pageType: PageType;
  setPageType: (pageType: PageType) => void;
  handleBack: () => void;
};

export const FollowingCheck: React.FC<FollowingCheckProps> = ({
  ylAndSentenceOrderArr,
  onSelectDetailDifficulty,
  onNext,
  pageType,
  setPageType,
  handleBack,
}) => {
  const [enqueteIndex, setEnqueteIndex] = useState(0);

  const _onSelectDifficulty = React.useCallback(
    (difficulty: DetailedFirstRecommendEnqueteDifficulty) => {
      onSelectDetailDifficulty(
        ylAndSentenceOrderArr[enqueteIndex].passageYL,
        difficulty,
        ylAndSentenceOrderArr[enqueteIndex].sentenceOrder
      );
      if (enqueteIndex === ylAndSentenceOrderArr.length - 1) {
        onNext();
        return;
      }
      setEnqueteIndex(enqueteIndex + 1);
      setPageType("readAll");
    },
    [
      enqueteIndex,
      ylAndSentenceOrderArr,
      onNext,
      onSelectDetailDifficulty,
      setPageType,
    ]
  );

  const _handleBack = React.useCallback(() => {
    if (enqueteIndex === 0) {
      handleBack();
    }
    setEnqueteIndex(enqueteIndex - 1);
    setPageType("selectDifficulty");
  }, [enqueteIndex, setPageType, handleBack]);

  const content = React.useMemo<React.ReactNode>(() => {
    const { passageYL, sentenceOrder } = ylAndSentenceOrderArr[enqueteIndex];
    const passageContent: FollowingPassageContent = passageDict[passageYL];
    const { textAndCaption, ...props } = passageContent;
    const sentence = {
      text: textAndCaption[sentenceOrder].text,
      caption: textAndCaption[sentenceOrder].caption,
      ...props,
    };
    switch (pageType) {
      case "readAll":
        return (
          <_LibraryWrapper>
            <YLEnqueteBackNavbar withBlur={true} onBackButton={_handleBack} />
            <_HeartLineWrapper>
              <HeartLine filledHearts={enqueteIndex + 1} />
            </_HeartLineWrapper>
            <ReadAllSentence
              sentence={sentence}
              onNext={() => {
                setPageType("selectDifficulty");
              }}
            />
          </_LibraryWrapper>
        );
      case "selectDifficulty":
        return (
          <_LibraryWrapper>
            <YLEnqueteBackNavbar
              withBlur={true}
              onBackButton={() => setPageType("readAll")}
            />
            <SelectDifficulty
              sentence={sentence}
              onSelect={_onSelectDifficulty}
            />
          </_LibraryWrapper>
        );
    }
  }, [
    _handleBack,
    _onSelectDifficulty,
    pageType,
    enqueteIndex,
    ylAndSentenceOrderArr,
    setPageType,
  ]);

  return <>{content}</>;
};

const _LibraryWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  overflow: hidden;
  background-image: url("/img/world_view_onboarding/bright-bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  position: relative;

  ::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.45);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;

const _HeartLineWrapper = styled.div`
  position: absolute;
  top: 50px;
  left: 50%;
  transform: translateX(-50%);
`;
