import React, { useState } from "react";
import styled from "styled-components";
import { IconButton } from "~/component/molecules/button/IconButton";
import { Heading1 } from "~/component/atoms/text/Heading1";
import { Stack } from "~/component/atoms/layout/Stack";
import { RemoveReason } from "~/generated/graphql";
import { EmojiButton } from "~/component/molecules/button/EmojiButton";
import { Icon } from "~/component/atoms/icon";
import { Text } from "~/component/atoms/text/Text";
import { ConfirmRemoveRecommendModal } from "./ConfirmRemoveRecommendModal";
import { ErrorMessage } from "~/component/molecules/text/ErrorMessage";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { theme } from "~/component/theme";
import { Row } from "~/component/atoms/layout/Row";

export type RemoveRecommendTemplateProps = {
  handleBack: (e: React.MouseEvent) => void;
  handleRemoveRecommend: (removeReason: RemoveReason) => Promise<boolean>;
  handleGotoPostReadLog: () => void;
  loading: boolean;
};

type ButtonFeatures = {
  emoji: string;
  text: string;
};

const buttonFeatureRecord: Record<RemoveReason, ButtonFeatures> = {
  TOO_DIFFICULT: { emoji: "😣", text: "むずかしそうだから" },
  TOO_EASY: { emoji: "😗", text: "かんたんそうだから" },
  NOT_INTERESTING: { emoji: "😒", text: "おもしろくなさそうだから" },
  CANNOT_GET: { emoji: "😔", text: "てにはいらなかったから" },
  HAVE_READ: { emoji: "😏", text: "よんだことあるから" },
};

export const RemoveRecommendTemplate: React.FC<
  RemoveRecommendTemplateProps
> = ({ handleBack, handleRemoveRecommend, handleGotoPostReadLog, loading }) => {
  const [removeReason, setRemoveReason] = useState<RemoveReason | undefined>(
    undefined
  );

  const [isErrorMessageDisplayed, setIsErrorMessageDisplayed] =
    useState<boolean>(false);

  const onClickReturn = (e: React.MouseEvent) => {
    e.preventDefault();
    setRemoveReason(undefined);
  };

  return (
    <>
      <_NavigationWrapper>
        <IconButton
          name={"arrowLeft"}
          onClick={handleBack}
          color={"sec"}
          fontSize={"XXXL"}
        />
      </_NavigationWrapper>
      <_Wrapper>
        <_StackWrapper rowGap="32px">
          <_RemoveRecommendBodyWrapper>
            <_Heading fontSize={"LG"} lineHeight={"MD"} bold>
              削除する理由を
              <br />
              教えてください
            </_Heading>
          </_RemoveRecommendBodyWrapper>
          <_ButtonContainer rowGap="16px">
            {Object.keys(buttonFeatureRecord).map(
              (reason: RemoveReason, index) => (
                <EmojiButton
                  key={index}
                  fontSize="LG"
                  lineHeight="MD"
                  onClick={(e: React.MouseEvent) => {
                    e.preventDefault();
                    setRemoveReason(reason);
                    setIsErrorMessageDisplayed(false);
                  }}
                  color="pri"
                  emoji={buttonFeatureRecord[reason].emoji}
                  text={buttonFeatureRecord[reason].text}
                  bold
                  disabled={loading}
                />
              )
            )}
          </_ButtonContainer>
          <_PostReadLogCardWrapper rowGap="12px">
            <_QuestionIconTextWrapper>
              <Icon
                name="question"
                color="tex"
                style={{ width: `24px`, height: `24px` }}
              />
              <Text color="tex.t700" fontSize="SM" lineHeight="EQ">
                途中まで読んだときは
              </Text>
            </_QuestionIconTextWrapper>
            <PrimaryButton
              color="lightGray"
              onClick={handleGotoPostReadLog}
              disabled={false}
              noMarginTop
            >
              <Row justifyContent="space-between">
                <div></div>
                <Text fontSize="MD" lineHeight="EQ" fontColor="pri.p400" bold>
                  まずは感想を送る
                </Text>
                <Icon
                  name={"arrowRight"}
                  style={{
                    color: theme.colors.pri.p400,
                  }}
                />
              </Row>
            </PrimaryButton>
          </_PostReadLogCardWrapper>
          {isErrorMessageDisplayed && (
            <ErrorMessage>
              本を削除できなかったよ！もう一度試してみてね！
            </ErrorMessage>
          )}
        </_StackWrapper>
      </_Wrapper>
      {removeReason && (
        <ConfirmRemoveRecommendModal
          onClickRemove={(e: React.MouseEvent) => {
            e.preventDefault();
            handleRemoveRecommend(removeReason).then((res) => {
              if (!res) {
                setRemoveReason(undefined);
                setIsErrorMessageDisplayed(true);
              }
            });
          }}
          onClickReturn={onClickReturn}
          isOpen={!!removeReason}
          disabled={loading}
        />
      )}
    </>
  );
};

const _NavigationWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  left: 5%;
  top: 20px;
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;

const _StackWrapper = styled(Stack)`
  align-items: center;
`;
export const _RemoveRecommendBodyWrapper = styled.div`
  padding-top: 60px;
`;

const _Heading = styled(Heading1)`
  color: ${({ theme }) => theme.colors.tex.t700};
  text-align: center;
`;

const _ButtonContainer = styled(Stack)`
  width: 100%;

  align-items: center;
`;

const _PostReadLogCardWrapper = styled(Stack)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 12px;

  border: 2px dashed ${({ theme }) => theme.colors.base.gray};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
`;

const _QuestionIconTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: center;
  gap: 4px;
`;

const _Wrapper = styled.div`
  width: 100%;
  padding: 0 7% 0 7%;
  min-height: 100vh;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: 0 10% 0 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    width: 100%;
    padding: 0 20% 0 20%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 100%;
    padding: 0 30% 0 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;
