import React, { useState, useEffect } from "react";
import styled from "styled-components";

import { config } from "../../../config";
import { TextButtonProps, baseStyle } from "./text/text_button";

declare global {
  interface Window {
    onTokenCreateCallback: any; //eslint-disable-line
    PayjpCheckout: any; //eslint-disable-line
  }
}

interface PayJpCheckoutButtonProps {
  isShown: boolean;
  onTokenCreated: (token: string) => void;
  buttonText: string;
}

export const resetPayjpButton = (buttonText?: string): void => {
  // Reset values
  window.PayjpCheckout.button.value =
    buttonText || window.PayjpCheckout.locale.defaultButton.partial;
  window.PayjpCheckout.button.classList.remove("has-token");
  window.PayjpCheckout.tokenBox.value = "";
};

const PayJpCheckoutButton: React.VFC<PayJpCheckoutButtonProps> = ({
  onTokenCreated,
  buttonText,
}) => {
  const [token, setToken] = useState("");

  useEffect(() => {
    if (!token) return;

    onTokenCreated(token);
    resetPayjpButton(buttonText);
  }, [token]);

  // Callback
  useEffect(() => {
    // Note: It's sad, but this is the only way to catch callback from PayJP's checkout script
    window.onTokenCreateCallback = function () {
      const el = document.querySelector(
        "input[name=payjp-token]"
      ) as HTMLInputElement;

      setToken(el.value);
    };
  }, []);

  // PayJP Script
  useEffect(() => {
    const existingScript = document.getElementById("payJpScript");

    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "payJpScript";
      script.src = "https://checkout.pay.jp/";
      script.className = "payjp-button";
      script.dataset.key = config.PAY_JP_PUBLIC_KEY;
      script.dataset.partial = "true";
      script.dataset.text = buttonText;
      script.dataset.submitText = buttonText;
      script.dataset.onCreated = "onTokenCreateCallback";

      document.getElementById("payjp-root")?.appendChild(script);
    }

    return () => {
      // PayJP checkout JS cleanup
      // Ref: https://tackeyy.com/blog/posts/implement-payjp-checkout-with-vue-spa
      const elem = document.getElementById("payjp-checkout-iframe");
      if (elem?.parentElement) {
        elem.parentElement.remove();
      }
      window.PayjpCheckout = null;
      window.onTokenCreateCallback = null;
    };
  }, []);

  return <PayJpButtonWrapper id="payjp-root" color="orange" />;
};

const PayJpButtonWrapper = styled.span<TextButtonProps>`
  #payjp_checkout_box {
    display: inline;

    > input[type="button"] {
      ${baseStyle}
    }
  }
`;

export default PayJpCheckoutButton;
