import { useCallback } from "react";
import { useIsValidUserNicknameLazyQuery } from "~/generated/graphql";

export const useNicknameValidation = (): {
  validationUserNickname: (nickname: string) => Promise<boolean>;
  loading: boolean;
} => {
  const [isValidUserNickname, { loading }] = useIsValidUserNicknameLazyQuery({
    fetchPolicy: "network-only",
  });

  const validationUserNickname = useCallback(
    (nickname: string): Promise<boolean> => {
      return new Promise((resolve) =>
        isValidUserNickname({
          variables: {
            userNickname: nickname,
          },
        })
          .then((res) => {
            if (!res.data?.isValidUserNickname) {
              resolve(false);
            } else {
              resolve(true);
            }
          })
          .catch(() => {
            resolve(true);
          })
      );
    },
    [isValidUserNickname]
  );

  return {
    validationUserNickname,
    loading,
  };
};
