import * as React from "react";
import styled, { css } from "styled-components";
import { Size } from "../../types";
import { Icon } from "~/component/atoms/icon";
import { useTheme } from "styled-components";

const fontSizes: Record<Size, string> = {
  large: "20px",
  medium: "16px",
};

export type FontType = "Kyokasho" | "Mincho";

export type ScrolledReadingViewerProps = {
  text: string;
  caption: string;
  size: Size;
  fontType: FontType;
  lineHeight: string;
  letterSpacing: string;
};

export const ScrolledReadingViewer: React.FC<ScrolledReadingViewerProps> = ({
  text,
  caption,
  size,
  fontType,
  lineHeight,
  letterSpacing,
}) => {
  const theme = useTheme();

  const [isBottom, setIsBottom] = React.useState(false);
  const [isTop, setIsTop] = React.useState(true);

  const ref = React.useRef<HTMLDivElement>(null);

  React.useEffect(() => {
    const onScroll = () => {
      if (
        ref.current &&
        ref.current?.scrollTop + ref.current?.clientHeight >=
          ref.current?.scrollHeight - 3
      ) {
        setIsBottom(true);
      } else if (ref.current && ref.current?.scrollTop === 0) {
        setIsTop(true);
      } else {
        setIsBottom(false);
        setIsTop(false);
      }
    };

    if (ref.current) {
      ref.current.addEventListener("scroll", onScroll);
      return () => {
        ref.current && ref.current.removeEventListener("scroll", onScroll);
      };
    }
  }, [ref]);

  return (
    <_Wrapper>
      <_ScrollWrapper ref={ref}>
        <_TextAndCaptionWrapper
          size={size}
          fontType={fontType}
          lineHeight={lineHeight}
          letterSpacing={letterSpacing}
        >
          <p className="sentence">{text}</p>
          <p className="caption">{caption}</p>
        </_TextAndCaptionWrapper>
      </_ScrollWrapper>
      <_TopArrowWrapper>
        {!isTop && (
          <Icon
            name="caretUp"
            style={{ width: theme.fontSize.LG, height: "auto" }}
            color="pri"
          />
        )}
      </_TopArrowWrapper>
      <_BottomArrowWrapper>
        {!isBottom && (
          <Icon
            name="caretDown"
            style={{ width: theme.fontSize.LG, height: "auto" }}
            color="pri"
          />
        )}
      </_BottomArrowWrapper>
      <_TopLeftImg src="/img/world_view_onboarding/reading_viewer_top_left.png" />
      <_TopRightImg src="/img/world_view_onboarding/reading_viewer_top_right.png" />
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  height: calc(100svh - 500px);
  max-height: 335px;
  min-height: 170px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;

  display: flex;
  flex-direction: column;
`;

const _TopLeftImg = styled.img`
  width: 100px;
  position: absolute;

  top: -20px;
  left: -20px;
`;

const _TopRightImg = styled.img`
  width: 100px;
  position: absolute;

  top: -20px;
  right: -20px;
`;

const _ScrollWrapper = styled.div`
  width: 100%;
  max-height: 335px;
  overflow-y: scroll;
  overflow-x: hidden;
`;

const _TextAndCaptionWrapper = styled.div<{
  size?: Size;
  fontType?: FontType;
  lineHeight: string;
  letterSpacing: string;
}>`
  padding: 32px 16px;
  width: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 12px;

  .sentence {
    color: ${({ theme }) => theme.colors.tex.t700};

    ${({ fontType }) => {
      if (fontType === "Mincho") {
        return css`
          font-family: YuMincho, "Yu Mincho", sans-serif;
        `;
      } else {
        return css`
          font-family: uddigikyokasho-pro, sans-serif;
        `;
      }
    }};

    font-weight: 400;
    font-style: normal;
    font-size: ${({ size = "medium" }) => fontSizes[size]};
    line-height: ${({ lineHeight }) => lineHeight};
    letter-spacing: ${({ letterSpacing }) => letterSpacing};

    white-space: pre-wrap;
    word-wrap: break-word;

    position: relative;
  }

  .caption {
    color: ${({ theme }) => theme.colors.tex.t300};

    font-family: uddigikyokasho-pro, sans-serif;
    font-weight: 400;
    font-style: normal;
    font-size: 12px;
  }
`;

const _BottomArrowWrapper = styled.div`
  position: absolute;
  bottom: 0;

  width: 100%;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: flex-end;

  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 82.81%);
  border-radius: 0px 0px 16px 16px;

  pointer-events: none;
`;

const _TopArrowWrapper = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  height: 50px;

  display: flex;
  justify-content: center;
  align-items: flex-start;

  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #fff 82.81%);
  border-radius: 16px 16px 0px 0px;

  pointer-events: none;
`;
