import React from "react";
import Header from "../component/organisms/common/header";
import {
  ContentsContainer,
  FirstHeadContainer,
  WhiteBox,
} from "../component/templates/box";
import { PageTitle } from "../component/atoms/heading/page_title";
import styled from "styled-components";
import { PlainText } from "../component/atoms/text";
import { MiddleWrapper } from "../component/templates/wrapper";

export const AboutEditCardnumber = (): React.ReactElement => {
  return (
    <>
      <Header />
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <PageTitle>クレジットカード番号を変更したい場合</PageTitle>
          </FirstHeadContainer>
          <WhiteBox>
            <SubTitle>
              休会手続きをしてからカード番号を再登録してください
            </SubTitle>
            <PlainText>
              一旦加入されているプランを休会してから、そのプランに再入会していただくというプロセスをふむ必要があります。
              その際、休会してもデータは引き継がれますし、アカウントが削除される事はありませんので、ご安心ください。
            </PlainText>
          </WhiteBox>
        </ContentsContainer>
      </MiddleWrapper>
    </>
  );
};

const SubTitle = styled.h2`
  font-size: 16px;
  line-height: 160%;
  font-weight: bold;
  color: ${(props) => props.theme.colors.tex.t800};
  margin-bottom: 8px;
`;
