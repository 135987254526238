import * as React from "react";
import { useLocation } from "react-router-dom";
import * as QueryString from "query-string";
import { RegisterWrapper, Title } from "../../component/form_style";

import { PortalFooter } from "../../component/organisms/common/footer";

/* const Subtitle = styled.p`
  margin: 20px 0;
  font-size: 15px;
  line-height: 160%;
  color: var(--color-gray02);
`; */

/* const ImgCon = styled.div`
  width: 40px;
  margin: 50px auto;
`;
 */
export const CertificateFailed = (): React.ReactElement => {
  const location = useLocation();
  const search = QueryString.parse(location.search);

  if (search.reason != null) {
    return <>reason: {search.reason}</>;
  }

  return (
    <>
      <section
        style={{ minHeight: "100vh" }}
        id="certificate-failed"
        className="white-back"
      >
        <RegisterWrapper>
          <Title>認証に失敗しました。</Title>
        </RegisterWrapper>
      </section>
      <PortalFooter />
    </>
  );
};
