import React, { VFC } from "react";
import styled, { useTheme } from "styled-components";
import { FlatButton } from "~/component/atoms/button/FlatButton";
import { Icon } from "~/component/atoms/icon";
import { Text, TextProps } from "~/component/atoms/text/Text";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/component/atoms/button/tag_changer";
import { ColorTheme } from "~/component/theme";
import { IconColorList } from "~/component/atoms/icon";

export type SecondaryFlatButtonProps = TextProps & {
  onClick: (event: React.MouseEvent) => void;
  disabled?: boolean;
  color: ColorTheme;
  text: string;
  withArrow?: boolean;
};

export const SecondaryFlatButton: VFC<SecondaryFlatButtonProps> = ({
  onClick,
  disabled = false,
  color,
  text,
  withArrow = false,
  ...textProps
}) => {
  const theme = useTheme();
  const { fontSize } = textProps;
  return (
    <FlatButton
      color={color}
      onClick={onClick}
      shape={"Normal"}
      variant={"Outline"}
      disabled={disabled}
    >
      <LeftSide></LeftSide>
      <Center>
        <InnerText {...textProps} color={color}>
          {text}
        </InnerText>
      </Center>
      <RightSide>
        {withArrow && (
          <Icon
            color={color}
            name={"arrowRight"}
            style={{ height: theme.fontSize[fontSize], width: "auto" }}
          />
        )}
      </RightSide>
    </FlatButton>
  );
};

export type SecondaryFlatLinkProps = Omit<SecondaryFlatButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof SecondaryFlatButtonProps>;

export const SecondaryFlatLink: VFC<SecondaryFlatLinkProps> = ({
  color,
  text,
  disabled = false,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <SecondaryFlatButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={() => {
        return;
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Linkerize>
);

export type SecondaryFlatAnchorProps = Omit<
  SecondaryFlatButtonProps,
  "onClick"
> &
  Partial<Pick<SecondaryFlatButtonProps, "onClick">> &
  Omit<AnchorizeProps, keyof SecondaryFlatButtonProps>;

export const SecondaryFlatAnchor: VFC<SecondaryFlatAnchorProps> = ({
  color,
  text,
  disabled = false,
  withArrow,
  fontSize,
  bold,
  lineHeight,
  onClick,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <SecondaryFlatButton
      color={color}
      fontSize={fontSize}
      lineHeight={lineHeight}
      bold={bold}
      onClick={(event) => {
        onClick && onClick(event);
      }}
      disabled={disabled}
      text={text}
      withArrow={withArrow}
    />
  </Anchorize>
);

const LeftSide = styled.div`
  width: 10%;
`;

const Center = styled.div`
  width: 80%;
`;

const RightSide = styled.div`
  width: 10%;
`;

const InnerText = styled(Text)<{ color: ColorTheme }>`
  color: ${({ color }) => IconColorList[color]};
  text-align: center;
`;
