import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { Loading } from "./component/atoms/progress_indicator/loading";
import { theme } from "./component/theme";
import { AboutEditCardnumber } from "./pages/about_edit_cardnumber";
import { ChangeLibrary } from "./pages/change_library";
import Child from "./pages/child";
import { CompleteRegister } from "./pages/CompleteRegister";
import { EditUser } from "./pages/edit_user";
import { DiagnosticReportContainer } from "./pages/EnqueteResult/container";
import { EnqueteForRecommend } from "./pages/enquete_for_recommend";
import { CertificateFailed } from "./pages/error/certificate_failed";
import { NotFound } from "./pages/error/not_found";
import { LoginForLine } from "./pages/login_for_line";
import { News } from "./pages/news";
import { RegisterMyLibraryContainer } from "./pages/RegisterMyLibrary/container";
import { Profile } from "./pages/profile";
import { QuitEnquete } from "./pages/quit_enquete";
import { TutorialLine } from "./pages/TutorialLine/index";
import { RegisterFirstUserContainer } from "./pages/RegisterUser/registerFirstUserContainer";
import { RegisterAdditionalUserContainer } from "./pages/WorldViewOnboarding/registerAdditionalUserContainer";
import { Top } from "./pages/top";
import { YondemyStoryPage } from "./pages/WorldViewOnboarding/YondemyStory/container";
import { CompleteLineRegister } from "./pages/CompleteLineRegister";
import { BookListViewer } from "./pages/BookList/container";
import { RemoveRecommend } from "./pages/RemoveRecommend/container";
import { PwaTutorial } from "./pages/PwaTutorial/container";
import { EditRecommendFrequencyContainer } from "./pages/EditRecommendFrequency/container";
import { RecommendDetail } from "./pages/RecommendDetail";
import { WantReadBookDetail } from "./pages/WantReadBookDetail";

export const App = (): React.ReactElement => {
  React.useEffect(() => {
    const element = document.getElementById("main");
    if (element) {
      element.style.background = theme.colors.semantic.layout.backgroundMain;
    }
  }, []);

  return (
    <>
      <Switch>
        <Route exact path="/" component={Top} />
        <Route exact path="/news" render={() => <News />} />
        <Route exact path="/profile" component={Profile} />
        <Route exact path="/line_tutorial" component={TutorialLine} />
        <Route
          exact
          path="/complete_line_register"
          component={CompleteLineRegister}
        />
        <Route
          exact
          path="/enquete_for_recommend/:id"
          component={EnqueteForRecommend}
        />
        <Route
          exact
          path="/register_additional_user"
          component={RegisterAdditionalUserContainer}
        />
        <Route
          exact
          path="/register_first_user"
          component={RegisterFirstUserContainer}
        />

        <Route exact path="/quit_enquete" component={QuitEnquete} />
        <Route
          exact
          path="/error/certificate_failed"
          render={() => <CertificateFailed />}
        />
        <Route exact path="/child/:id" component={Child} />
        <Route
          exact
          path="/child/edit/:id"
          render={(props) => <EditUser {...props} />}
        />
        <Route exact path="/login_for_line" render={() => <LoginForLine />} />
        <Route exact path="/yondemy_story" component={YondemyStoryPage} />
        <Route exact path="/change_library" render={() => <ChangeLibrary />} />
        <Route
          exact
          path="/booklist/:id"
          render={(props) => <BookListViewer {...props} />}
        />
        <Route
          exact
          path="/edit_recommend_frequency/:id"
          component={EditRecommendFrequencyContainer}
        />
        <Route
          exact
          path="/remove_recommend/:recommendId"
          component={RemoveRecommend}
        />
        <Route exact path="/recommend/:id" component={RecommendDetail} />
        <Route exact path="/wantreadbook/:id" component={WantReadBookDetail} />

        <Route
          exact
          path="/register_my_library"
          component={RegisterMyLibraryContainer}
        />
        <Route
          exact
          path="/firstbookrecommendreport/:id"
          component={DiagnosticReportContainer}
        />
        <Route exact path="/complete_register" component={CompleteRegister} />
        <Route exact path="/pwa_tutorial" component={PwaTutorial} />

        {/* 
          register後、authorized=trueの状態でこのパスがルーティングされる。
          その際に一瞬NotFoundが表示されるのを防ぐ
        */}
        <Route
          exact
          path="/signup/:token"
          render={() => (
            <div
              style={{
                width: "100%",
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Loading />
            </div>
          )}
        />

        <Route
          exact
          path="/about_edit_cardnumber"
          render={() => <AboutEditCardnumber />}
        />
        <NotFound />
      </Switch>
    </>
  );
};
