import * as React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { OnboardingTemplate } from "~/component/templates/onboarding";
import { ProgressBar } from "~/component/atoms/ProgressBar";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { theme } from "../../component/theme";
import { useAnswer } from "./useAnswer";
import { useQuestion } from "./useQuestion";
import { Steps } from "./Steps";
import { questions } from "./data";

export const Onboarding = (): React.ReactElement => {
  const question = useQuestion();
  const answer = useAnswer({ questionId: question.current.id });

  const disabled =
    questions.find((item) => item.id === question.current.id)?.question
      .length !== answer.currentAnswer?.body.length ||
    answer.currentAnswer?.body.some(
      (item) => item.value === "選択してください"
    );

  return (
    <OnboardingTemplate
      ContentCon={
        <>
          <Header>
            {question.current.id !== "1" && question.current.id !== "5" && (
              <Icon>
                <FontAwesomeIcon
                  style={{ width: 26, height: 26 }}
                  icon={faArrowLeft}
                  color={theme.colors.sec.s500}
                  onClick={() => question.back()}
                />
              </Icon>
            )}
            <ProgressBarContainer>
              <ProgressBar progress={parseInt(question.current.id) * 20} />
            </ProgressBarContainer>
          </Header>
          <Steps question={question} answer={answer} />
          {question.current.id !== "5" && (
            <PrimaryButton onClick={() => question.next()} disabled={disabled}>
              次へ
            </PrimaryButton>
          )}
        </>
      }
    />
  );
};

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 0 24px;
`;

const Icon = styled.div`
  margin-right: 24px;
`;

const ProgressBarContainer = styled.div`
  width: 100%;
  height: 10px;
`;
