import React from "react";
import moment from "moment-timezone";
import {
  OrangeSpanText,
  RedSpanText,
} from "../component/atoms/text/styled_text";
const TIME_ZONE = "Asia/Tokyo";

// Types & constants
export type UserProps = {
  id?: number;
  parentId?: number;
  nickname?: string;
  birthday?: string;
  userPlan?: number;
  createdAt?: string;
  customerCardNumber?: string | null;
  subscriptionId?: string;
  subscriptionStatus?: string;
  subscriptionCurrentPeriodStartsAt?: string;
  subscriptionCurrentPeriodEndsAt?: string;
  subscriptionTrialStartsAt?: string;
  subscriptionTrialEndsAt?: string;
  subscriptionRemainingTrialDays?: number | null;
  subscriptionCreatedAt?: string;
  subscriptionDeletedAt?: string;
  subscriptionPausedAt?: string;
  planId?: string;
  planPrimary?: boolean;
  planAmount?: number;
  planTrialDays?: number;
  planCurrency?: string;
  planDuration?: string;
  planDurationLength?: number;
  planName?: string;
};

export type UsersProps = {
  id?: number;
  customerCardNumber?: string | null;
  subscriptionId?: string;
  subscriptionStatus?: string;
  subscriptionCurrentPeriodStartsAt?: string;
  subscriptionCurrentPeriodEndsAt?: string;
  subscriptionTrialStartsAt?: string;
  subscriptionTrialEndsAt?: string;
  subscriptionRemainingTrialDays?: number | null;
  subscriptionCreatedAt?: string;
  planId?: string;
  planName?: string;
  planAmount?: number;
  planCurrency?: string;
  planPrimary?: boolean;
}[];

// ts-unused-exports:disable-next-line
export const SUBSCRIPTION_PENDING = "pending";
export const SUBSCRIPTION_ACTIVE = "active";
export const SUBSCRIPTION_TRIAL = "trial";
export const SUBSCRIPTION_PAUSED = "paused";
export const SUBSCRIPTION_CANCELED = "canceled";
export const SUBSCRIPTION_EXPIRED = "expired";

const SUBSCRIPTION_STATUSES = {
  [SUBSCRIPTION_PAUSED]: "料金の引き落としができませんでした",
  [SUBSCRIPTION_CANCELED]: "休会しました",
  [SUBSCRIPTION_ACTIVE]: "受講中です",
  [SUBSCRIPTION_TRIAL]: "無料期間中です",
  [SUBSCRIPTION_EXPIRED]: "カードを登録して料金をお支払いください",
};

export const MONTH_DAY_FORMAT = "M月D日";
export const formatDate = (dateString: string, formatting?: string): string => {
  return moment.tz(dateString, TIME_ZONE).format(formatting || "YYYY年M月D日");
};

/*
const addAndFormatDate = (
  dateString: string,
  addedDay: number,
  formatting?: string
): string => {
  return moment
    .tz(dateString, TIME_ZONE)
    .add(addedDay, "days")
    .format(formatting || "YYYY年M月D日");
};
*/

export const buildSubscriptionStatus = (
  subscriptionStatus: string
): React.ReactElement => {
  if (subscriptionStatus === SUBSCRIPTION_TRIAL) {
    return (
      <OrangeSpanText>
        {SUBSCRIPTION_STATUSES[SUBSCRIPTION_TRIAL]}
      </OrangeSpanText>
    );
  } else if (subscriptionStatus === SUBSCRIPTION_PAUSED) {
    return (
      <RedSpanText>{SUBSCRIPTION_STATUSES[SUBSCRIPTION_PAUSED]}</RedSpanText>
    );
  } else if (subscriptionStatus === SUBSCRIPTION_CANCELED) {
    return (
      <RedSpanText>{SUBSCRIPTION_STATUSES[SUBSCRIPTION_CANCELED]}</RedSpanText>
    );
  } else if (subscriptionStatus === SUBSCRIPTION_EXPIRED) {
    return (
      <RedSpanText>{SUBSCRIPTION_STATUSES[SUBSCRIPTION_EXPIRED]}</RedSpanText>
    );
  } else {
    return <span>{SUBSCRIPTION_STATUSES[subscriptionStatus]}</span>;
  }
};

export const hasExpiredSubscription = (user: UserProps): boolean => {
  return (
    user.subscriptionStatus === SUBSCRIPTION_EXPIRED &&
    (!!user.subscriptionDeletedAt || !!user.subscriptionPausedAt)
  );
};

export const hasSubscription = (user: UserProps): boolean => {
  return !!user.subscriptionCurrentPeriodStartsAt;
};
