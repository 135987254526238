import React from "react";
import styled from "styled-components";
import { Finger } from "~/component/atoms/icon/finger";
import { Stack } from "~/component/atoms/layout/Stack";
import { theme } from "~/component/theme";
import {
  CONTENT_HEIGHT,
  CONTENT_WIDTH,
  useScale,
} from "~/pages/WorldViewOnboarding/ui/constants";
import { BookColor } from "../..";

export type BookListProps = {
  onNext: (color: BookColor, bookNumber: number) => void;
};

const ColorTable: Record<BookColor, string> = {
  green: theme.colors.green.g400,
  pri: theme.colors.pri.p400,
  red: theme.colors.red.r400,
  sky: theme.colors.sky.s300,
  sec: theme.colors.sec.s400,
  violet: theme.colors.violet.v400,
};

export const BookList: React.FC<BookListProps> = ({ onNext }) => {
  const scale = useScale();
  return (
    <_BackgroundImg>
      <_Wrapper>
        <_Container style={{ scale: `${scale}` }}>
          <Stack rowGap="12px">
            <_BubbleContainer>
              <_BubbleMessageContainer>
                <_BoldMessage>
                  ヨンデミー先生から
                  <br />
                  本をうけとったよ！
                </_BoldMessage>
                <_LibraryMessage>
                  これを、としょかんなどで かりてきてね！
                </_LibraryMessage>
              </_BubbleMessageContainer>
              <_TailWrapper>
                <_Tail />
              </_TailWrapper>
            </_BubbleContainer>
          </Stack>
          <_BookContainer>
            {Object.keys(ColorTable).map((color, index) => (
              <_BookImage
                color={ColorTable[color]}
                key={color}
                onClick={() => onNext(color as BookColor, index + 1)}
              >
                ？
                {index === 0 && (
                  <_FingerWrapper>
                    <Finger />
                  </_FingerWrapper>
                )}
              </_BookImage>
            ))}
          </_BookContainer>
        </_Container>
      </_Wrapper>
    </_BackgroundImg>
  );
};

const _BackgroundImg = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/img/world_view_onboarding/bright-bookshelf.png");
  background-repeat: repeat-x;
  background-size: contain;
`;

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.base.overlay};
`;

const _Container = styled.div`
  position: relative;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;
  transform-origin: top center;
  margin: 0 auto;
  padding: 64px 25px 60px 25px;
  overflow: scroll;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 22px;
`;

const _BubbleMessageContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-radius: 12px;
`;

const _BubbleContainer = styled.div`
  position: relative;
`;

const _BoldMessage = styled.p`
  width: 100%;
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSize.XXL};
  color: ${({ theme }) => theme.colors.tex.t500};
  line-height: 160%;
  padding-bottom: 12px;
`;

const _TailWrapper = styled.div`
  position: absolute;
  bottom: -9px;
  width: 100%;

  display: flex;
  justify-content: center;
`;
const _Tail: React.FC = () => (
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M7 10L0.937822 0.25L13.0622 0.249999L7 10Z" fill="white" />
  </svg>
);

const _LibraryMessage = styled.p`
  width: 100%;
  font-size: ${({ theme }) => theme.fontSize.MD};
  color: ${({ theme }) => theme.colors.tex.t500};
  line-height: 160%;
`;

const _BookContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(3, auto);
  row-gap: ${({ theme }) => theme.size.s3};
  column-gap: ${({ theme }) => theme.size.s3};
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.s3};
  background-color: rgba(0, 0, 0, 0.45);
  border-radius: ${({ theme }) => theme.size.s2};
  width: calc(100% - 14px);
  height: 100%;
  margin: 0 auto;
`;

const _BookImage = styled.button<{ color: string }>`
  position: relative;
  background-color: ${({ color }) => color};
  border-radius: 6px;
  box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.25);
  border: none;
  padding: 0;
  width: calc(${CONTENT_WIDTH}px * 0.319);
  height: calc(${CONTENT_WIDTH}px * 0.319 * 1.167);

  color: ${({ theme }) => theme.colors.base.white};
  font-weight: 700;
  font-size: 70px;
  border: 2px solid ${({ theme }) => theme.colors.base.white};
  &:focus {
    outline: none;
  }
`;

const _FingerWrapper = styled.div`
  position: absolute;
  right: -20px;
  bottom: -15px;
  animation: 1.5s ease-in-out moveFinger infinite;

  @keyframes moveFinger {
    from {
      right: -20px;
      bottom: -15px;
    }
    50% {
      right: -10px;
      bottom: -5px;
    }
    to {
      right: -20px;
      bottom: -15px;
    }
  }
`;
