import React from "react";
import styled from "styled-components";
import { ExclamationTriangle } from "~/component/atoms/icon/exclamationTriangle";
import { Stack } from "~/component/atoms/layout/Stack";
import { Conversation } from "~/pages/WorldViewOnboarding/ui/Conversation";
import { Message, MessageProps } from "~/pages/WorldViewOnboarding/ui/Message";
import { useSnd } from "~/utils/hooks/useSnd";
import { PrimaryButtonWithSound } from "../../Button/PrimaryButtonWithSound";
import { CONTENT_HEIGHT, CONTENT_WIDTH } from "../../constants";
import { ButtonProps } from "../types";

export type InputQuestionProps = {
  imgSrc: string;
  messageProps?: MessageProps;
  error?: boolean;
  errorMessage?: string;
  buttonProps: ButtonProps;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  value: string | number;
  scale?: number;
  disabled?: boolean;
};

export const InputQuestion: React.FC<InputQuestionProps> = ({
  imgSrc,
  messageProps,
  buttonProps,
  onChange,
  error,
  errorMessage,
  value,
  scale = 1.0,
  disabled,
}) => {
  const { play } = useSnd();
  const Dialog = React.useMemo(() => {
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      onChange(event);
      play("TYPE");
    };
    return (
      <_Wrapper>
        <_Container>
          {messageProps && (
            <_MessageWrapper>
              <Message {...messageProps} />
            </_MessageWrapper>
          )}
          <_FormWrapper rowGap={errorMessage ? "24px" : "36px"}>
            <_InputWrapper rowGap="8px">
              <_Input
                error={error}
                onChange={handleChange}
                value={value}
                autoFocus
              />
              {errorMessage && (
                <_ErrorMessageWrapper>
                  <_ErrorMessage>
                    <ExclamationTriangle />
                    {errorMessage}
                  </_ErrorMessage>
                </_ErrorMessageWrapper>
              )}
            </_InputWrapper>
            <PrimaryButtonWithSound
              onClick={buttonProps.onClick}
              noMarginTop={true}
              disabled={error || disabled}
            >
              {buttonProps.label}
            </PrimaryButtonWithSound>
          </_FormWrapper>
        </_Container>
      </_Wrapper>
    );
  }, [
    messageProps,
    buttonProps,
    error,
    onChange,
    value,
    disabled,
    errorMessage,
    play,
  ]);

  return <Conversation scale={scale} imgSrc={imgSrc} dialog={Dialog} overlay />;
};

const _Input = styled.input<{ error?: boolean }>`
  width: 100%;
  padding: 19px 16px;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.MD};
  background-color: ${({ theme }) => theme.colors.base.white};

  border-radius: 16px;
  border: 2px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red.r300 : theme.colors.base.gray};
  outline: none;
  margin: 0;
  &:focus {
    border-color: ${({ theme }) => theme.colors.pri.p400};
  }
  &::placeholder {
    color: ${(props) => props.theme.colors.tex.t200};
  }
`;

const _Wrapper = styled.div`
  position: absolute;
  top: 0;
`;

const _Container = styled.div`
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  margin: 0 auto;

  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 36px;

  padding-bottom: 80px;
  box-sizing: border-box;

  justify-content: flex-end;
`;

const _MessageWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;

const _ErrorMessageWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

const _ErrorMessage = styled.p`
  position: relative;
  background-color: ${({ theme }) => theme.colors.red.r300};
  padding: 8px 16px;
  margin-top: 10px;
  border-radius: 12px;

  display: flex;
  column-gap: 6px;
  align-items: center;

  color: ${({ theme }) => theme.colors.base.white};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;

  &::before {
    content: "";
    position: absolute;
    top: -16px;
    right: calc(50% - 5px);
    border: 6px solid transparent;
    border-bottom: 10px solid ${({ theme }) => theme.colors.red.r300};
  }
`;

const _InputWrapper = styled(Stack)``;

const _FormWrapper = styled(Stack)`
  padding: 0 20px;
  width: 100%;
`;
