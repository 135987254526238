import * as React from "react";
import styled from "styled-components";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { Row } from "~/component/atoms/layout/Row";

type PrimaryButtonProps = Parameters<typeof PrimaryButton>[0];

type Props = PrimaryButtonProps;

export const NavigationButton: React.FC<Props> = ({
  children,
  ...props
}: Props) => {
  return (
    <PrimaryButton {...props} noMarginTop>
      <_ButtonText>
        <Row>{children}</Row>
      </_ButtonText>
    </PrimaryButton>
  );
};

const _ButtonText = styled.div`
  text-align: center;
`;
