import React, { useState, FC } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { Heading2 } from "~/component/atoms/text/Heading2";
import { IconTextButton } from "~/component/molecules/button/IconTextButton";
import {
  SecondaryFlatAnchor,
  SecondaryFlatButton,
} from "~/component/molecules/button/SecondaryFlatButton";
import { NotifyTextToast } from "~/component/organisms/toasts/NotifyTextToast";
import { AvailableBookType } from "~/generated/graphql";
import { theme } from "~/component/theme";
import { CalilBookInfo } from "./const";
import { Row } from "~/component/atoms/layout/Row";
import { Stack } from "~/component/atoms/layout/Stack";
import { ProcureStatus } from "~/generated/graphql";
import { CustomSelectBox } from "./selectBox";

type ProcureStatusManager = {
  procureStatus: ProcureStatus;
  updateProcureStatus: (procureStatus: ProcureStatus) => Promise<boolean>;
};

export type BookListViewerItemProps = {
  recommendId: number;
  isbn: string;
  title: string;
  imageUrl: string;
  isNew: boolean;
  procureStatusManager?: ProcureStatusManager;
  affiliateLink: string | undefined;
  availableBookType?: AvailableBookType;
  myLibraryName?: string;
  handleEraseNewTag: (recommendId: number) => void;
  handleRemoveItem: (e: React.MouseEvent) => void;
  onClickDetail: () => void;
};

export const BookListViewerItem: FC<BookListViewerItemProps> = ({
  recommendId,
  isbn,
  title,
  imageUrl,
  isNew,
  procureStatusManager,
  affiliateLink,
  availableBookType,
  myLibraryName,
  handleEraseNewTag,
  handleRemoveItem,
  onClickDetail,
}) => {
  const history = useHistory();
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const [popupText, setPopupText] = useState("");
  const [isOpenSelectBox, setIsOpenSelectBox] = useState<boolean>(false);

  const onClickCopyISBN = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!navigator.clipboard) {
      alert("クリップボードにコピーできませんでした");
      return false;
    }
    navigator.clipboard.writeText(isbn).then(() => {
      setPopupText("コピーしました");
      setPopupIsOpen(true);
    });
  };

  const onClickCopyTitle = (event: React.MouseEvent) => {
    event.preventDefault();
    if (!navigator.clipboard) {
      alert("クリップボードにコピーできませんでした");
      return false;
    }
    navigator.clipboard.writeText(title).then(() => {
      setPopupText("コピーしました");
      setPopupIsOpen(true);
    });
  };

  const handleClickGoToCalil = () => {
    if (myLibraryName) {
      setIsOpenSelectBox(true);
      handleEraseNewTag(recommendId);
      window.open(
        `https://calil.jp/book/${isbn}/search?nearby=${myLibraryName}`,
        "_blank"
      );
      window.gtag("event", "reserve", {
        event_category: "book",
        event_label: myLibraryName,
        value: 1,
        isbn: isbn,
      });
    } else {
      history.push("/change_library");
    }
  };

  React.useEffect(() => {
    if (
      isNew &&
      procureStatusManager?.procureStatus !== ProcureStatus.Untouched
    ) {
      handleEraseNewTag(recommendId);
    }
  }, [procureStatusManager, isNew, recommendId, handleEraseNewTag]);

  return (
    <>
      <Container>
        {isNew && <_NewTag>新着</_NewTag>}
        <Stack rowGap="10px">
          {procureStatusManager && (
            <Row justifyContent="flex-end">
              <CustomSelectBox
                procureStatus={procureStatusManager.procureStatus}
                updateProcureStatus={procureStatusManager.updateProcureStatus}
                isOpen={isOpenSelectBox}
                setIsOpen={(isOpen: boolean) => {
                  setIsOpenSelectBox(isOpen);
                  handleEraseNewTag(recommendId);
                }}
              />
            </Row>
          )}
          <Upper>
            <Row>
              <BookCoverContainer>
                <Img src={imageUrl} alt={`これは${title}の表紙`} />
              </BookCoverContainer>
              <CardInfo>
                <Stack rowGap="4px">
                  <Title
                    onClick={onClickCopyTitle}
                    fontSize={"LG"}
                    lineHeight={"MD"}
                    bold
                  >
                    {title}
                  </Title>
                  <IconTextButton
                    iconPlace="pre"
                    name={"clone"}
                    text={"コピー"}
                    onClick={onClickCopyTitle}
                    fontSize={"XS"}
                    lineHeight={"EQ"}
                    bold={true}
                    color={"gray"}
                    textStyle={{
                      marginRight: "-5px",
                      color: theme.colors.semantic.text.label,
                    }}
                  />
                </Stack>
                <IsbnCopyDeleteContainer>
                  <IsbnCopyButtonContainer>
                    <IconTextButton
                      iconPlace={"sub"}
                      name={"clone"}
                      text={isbn}
                      onClick={onClickCopyISBN}
                      fontSize={"XS"}
                      lineHeight={"EQ"}
                      color={"gray"}
                      textStyle={{
                        marginRight: "-5px",
                        color: theme.colors.semantic.text.label,
                      }}
                    />
                  </IsbnCopyButtonContainer>
                  <NotifyTextToast
                    text={popupText}
                    isOpen={popupIsOpen}
                    setIsOpen={setPopupIsOpen}
                  />
                  <IconTextButton
                    onClick={handleRemoveItem}
                    text={"削除"}
                    name={"trash"}
                    iconPlace={"pre"}
                    fontSize={"XS"}
                    lineHeight={"EQ"}
                    bold
                    keepAll
                    color={"gray"}
                    textStyle={{
                      marginLeft: "-5px",
                      color: theme.colors.semantic.text.label,
                    }}
                  />
                </IsbnCopyDeleteContainer>
              </CardInfo>
            </Row>
            <SecondaryFlatButton
              text="詳しく見る"
              onClick={onClickDetail}
              disabled={false}
              color="pri"
              lineHeight="MD"
              fontSize="SM"
            />
          </Upper>
        </Stack>
        <Stack rowGap="8px">
          {myLibraryName && availableBookType && (
            <CalilBookInfo
              myLibraryName={myLibraryName}
              availableBookType={availableBookType}
            />
          )}
          <CalilButtonsContainer>
            <SecondaryFlatButton
              text={"図書館で予約・取り置きする"}
              onClick={handleClickGoToCalil}
              disabled={false}
              color={"pri"}
              lineHeight={"MD"}
              fontSize={"SM"}
            />
          </CalilButtonsContainer>
        </Stack>
        <AffiliateButtonsContainer>
          <MercariButton
            text={"メルカリ"}
            href={`https://www.mercari.com/jp/search/?keyword=${title.substring(
              0,
              title.indexOf("(") === -1 ? title.length : title.indexOf("(")
            )}`}
            target={"_blank"}
            rel={"noreferrer noopener"}
            disabled={false}
            color={"pri"}
            lineHeight={"MD"}
            fontSize={"SM"}
            onClick={() => {
              handleEraseNewTag(recommendId);
              setIsOpenSelectBox(true);
            }}
          />
          <AmazonButton
            text={"Amazon"}
            href={affiliateLink}
            target={"_blank"}
            rel={"noreferrer noopener"}
            disabled={affiliateLink === undefined}
            color={"pri"}
            lineHeight={"MD"}
            fontSize={"SM"}
            onClick={() => {
              handleEraseNewTag(recommendId);
              setIsOpenSelectBox(true);
            }}
          />
        </AffiliateButtonsContainer>
      </Container>
    </>
  );
};

const Container = styled.div`
  ${({ theme }) => {
    return css`
      position: relative;
      border: none;
      background-color: ${theme.colors.semantic.layout.backgroundBox};
      border-radius: ${theme.borderRadius.MD};
      width: 100%;
      padding: 20px;
      text-align: left;
    `;
  }}
`;

const _NewTag = styled.div`
  position: absolute;
  left: -8px;
  top: -8px;
  background-color: ${({ theme }) => theme.colors.semantic.alert.minus1};
  padding: 10px;
  color: ${({ theme }) => theme.colors.semantic.layout.backgroundBox};
  border-radius: 8px;
  font-size: ${({ theme }) => theme.fontSize.SM};
`;

const IsbnCopyButtonContainer = styled.div`
  ${({ theme }) => {
    return css`
      border: none;
      background-color: ${theme.colors.semantic.layout.border};
      border-radius: ${theme.borderRadius.MD};
      padding: 4px;
    `;
  }}
`;

const Upper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  padding-bottom: 15px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
  margin-bottom: 16px;
`;

const CardInfo = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  gap: 12px;
`;

const BookCoverContainer = styled.div`
  width: 36%;
  max-width: 120px;
  padding: 0 10px 0 0;
`;

const Img = styled.img`
  width: 80px;
  border-radius: 7px;
  @media screen and (min-width: 768px) {
    width: 100px;
  }
`;

const Title = styled(Heading2)`
  font-family: inherit;
  color: ${({ theme }) => theme.colors.semantic.text.body};
`;

const IsbnCopyDeleteContainer = styled.div`
  font-family: inherit;
  color: ${({ theme }) => theme.colors.semantic.text.body};
  display: flex;
  justify-content: space-between;
  margin-top: 6px;
  margin-bottom: 16px;
  font-size: 12px;
  font-weight: bold;
  @media screen and (min-width: 700px) {
    font-size: 14px;
  }
`;

const CalilButtonsContainer = styled.div`
  width: 100%;
  margin-bottom: 3px;
`;

const AffiliateButtonsContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 8px;
`;

const AmazonButton = styled(SecondaryFlatAnchor)`
  width: 49%;
`;

const MercariButton = styled(SecondaryFlatAnchor)`
  width: 49%;
`;
