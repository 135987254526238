import * as React from "react";
import styled from "styled-components";
import { Popup, PopupColorTheme } from "../../atoms/modal/popup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

type IndicateMutationStateProps = {
  displayedMessage: string;
  mutationState: string;
  setMutationState: (mutationState: string) => void;
};

export const IndicateMutationState: React.VFC<IndicateMutationStateProps> = ({
  displayedMessage,
  mutationState,
  setMutationState,
}) => {
  const handleClose = (event: React.MouseEvent) => {
    event.preventDefault();
    setMutationState("idle");
  };

  React.useEffect(() => {
    const autoHide = setTimeout(() => setMutationState("idle"), 3000);
    return () => {
      clearTimeout(autoHide);
    };
  }, [mutationState]);

  const getMessage = () => {
    switch (mutationState) {
      case "success":
        return displayedMessage;
      case "fail":
        return displayedMessage || "エラーです。";
      default:
        return "";
    }
  };

  const getColor = (): PopupColorTheme => {
    switch (mutationState) {
      case "success":
        return "green";
      case "fail":
        return "red";
      default:
        return "orange";
    }
  };

  return (
    <>
      {(mutationState === "success" || mutationState === "fail") && (
        <Popup onClose={handleClose} color={getColor()}>
          <Upper>
            <FontAwesomeIcon
              icon={"bell"}
              style={{ marginRight: "12px", color: "black", opacity: "0.2" }}
            />
            <Message>{getMessage()}</Message>
          </Upper>
        </Popup>
      )}
    </>
  );
};

const Upper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const Message = styled.p`
  color: ${(props) => props.theme.colors.base.white};
  font-size: 14px;
  font-weight: bold;
  font-family: inherit;
  margin: 0;
`;
