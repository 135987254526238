import { SoundKey } from "./useAudio";

export type YondemyStorySetting = {
  image: string;
  text?: string;
  lineImage?: LineImage;
  sound?: SoundKey;
};

export const lineImages = [
  "/img/world_view_onboarding/yondemy_story/line_gray.png",
  "/img/world_view_onboarding/yondemy_story/line_green.png",
  "/img/world_view_onboarding/yondemy_story/line_orange.png",
  "/img/world_view_onboarding/yondemy_story/line_pink.png",
  "/img/world_view_onboarding/yondemy_story/line_yellow.png",
] as const;
export type LineImage = typeof lineImages[number];

export const yondemyStories: YondemyStorySetting[] = [
  {
    image: "/img/world_view_onboarding/yondemy_story/cover.jpg",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/1.jpg",
    text: `むかし、
《まぼろしのきょだいとしょかん》では、ほんたちが いきいきと かがやいていた。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_orange.png",
    sound: "story1",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/2.jpg",
    text: `たくさんの ひとたちに よまれ、
ちからを もらっていたからだ。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_pink.png",
    sound: "story2",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/3.jpg",
    text: `ところが、だんだん、ひとびとが 
ほんを よまなくなって しまった。      
としょかんで まっていても、
ひとが やってこない。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_gray.png",
    sound: "story3",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/4.jpg",
    text: "すると、どんどん、ほんから いのちが うしなわれていった。",
    lineImage: "/img/world_view_onboarding/yondemy_story/line_green.png",
    sound: "story4",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/5.jpg",
    text: `ほんせいぶつたちも、
げんきが でなくなってしまった。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_gray.png",
    sound: "story5",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/6.jpg",
    text: "ほんを あいしていた ヨンデミー先生は かなしんだ。",
    lineImage: "/img/world_view_onboarding/yondemy_story/line_gray.png",
    sound: "story6",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/7.jpg",
    text: `そして ちからを ふりしぼって
《どくしょかのしょ》を つくった。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_green.png",
    sound: "story7",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/9.jpg",
    text: "《どくしょか》は、ほんのせかいのぼうけんかだ。",
    lineImage: "/img/world_view_onboarding/yondemy_story/line_yellow.png",
    sound: "story8",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/8.jpg",
    text: `《どくしょかのしょ》に えらばれた きみが ほんを よめば、
としょかんを たすけることができる。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_yellow.png",
    sound: "story9",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/10.jpg",
    text: `ほんたちは、きみを  ずっと 
まっている。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_gray.png",
    sound: "story10",
  },
  {
    image: "/img/world_view_onboarding/yondemy_story/11.jpg",
    text: `どうか ほんのせかいを たのしんで。              
そして、ほんたちと ヨンデミーのなかまたちを たすけてほしい。`,
    lineImage: "/img/world_view_onboarding/yondemy_story/line_green.png",
    sound: "story11",
  },
];

export const endOfBookImage =
  "/img/world_view_onboarding/yondemy_story/end.jpg";
