import React from "react";
import styled from "styled-components";
import { Toast } from "../Toast";
import { Text } from "~/component/atoms/text/Text";

export type NotifyTextToastProps = {
  text: string;
  isOpen: boolean;
  setIsOpen: (b: boolean) => void;
};

/**
 * NotifyToastの軽量板
 */
export const NotifyTextToast: React.VFC<NotifyTextToastProps> = ({
  isOpen,
  setIsOpen,
  text,
}) => {
  const handleClick = (event: React.MouseEvent) => {
    event.preventDefault();
    setIsOpen(false);
  };
  React.useEffect(() => {
    if (isOpen) {
      setTimeout(() => setIsOpen(false), 4000);  
      //1000ms後にisOpenをfalseに
    }
  }, [isOpen, setIsOpen]);
  if (isOpen) {
    return (
      <Toast
        icon="bell"
        stickPlace={"top"}
        onClick={handleClick}
        buttonLabel={"とじる"}
      >
        <Message fontSize={"XS"} lineHeight={"MD"}>
          {text}
        </Message>
      </Toast>
    );
  } else {
    return <></>;
  }
};

const Message = styled(Text)`
  color: ${({ theme }) => theme.colors.pri.p500};
  @media screen and (min-width: 768px) {
    font-size: ${({ theme }) => theme.fontSize.SM};
  }
`;
