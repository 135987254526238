import React, { FC } from "react";
import styled from "styled-components";
import { Icon } from "~/component/atoms/icon";
import { Row } from "~/component/atoms/layout/Row";
import { Text } from "~/component/atoms/text/Text";
import { AvailableBookType } from "~/generated/graphql";

type CalilBookInfoProps = {
  myLibraryName: string;
  availableBookType: AvailableBookType;
};

export const CalilBookInfo: FC<CalilBookInfoProps> = ({
  myLibraryName,
  availableBookType,
}) => {
  if (
    availableBookType === AvailableBookType.Available ||
    availableBookType === AvailableBookType.Ordered
  ) {
    return (
      <Row justifyContent="flex-start">
        <Text fontSize="XS" lineHeight="MD">
          {myLibraryName}
        </Text>
        {availableBookType === AvailableBookType.Available && (
          <IsAvailable>
            <Icon name="thumbsUp" color="green" />
            <Text fontSize="XS" fontColor="green.g600" lineHeight="MD" bold>
              貸出可
            </Text>
          </IsAvailable>
        )}
        {availableBookType === AvailableBookType.Ordered && (
          <IsOrdered>
            <Icon name="thumbsUp" color="sky" />
            <Text fontSize="XS" fontColor="sky.s600" lineHeight="MD" bold>
              近隣の図書館から取寄可
            </Text>
          </IsOrdered>
        )}
      </Row>
    );
  }
  return <></>;
};

const IsAvailable = styled.div`
  background-color: #d1f7ce;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  padding: 8px;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;

const IsOrdered = styled.div`
  background-color: #d1f7ff;
  border-radius: ${({ theme }) => theme.borderRadius.SM};
  padding: 8px;
  display: flex;
  align-items: center;
  column-gap: 10px;
`;
