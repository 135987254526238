import {
  LoginIDMaxLength,
  LoginIDMinLength,
  NameMaxLength,
  NicknameMaxLength,
  ParentPasswordMaxLength,
  ParentPasswordMinLength,
  UserPasswordMaxLength,
  UserPasswordMinLength,
} from "./constant";

export function Validation_AllowHiraganaOnly(s: string): boolean {
  s = s == null ? "" : s;
  if (s.match(/^[ぁ-んー　ゔ]*$/)) {
    return true;
  } else {
    return false;
  }
}

export function Validation_AllowZenkakuKatakanaOnly(s: string): boolean {
  s = s == null ? "" : s;
  if (s.match(/^[ァ-ヶー　]*$/)) {
    return true;
  } else {
    return false;
  }
}

export function Validation_NotAllowNullString(s: string): boolean {
  if (s == null) {
    return false;
  }
  return true;
}
export function Validation_NotAllowEmptyString(s: string): boolean {
  if (s == "") {
    return false;
  }
  return true;
}
export function Validation_NotAllowHeadAndTailSpace(s: string): boolean {
  if (s.endsWith(" ")) return false;
  if (s.endsWith("　")) return false;
  if (s.startsWith(" ")) return false;
  if (s.startsWith("　")) return false;
  return true;
}
export function Validation_RestrictCharType(
  s: string,
  lower: boolean,
  upper: boolean,
  number: boolean,
  symbol: boolean
): boolean {
  if (s.match(/\W/) != null) {
    return false;
  }
  if (!lower && s.match(/[a-z]/) != null) {
    return false;
  }
  if (!upper && s.match(/[A-Z]/) != null) {
    return false;
  }
  if (!number && s.match(/\d/) != null) {
    return false;
  }
  if (!symbol && s.match(/[_]/) != null) {
    return false;
  }
  return true;
}
export function Validation_RequireCharType(
  s: string,
  lower: boolean,
  upper: boolean,
  number: boolean
): boolean {
  if (lower && s.match(/[a-z]/) == null) {
    return false;
  }
  if (upper && s.match(/[A-Z]/) == null) {
    return false;
  }
  if (number && s.match(/\d/) == null) {
    return false;
  }
  return true;
}
export function Validation_RestrictStringLength(
  s: string,
  min: number,
  max: number
): boolean {
  if (min != null) {
    if (s.length < min) {
      return false;
    }
  }
  if (max != null) {
    if (s.length > max) {
      return false;
    }
  }
  return true;
}

export function Validation_StringLengthBySegmenter(
  s: string,
  min: number,
  max: number
): boolean {
  if (Intl.Segmenter) {
    const segmenter = new Intl.Segmenter("ja", { granularity: "grapheme" });
    const len = Array.from(segmenter.segment(s)).length;
    if (max) {
      if (len > max) {
        return false;
      }
    }
    if (min) {
      if (len < min) {
        return false;
      }
    }
    return true;
  } else {
    const len = s.length;
    if (max) {
      if (len > max) {
        return false;
      }
    }
    if (min) {
      if (len < min) {
        return false;
      }
    }
    return true;
  }
}

export function Validation_NotAllowNotExistDate(
  year: number,
  month: number,
  day: number
): boolean {
  if (year < 0) {
    return false;
  }
  switch (month) {
    case 1:
    case 3:
    case 5:
    case 7:
    case 8:
    case 10:
    case 12:
      if (day > 0 && day <= 31) {
        return true;
      } else {
        return false;
      }
      break;
    case 4:
    case 6:
    case 9:
    case 11:
      if (day > 0 && day <= 30) {
        return true;
      } else {
        return false;
      }
      break;
    case 2:
      if (year % 400 === 0) {
        if (day > 0 && day <= 29) {
          return true;
        } else {
          return false;
        }
      }
      if (year % 100 === 0) {
        if (day > 0 && day <= 28) {
          return true;
        } else {
          return false;
        }
      }
      if (year % 4 === 0) {
        if (day > 0 && day <= 29) {
          return true;
        } else {
          return false;
        }
      }
      if (day > 0 && day <= 28) {
        return true;
      } else {
        return false;
      }
    default:
      return false;
  }
}

export const OnErrorSetMessage = (
  ok: [boolean, string],
  setMessage: (s: string) => void
): void => {
  if (ok[0] !== true) {
    setMessage(ok[1]);
  }
  return;
};

export const ValidateParentPassword = (s: string): [boolean, string] => {
  if (Validation_NotAllowNullString(s) !== true) return [false, "null string"]; //near error
  if (
    Validation_RestrictStringLength(
      s,
      ParentPasswordMinLength,
      ParentPasswordMaxLength
    ) !== true
  )
    return [
      false,
      "パスワードは" +
        String(ParentPasswordMinLength) +
        "~" +
        String(ParentPasswordMaxLength) +
        "文字にしてください",
    ];
  if (Validation_RestrictCharType(s, true, true, true, true) !== true)
    return [false, "英数字、アンダースコア以外の文字を含めないでください"];
  if (Validation_RequireCharType(s, true, true, true) !== true)
    return [false, "小文字、大文字、数字をすべて含む文字列にしてください"];
  return [true, ""];
};

export const ValidateEmailAddress = (s: string): [boolean, string] => {
  const reg =
    /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  if (reg.test(s) !== true) {
    return [false, "正しいメールアドレスを入力してください"];
  }
  return [true, ""];
};

export const ValidateName = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "文字を入力してください"];
  if (Validation_RestrictStringLength(s, 1, NameMaxLength) !== true)
    return [false, "名前が長すぎます"];
  return [true, ""];
};

export const ValidateNickname = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "文字を入力してください"];
  if (Validation_StringLengthBySegmenter(s, 1, NicknameMaxLength) !== true)
    return [false, "名前が長すぎます"];
  return [true, ""];
};

export const ValidateYomigana = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "文字を入力してください"];
  if (Validation_RestrictStringLength(s, 1, NameMaxLength) !== true)
    return [false, "名前が長すぎます"];
  if (Validation_AllowHiraganaOnly(s) !== true)
    return [false, "全角ひらがなのみを入力してください"];
  return [true, ""];
};
export const ValidateLoginID = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "文字を入力してください"];
  if (Validation_RestrictCharType(s, true, true, true, true) !== true)
    return [false, "英数字、アンダースコア以外の文字を含めないでください"];
  if (
    Validation_RestrictStringLength(s, LoginIDMinLength, LoginIDMaxLength) !==
    true
  )
    return [
      false,
      "ログインIDは" +
        String(ParentPasswordMinLength) +
        "~" +
        String(ParentPasswordMaxLength) +
        "文字にしてください",
    ];
  return [true, ""];
};

export const ValidateBirthYear = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "年を選択してください"];
  return [true, ""];
};

export const ValidateBirthMonth = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "月を選択してください"];
  return [true, ""];
};

export const ValidateBirthDate = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "日を選択してください"];
  return [true, ""];
};

export const ValidateDateInput = (
  year: number,
  month: number,
  day: number
): [boolean, string] => {
  if (Validation_NotAllowNotExistDate(year, month, day) !== true)
    return [false, "実在しない日付です"];
  return [true, ""];
};

export const ValidationPostalCode = (s: string): [boolean, string] => {
  const reg = /^\d{7}$/;
  if (reg.test(s) !== true) {
    return [false, "正しい郵便番号を入力してください"];
  }
  return [true, ""];
};

export const ValidateRecaptchaValue = (s: string): [boolean, string] => {
  if (Validation_NotAllowEmptyString(s) !== true)
    return [false, "あなたがロボットでないことを証明してください"];
  return [true, ""];
};

export const ValidateFavoritePage = (n: number | ""): [boolean, string] => {
  if (n === "") {
    return [true, ""];
  }
  if (n > 0 && n <= 100000) {
    return [true, ""];
  }
  return [false, "正しいページ数を入力してください"];
};
