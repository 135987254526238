import React from "react";
import { useHistory } from "react-router-dom";
import { BreadCrumb, Crumb } from "~/component/atoms/bread_crumb";
import Header from "~/component/organisms/common/header";
import { SurveyWrapper } from "~/component/organisms/survey/SurveyWrapper";
import { FirstHeadContainer } from "~/component/templates/box";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import {
  CalilLibrary,
  useSearchLibraryQuery,
  useUpsertMyLibraryMutation,
} from "../generated/graphql";
import { RegisterMyLibraryContent } from "./RegisterMyLibrary";

export const ChangeLibrary = (): React.ReactElement => {
  const history = useHistory();

  const [pref, setPref] = React.useState<string>("");
  const [city, setCity] = React.useState<string>("");

  const {
    refetch: onSearchLibrary,
  } = useSearchLibraryQuery({
    skip: !pref || !city,
    variables: { pref: pref, city: city },
    onCompleted: (data) => {
      if (data && data.searchLibrary) {
        setLibraryList(data.searchLibrary);
      }
    },
  });

  const [libraryList, setLibraryList] = React.useState<CalilLibrary[]>([]);

  const [upsert, { loading }] = useUpsertMyLibraryMutation();
  const { refetch } = React.useContext(CurrentUserContext);

  return (
    <>
      <Header />
      <SurveyWrapper>
        <FirstHeadContainer>
          <BreadCrumb>
            <Crumb.FirstList>
              <Crumb.Link to={"/"}>トップ</Crumb.Link>
            </Crumb.FirstList>
            <Crumb.List>
              <Crumb.Para>図書館登録・変更</Crumb.Para>
            </Crumb.List>
          </BreadCrumb>
        </FirstHeadContainer>
        <RegisterMyLibraryContent
          pref={pref}
          setPref={setPref}
          city={city}
          setCity={setCity}
          libraryList={libraryList}
          onClearLibraryList={() => setLibraryList([])}
          onSearchLibrary={onSearchLibrary}
          onNext={() => history.push("/")}
          onSelectLibrary={(params) => {
            return new Promise((resolve) => {
              upsert({
                variables: {
                  calilLibraryAreaID: params.calilLibraryAreaID,
                  calilLibraryKey: params.calilLibraryKey,
                },
              })
                .then((res) => {
                  if (res.errors) {
                    resolve(false);
                  } else {
                    refetch();
                    resolve(true);
                  }
                })
                .catch(() => resolve(false));
            });
          }}
          loading={loading}
          submitButtonText={"図書館を登録して完了"}
        />
      </SurveyWrapper>
    </>
  );
};
