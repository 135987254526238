import { FontType } from "../ui/ReadingViewer";
import { DifficultyYL, PassageYL, passageYLs, Size } from "../types";

type PassageContent = {
  text: string;
  caption: string;
  size: Size;
  characterSrc: string;
  fontType: FontType;
  lineHeight: string;
  letterSpacing: string;
};

type CharacterList = "apple" | "penguin" | "bookWarm" | "lion";
const characterSrcList: Record<CharacterList, string> = {
  apple: "/img/world_view_onboarding/YLCheck/apple.png",
  penguin: "/img/world_view_onboarding/YLCheck/penguin.png",
  bookWarm: "/img/world_view_onboarding/YLCheck/bookWarm.png",
  lion: "/img/world_view_onboarding/YLCheck/lion.png",
};

export const getNextPassageYL = (
  lastAnswer: DifficultyYL,
  history: PassageYL[]
): PassageYL => {
  const len = history.length;

  if (len === 0) {
    return 20;
  }

  if (len === 1) {
    switch (lastAnswer) {
      case DifficultyYL.VeryDifficult:
        return undefined;
      case DifficultyYL.Difficult:
        return 15;
      case DifficultyYL.Normal:
        return 25;
      case DifficultyYL.Easy:
        return 30;
      case DifficultyYL.VeryEasy:
        return 35;
      default:
        return 25;
    }
  }

  if (len >= 3) {
    return undefined;
  }

  let idx = passageYLs.indexOf(history[len - 1]);

  switch (lastAnswer) {
    case DifficultyYL.VeryDifficult:
      idx -= 2;
      break;
    case DifficultyYL.Difficult:
      idx -= 1;
      break;
    case DifficultyYL.Normal:
      idx += 1;
      break;
    case DifficultyYL.Easy:
      idx += 1;
      break;
    case DifficultyYL.VeryEasy:
      idx += 2;
      break;
  }

  if (idx < 0 || 7 < idx) {
    return undefined;
  }

  if (history.includes(passageYLs[idx])) {
    return undefined;
  }

  return passageYLs[idx];
};

/* eslint-disable no-irregular-whitespace */
export const passageDict: Record<PassageYL, PassageContent> = {
  15: {
    text: `そこで、かあさんぐまは、
くまくんに、いいものを
こしらえてやりました。
「ほうら、いいものが
できましたよ。
かぶってごらん。」`,
    caption: "E・H・ミナリック『こぐまのくまくん』",
    size: "large",
    characterSrc: characterSrcList["bookWarm"],
    fontType: "Kyokasho",
    lineHeight: "38px",
    letterSpacing: "0.2em",
  },
  20: {
    // start yl check with this passage
    text: `パパが　アイスの　ふくろを
うらがえしてみると、そこには
「アイスおうこく」と、
かいてありました。
そして、『ききたいことや
ごしつもんの　ある　ひとは、
おうちの　れいとうこの
ひだりおくを　3かい　ノック
して、いりぐちから　
おはいりください』
と、せつめいが　あります。`,
    caption: "きたじま　ごうき『あたりかも』",
    size: "medium",
    characterSrc: characterSrcList["penguin"],
    fontType: "Kyokasho",
    lineHeight: "34px",
    letterSpacing: "0.1em",
  },
  25: {
    text: `「やれやれ、あれは きつねの子
だったのかい、とんと
わからなかったよ。そういえば
みたことのない子だったなぁ。」
先生は めがねを ふきふきいいました。
そして、コッペパンを 次郎の
手にのせて いいました。
「これをな、きつねの子に
もっていって おやり。」
次郎と たかしは うれしくて 
たまりません。コンがパンをみたら、
どんなによろこぶでしょう。`,
    caption: "松谷みよ子『ジャムねこさん』",
    size: "medium",
    characterSrc: characterSrcList["lion"],
    fontType: "Kyokasho",
    lineHeight: "160%",
    letterSpacing: "0.075em",
  },
  30: {
    text: `ヤギのヤギマロ先生は、
山のてっぺんにひとりでくらして
いる、お習字の先生です。
村の動物たちからすると、先生は、
まるでえらくて、ちょっとこわい
かんじのするひとなのでした。
そんなわけで、お習字を習いに、
先生の家に行く生徒たちは、みな、
きんちょうして、げんかんを入ります。
先生の家は、古いけれど、
きちんと手入れがしてあり、
ろうかなんか、黒く、ぴかぴかです。`,
    caption: "たかどの　ほうこ『へろりのだいふく』",
    size: "medium",
    characterSrc: characterSrcList["apple"],
    fontType: "Kyokasho",
    lineHeight: "160%",
    letterSpacing: "0.06em",
  },
  35: {
    text: `トムは、（だれかぼくのことを飼ってくれるひとがいたら、もうさむい夜にそとでねたりしなくてすむのに）と思いました。
「よい飼い主をみつけることが、ネコにとっていちばんたいせつなことなのよ。
そうすれば、ひもじい思いや、さむい思いをしなくてすむのだから」
母親は、トムがまだオッパイをのんでいるころから、そういっていました。
トムはいま、ほんとうにそうなんだなと思いました。
トムは、二匹の兄弟たちと、港の倉庫で生まれました。
お兄さんはとても大きくて、つよくて、トラのようにりっぱでした。`,
    caption: "南部和也『ネコのタクシー』",
    size: "medium",
    characterSrc: characterSrcList["bookWarm"],
    fontType: "Kyokasho",
    lineHeight: "155%",
    letterSpacing: "0.03em",
  },
  40: {
    text: `マジヒコ氏の席に、白い服を着た人が背中を向けて、プカンと浮いたような感じですわっていたのだ。マジヒコ氏は、びっくりしたあとはたいへん不愉快なきもちになった。こういうずうずうしい悪ふざけは、マジヒコ氏のとりわけきらいなことだった。
そこで、せきばらいしてからいった。
「オホン！ いったい、どなたですかな。人の家にあがりこみ、わたしの席にかってにすわっておられるのは」
その人はびくっとひとつうごくと、ゆっくりふりむいた。その顔を見たとき、マジヒコ氏はまたまたどきっとして、手に持ったお盆をおとしそうになった。なぜなら、その顔は、自分にそっくりだったからだ。`,
    caption: "たかどの　ほうこ『紳士とオバケ氏』",
    size: "medium",
    characterSrc: characterSrcList["penguin"],
    fontType: "Kyokasho",
    lineHeight: "155%",
    letterSpacing: "0.02em",
  },
  45: {
    text: `もしパトカーのサイレンがあれほどけたたましくなかったら、おそらく切手偽造犯は、セメントミキサーのなかにずっとかくれていたにちがいありません。
しかしくろて団が目の当たりに見た光景は、しまのネクタイのミスターXが、金属製のアタッシュケースをしっかりさげたままミキサーからとびだし、水平飛行で壁をこえ、そのあと消えたというものでした。
「あいつ、ぜったいにけがしたわよ！」
と、アデーレがさけびました。
ところが、ミスターXはまったくけがをしませんでした。堆肥の山の上に胴体着陸したからです。
「あいつ、ぜったいにけがしたな。」
「なんてすさまじいにおいだろ!」
と、クルツバッハ巡査部長は、三台のパトカー警官隊の先頭に立って、その農家にかけつけるなり、さけびました。`,
    caption: "ハンス・ユルゲン・ブレス『くろて団は名探偵』",
    size: "medium",
    characterSrc: characterSrcList["lion"],
    fontType: "Mincho",
    lineHeight: "149%",
    letterSpacing: "auto",
  },
  50: {
    // 今はYL50の文章には到達しないが，YL判定の文章の表示順について検討中なため残しておく
    text: `夜明け、メイ・タックが馬にのってトゥリーギャップ村のはずれにある森にむかった。息子のマイルズとジェシィに会うために、十年に一回だけそうしている。
正午、その森の持ち主であるフォスター家のひとり娘ウィニー・フォスターは、とうとうがまんできなくて家出を考えることにした。
暮れがた、ひとりの旅人がフォスター家の門にあらわれた。だれかをさがしていたが、その相手についてはなにも語らなかった。
この三つの出来事には、なんのつながりもない、あなたもそう思うでしょう。けれど、出来事はふしぎな方法でピタリと結びつくことがある。
この三つをつないだのは森である。車輪でいえば、森はその軸のようなはたらきをしていた。まわる車輪にはみんな軸がある。月日が太陽を軸にしてまわっているように、観覧車だって軸をもっているからまわる。`,
    caption: "ナタリー・バビット『時をさまようタック』",
    size: "medium",
    characterSrc: characterSrcList["apple"],
    fontType: "Mincho",
    lineHeight: "150%",
    letterSpacing: "auto",
  },
};
/* eslint-enable no-irregular-whitespace */
