import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { FontSize } from "~/component/theme";
import { useSnd } from "~/utils/hooks/useSnd";

type BaseButtonProps = {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent) => void;
  fontSize: FontSize;
  disabled?: boolean;
  style?: React.CSSProperties;
  trackingLabel?: string;
};

export const BaseButton: VFC<BaseButtonProps> = ({
  children,
  onClick,
  fontSize,
  disabled = false,
  style,
}) => {
  const { play } = useSnd();
  const handleClick = (event: React.MouseEvent) => {
    play("TAP");
    onClick(event);
  };

  return (
    <ButtonCon disabled={disabled}>
      <StyledButton
        onClick={handleClick}
        disabled={disabled}
        fontSize={fontSize}
        style={style}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const ButtonCon = styled.div<Pick<BaseButtonProps, "disabled">>`
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  display: flex;
  align-items: center;
`;

const StyledButton = styled.button<
  Pick<BaseButtonProps, "disabled" | "fontSize">
>`
  ${({ theme, disabled, fontSize }) => {
    return css`
      font-size: ${theme.fontSize[fontSize]};
      border: none;
      background: transparent;
      width: auto;
      margin: 0;
      padding: 0;
      cursor: ${!disabled && "pointer"};
      &:focus,
      &:active {
        outline: none;
      }
    `;
  }}
`;
