import * as React from "react";
import styled from "styled-components";
import { Center } from "~/component/atoms/layout/Center";
import { Stack } from "~/component/atoms/layout/Stack";
import { FirstBookRecommendEnqueteResult as Result } from "~/generated/graphql";
import { CommentFromSensei } from "./CommentFromSensei";
import { VeryEasyIcon } from "./icons";
import yl1Image from "./images/yl1.png";
import yl2Image from "./images/yl2.png";
import { Manual } from "./Manual";
import { Row } from "~/component/atoms/layout/Row";
import { Text } from "~/component/atoms/text/Text";
import { PassageYL, passageDict, extendedPassageYLs } from "./const";
import { theme } from "~/component/theme";

type Props = {
  result: Result;
  header: React.ReactNode;
  userId: number;
};

const HEADER_OFFSET = 100;

const roundUpToPassageYL = (value: number): PassageYL => {
  const passageYL = extendedPassageYLs.find((yl) => {
    return yl - 5 < value && value <= yl;
  });
  if (!passageYL) {
    if (value > 50) {
      return 51;
    } else {
      return 15;
    }
  }

  return passageYL;
};

export const EnqueteResult: React.FC<Props> = ({ result, header, userId }) => {
  const { nickname, yl, charNum, answerdBookGenres } = result;
  const handleAnchor = React.useCallback((id: string) => {
    const target = document.getElementById(id);
    target &&
      window.scrollTo({
        top: target.offsetTop - HEADER_OFFSET,
        behavior: "smooth",
      });
  }, []);

  let tableContentYL: PassageYL[];
  const roundedUpYL = roundUpToPassageYL(yl);

  if (roundedUpYL === 15) {
    tableContentYL = [15, 20, 25];
  } else if (roundedUpYL >= 50) {
    tableContentYL = [45, 50, 51];
  } else {
    tableContentYL = [
      (roundedUpYL - 5) as PassageYL,
      roundedUpYL,
      (roundedUpYL + 5) as PassageYL,
    ];
  }

  return (
    <>
      {header}
      <_Wrapper>
        <_Container>
          <Stack rowGap="16px">
            <_MainTitle>
              {nickname}
              <span className="san">さん</span>
              <_GraduateCapContainer>
                <_GraduateCapSVG />
              </_GraduateCapContainer>
              読書診断
            </_MainTitle>
            <_SectionContainer>
              <Stack rowGap="12px">
                <_ChapterTitle>目次</_ChapterTitle>
                <Stack rowGap="0">
                  <_ChapterItem
                    onClick={() => handleAnchor("report-chapter-1")}
                  >
                    適正YL
                  </_ChapterItem>
                  <_ChapterItem
                    onClick={() => handleAnchor("report-chapter-2")}
                  >
                    適正文字数
                  </_ChapterItem>
                  <_ChapterItem
                    onClick={() => handleAnchor("report-chapter-3")}
                  >
                    好みのジャンル
                  </_ChapterItem>
                  <_ChapterItem
                    onClick={() => handleAnchor("report-chapter-4")}
                  >
                    YLとは
                  </_ChapterItem>
                  <_ChapterItem
                    onClick={() => handleAnchor("report-chapter-5")}
                  >
                    ヨンデミー先生からのコメント
                  </_ChapterItem>
                </Stack>
              </Stack>
            </_SectionContainer>
            <_SectionContainer id="report-chapter-1">
              <Stack rowGap="8px">
                <_SectionTitle>適正YL</_SectionTitle>
                <_Description>
                  絵本・児童書の難しさを示す
                  <br />
                  ヨンデミー独自の指標です。
                </_Description>
                <Center>
                  <_YLScore>{yl}</_YLScore>
                </Center>
              </Stack>
            </_SectionContainer>

            <_SectionContainer id="report-chapter-2">
              <Stack rowGap="12px">
                <Stack rowGap="8px">
                  <_SectionTitle>適正文字数</_SectionTitle>
                  <_CharacterCountWrapper>
                    <_CharacterCount>
                      <b>{charNum}</b> 文字
                    </_CharacterCount>
                  </_CharacterCountWrapper>
                </Stack>
                <_Description>
                  アンケートの問いと答えから、{nickname}
                  さんの推定YLと1冊あたり集中して読むことのできる文字数を算出しました。
                </_Description>
              </Stack>
            </_SectionContainer>
            <_SectionContainer id="report-chapter-3">
              <Stack rowGap="12px">
                <_SectionTitle>好みのジャンル</_SectionTitle>
                <_MostFavoriteGenre>
                  {answerdBookGenres.mostFavoriteGenre}
                </_MostFavoriteGenre>
                {answerdBookGenres.favoriteGenres && (
                  <_FavoriteGenres>
                    {answerdBookGenres.favoriteGenres}
                  </_FavoriteGenres>
                )}
              </Stack>
            </_SectionContainer>
            <_SectionContainer id="report-chapter-4">
              <Stack rowGap="12px">
                <_WhatIsYLTitle>
                  <svg
                    width="13"
                    height="14"
                    viewBox="0 0 13 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M12.7969 7C12.7969 10.4784 9.97742 13.2969 6.5 13.2969C3.02258 13.2969 0.203125 10.4784 0.203125 7C0.203125 3.52359 3.02258 0.703125 6.5 0.703125C9.97742 0.703125 12.7969 3.52359 12.7969 7ZM6.66897 2.78516C5.28526 2.78516 4.40273 3.36805 3.70972 4.40401C3.61994 4.53823 3.64998 4.71924 3.77866 4.81681L4.65969 5.48484C4.79185 5.58506 4.98014 5.56121 5.08282 5.43096C5.5364 4.85566 5.84741 4.52205 6.53778 4.52205C7.05649 4.52205 7.69808 4.85589 7.69808 5.35888C7.69808 5.73913 7.38418 5.93441 6.87202 6.22155C6.27473 6.55638 5.48438 6.97311 5.48438 8.01562V8.11719C5.48438 8.28545 5.6208 8.42188 5.78906 8.42188H7.21094C7.3792 8.42188 7.51562 8.28545 7.51562 8.11719V8.08334C7.51562 7.36067 9.62777 7.33059 9.62777 5.375C9.62777 3.90229 8.10014 2.78516 6.66897 2.78516ZM6.5 9.08203C5.85597 9.08203 5.33203 9.60597 5.33203 10.25C5.33203 10.894 5.85597 11.418 6.5 11.418C7.14403 11.418 7.66797 10.894 7.66797 10.25C7.66797 9.60597 7.14403 9.08203 6.5 9.08203Z"
                      fill="#828282"
                    />
                  </svg>
                  YLとは
                </_WhatIsYLTitle>
                <_Description>
                  YLは絵本・児童書の難しさを示すヨンデミー独自の指標です。YLが高いほど、その本が難しいことを示しています。
                </_Description>
                <Stack>
                  <_SectionSubTitle>YLと本の対応表</_SectionSubTitle>
                  <Center>
                    <_Image src={yl1Image} style={{ width: 120 }} />
                  </Center>
                </Stack>
                <Stack rowGap="12px">
                  <Stack rowGap="0">
                    <_SectionSubTitle>学年との対応表</_SectionSubTitle>
                    <_SubDescription>
                      ※学年はあくまでも目安であり、個人差があります
                      <br />
                      ※学年の7割程度が含まれるような水準を表示しています
                    </_SubDescription>
                  </Stack>
                  <Center>
                    <_Image src={yl2Image} />
                  </Center>
                </Stack>

                <Stack rowGap="12px">
                  <Stack rowGap="0">
                    <_SectionSubTitle>YLごとの参考例文</_SectionSubTitle>
                    <_Description>
                      アンケートにて、お子さんにYLごとの文章を読んでいただきました。
                      <br />
                      それぞれの文章へのお子さんの評価から、適正YLを算出しています。
                    </_Description>
                  </Stack>
                  <Row>
                    <VeryEasyIcon />
                    <Text color="tex.t500" fontSize="SM" lineHeight="MD">
                      ・・・あなたの該当するYL
                    </Text>
                  </Row>
                  <Stack>
                    <_Table cellPadding="0" cellSpacing="0">
                      <_Tr>
                        <_Th>YL</_Th>
                        <_Td
                          style={{
                            textAlign: "center",
                            fontSize: "16px",
                            fontWeight: 700,
                          }}
                        >
                          参考例文
                        </_Td>
                      </_Tr>
                      {tableContentYL.map((YL, index) => (
                        <_Tr key={index}>
                          <_Th
                            style={
                              YL === roundedUpYL
                                ? { backgroundColor: theme.colors.pri.p100 }
                                : {}
                            }
                          >
                            <_Row>
                              {YL === roundedUpYL && <VeryEasyIcon />}
                              {YL <= 50 ? `~${YL}` : "51~"}
                            </_Row>
                          </_Th>
                          <_Td>
                            {passageDict[YL].text}
                            <Text
                              fontColor="semantic.text.label"
                              fontSize="XS"
                              lineHeight="MD"
                            >
                              {passageDict[YL].caption}
                            </Text>
                          </_Td>
                        </_Tr>
                      ))}
                    </_Table>
                  </Stack>
                </Stack>
              </Stack>
            </_SectionContainer>
            <_SectionContainer id="report-chapter-5">
              <CommentFromSensei result={result} userId={userId} />
            </_SectionContainer>
            <Manual />
          </Stack>
        </_Container>
      </_Wrapper>
    </>
  );
};

const _Row = styled(Row)`
  justify-content: center;
`;

const _Table = styled.table`
  width: 100%;
  border-collapse: separate;
  border: solid 4px ${({ theme }) => theme.colors.base.lightGray};
  border-radius: 16px;
`;

const _Tr = styled.tr`
  &:last-child {
    th,
    td {
      border-bottom: none;
    }
  }
`;

const _Th = styled.th`
  width: 30%;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 16px;
  font-weight: 700;

  text-align: center;
  border-bottom: solid 4px ${({ theme }) => theme.colors.base.lightGray};
  vertical-align: middle;
`;

const _Td = styled.td`
  width: 70%;
  padding: 12px 20px;
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 14px;
  font-weight: 400;
  line-height: 160%;

  border-left: solid 4px ${({ theme }) => theme.colors.base.lightGray};
  border-bottom: solid 4px ${({ theme }) => theme.colors.base.lightGray};
`;

const _MainTitle = styled.h1`
  font-weight: 700;
  font-size: 20px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.tex.t700};

  span.san {
    font-size: 12px;
  }
`;

const _Wrapper = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.base.background};
`;

const _Container = styled.div`
  margin: 0 auto;
  box-sizing: border-box;
  padding: 16px;
  padding-top: ${HEADER_OFFSET}px;
  width: 100%;
  max-width: 800px;
`;

const _MostFavoriteGenre = styled.div`
  font-weight: 700;
  font-size: 24px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.pri.p500};
`;
const _FavoriteGenres = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.pri.p500};

  b {
    font-weight: 700;
    font-size: 16px;
  }
`;

const _YLScore = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 700;
  font-size: 52px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.sec.s400};
`;
const _CharacterCount = styled.div`
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.green.g400};

  b {
    font-weight: 700;
    font-size: 52px;
    line-height: 100%;
  }
`;

const _CharacterCountWrapper = styled(Center)`
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.base.lightGray};
`;

const _Description = styled.div`
  font-weight: 400;
  font-size: 12px;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.tex.t500};
`;
const _SubDescription = styled.div`
  font-weight: 400;
  font-size: 9px;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.tex.t200};
`;

const _ChapterItem = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.base.lightGray};
  font-weight: 400;
  font-size: 14px;
  padding: 4px 0;
  color: ${({ theme }) => theme.colors.tex.t500};

  &:first-child {
    border-top: 1px solid ${({ theme }) => theme.colors.base.lightGray};
  }
`;

const _SectionTitle = styled.div`
  font-weight: 700;
  font-size: 14px;
  line-height: 100%;
  color: ${({ theme }) => theme.colors.tex.t700};
`;

const _WhatIsYLTitle = styled(_SectionTitle)`
  display: flex;
  flex-direction: row;
  column-gap: 8px;
  align-items: center;
  color: ${({ theme }) => theme.colors.tex.t300};
`;

const _SectionSubTitle = styled(_SectionTitle)`
  font-size: 12px;
  line-height: 160%;
  color: ${({ theme }) => theme.colors.tex.t500};
`;
const _ChapterTitle = styled(_SectionTitle)`
  color: ${({ theme }) => theme.colors.sec.s400};
`;

const _SectionContainer = styled.div`
  background-color: white;
  padding: 16px;
  border-radius: 16px;
`;

const _Image = styled.img`
  width: 100%;
  height: auto;
`;

const _GraduateCapSVG: React.FC = () => (
  <svg
    width="29"
    height="29"
    viewBox="0 0 29 29"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1075_3587)">
      <path
        d="M28.1998 9.84188L15.5603 5.9586C14.8716 5.74699 14.1285 5.74699 13.4402 5.9586L0.800236 9.84188C-0.26642 10.1695 -0.26642 11.5801 0.800236 11.9077L3.00378 12.5846C2.5203 13.1823 2.22305 13.9114 2.1936 14.7098C1.75724 14.9599 1.45002 15.4112 1.45002 15.95C1.45002 16.4385 1.70739 16.8495 2.07805 17.1123L0.921221 22.3178C0.820627 22.7704 1.165 23.2 1.62855 23.2H4.17103C4.63503 23.2 4.97941 22.7704 4.87881 22.3178L3.72199 17.1123C4.09264 16.8495 4.35002 16.4385 4.35002 15.95C4.35002 15.4257 4.05685 14.9871 3.64042 14.7325C3.67486 14.0519 4.02286 13.4501 4.57794 13.0686L13.4397 15.7914C13.8502 15.9174 14.6378 16.0746 15.5599 15.7914L28.1998 11.9081C29.2669 11.5801 29.2669 10.1699 28.1998 9.84188ZM15.9858 17.1775C14.693 17.5745 13.5915 17.3551 13.0138 17.1775L6.44255 15.1589L5.80002 20.3C5.80002 21.9018 9.69508 23.2 14.5 23.2C19.305 23.2 23.2 21.9018 23.2 20.3L22.5575 15.1584L15.9858 17.1775Z"
        fill="#323232"
      />
    </g>
    <defs>
      <clipPath id="clip0_1075_3587">
        <rect width="29" height="29" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const _GraduateCapContainer = styled.div`
  // transform: translateY(7px);
  display: inline-block;
  padding: 0 4px;
`;
