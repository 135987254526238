import React, { FC } from "react";
import styled from "styled-components";
import { Text } from "~/component/atoms/text/Text";
import { BookshelfType } from "../container";

export type BookshelfTabProps = {
  value: BookshelfType;
  onChange: (value: BookshelfType) => void;
};

type TabType = { label: string; value: BookshelfType };

export const BookshelfTab: FC<BookshelfTabProps> = ({ value, onChange }) => {
  const items: TabType[] = [
    { label: "おすすめ", value: "recommend" },
    { label: "読みま書架", value: "wantReadBook" },
  ];

  return (
    <_TabWrapper>
      {items.map((item) => (
        <_ButtonWrapper key={item.value}>
          {value === item.value ? (
            <_FlatButton
              onClick={() => {
                onChange(item.value);
              }}
              selected={true}
            >
              <Text
                fontColor="base.white"
                fontSize="SM"
                lineHeight="EQ"
                bold
                keepAll
              >
                {item.label}
              </Text>
            </_FlatButton>
          ) : (
            <_FlatButton
              onClick={() => {
                onChange(item.value);
              }}
              selected={false}
            >
              <Text
                fontColor="semantic.text.unselected"
                fontSize="SM"
                lineHeight="EQ"
                keepAll
              >
                {item.label}
              </Text>
            </_FlatButton>
          )}
        </_ButtonWrapper>
      ))}
    </_TabWrapper>
  );
};

const _TabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _FlatButton = styled.button<{ selected: boolean }>`
  border: none;
  cursor: pointer;
  outline: none;
  appearance: none;
  padding: 10px 6px;
  border-radius: 18px;
  width: 100%;

  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.semantic.primary.main : "transparent"};
`;

const _ButtonWrapper = styled.div`
  flex: 1;
`;
