import React, { useCallback } from "react";
import { RemoveRecommendTemplate } from ".";
import { RemoveReason, useRemoveRecommendMutation } from "~/generated/graphql";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { ReadStatusForPostReadLog } from "~/generated/graphql";
import { config } from "../../../config";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { BOOKLIST_USER_SESSIONSTORAGE_KEY } from "../BookList/container";

export type EntryPageOfRemoveRecommend = "bookShelf" | "bookListViewer";

export const RemoveRecommend: React.FC = () => {
  const { currentUser } = React.useContext(CurrentUserContext);
  const entryPage: string = React.useMemo(() => {
    let userId: number | undefined = undefined;
    if (currentUser && currentUser.children.get.length > 0) {
      userId = currentUser.children.get[0].id;
    }
    const _storageItem = sessionStorage.getItem(
      BOOKLIST_USER_SESSIONSTORAGE_KEY
    );
    let _storageUserId = undefined;
    if (_storageItem) {
      _storageUserId = JSON.parse(_storageItem);
    }
    if (
      typeof _storageUserId === "number" &&
      currentUser &&
      currentUser.children.get.some((child) => _storageUserId === child.id)
    ) {
      userId = _storageUserId;
    }
    if (userId) {
      return `/booklist/${userId}`;
    } else {
      return "/";
    }
  }, [currentUser]);

  const [removeRecommend, { loading }] = useRemoveRecommendMutation({
    onCompleted: () => {
      history.push(entryPage);
    },
  });

  const search = useLocation().search;
  const searchParams = new URLSearchParams(search);
  const ISBN = searchParams.get("ISBN");
  const title = searchParams.get("title");

  const { recommendId } = useParams<{
    recommendId: string;
  }>();

  const history = useHistory();

  const handleBack = useCallback(() => {
    history.goBack();
  }, [history]);

  const handleRemoveRecommend = useCallback(
    (removeReason: RemoveReason): Promise<boolean> => {
      return new Promise((resolve) => {
        removeRecommend({
          variables: {
            recommendID: Number(recommendId),
            removeReason: removeReason,
          },
        })
          .then((res) => {
            if (res.errors || !res.data) {
              resolve(false);
            }
            resolve(true);
          })
          .catch(() => {
            resolve(false);
          });
      });
    },
    [recommendId, removeRecommend]
  );

  const handleGotoPostReadLog = useCallback(() => {
    const searchParams = new URLSearchParams({
      recommendID: recommendId,
      title: title ? title : "",
      ISBN: ISBN || "",
      readStatus: ReadStatusForPostReadLog.Reading,
      flow: "confirmLike",
    });
    window.open(
      `${config.USER_APP}postreadlog/init?${searchParams.toString()}`,
      "_brank"
    );
  }, [recommendId, title, ISBN]);

  if (!ISBN || !recommendId) {
    history.push(entryPage);
    return null;
  }

  return (
    <RemoveRecommendTemplate
      handleBack={handleBack}
      handleRemoveRecommend={handleRemoveRecommend}
      handleGotoPostReadLog={handleGotoPostReadLog}
      loading={loading}
    />
  );
};
