import React from "react";
import { useHistory } from "react-router-dom";
import styled from "styled-components";
import { BookshelfImage } from "~/component/atoms/images/BookshelfImage";
import { BackNavbar } from "~/component/molecules/BackNavbar";
import { BookInfo, BookInfoProps } from "~/component/organisms/BookInfo";
import { Text } from "~/component/atoms/text/Text";
import { theme } from "~/component/theme";
import { Icon } from "~/component/atoms/icon";
import { LargeButton } from "~/component/atoms/button/Button";
import { WantReadBookDetailFooter } from "./WantReadBookDetailFooter";

export type WantReadBookDetailTemplateProps = {
  bookInfoProps: BookInfoProps;
  handleRemoveWantReadBook: () => void;
  handleCopyISBN: (isbn: string) => void;
  myLibraryName?: string;
};

export const WantReadBookDetailTemplate: React.FC<
  WantReadBookDetailTemplateProps
> = ({
  bookInfoProps,
  handleRemoveWantReadBook,
  handleCopyISBN,
  myLibraryName,
}) => {
  const history = useHistory();

  const handleClickGoToCalil = () => {
    if (myLibraryName) {
      window.open(
        `https://calil.jp/book/${bookInfoProps.book.isbn}/search?nearby=${myLibraryName}`,
        "_blank"
      );
      window.gtag("event", "reserve", {
        event_category: "book",
        event_label: myLibraryName,
        value: 1,
        isbn: bookInfoProps.book.isbn,
      });
    } else {
      history.push("/change_library");
    }
  };

  return (
    <>
      <_NavContainer>
        <BackNavbar
          onClick={() => history.goBack()}
          titleName={"読みま書架の本"}
        />
      </_NavContainer>
      <_Wrapper>
        <_Contents>
          <_BookInfoWrapper>
            <_ImgWrapper>
              <BookshelfImage
                url={bookInfoProps.book.imageUrl}
                alt={bookInfoProps.book.title ? bookInfoProps.book.title : ""}
              />
            </_ImgWrapper>
            <div className="book-props">
              <Text fontSize="MD" lineHeight="MD" bold>
                {bookInfoProps.book.title}
              </Text>
              <div className="isbn">
                <Text
                  fontSize="SM"
                  lineHeight="EQ"
                  style={{ color: theme.colors.semantic.text.label }}
                >
                  ISBN
                </Text>
                <Text fontSize="SM" lineHeight="EQ">
                  {bookInfoProps.book.isbn}
                </Text>
                <Icon
                  name="copy"
                  onClick={() => handleCopyISBN(bookInfoProps.book.isbn)}
                  color="pri"
                />
              </div>
              <LargeButton onClick={() => handleClickGoToCalil()}>
                <Text
                  fontSize="MD"
                  lineHeight="MD"
                  bold
                  style={{ color: "white" }}
                >
                  {"近くの図書館で予約"}
                </Text>
              </LargeButton>
            </div>
          </_BookInfoWrapper>
        </_Contents>
        <_Section>
          <BookInfo {...bookInfoProps} />
        </_Section>
        <WantReadBookDetailFooter
          handleRemoveWantReadBook={handleRemoveWantReadBook}
        />
      </_Wrapper>
    </>
  );
};

const _NavContainer = styled.div`
  width: 100%;
  position: fixed;
  top: -1px;
  z-index: ${(props) => props.theme.zIndex.navbar};
`;

const _Wrapper = styled.div`
  width: 100%;
  padding: 56px 7% 84px 7%;
  min-height: 100vh;
  @media screen and (min-width: 700px) {
    width: 100%;
    padding: 56px 20% 84px 20%;
  }
  @media screen and (min-width: 1000px) {
    width: 100%;
    padding: 56px 32% 84px 32%;
  }
  background-color: ${(props) =>
    props.theme.colors.semantic.layout.backgroundMain};

  display: flex;
  flex-direction: column;
  row-gap: 24px;
`;

const _Contents = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 20px;
`;

const _BookInfoWrapper = styled.div`
  padding: 24px 0 0 0;

  display: flex;
  flex-direction: row;
  column-gap: 16px;
  align-items: center;

  .book-props {
    display: flex;
    flex-direction: column;
    width: 60%;
    row-gap: 16px;
    justify-content: center;

    .isbn {
      display: flex;
      flex-direction: row;
      column-gap: 10px;
      align-items: center;
    }
  }
`;

const _ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  width: 30%;
  min-width: 120px;
`;

const _Section = styled.section`
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;
