import * as React from "react";
import styled from "styled-components";

type IconTitleProps = {
  title: string;
  iconPath: string;
}

export const IconTitle: React.VFC<IconTitleProps> = ({ title, iconPath }) => {
  return (
    <Con>
      <IconImg src={iconPath} />
      <Title>
        {title}
      </Title>
    </Con>
  );
};

const Con = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  color: ${(props) => props.theme.colors.tex.t700};
  path{
    fill: ${(props) => props.theme.colors.tex.t700};
  }
`;

const IconImg = styled.img`
  height: 16px;
  margin-right: 12px;
`;

const Title = styled.h2`
  font-weight: bold;
  font-size: 16px;
`;