import React from "react";
import styled from "styled-components";
import { PrimaryFlatButton } from "../PrimaryFlatButton";
import { Text } from "~/component/atoms/text/Text";

export type PaginationButtonProps = {
  currentCount: number;
  onClick: (n: number) => void;
  itemsPerPage: number;
  totalItemsCount: number;
};

export const PaginationButton = ({
  currentCount,
  onClick,
  itemsPerPage,
  totalItemsCount,
}: PaginationButtonProps): React.ReactElement => {
  const onClickNext = () => {
    onClick(currentCount + itemsPerPage);
  };
  const onClickPrev = () => {
    onClick(currentCount - itemsPerPage);
  };

  return (
    <Wrapper>
      <_PrimaryFlatButton
        fontSize={"SM"}
        color={"pri"}
        lineHeight={"MD"}
        onClick={onClickPrev}
        isVisible={!(currentCount < itemsPerPage)}
        disabled={currentCount < itemsPerPage}
        text="←前へ"
      />
      <PaginationCon>
        <_Text
          fontSize={"SM"}
          lineHeight={"MD"}
          isVisible={totalItemsCount > itemsPerPage}
        >
          {`${currentCount / itemsPerPage + 1}ページ目`}
        </_Text>
      </PaginationCon>
      <_PrimaryFlatButton
        fontSize={"SM"}
        color={"pri"}
        lineHeight={"MD"}
        onClick={onClickNext}
        disabled={
          totalItemsCount != null &&
          totalItemsCount <= currentCount + itemsPerPage
        }
        isVisible={
          !(
            totalItemsCount != null &&
            totalItemsCount <= currentCount + itemsPerPage
          )
        }
        text="次へ→"
      />
    </Wrapper>
  );
};

type TextVisibilityProps = {
  isVisible: boolean;
};

const _Text = styled(Text)<TextVisibilityProps>`
  display: ${(props) => (props.isVisible ? "block" : "none")};
`;

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  column-gap: 16px;
`;

const PaginationCon = styled.div`
  min-width: 82px;
  display: flex;
  justify-content: center;
`;

const _PrimaryFlatButton = styled(PrimaryFlatButton)<TextVisibilityProps>`
  display: ${(props) => (props.isVisible ? "auto" : "none")};
  min-width: 82px;
  padding: 5px 14px;
  color: ${(props) => props.theme.colors.base.white};
  @media screen and (min-width: 700px) {
    width: 100px;
  }
`;
