import * as React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Auth0Context } from "./contexts/Auth0Context";
import { LoginTemplate } from "./component/templates/Login";

export const Login = (): React.ReactElement => {
  const history = useHistory();
  const [email, setEmail] = React.useState("");
  const [password, setPassword] = React.useState("");
  const [isOpenForgetPasswordToast, setIsOpenForgetPasswordToast] =
    React.useState(false);

  const { search } = useLocation();
  const location = new URLSearchParams(search);

  //NOTE: LPからの遷移時に404を表示させないため/に飛ばしてからリダイレクトする
  if (location && location.get("location") === "introduction") {
    history.push("/introduction");
  }

  const { login } = React.useContext(Auth0Context);

  const handleChangeEmail = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setEmail(event.target.value);
  };

  const handleChangePassword = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    event.preventDefault();
    setPassword(event.target.value);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    login(email, password, {
      redirectUri: `${window.location.origin}/`,
      onLoginError: () => {
        setIsOpenForgetPasswordToast(true);
      },
    });
  };
  return (
    <LoginTemplate
      email={email}
      password={password}
      handleChangeEmail={handleChangeEmail}
      handleChangePassword={handleChangePassword}
      handleSubmit={handleSubmit}
      isOpenForgetPasswordToast={isOpenForgetPasswordToast}
      setIsOpenForgetPasswordToast={setIsOpenForgetPasswordToast}
    />
  );
};
