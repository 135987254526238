import React, { VFC } from "react";
import { useTheme } from "styled-components";
import {
  Anchorize,
  AnchorizeProps,
  Linkerize,
  LinkerizeProps,
} from "~/component/atoms/button/tag_changer";
import { BaseButton } from "~/component/molecules/button/base";
import { ColorTheme } from "~/component/theme";
import { FontSize } from "~/component/theme";
import { Icon, IconType } from "~/component/atoms/icon";

export type IconButtonProps = {
  name: IconType;
  onClick: (event: React.MouseEvent) => void;
  color: ColorTheme;
  fontSize: FontSize;
  disabled?: boolean;
  style?: React.CSSProperties;
};

/**
 * @deprecated アイコンボタン単体で使用することはないため、BackNavbarなど例外的にアイコンボタンに相当するものが必要な場合はそのコンポーネント内で実装してください
 */
export const IconButton: VFC<IconButtonProps> = ({
  name,
  onClick,
  color,
  fontSize,
  disabled = false,
  style,
}) => {
  const theme = useTheme();
  return (
    <BaseButton
      onClick={onClick}
      disabled={disabled}
      style={style}
      fontSize={fontSize}
      trackingLabel={`icon_${name}`}
    >
      <Icon
        name={name}
        color={color}
        style={{ height: theme.fontSize[fontSize], width: "auto" }}
      />
    </BaseButton>
  );
};

export type IconAnchorProps = Omit<IconButtonProps, "onClick"> &
  Omit<AnchorizeProps, keyof IconButtonProps>;

export const IconAnchor: VFC<IconAnchorProps> = ({
  name,
  color,
  disabled,
  style,
  fontSize,
  ...anchorProps
}) => (
  <Anchorize {...anchorProps} disabled={disabled}>
    <IconButton
      name={name}
      onClick={() => {
        return;
      }}
      color={color}
      fontSize={fontSize}
      disabled={disabled}
      style={style}
    />
  </Anchorize>
);

export type IconLinkProps = Omit<IconButtonProps, "onClick"> &
  Omit<LinkerizeProps, keyof IconButtonProps>;

export const IconLink: VFC<IconLinkProps> = ({
  name,
  color,
  disabled,
  style,
  fontSize,
  ...linkProps
}) => (
  <Linkerize {...linkProps} disabled={disabled}>
    <IconButton
      name={name}
      onClick={() => {
        return;
      }}
      color={color}
      fontSize={fontSize}
      disabled={disabled}
      style={style}
    />
  </Linkerize>
);
