import { ConfigType } from "./type";

export const prodConf: ConfigType = {
  ENV: "production",

  PAGE_PREFIX: "/",
  API_HOST: "https://api.portal.yondemy.com/",
  SUBSCRIPTIONS_API_HOST: "https://subscriptions.portal.yondemy.com",

  CSRFP_TOKEN_HEADER: "X-CSRFP-Token",
  RAP_TOKEN_HEADER: "X-RAP-Token",

  RAP_TOKEN_LENGTH: 5,
  REQUEST_REPEAT: 4,

  ACCESS_TOKEN_HEADER: null,

  CERTIFICATE_TOKEN_HEADER: "X-Certificate-Token",

  USER_APP: "https://app.yondemy.com/",
  PARENT_APP: "https://portal.yondemy.com/",
  PAY_JP_PUBLIC_KEY: "pk_live_a9131d8614c1554fb8f47ab8",

  GA_MEASUREMENT_ID: "G-59XHQRMCQD",
  RECAPTCHA_KEY: "6LcsHf4ZAAAAAJ3TZGkmn8PRqGr-Rips_HQrdYVF",
  GOOGLE_TAG_MANAGER_ID: "GTM-TKB2T45",
  SENTRY_DSN:
    "https://2ce807d4c2cf45a8b59c835f0cd9937f@o967000.ingest.sentry.io/4504483415916544",

  AUTH0_DOMAIN: "auth.yondemy.com",
  AUTH0_CLIENT_ID: "w68iG4WIg0IieVWLA1WOSDdhLxWKf9KD",
  AUTH0_AUDIENCE: "https://api.portal.yondemy.com",
  NOTIFICATION_LINE_LINK: "https://lin.ee/20X0avx",
};
