import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import styled, { css } from "styled-components";
import { Select } from "~/component/atoms/select";
import { Text } from "~/component/atoms/text/Text";
import { IconButton } from "~/component/molecules/button/IconButton";
import { PageTitle } from "~/component/molecules/text/PageTitle";
import { BookBuyLinkList } from "../BookListViewer";
import { BookListViewerItemProps } from "../BookListViewer/BookListViewerItem";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { BottomNavbar } from "~/component/molecules/BottomNavbar";
import { BookshelfTab } from "../BookshelfTab";
import {
  RemoveWantReadBookModal,
  RemoveWantReadBookModalProps,
} from "./RemoveWantReadBookModal";
import { Icon } from "~/component/atoms/icon";
import { Stack } from "~/component/atoms/layout/Stack";
import { BookshelfType } from "../container";

export type WantReadBookViewerTemplateProps = {
  currentCount: number;
  items: Exclude<BookListViewerItemProps, "availableBookType">[];
  itemTotal: number;
  itemsPerPage: number;
  onPageChange: (currentCount: number) => void;
  handleBack: () => void;
  currentUserId: number;
  changeCurrentTab: (value: BookshelfType) => void;
  removeWantReadBookModalProps: RemoveWantReadBookModalProps;
};

export const WantReadBookViewerTemplate: FC<
  WantReadBookViewerTemplateProps
> = ({
  currentCount,
  items,
  itemTotal,
  itemsPerPage,
  onPageChange,
  handleBack,
  currentUserId,
  changeCurrentTab,
  removeWantReadBookModalProps,
}) => {
  const history = useHistory();
  const { currentUser } = React.useContext(CurrentUserContext);
  return (
    <>
      <NavWrapper>
        <IconButton
          onClick={handleBack}
          color={"pri"}
          name={"arrowLeft"}
          fontSize={"XXXL"}
          disabled={false}
        />
        <PageTitle>ほんだな</PageTitle>
        <div></div>
      </NavWrapper>
      <TopWrapper>
        {currentUser?.children.get && currentUser?.children.get.length > 0 && (
          <Select
            defaultValue={currentUserId}
            onChange={(event) =>
              history.push(`/booklist/${event.target.value}`)
            }
          >
            {currentUser.children.get.map((child) => {
              return (
                <option value={child.id} key={child.id}>
                  {child.nickname}
                </option>
              );
            })}
          </Select>
        )}
        <BookshelfTab value="wantReadBook" onChange={changeCurrentTab} />
        <BookBuyLinkList
          currentCount={currentCount}
          items={items}
          itemTotal={itemTotal}
          itemsPerPage={itemsPerPage}
          onPageChange={onPageChange}
          renderEmptyComponent={EmptyComponent}
        />
      </TopWrapper>
      <BottomNavbar
        currentTab={1}
        tabItems={[
          { page: 0, title: "ホーム", iconName: "home" },
          { page: 1, title: "本棚", iconName: "book" },
        ]}
        onChangeTab={(tabNumber: number) => {
          switch (tabNumber) {
            case 0:
              history.push("/");
              return;
            case 1:
              return;
            default:
              return;
          }
        }}
      />
      {removeWantReadBookModalProps.isOpen && (
        <RemoveWantReadBookModal {...removeWantReadBookModalProps} />
      )}
    </>
  );
};

const EmptyWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 8px;
`;

const EmptyComponent = (): JSX.Element => {
  return (
    <EmptyWrapper>
      <Icon
        name="exclamationCircle"
        color="tex"
        style={{ width: "20px", height: "20px" }}
      />
      <Stack rowGap="8px" alignItems="flex-start">
        <Text
          lineHeight="MD"
          fontSize="SM"
          fontColor="semantic.text.body"
          bold={false}
        >
          お子さんが本の友から読みま書架に本を追加するまでお待ちください。
        </Text>
        <Text
          lineHeight="MD"
          fontSize="XS"
          fontColor="semantic.text.label"
          bold={false}
        >
          読みま書架はレベル25から使えます。
        </Text>
      </Stack>
    </EmptyWrapper>
  );
};

const NavWrapper = styled.div`
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  display: flex;
  width: 100%;
  position: fixed;
  top: -1px;
  align-items: center;
  justify-content: space-between;
  padding: 16px 5%;
  z-index: ${({ theme }) => theme.zIndex.navbar};
  border-bottom: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
`;

const TopWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  padding: 80px 16px;

  ${({ theme }) => css`
    @media screen and ${theme.breakPoints.minWidthSmallSmartPhone} {
      padding: 80px 10%;
    }
    @media ${theme.breakPoints.minWidthSmartPhone} {
      padding: 80px 20%;
    }
    @media ${theme.breakPoints.minWidthSmallPC} {
      padding: 80px 30%;
    }
    background-color: ${theme.colors.semantic.layout.backgroundMain};
  `}
`;
