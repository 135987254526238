import { config } from "../../config";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { getClarityURL } from "./clarity";
import { v4 as uuidv4 } from "uuid";

export const sentryErrorTrackingID = uuidv4();

if (config.SENTRY_DSN) {
  Sentry.init({
    debug: false,
    environment: config.ENV,
    // release: env("SENTRY_RELEASE_VERSION"),
    dsn: config.SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: config.ENV === "production" ? 0.1 : 1.0,
  });

  Sentry.setTag("errorTrackingID", sentryErrorTrackingID);
  Sentry.setExtra("clarityURL", getClarityURL(sentryErrorTrackingID));
}
