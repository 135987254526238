import React from "react";
import styled, { css } from "styled-components";
import { Text } from "~/component/atoms/text/Text";
import { Icon, IconType } from "~/component/atoms/icon";

export type BottomNavbarProps = {
  currentTab: number;
  tabItems: {
    page: number;
    title: string;
    iconName: IconType;
  }[];
  onChangeTab: (page: number) => void;
};

export const BottomNavbar: React.FC<BottomNavbarProps> = ({
  tabItems,
  currentTab,
  onChangeTab,
}) => {
  return (
    <_Wrapper>
      {tabItems.map((tab) => {
        const selected = currentTab === tab.page;
        return (
          <Tab
            key={tab.page}
            selected={selected}
            onClick={() => {
              // 現在のタブをクリックした場合は抜ける
              if (selected) {
                return;
              }
              onChangeTab(tab.page);
            }}
            tabNumber={tabItems.length}
          >
            <Icon
              name={tab.iconName}
              color={selected ? "pri" : "lightGray"}
              style={{ width: "20px", height: "100%" }}
            />
            <TabText fontSize={"SM"} lineHeight={"EQ"}>
              {tab.title}
            </TabText>
          </Tab>
        );
      })}
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  position: fixed;
  bottom: 0;

  height: 72px;
  padding: 10px 20px;
  background: ${({ theme }) => theme.colors.base.white};
  width: 100vw;

  display: flex;
  justify-content: space-evenly;
`;

const Tab = styled.div<{ tabNumber: number; selected: boolean }>`
  ${({ theme, selected, tabNumber }) => {
    return css`
      width: ${100 / tabNumber}%;
      cursor: ${selected ? "inherit" : "pointer"};
      flex-grow: 1;
      height: 100%;
      font-weight: ${selected ? "bold" : "normal"};
      color: ${selected ? theme.colors.pri.p400 : theme.colors.tex.t300};

      padding: 12px 0;
      position: relative;

      display: flex;
      flex-direction: column;
      row-gap: 4px;

      justify-content: center;
      align-items: center;
    `;
  }}
`;

const TabText = styled(Text)`
  font-family: inherit;
  font-weight: inherit;
  color: inherit;
  text-align: center;
`;
