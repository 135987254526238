import React from "react";
import { CustomerSupportContactTemplate } from ".";
import { useHistory } from "react-router-dom";

export const CustomerSupportContact: React.FC = () => {
  const history = useHistory();
  return (
    <CustomerSupportContactTemplate
      onBack={() => {
        history.goBack();
      }}
      entryPage="login"
    />
  );
};
