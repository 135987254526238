import React from "react";
import { BaseModal } from "~/component/atoms/modal/base_modal";
import styled from "styled-components";
import { ModalTransitionButton as BaseModalTransitionButton } from "~/component/atoms/button/modal_transition_button";

type ConnectLineSkipModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSkip: () => void;
};

export const ConnectLineSkipModal: React.VFC<ConnectLineSkipModalProps> = ({
  isOpen,
  onClose,
  onSkip,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title="LINEの友だち追加">
      <Contents>
        <Title>友だち追加をスキップしてよろしいですか？</Title>
        <Para>
          友だち追加をした方は、されていない方に比べて、
          <br />
          お子さんの読書の習慣化度や成長率が約3倍になる傾向があります。
          <br />
          友だち追加をしない場合、
          <br />
          『ヨンデミー活用法』などの重要情報の受け取りや、
          <br />
          「ヨンデミーなんでも相談窓口」の利用ができません。
        </Para>
        <ButtonCon>
          <ModalTransitionSubButton color={"orange"} onClick={onClose}>
            戻る
          </ModalTransitionSubButton>
          <ModalTransitionButton color={"orange"} onClick={onSkip}>
            スキップ
          </ModalTransitionButton>
        </ButtonCon>
      </Contents>
    </BaseModal>
  );
};

const ButtonCon = styled.div`
  width: 100%;
  padding-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
`;

const Para = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.tex.t700};
`;

const Title = styled.h2`
  font-size: 18px;
  margin-bottom: 12px;
  line-height: 1.4;
  font-weight: bold;
`;

const Contents = styled.div``;

const ModalTransitionButton = styled(BaseModalTransitionButton)`
  font-size: 16px;
`;

const ModalTransitionSubButton = styled(BaseModalTransitionButton)`
  font-size: 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
  color: ${({ theme }) => theme.colors.pri.p500};
  border: 2px solid ${({ theme }) => theme.colors.pri.p500};
  &:hover,
  :active,
  :focus {
    background-color: ${({ theme }) => theme.colors.base.white};
    color: ${({ theme }) => theme.colors.pri.p500};
  }
`;
