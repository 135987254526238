import styled, { css } from "styled-components";

type TextButtonColors = "orange" | "red" | "black";
export type TextButtonProps = {
  color?: TextButtonColors;
  disabled?: boolean;
};

export const baseStyle = css<TextButtonProps>`
  border: none;
  outline: none;
  background: none;
  font-size: 14px;
  text-align: left;
  line-height: 160%;
  ${({ color = "black" }) => {
    switch (color) {
      case "orange":
        return Orange;
      case "red":
        return Red;
      case "black":
        return Black;
      default:
        throw new Error("色が間違っています");
    }
  }};
  ${({ disabled = false, color = "black" }) => {
    if (disabled) {
      switch (color) {
        case "orange":
          return DisabledOrange;
        case "red":
          return DisabledRed;
        case "black":
          return DisabledBlack;
        default:
          throw new Error("色が間違っています");
      }
    } else {
      return;
    }
  }}
`;

export const TextButton = styled.button<TextButtonProps>`
  ${baseStyle}
`;

const Orange = css`
  color: ${(props) => props.theme.colors.pri.p400};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.pri.p500};
    text-decoration: underline;
  }
`;

const Red = css`
  color: ${(props) => props.theme.colors.red.r300};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.red.r400};
    text-decoration: underline;
  }
`;

const Black = css`
  color: ${(props) => props.theme.colors.tex.t700};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.tex.t800};
    text-decoration: underline;
  }
`;

const DisabledOrange = css`
  color: ${(props) => props.theme.colors.pri.p200};
  &:hover,
  &:active,
  &:focus {
    cursor: inherit;
    color: ${(props) => props.theme.colors.pri.p200};
  }
`;

const DisabledRed = css`
  color: ${(props) => props.theme.colors.red.r200};
  &:hover,
  &:active,
  &:focus {
    cursor: inherit;
    color: ${(props) => props.theme.colors.red.r200};
  }
`;

const DisabledBlack = css`
  color: ${(props) => props.theme.colors.tex.t300};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.tex.t300};
  }
`;
