import * as React from "react";
import styled from "styled-components";
import { useSnd } from "~/utils/hooks/useSnd";

export type FavoriteGenreButtonProps = Pick<
  JSX.IntrinsicElements["button"],
  "disabled" | "onClick"
> & { selected?: boolean; label: string };

export const FavoriteGenreButton: React.FC<FavoriteGenreButtonProps> = ({
  selected,
  label,
  ...props
}) => {
  const { play } = useSnd();
  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    play("TAP");
    props.onClick(e);
  };
  return (
    <_ButtonCon>
      <_StyledButton
        {...props}
        type="button"
        selected={selected}
        onClick={handleClick}
      >
        <_ButtonText>{label}</_ButtonText>
      </_StyledButton>
    </_ButtonCon>
  );
};

const _ButtonText = styled.div`
  font-size: ${({ theme }) => theme.fontSize.MD};
`;

const _ButtonCon = styled.div`
  position: relative;
  width: 100%;
`;

const _StyledButton = styled.button<{ selected?: boolean }>`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 0;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  font-size: ${({ theme }) => theme.fontSize.XL};
  font-family: inherit;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: ${({ theme, selected }) =>
    selected ? theme.colors.base.white : theme.colors.pri.p400};
  background-color: ${({ theme, selected }) =>
    selected ? theme.colors.pri.p500 : theme.colors.base.white};
  border: 3px solid ${({ theme }) => theme.colors.pri.p600};
  border-bottom-width: 5px;

  white-space: nowrap;

  opacity: 1;

  &:active {
    color: ${({ theme }) => theme.colors.base.white};
    outline: none;
    box-shadow: ${({ disabled }) => !disabled && "none"};
    transform: ${({ disabled }) => !disabled && "translateY(5px)"};
  }
  &:disabled {
    opacity: 0.3;
  }

  &:focus {
    outline: none;
  }
`;
