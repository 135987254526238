import React from "react";
import styled from "styled-components";
import { LargeButton } from "~/component/atoms/button/Button";
import { Text } from "~/component/atoms/text/Text";

export type RecommendDetailFooterProps = {
  handleRemoveRecommend: () => void;
};

export const RecommendDetailFooter: React.FC<RecommendDetailFooterProps> = ({
  handleRemoveRecommend,
}) => {
  return (
    <_FooterWrapper>
      <LargeButton onClick={handleRemoveRecommend} color="gray" variant="blank">
        <Text
          fontSize="MD"
          lineHeight="LG"
          fontColor="semantic.primary.minus1"
          bold
        >
          リストから消す
        </Text>
      </LargeButton>
    </_FooterWrapper>
  );
};

const _FooterWrapper = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;

  border-top: 1px solid ${({ theme }) => theme.colors.semantic.layout.border};

  padding: 16px 22px;
  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
`;
