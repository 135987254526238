import React, { useContext, FC } from "react";
import { PwaTutorialTemplate } from ".";
import { A2HSContext } from "~/contexts/A2HSContext";
import { NotifyTextToast } from "~/component/organisms/toasts/NotifyTextToast";
import { useHistory } from "react-router";
import { useGetBrowserContent } from "./const";

export const PwaTutorial: FC = () => {
  const { showPwaTutorial } = useContext(A2HSContext);
  const { content, popupIsOpen, popupText, setIsOpen } = useGetBrowserContent();
  const history = useHistory();

  React.useEffect(() => {
    if (!showPwaTutorial) {
      history.push("/");
    }
  }, [showPwaTutorial, history]);

  React.useEffect(() => {
    if (content === null) {
      history.push("/");
    }
  }, [history, content]);

  return (
    <>
      <PwaTutorialTemplate items={content} />
      <NotifyTextToast
        text={popupText}
        isOpen={popupIsOpen}
        setIsOpen={setIsOpen}
      />
    </>
  );
};
