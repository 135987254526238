import * as React from "react";
import styled from "styled-components";
import { Row } from "~/component/atoms/layout/Row";
import { PrimaryButtonWithSound } from "../../../Button/PrimaryButtonWithSound";

type PrimaryButtonProps = Parameters<typeof PrimaryButtonWithSound>[0];

type Props = PrimaryButtonProps & {
  toNext?: boolean;
};

export const NavigationButton: React.FC<Props> = ({
  children,
  toNext = true,
  ...props
}: Props) => {
  return (
    <PrimaryButtonWithSound {...props} noMarginTop>
      <_ButtonText>
        <Row>
          {children}
          {toNext && <_RightArrow />}
        </Row>
      </_ButtonText>
    </PrimaryButtonWithSound>
  );
};

const _ButtonText = styled.div`
  text-align: center;
`;

const _RightArrow = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.75301 2.96579L9.57881 2.13999C9.92848 1.79033 10.4939 1.79033 10.8398 2.13999L18.0712 9.36761C18.4208 9.71727 18.4208 10.2827 18.0712 10.6286L10.8398 17.86C10.4902 18.2096 9.92476 18.2096 9.57881 17.86L8.75301 17.0342C8.39963 16.6808 8.40707 16.1042 8.76789 15.7583L13.2503 11.4879H2.55951C2.06477 11.4879 1.66675 11.0899 1.66675 10.5952V9.40481C1.66675 8.91007 2.06477 8.51205 2.55951 8.51205H13.2503L8.76789 4.24169C8.40335 3.89575 8.39591 3.31918 8.75301 2.96579Z"
      fill="white"
    />
  </svg>
);
