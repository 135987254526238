import * as React from "react";
import styled from "styled-components";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";
import {
  LargeButtonTemplateProps,
  LargeButtonTemplate,
  ButtonColors,
} from "./large_button_template";

type ModalTransitionButtonProps = {
  children: React.ReactNode;
  onClick: (event: React.MouseEvent) => void;
  color?: ButtonColors;
  disabled?: boolean;
  className?: string;
};

export const ModalTransitionButton: React.VFC<ModalTransitionButtonProps> = ({
  children,
  onClick,
  color = "orange",
  disabled = false,
  className,
}) => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick,
    label: children,
  });
  return (
    <ButtonContainer
      onClick={onClickWithTracking}
      color={color}
      disabled={disabled}
      className={className}
    >
      {children}
    </ButtonContainer>
  );
};

const ButtonContainer = styled.button<LargeButtonTemplateProps>`
  ${LargeButtonTemplate};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.base.white};
  }
`;
