import React, { isValidElement, ReactNode, useCallback } from "react";

type Options = {
  onClick?: (event: React.MouseEvent) => void;
  label?: ReactNode;
};
export const useButtonClickTracking = ({
  onClick,
  label,
}: Options): { onClickWithTracking: (event: React.MouseEvent) => void } => {
  const onClickWithTracking = useCallback(
    (event: React.MouseEvent) => {
      onClick?.(event);

      const _label = reactNodeToString(label);
      if (window.gtag) {
        window.gtag("event", "button_click", {
          event_category: "button",
          event_label: _label,
          pathname: window.location.pathname,
          clientX: event.clientX,
          clientY: event.clientY,
        });
      }
    },
    [onClick, label]
  );

  return { onClickWithTracking };
};

const reactNodeToString = (reactNode: React.ReactNode): string => {
  let string = "";
  if (typeof reactNode === "string") {
    string = reactNode;
  } else if (typeof reactNode === "number") {
    string = reactNode.toString();
  } else if (reactNode instanceof Array) {
    reactNode.forEach(function (child) {
      string += reactNodeToString(child);
    });
  } else if (isValidElement(reactNode)) {
    string += reactNodeToString(reactNode.props.children);
  }
  return string;
};
