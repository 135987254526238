import styled from "styled-components";

export const RegisterWrapper = styled.div`
  @media screen and (max-width: 768px) {
    padding: 30px 10%;
  }
  @media screen and (max-width: 375px) {
    padding: 30px 5%;
  }
  @media screen and (min-width: 768px) {
    padding: 30px 20%;
  }
`;

export const FlexCon = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 10px 0 20px 0;
`;

export const VisPassCon = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const InputCon = styled.div`
  width: 100%;
  margin: 20px 0;
`;

export const ButtonCon = styled.div`
  padding: 10px 0 25px 0;
  display: flex;
  justify-content: center;
`;

export const InputCon3 = styled.div`
  width: 30%;
`;

export const FLabel = styled.label`
  font-size: 17px;
  color: var(--color-gray05);
  font-weight: bold;
  width: 100%;
`;

export const FsubLabel = styled(FLabel)`
  font-size: 13px;
  line-height: 1.4;
  font-weight: normal;
  width: 100%;
  color: ${({ theme }) => theme.colors.tex.t300};
  margin-top: 4px;
`;

export const VisualizePassword = styled.p`
  font-size: 12px;
  display: inline;
  font-weight: bold;
  line-height: 170%;
  text-align: right;
  opacity: 0.9;
  &:hover,
  &:active {
    text-decoration: outline;
    cursor: pointer;
    opacity: 1;
  }
`;

type InputOfEnquete = {
  isError: boolean;
};

export const FInput = styled.input<InputOfEnquete>`
  width: 100%;
  font-size: 16px;
  padding: 16px 12px;
  margin: 10px 0;
  border: 1px solid ${(props) => (props.isError ? "#ce0914" : "#e2e2e2")};
  border-radius: 8px;
  transition: all 0.1s ease-in;
  &:focus {
    outline: none;
    border: 1px solid var(--color-prim03);
    transition: all 0.1s ease-in;
  }
  &::placeholder {
    color: #d8d8d8;
  }
`;

export const Title = styled.h1`
  font-size: 20px;
  color: var(--color-gray04);
  line-height: 170%;
  margin: 35px 0;
  font-weight: bold;
`;

export const Annot = styled.p`
  padding-top: 6px;
  font-size: 12px;
  font-weight: normal;
  line-height: 160%;
  color: var(--color-gray02);
`;

export const ErrorMessage = styled(Annot)`
  color: var(--color-red);
  margin-top: -4px;
`;

export const ForgetCon = styled.div`
  @media screen and (max-width: 768px) {
    width: 80%;
  }
  @media screen and (max-width: 500px) {
    width: 100%;
  }
  @media screen and (min-width: 768px) {
    width: 485px;
  }
  display: flex;
  flex-direction: row-reverse;
  padding: 15px 0;
  margin: 0 auto;
`;

export const ForgetButton = styled.button`
  border: none;
  background: none;
  padding: 0;
  color: var(--color-gray03);
  opacity: 0.9;
  text-align: right;
  font-size: 12px;
  &:hover,
  &:active,
  &:focus {
    opacity: 1;
    text-decoration: underline;
  }
`;
