import * as React from "react";
import { IconTitle } from "../atoms/heading/icon_title";
import { PlainText } from "../atoms/text";
import Header from "../organisms/common/header";
import { ContentsContainer, FirstHeadContainer } from "./box";
import { MiddleWrapper } from "./wrapper";

export const QuitEnqueteTemplate: React.VFC = () => {
  return (
    <>
      <Header/>
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <IconTitle
              iconPath={"/img/chat-icon.svg"}
              title={"休会手続きが完了しました"}
            />
          </FirstHeadContainer>
          <PlainText>
            ヨンデミー先生のアイコンをクリックして、チャット形式のアンケートにご回答ください。
          </PlainText>
        </ContentsContainer>
      </MiddleWrapper>
    </>
  );
};

