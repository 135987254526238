import React from "react";
import styled from "styled-components";
import { Text } from "~/component/atoms/text/Text";
import { Icon } from "~/component/atoms/icon";
import {
  PrimaryButton,
  PrimaryButtonProps,
} from "~/component/atoms/button/primary_button";

export type PwaTutorialCardItemProps = {
  title: string;
  description?: string;
  imageUrl?: string;
  buttonProps?: {
    text: string;
    onClick?: PrimaryButtonProps["onClick"];
  };
  disabled?: {
    next?: boolean;
    content?: boolean;
  };
  isHideNext?: boolean;
};

export const PwaTutorialCardItem: React.VFC<PwaTutorialCardItemProps> = ({
  title,
  description,
  imageUrl,
  buttonProps,
  isHideNext,
  disabled,
}) => {
  return (
    <Container>
      <Card>
        <CardContent>
          <_CheckIconWrapper>
            <Icon
              name="check"
              color="white"
              style={{ width: "16px", height: "16px" }}
            />
          </_CheckIconWrapper>
          <CardDescription>
            <CardTitle fontSize="MD" lineHeight="MD" bold>
              {title}
            </CardTitle>
            {description && (
              <Description fontSize="SM" lineHeight="MD">
                {description}
              </Description>
            )}
          </CardDescription>
        </CardContent>
        {imageUrl && (
          <ImageContent>
            <img src={imageUrl} style={{ maxWidth: "60%" }} />
          </ImageContent>
        )}
        {buttonProps && buttonProps.onClick && (
          <_PrimaryButton onClick={buttonProps.onClick} color="pri">
            <Text fontSize={"MD"} lineHeight={"MD"} bold fontColor="base.white">
              {buttonProps.text}
            </Text>
          </_PrimaryButton>
        )}
        {disabled?.content && <Overlay />}
      </Card>

      {!isHideNext && (
        <NextIconWrapper>
          {disabled?.next ? (
            <NextIcon src="/img/pwa/disable-next-icon.svg" />
          ) : (
            <NextIcon src="/img/pwa/next-icon.svg" />
          )}
        </NextIconWrapper>
      )}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Overlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: ${({ theme }) => theme.colors.base.white};
  opacity: 0.8;
  border-radius: 12px;
`;

const _CheckIconWrapper = styled.div`
  border-radius: 50%;
  padding: 4px;
  width: 24px;
  height: 24px;
  background: ${({ theme }) => theme.colors.green.g400};
  margin-right: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ImageContent = styled.div`
  display: flex;
  justify-content: center;
`;

const CardContent = styled.div`
  display: flex;
  margin-bottom: 15px;
`;

const CardTitle = styled(Text)`
  margin-bottom: 5px;
`;

const NextIcon = styled.img``;

const NextIconWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12px 0;
`;

const Description = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t300};
`;

const CardDescription = styled.div`
  display: flex;
  flex-direction: column;
`;

const _PrimaryButton = styled(PrimaryButton)`
  padding: 16px 0;
`;

const Card = styled.div`
  max-width: 560px;
  position: relative;
  background: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-radius: 12px;
`;
