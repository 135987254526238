import * as React from "react";
import { WhiteBox } from "../../templates/box";
import { InfoList } from "../../molecules/list/info_list";

type ParentInfoListProps = {
  email: string;
  nickname: string;
};

export const ParentInfoList: React.VFC<ParentInfoListProps> = ({
  email,
  nickname,
}) => {
  const userInfoItems = [
    { label: "お名前", value: nickname },
    { label: "メール", value: email },
  ];
  return (
    <WhiteBox>
      <InfoList list={userInfoItems} />
    </WhiteBox>
  );
};
