import * as React from "react";
import styled from "styled-components";
import { WhiteBox } from "../../templates/box";
import { Link } from "react-router-dom";
import { ItemHead } from "../../atoms/heading/item_head";
import { ArrowIcon } from "../../atoms/icon/arrow";
import { ListText } from "../../atoms/text";
import {
  UserProps,
  SUBSCRIPTION_CANCELED,
  SUBSCRIPTION_TRIAL,
  SUBSCRIPTION_ACTIVE,
  formatDate,
  MONTH_DAY_FORMAT,
  buildSubscriptionStatus,
} from "../../../utils/subscription_config";

type UserItemProps = {
  to: string;
  user: UserProps;
};

type UserItem = {
  label: string;
  text: React.ReactElement | string;
};

export const UserItem: React.VFC<UserItemProps> = ({ to, user }) => {
   
  const buildUserItems = (user) => {
    let items: UserItem[] = [
      {
        label: "ステータス",
        text: buildSubscriptionStatus(user.subscriptionStatus),
      },
    ];

    if (user.subscriptionStatus === SUBSCRIPTION_TRIAL) {
      // NOTE: 期限が切れる日の前の日を表示する
      // 例: 11日の15時に切れる場合、実際にはその時間まで使えるが表示の際には10日と出す
      const _TrialEndsAt = new Date(user.subscriptionTrialEndsAt);
      _TrialEndsAt.setDate(_TrialEndsAt.getDate() - 1);
      items = [
        ...items,
        {
          label: "無料期間残り",
          text: `${user.subscriptionRemainingTrialDays} 日`,
        },
        {
          label: "無料期間",
          text: `${formatDate(
            user.subscriptionTrialStartsAt,
            MONTH_DAY_FORMAT
            // NOTE: formatDateの仕様的にtoUTCStringの形で渡す必要がある
          )} ~ ${formatDate(_TrialEndsAt.toUTCString(), MONTH_DAY_FORMAT)}`,
        },
      ];
    } else if (user.subscriptionStatus === SUBSCRIPTION_ACTIVE) {
      items = [
        ...items,
        {
          label: "プラン",
          text: `${user.planCurrency} ${user.planAmount.toLocaleString()} ${
            user.planName
          }`,
        },
        {
          label: "次回決済日",
          text: formatDate(user.subscriptionCurrentPeriodEndsAt),
        },
      ];
    } else if (user.subscriptionStatus === SUBSCRIPTION_CANCELED) {
      const _CurrentPeriodEndsAt = new Date(
        user.subscriptionCurrentPeriodEndsAt
      );
      _CurrentPeriodEndsAt.setDate(_CurrentPeriodEndsAt.getDate() - 1);
      items = [
        ...items,
        {
          label: "ご利用可能期間",
          text: `~ ${formatDate(
            _CurrentPeriodEndsAt.toUTCString(),
            MONTH_DAY_FORMAT
          )}`,
        },
      ];
    }
    return items;
  };

  return (
    <A to={to}>
      <WhiteBoxFocusable>
        <div>
          <ItemHeadContainer>
            <ItemHead>{user.nickname}</ItemHead>
          </ItemHeadContainer>
          <table>
            <tbody>
              {buildUserItems(user).map((item) => (
                <tr key={item.label}>
                  <Td>
                    <ListText.Label>{item.label}</ListText.Label>
                  </Td>
                  <td>
                    <ListText.ValueSm>{item.text}</ListText.ValueSm>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        <div>
          <ArrowIcon />
        </div>
      </WhiteBoxFocusable>
    </A>
  );
};

const A = styled(Link)`
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  display: block;
`;

const Td = styled.td`
  padding-right: 10px;
`;

const WhiteBoxFocusable = styled(WhiteBox)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.2s;
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.base.lightGray};
  }
`;

const ItemHeadContainer = styled.div`
  margin-bottom: 16px;
`;
