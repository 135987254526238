import * as React from "react";
import styled from "styled-components";
import { RegisterWrapper, Title } from "../component/form_style";
import { PortalFooter } from "../component/organisms/common/footer";

const Subtitle = styled.p`
  margin: 20px 0;
  font-size: 15px;
  line-height: 160%;
  color: var(--color-gray02);
`;

const ImgCon = styled.div`
  width: 40px;
  margin: 50px auto;
`;

export const CheckMail = (): React.ReactElement => {
  return (
    <>
      <section
        style={{ minHeight: "100vh" }}
        id="check-mail"
        className="white-back"
      >
        <RegisterWrapper>
          <ImgCon>
            <img
              src="img/airplane.png"
              alt="紙飛行機の画像"
              style={{ width: "100%" }}
            />
          </ImgCon>
          <Title>メールを送信しました。</Title>
          <Subtitle>
            メールを確認してください。<br></br>
            プロモーションフォルダで受信する場合もあるので、お手数ですがそちらもご確認ください。
          </Subtitle>
        </RegisterWrapper>
      </section>
      <PortalFooter />
    </>
  );
};
