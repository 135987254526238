import { useEffect, useState } from "react";

export const CONTENT_WIDTH = 376;
export const CONTENT_HEIGHT = 812;

type UseScalePrams = {
  resizeObserverElement?: HTMLElement;
};

export const useScale = ({
  resizeObserverElement = window.document.body,
}: UseScalePrams = {}): number => {
  const [scale, setScale] = useState<number>(1.0);
  useEffect(() => {
    const calcScale = () => {
      const ih = window.innerHeight;
      const _scale = ih / CONTENT_HEIGHT;
      setScale(_scale);
    };
    // マウント直後に
    calcScale();

    const observer = new ResizeObserver(() => {
      calcScale();
    });

    if (resizeObserverElement) {
      observer.observe(resizeObserverElement);

      return () => {
        observer.unobserve(resizeObserverElement);
      };
    }
  }, []);

  return scale;
};
