import React from "react";
import { Icon } from "~/component/atoms/icon";
import { ProcureStatus } from "~/generated/graphql";
import styled, { css } from "styled-components";
import { Interpolation } from "~/component/interpolation";

type CustomSelectBoxProps = {
  procureStatus: ProcureStatus;
  updateProcureStatus: (procureStatus: ProcureStatus) => Promise<boolean>;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};

const procureStatusWordList: Record<ProcureStatus, string> = {
  UNTOUCHED: "未対応",
  RESERVED: "予約済",
  PURCHASED: "購入済",
};

// NOTE: selectタグの開閉が外のアクションでトリガーできないので自作
export const CustomSelectBox: React.FC<CustomSelectBoxProps> = ({
  procureStatus,
  updateProcureStatus,
  isOpen,
  setIsOpen,
}) => {
  return (
    <_Select
      defaultValue={procureStatus}
      valueForStyle={procureStatus}
      onClick={() => setIsOpen(!isOpen)}
    >
      {procureStatusWordList[procureStatus]}
      <_DropdownTarget
        valueForStyle={procureStatus}
        width="16"
        height="11"
        viewBox="0 0 16 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M6.98486 9.74365L0.344238 3.10303C-0.114746 2.64404 -0.114746 1.90186 0.344238 1.44775L1.44775 0.344238C1.90674 -0.114746 2.64893 -0.114746 3.10303 0.344238L7.81006 5.05127L12.5171 0.344238C12.9761 -0.114746 13.7183 -0.114746 14.1724 0.344238L15.2759 1.44775C15.7349 1.90674 15.7349 2.64893 15.2759 3.10303L8.63525 9.74365C8.18604 10.2026 7.44385 10.2026 6.98486 9.74365V9.74365Z" />
      </_DropdownTarget>
      {isOpen && (
        <_OptionWrapper>
          <table>
            <colgroup>
              <col style={{ width: "20%" }} />
              <col style={{ width: "80%" }} />
            </colgroup>
            <thead>
              <_Option
                onClick={() => {
                  updateProcureStatus(ProcureStatus.Untouched).then(() =>
                    setIsOpen(false)
                  );
                }}
              >
                <th>
                  {procureStatus === ProcureStatus.Untouched && (
                    <_InvisibleIcon name="check" color="white" />
                  )}
                </th>
                <th>{procureStatusWordList.UNTOUCHED}</th>
              </_Option>
            </thead>
            <tbody>
              <_Option
                onClick={() => {
                  updateProcureStatus(ProcureStatus.Purchased).then(() =>
                    setIsOpen(false)
                  );
                }}
              >
                <td>
                  {procureStatus === ProcureStatus.Purchased && (
                    <_Icon name="check" color="tex" />
                  )}
                </td>
                <td>{procureStatusWordList.PURCHASED}</td>
              </_Option>
              <_Option
                onClick={() => {
                  updateProcureStatus(ProcureStatus.Reserved).then(() =>
                    setIsOpen(false)
                  );
                }}
              >
                <td>
                  {procureStatus === ProcureStatus.Reserved && (
                    <_Icon name="check" />
                  )}
                </td>
                <td>{procureStatusWordList.RESERVED}</td>
              </_Option>
            </tbody>
          </table>
        </_OptionWrapper>
      )}
    </_Select>
  );
};

const _Select = styled.div<{ valueForStyle: ProcureStatus; error?: boolean }>`
  ${({ theme, valueForStyle }) => {
    switch (valueForStyle) {
      case "UNTOUCHED":
        return css`
          ${Interpolation.Form}
          background-color: ${({ theme }) => theme.colors.red.r100};
          border-color: ${({ theme }) => theme.colors.red.r400};
          color: ${({ theme }) => theme.colors.red.r400};
          font-weight: 700;
        `;
      default:
        return css`
          ${Interpolation.Form}
          background-color: ${theme.colors.base.white};
          color: ${({ theme }) => theme.colors.tex.t700};
        `;
    }
  }}
  position: relative;
  width: 120px;
  margin-left: auto;
  padding: 16px;
  font-size: ${({ theme }) => theme.fontSize.MD};
  line-height: ${({ theme }) => theme.lineHeight.EQ};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  font-family: inherit;
  &:active {
    outline: none;
  }
  &:focus {
    outline: none;
    + svg {
      path {
        fill: ${({ theme }) => theme.colors.pri.p400};
        transition: all 100ms linear;
      }
    }
  }
  ${({ error }) =>
    error &&
    css`
      + svg {
        path {
          fill: ${({ theme }) => theme.colors.red.r300};
        }
      }
    `}
`;

const _OptionWrapper = styled.div`
  position: absolute;
  width: 200px;
  top: 8px;
  right: 8px;
  padding: 4px;
  margin: 0;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.5);
`;

const _Option = styled.tr`
  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: ${({ theme }) => theme.fontSize.LG};
  overflow: hidden;
  border-radius: 8px;
  font-weight: 400;
  th {
    padding: 10px;
    font-weight: 400;
  }
  td {
    padding: 10px;
    border-top: 1px solid ${({ theme }) => theme.colors.base.gray};
  }
  :hover {
    background-color: ${({ theme }) => theme.colors.sec.s500};
    color: ${({ theme }) => theme.colors.base.white};
  }
`;

const _DropdownTarget = styled.svg<{ valueForStyle: ProcureStatus }>`
  position: absolute;
  right: 16px;
  top: 42%;
  path {
    fill: ${({ theme, valueForStyle }) =>
      valueForStyle === ProcureStatus.Untouched
        ? theme.colors.red.r400
        : theme.colors.base.gray};
  }
`;

const _Icon = styled(Icon)`
  color: inherit;
`;

const _InvisibleIcon = styled(Icon)`
  opacity: 0;
`;
