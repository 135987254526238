import styled from "styled-components";
import * as React from "react";

type InputType = "text" | "password" | "number" | "time";

type Props = {
  type?: InputType;
  hasError?: boolean;
  endAdornment?: React.ReactNode;
} & Omit<JSX.IntrinsicElements["input"], "type">;

// eslint-disable-next-line react/display-name
export const Input = React.forwardRef<HTMLInputElement, Props>(
  (
    {
      hasError = false,
      endAdornment,
      type = "text",
      autoComplete = "off",
      ...inputProps
    },
    ref
  ) => {
    return (
      <_Container>
        <_Input
          {...inputProps}
          autoComplete={autoComplete}
          ref={ref}
          type={type}
          hasError={hasError}
        />
        {endAdornment && (
          <_EndAdornmentWrapper>{endAdornment}</_EndAdornmentWrapper>
        )}
      </_Container>
    );
  }
);

type _InputProps = {
  hasError: boolean;
};

const _Input = styled.input<_InputProps>`
  box-sizing: border-box;
  width: 100%;
  height: 54px;

  padding: 0 ${({ theme }) => theme.size.s2};

  color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.semantic.text.placeholder
      : theme.colors.semantic.text.body};
  background-color: ${({ theme, disabled }) =>
    disabled
      ? theme.colors.semantic.layout.border
      : theme.colors.semantic.layout.backgroundBox};
  font-size: ${({ theme }) => theme.fontSize.MD};

  outline: none;

  border-radius: ${({ theme }) => theme.size.s2};
  border: 2px solid
    ${({ theme: { colors }, hasError }) =>
      hasError
        ? colors.semantic.alert.main
        : colors.semantic.layout.borderPlus1};

  transition: border-color 0.2s;

  cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};

  &:hover {
    border-color: ${({ hasError, theme }) =>
      hasError
        ? theme.colors.semantic.alert.main
        : theme.colors.semantic.primary.minus1};
  }

  &:disabled {
    pointer-events: none;
  }

  &:focus-within {
    border-color: ${({ hasError, theme }) =>
      hasError
        ? theme.colors.semantic.alert.main
        : theme.colors.semantic.primary.minus1};
    cursor: text;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.semantic.text.placeholder};
  }
`;

const _EndAdornmentWrapper = styled.div`
  height: 48px;
  display: flex;
  flex-direction: row;
  align-items: center;
  position: absolute;
  top: 0;
  right: ${({ theme }) => theme.size.s2};
`;

const _Container = styled.div`
  width: 100%;
  box-sizing: border-box;

  position: relative;
`;
