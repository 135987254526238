import { makeVar, useReactiveVar } from "@apollo/client";
import { useCallback, useEffect } from "react";

type PreferenceValue<T> = T | undefined;

type Preference = {
  // sndの再生音量(0~10)
  sndVolume?: PreferenceValue<number>;
  // sndのmute
  sndMuted?: PreferenceValue<boolean>;
  // 新着のおすすめ本の配列
  newRecommendIdList?: PreferenceValue<string>;
  // 最後におすすめ本一覧画面を開いた時の最新のレコメンド
  booklistLatestRecommendId?: PreferenceValue<number>;
};

export type PreferenceKey = keyof Preference;

const PREFERENCE_LOCAL_STORAGE_KEY = "yondemy_preference" as const;

const loadPreference = (storageKey: string): Preference => {
  let savedPreference: string | null = null;
  try {
    savedPreference = localStorage.getItem(storageKey);
  } catch (e) {
    console.error(e);
  }
  if (savedPreference) {
    return JSON.parse(savedPreference) as Preference;
  }

  const initPrefs: Preference = {};

  localStorage.setItem(storageKey, JSON.stringify(initPrefs));

  return initPrefs;
};

const preferenceVar = makeVar<Preference | undefined>(undefined);

type SavePreferenceOptions = {
  updateCurrentValue?: boolean; // falseを渡した場合、次回のlocalStorageのロードまで値が反映されない
};

const defaultSavePreferenceOptions: SavePreferenceOptions = {
  updateCurrentValue: true,
};

export const usePreference = (): {
  preference?: Preference;
  savePreference: (
    values: Partial<Preference>,
    options?: SavePreferenceOptions
  ) => void;
} => {
  useEffect(() => {
    preferenceVar(loadPreference(PREFERENCE_LOCAL_STORAGE_KEY));
  }, []);

  const preference = useReactiveVar(preferenceVar);

  const savePreference = useCallback(
    (values: Partial<Preference>, options: SavePreferenceOptions = {}) => {
      const _options: SavePreferenceOptions = {
        ...defaultSavePreferenceOptions,
        ...options,
      };

      const _currentPrefs = preferenceVar();

      if (!_currentPrefs) return;
      const newPref: Preference = { ..._currentPrefs, ...values };

      _options.updateCurrentValue && preferenceVar(newPref);

      localStorage.setItem(
        PREFERENCE_LOCAL_STORAGE_KEY,
        JSON.stringify(newPref)
      );
    },
    []
  );

  return { preference, savePreference };
};
