import * as React from "react";
import { useNotificationLineInfoQuery } from "../../../generated/graphql";
import { Loading } from "../../atoms/progress_indicator/loading";
import { ContentsContainer } from "../../templates/box";
import { IconTitle } from "../../atoms/heading/icon_title";
import { PlainText } from "../../atoms/text";
import { ButtonContainer } from "../../templates/button_container";
import { OutsideTransitionButton } from "../../atoms/button/outside_transition_button";
import styled from "styled-components";
import { config } from "../../../../config";

export const NotificationLine: React.VFC = () => {
  const { data, loading, error } = useNotificationLineInfoQuery();
  if (loading || !data) {
    return <Loading />;
  }
  if (error) {
    return <>エラー</>;
  }
  if (!data.me.lineRegistered) {
    //lineRegistered=falseの人に表示する
    return (
      <>
        <ContentsContainer>
          <TitleContainer>
            <IconTitle iconPath="/img/chat-icon.svg" title="ヨンデミー先生" />
            <PlainText>
              ヨンデミー先生からお子さんへのメッセージを、保護者さまのLINEを通してお送りすることがございます。
              <br />
              宣伝等の配信は一切行いませんのでご安心ください。
            </PlainText>
          </TitleContainer>
          <ButtonContainer>
            <OutsideTransitionButton
              href={config.NOTIFICATION_LINE_LINK}
              color={"green"}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              友だち追加して通知を受け取る
            </OutsideTransitionButton>
          </ButtonContainer>
        </ContentsContainer>
      </>
    );
  } else {
    return <></>;
  }
};

const TitleContainer = styled.div`
  margin-bottom: 12px;
`;
