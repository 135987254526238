import React from "react";
import styled from "styled-components";
import { Message } from "~/pages/WorldViewOnboarding/ui/Message";
import { PrimaryButton } from "~/component/atoms/button/primary_button";

export type RecoverModalProps = {
  onRestore: (e: React.MouseEvent) => void;
  onClose: () => void;
};

export const RecoverModal: React.FC<RecoverModalProps> = (
  props: RecoverModalProps
) => {
  return (
    <_Wrapper>
      <_Container>
        <Message
          message={"会員登録が中断されています。\n続きから再開しますか？"}
          showTriangle={false}
        />
        <PrimaryButton onClick={props.onRestore} noMarginTop={true}>
          再開する
        </PrimaryButton>
        <PrimaryButton onClick={props.onClose} noMarginTop={true}>
          最初からはじめる
        </PrimaryButton>
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: ${({ theme }) => theme.colors.base.white};
`;

const _Container = styled.div`
  height: 100%;
  padding-bottom: 60px;
  left: 0;
  right: 0;
  margin: auto;
  width: 340px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  row-gap: 20px;
`;
