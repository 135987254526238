type screenSizeMax = "smallSmartPhoneMax" | "smartPhoneMax" | "smallPCMax";

export const defaultScreenSize: Readonly<Record<screenSizeMax, string>> = {
  smallSmartPhoneMax: "380px",
  smartPhoneMax: "800px",
  smallPCMax: "1200px",
};

type screenSize = "smallSmartPhone" | "smartPhone" | "smallPC";

type BreakPoint =
  | `minWidth${Capitalize<screenSize>}`
  | `maxWidth${Capitalize<screenSize>}`;

export const defaultBreakPoints: Record<BreakPoint, string> = {
  maxWidthSmallSmartPhone: `(max-width: ${defaultScreenSize.smallSmartPhoneMax})`,
  minWidthSmallSmartPhone: `(min-width: ${defaultScreenSize.smallSmartPhoneMax})`,
  maxWidthSmartPhone: `(max-width: ${defaultScreenSize.smartPhoneMax})`,
  minWidthSmartPhone: `(min-width: ${defaultScreenSize.smartPhoneMax})`,
  maxWidthSmallPC: `(max-width: ${defaultScreenSize.smallPCMax})`,
  minWidthSmallPC: `(min-width: ${defaultScreenSize.smallPCMax})`,
};
