import * as React from "react";
import styled, { useTheme } from "styled-components";
import { Row } from "../../layout/Row";
import { ButtonColor, ButtonColorValue, buttonColors } from "./colors";
import { useSnd } from "~/utils/hooks/useSnd";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";

type Size = "large" | "small" | "tiny";
type Variant = "default" | "blank";

type ButtonProps = React.PropsWithoutRef<JSX.IntrinsicElements["button"]> & {
  size?: Size;
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  color?: ButtonColor;
  variant?: Variant;
  trackingLabel?: string;
};

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      size = "large",
      leftIcon,
      rightIcon,
      children,
      color = "primary",
      variant = "default",
      onClick,
      trackingLabel,
      ...props
    },
    ref
  ) => {
    const theme = useTheme();
    const colors = React.useMemo(() => {
      return buttonColors(theme)[color];
    }, [color, theme]);

    const { play } = useSnd();
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
      play("BUTTON");
      onClick && onClick(event);
    };

    const { onClickWithTracking } = useButtonClickTracking({
      onClick: handleClick,
      label: trackingLabel || children,
    });

    return (
      <_Button
        ref={ref}
        {...props}
        size={size}
        colors={colors}
        variant={variant}
        onClick={onClickWithTracking}
      >
        <Row columnGap="16px" justifyContent="center">
          {leftIcon}
          <div>{children}</div>
          {rightIcon}
        </Row>
      </_Button>
    );
  }
);

Button.displayName = "Button";

const height: Record<Size, string> = {
  large: "48px",
  small: "44px",
  tiny: "36px ",
};

const borderRadius: Record<Size, string> = {
  large: "16px",
  small: "12px",
  tiny: "8px",
};

const _Button = styled.button<{
  size: Size;
  colors: ButtonColorValue;
  variant: Variant;
}>`
  width: 100%;
  min-width: 128px;
  max-width: 400px;

  box-sizing: border-box;
  height: ${({ size }) => height[size]};

  padding: 0 ${({ theme }) => theme.size.s2}px;

  outline: none;
  border: none;

  background: ${({ colors, variant }) =>
    variant === "blank" ? colors.blankBackground : colors.background};

  border: ${({ variant, colors }) =>
    variant === "blank" ? `3px solid ${colors.blankBorder}` : "none"};

  border-bottom: 5px solid
    ${({ colors, variant }) =>
      variant === "blank" ? colors.blankBorder : colors.border};

  color: ${({ colors, variant }) =>
    variant === "blank" ? colors.blankText || colors.blankBorder : colors.text};

  font-size: ${({ theme, size }) =>
    size === "large" ? theme.fontSize.LG : theme.fontSize.MD};
  font-weight: 700;

  border-radius: ${({ size }) => borderRadius[size]};

  cursor: pointer;

  &:disabled {
    pointer-events: none;
    opacity: 0.3;
  }

  &:focus,
  &:hover {
    color: ${({ colors, variant }) =>
      variant === "blank"
        ? colors.blankFocusText || colors.blankFocusBorder
        : colors.text};
    background: ${({ colors, variant }) =>
      variant === "blank" ? colors.blankBackground : colors.focusBackground};
    border-color: ${({ colors, variant }) =>
      variant === "blank" ? colors.blankFocusBorder : colors.focusBorder};
  }

  &:active {
    color: ${({ colors, variant }) =>
      variant === "blank"
        ? colors.blankActiveText || colors.blankActiveBorder
        : colors.text};
    background: ${({ colors, variant }) =>
      variant === "blank" ? colors.blankBackground : colors.activeBackground};
    border-bottom-width: 3px;
    height: ${({ size }) => `calc(${height[size]} - 2px)`};
    margin-top: 2px;
  }
`;

type LargeButtonProps = Omit<ButtonProps, "size">;

export const LargeButton = React.forwardRef<
  HTMLButtonElement,
  LargeButtonProps
>((props, ref) => {
  return <Button ref={ref} {...props} size="large" />;
});

LargeButton.displayName = "LargeButton";

type SmallButtonProps = Omit<ButtonProps, "size">;

export const SmallButton = React.forwardRef<
  HTMLButtonElement,
  SmallButtonProps
>((props, ref) => {
  return <Button ref={ref} {...props} size="small" />;
});

SmallButton.displayName = "SmallButton";

type TinyButtonProps = Omit<ButtonProps, "size">;

export const TinyButton = React.forwardRef<HTMLButtonElement, TinyButtonProps>(
  (props, ref) => {
    return <Button ref={ref} {...props} size="tiny" />;
  }
);

TinyButton.displayName = "TinyButton";
