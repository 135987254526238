import * as React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const BreadCrumb: React.VFC<{ children: React.ReactNode }> = ({
  children,
}) => {
  return (
    <>
      <nav>
        <LinkList>{children}</LinkList>
      </nav>
    </>
  );
};

const LinkList = styled.ol`
  list-style: none;
  display: flex;
  align-items: center;
  padding: 5px 0;
`;

export const Crumb = {
  List: styled.li`
    margin-right: 4px;
    display: block;
    &::before {
      content: ">";
      color: ${(props) => props.theme.colors.tex.t400};
      display: inline-block;
      margin-right: 4px;
    }
  `,
  FirstList: styled.li`
    margin-right: 4px;
    display: block;
    &::before {
      content: "";
    }
  `,
  Link: styled(Link)`
    font-size: 14px;
    line-height: 160%;
    text-decoration: underline;
    color: ${(props) => props.theme.colors.tex.t400};
    &:hover,
    &:focus,
    &:active {
      text-decoration: none;
      color: ${(props) => props.theme.colors.tex.t500};
    }
  `,
  Para: styled.p`
    font-size: 13px;
    display: inline-block;
    margin: 0 2px;
    line-height: 160%;
    color: ${(props) => props.theme.colors.tex.t400};
  `,
};
