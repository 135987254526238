import React, { useState, useMemo } from "react";
import { FavoriteBookGenreInput, Omakase } from "../types";
import {
  MultiSelectQuestion,
  MultiSelectQuestionProps,
} from "../ui/Dialog/MultiSelectQuestion";
import { FavoriteGenreButtonProps } from "../ui/Dialog/MultiSelectQuestion/FavoriteGenreButton";
import { FirstSurvey } from "../types";
import { NavContainer } from "~/component/molecules/NavContainer";
import { TransparentBackNavbar } from "~/component/molecules/TransparentBackNavbar";

export type AskFavortiteGenreFlow = "AskMostFavorite" | "AskFavorites";
type AskFavoriteGenreProps = {
  onNextPage: () => void;
  nickname: string;
  firstSurvey: FirstSurvey;
  updateFirstSurvey: (fistSurvey: FirstSurvey) => void;
  flow: AskFavortiteGenreFlow;
  setFlow: (flow: AskFavortiteGenreFlow) => void;
  onBack: () => void;
};

const genreTitles: Record<FavoriteBookGenreInput, string> = {
  DOUBUTSU: "🐷 どうぶつ",
  BOUKEN: "🏝 ぼうけん",
  MAHOU: "🪄 まほう",
  OBAKE: "👻 おばけ",
  TATAKAU: "💥 たたかう",
  WARAERU: "😂 わらえる",
  KANDOU: "🥺 かんどう",
};

export const AskFavoriteGenre: React.FC<AskFavoriteGenreProps> = ({
  onNextPage,
  nickname,
  firstSurvey,
  updateFirstSurvey,
  flow,
  setFlow,
  onBack,
}) => {
  const [mostFavorite, setMostFavorite] = React.useState<
    FavoriteBookGenreInput | Omakase | undefined
  >(firstSurvey.mostFavoriteBookGenre);

  // NOTE: 複数ジャンルを選ぶモーダルに関しては、「おまかせ」を選んだ場合空の配列にset
  const [favoriteGenres, setFavoriteGenres] = React.useState<
    FavoriteBookGenreInput[]
  >(firstSurvey.favoriteBookGenres || []);

  const mostFavoriteProps = useMemo((): MultiSelectQuestionProps => {
    const buttons: FavoriteGenreButtonProps[] = [];

    const goNextInFirstModal = (e: React.MouseEvent) => {
      e.preventDefault();
      if (mostFavorite !== "Omakase") {
        setFlow("AskFavorites");
      } else {
        setFavoriteGenres([]);
        updateFirstSurvey({
          mostFavoriteBookGenre: mostFavorite,
          favoriteBookGenres: [],
        });
        onNextPage();
      }
    };

    Object.keys(genreTitles).map((genre: FavoriteBookGenreInput) => {
      buttons.push({
        selected: genre === mostFavorite,
        label: genreTitles[genre],
        onClick: () => setMostFavorite(genre),
      });
    });

    buttons.push({
      label: "🎓おまかせ",
      selected: mostFavorite === "Omakase",
      onClick: () => setMostFavorite("Omakase"),
    });

    return {
      messageProps: {
        speaker: "ヨンデミー先生",
        message: `${nickname}さんのすきなおはなしを、\n1つおしえて！\nえらべないときは、\nおまかせがあるよ👍`,
      },
      imgSrc: "/img/sensei/standing.svg",
      onNext: goNextInFirstModal,
      buttons: buttons,
      disabled: !mostFavorite,
    };
  }, [nickname, mostFavorite, onNextPage, updateFirstSurvey, setFlow]);

  const [isOmakaseInFavoriteGenres, setIsOmakaseInFavoriteGenres] =
    useState<boolean>(false);

  const favoriteGenresProps = useMemo((): MultiSelectQuestionProps => {
    const buttons: FavoriteGenreButtonProps[] = [];

    const goNextInSecondModal = (e: React.MouseEvent) => {
      e.preventDefault();

      updateFirstSurvey({
        mostFavoriteBookGenre: mostFavorite,
        // favoriteBookGenresからmostFavoriteを除いたものをセット
        favoriteBookGenres: favoriteGenres.filter(
          (genre) => genre !== mostFavorite
        ),
      });
      onNextPage();
    };

    Object.keys(genreTitles).map((genre: FavoriteBookGenreInput) => {
      buttons.push({
        selected: genre === mostFavorite || favoriteGenres.includes(genre),
        disabled: genre === mostFavorite || isOmakaseInFavoriteGenres,
        onClick: () => {
          if (favoriteGenres.includes(genre)) {
            setFavoriteGenres(
              favoriteGenres.filter((item) => {
                return item !== genre;
              })
            );
          } else {
            setFavoriteGenres([...favoriteGenres, genre]);
          }
        },
        label: genreTitles[genre],
      });
    });

    buttons.push({
      label: "🎓おまかせ",
      selected: isOmakaseInFavoriteGenres,
      onClick: () => {
        !isOmakaseInFavoriteGenres && setFavoriteGenres([]);
        setIsOmakaseInFavoriteGenres(!isOmakaseInFavoriteGenres);
      },
    });

    return {
      messageProps: {
        speaker: "ヨンデミー先生",
        message: "ほかにすきなおはなしを、\nすきなだけ おしえてね！",
      },
      imgSrc: "/img/sensei/standing.svg",
      onNext: goNextInSecondModal,
      buttons: buttons,
      disabled: favoriteGenres.length === 0 && !isOmakaseInFavoriteGenres,
    };
  }, [
    favoriteGenres,
    mostFavorite,
    isOmakaseInFavoriteGenres,
    onNextPage,
    updateFirstSurvey,
  ]);

  const _handleBack = () => {
    switch (flow) {
      case "AskMostFavorite":
        onBack();
        break;
      case "AskFavorites":
        setFlow("AskMostFavorite");
        break;
    }
  };

  return (
    <>
      <NavContainer>
        <TransparentBackNavbar
          withBlur={true}
          leftBlock={{ arrowColor: "pri", onClick: _handleBack }}
        />
      </NavContainer>
      {flow === "AskMostFavorite" && (
        <MultiSelectQuestion {...mostFavoriteProps} />
      )}
      {flow === "AskFavorites" && (
        <MultiSelectQuestion {...favoriteGenresProps} />
      )}
    </>
  );
};
