import * as React from "react";
import styled from "styled-components";
import { FirstBookRecommendEnqueteResult as Result } from "~/generated/graphql";
import { Stack } from "~/component/atoms/layout/Stack";
import senseiImage from "./images/sensei.svg";
import { Row } from "~/component/atoms/layout/Row";
import { config } from "../../../config";

type Props = {
  result: Result;
  userId: number;
};

/* eslint-disable no-irregular-whitespace */

export const CommentFromSensei: React.FC<Props> = ({ result, userId }) => {
  const { nickname, yl } = result;

  return (
    <Stack rowGap="12px">
      <_SenseiCommentTitle>
        <_SenseiIcon />
        ヨンデミー先生のコメント
      </_SenseiCommentTitle>
      <Stack rowGap="8px">
        <_CommentText>
          {nickname}
          さんにとって、
          <b>
            YL{yl}
            前後の本
          </b>
          が最も楽しく読み切りやすい本です。 まずは{nickname}
          さんにヨンデミーに慣れていただくため、
          <span className="emphasis">
            おすすめの本は、手に取りやすく最後まで無理なく読み切ることのできる難しさ
          </span>
          に合わせています。
          <br />
          <br />
          　なお、今回のおすすめの本は、
          <span className="emphasis">難しさや長さがある程度ばらつくように</span>
          選出しております。お子さんの気分に合わせて、気になった本からお読みください。
          <br />
          　お子さんが感想を提出してくださるごとに、ヨンデミー先生が改めてお子さんの適正YLと適正文字数を推定いたします。
          <b>感想が溜まれば溜まるほど</b>
          、ヨンデミー先生はお子さんによりぴったりな一冊を選ぶことができるようになりますので、ご期待ください。
          <br />
        </_CommentText>
        <_RequestWrapper>
          <_RequestMessage>
            YL（ヨンデミーレベル）が合っていないときは、YL相談所で再診断を受けることができます！
          </_RequestMessage>
          <Row justifyContent="flex-start" columnGap="8px">
            <_RequestAnchor
              href={
                config.USER_APP +
                `set_user/${userId}?path=/yl_reassessment_enquete?entry_page=first_book_recommend_report`
              }
              rel="noopener noreferer"
              target="_blank"
            >
              <_CommentDotIcon />
              <_RequestButtonText>YL相談所にいく</_RequestButtonText>
            </_RequestAnchor>
          </Row>
        </_RequestWrapper>
      </Stack>
    </Stack>
  );
};

const _CommentText = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 160%;
  letter-spacing: 0.04em;
  color: ${({ theme }) => theme.colors.tex.t600};
  white-space: pre-wrap;

  span.emphasis {
    color: ${({ theme }) => theme.colors.pri.p500};
    font-weight: 700;
  }

  b {
    font-weight: 700;
  }
`;

const _SenseiCommentTitle = styled.h2`
  font-weight: 700;
  line-height: 100%;
  padding-bottom: 12px;
  border-bottom: 2px solid ${({ theme }) => theme.colors.base.lightGray};

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  column-gap: 10px;

  font-size: ${({ theme }) => theme.fontSize.MD};
  color: ${({ theme }) => theme.colors.sec.s400};
`;

const _SenseiIcon = styled.div`
  width: 32px;
  height: 32px;
  border-radius: 16px;
  background-color: #006ed7;
  background-image: url(${senseiImage});
  background-size: 24px;
  background-repeat: no-repeat;
  background-position: center 4px;
`;

const _RequestWrapper = styled.div`
  border-top: 1px solid ${({ theme }) => theme.colors.base.lightGray};
  padding-top: 8px;

  display: flex;
  flex-direction: column;
  row-gap: 8px;
  align-items: flex-start;
`;

const _RequestMessage = styled.p`
  color: ${({ theme }) => theme.colors.tex.t300};
  font-size: ${({ theme }) => theme.fontSize.XS};
  line-height: 160%;
`;

const _RequestButtonText = styled.p`
  color: ${({ theme }) => theme.colors.sec.s400};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  text-decoration: underline;
  text-decoration-color: ${({ theme }) => theme.colors.sec.s400};
  cursor: pointer;
`;

const _RequestAnchor = styled.a`
  border: none;
  background-color: inherit;
  display: flex;
  column-gap: 8px;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
`;

const _CommentDotIcon: React.FC = () => (
  <svg
    width="20"
    height="21"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10 1.29004C4.47656 1.29004 0 4.92676 0 9.41504C0 11.3525 0.835938 13.126 2.22656 14.5205C1.73828 16.4893 0.105469 18.2432 0.0859375 18.2627C0 18.3525 -0.0234375 18.4854 0.0273438 18.6025C0.078125 18.7197 0.1875 18.79 0.3125 18.79C2.90234 18.79 4.84375 17.5479 5.80469 16.7822C7.08203 17.2627 8.5 17.54 10 17.54C15.5234 17.54 20 13.9033 20 9.41504C20 4.92676 15.5234 1.29004 10 1.29004ZM5 10.665C4.30859 10.665 3.75 10.1064 3.75 9.41504C3.75 8.72363 4.30859 8.16504 5 8.16504C5.69141 8.16504 6.25 8.72363 6.25 9.41504C6.25 10.1064 5.69141 10.665 5 10.665ZM10 10.665C9.30859 10.665 8.75 10.1064 8.75 9.41504C8.75 8.72363 9.30859 8.16504 10 8.16504C10.6914 8.16504 11.25 8.72363 11.25 9.41504C11.25 10.1064 10.6914 10.665 10 10.665ZM15 10.665C14.3086 10.665 13.75 10.1064 13.75 9.41504C13.75 8.72363 14.3086 8.16504 15 8.16504C15.6914 8.16504 16.25 8.72363 16.25 9.41504C16.25 10.1064 15.6914 10.665 15 10.665Z"
      fill="#0068C9"
    />
  </svg>
);
