import { css } from "styled-components";

export type ButtonColors = "orange" | "red" | "green" | "gray";

export type LargeButtonTemplateProps = {
  color: ButtonColors;
  disabled: boolean;
};

export const LargeButtonTemplate = css<LargeButtonTemplateProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 16px;
  border: none;
  border-radius: 15px;
  color: white;
  font-family: inherit;
  font-weight: bold;
  font-size: 20px;
  line-height: 160%;
  transition: all 0.2s;
  &:hover {
    cursor: pointer;
  }
  ${(props) => {
    switch (props.color) {
      case "orange":
        return Orange;
      case "red":
        return Red;
      case "green":
        return Green;
      case "gray":
        return Gray;
      default:
        throw new Error("色が間違っています");
    }
  }};
  ${(props) => {
    if (props.disabled) {
      switch (props.color) {
        case "orange":
          return DisabledOrange;
        case "red":
          return DisabledRed;
        case "green":
          return DisabledGreen;
        case "gray":
          return DisabledGray;
        default:
          throw new Error("色が間違っています");
      }
    } else {
      return;
    }
  }}
`;

const Orange = css`
  background-color: ${(props) => props.theme.colors.pri.p400};
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.pri.p500};
  }
`;

const Red = css`
  background-color: ${(props) => props.theme.colors.red.r300};
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.red.r400};
  }
`;

const Green = css`
  background-color: ${(props) => props.theme.colors.green.g300};
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.green.g400};
  }
`;

const Gray = css`
  background-color: ${(props) => props.theme.colors.tex.t200};
  &:hover,
  &:active,
  &:focus {
    background-color: ${(props) => props.theme.colors.tex.t300};
  }
`;

const DisabledOrange = css`
  background-color: ${(props) => props.theme.colors.pri.p200};
  &:hover,
  &:active,
  &:focus {
    cursor: inherit;
    background-color: ${(props) => props.theme.colors.pri.p200};
  }
`;

const DisabledRed = css`
  background-color: ${(props) => props.theme.colors.red.r200};
  &:hover,
  &:active,
  &:focus {
    cursor: inherit;
    background-color: ${(props) => props.theme.colors.red.r200};
  }
`;

const DisabledGreen = css`
  background-color: ${(props) => props.theme.colors.green.g200};
  &:hover,
  &:active,
  &:focus {
    cursor: inherit;
    background-color: ${(props) => props.theme.colors.green.g200};
  }
`;

const DisabledGray = css`
  background-color: ${(props) => props.theme.colors.tex.t100};
  &:hover,
  &:active,
  &:focus {
    cursor: inherit;
    background-color: ${(props) => props.theme.colors.tex.t100};
  }
`;
