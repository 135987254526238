import * as React from "react";
import { PortalFooter } from "../../component/organisms/common/footer";
import Header from "../../component/organisms/common/header";
import { MiddleWrapper } from "../../component/templates/wrapper";
import { FirstHeadContainer, ContentsContainer, WhiteBox } from "../../component/templates/box";
import { PageTitle } from "../../component/atoms/heading/page_title";
import { PlainText } from "../../component/atoms/text";
import { TransitionButton } from "../../component/atoms/button/transition_button";
import { ButtonContainer } from "../../component/templates/button_container";

export const NotFound: React.VFC = ()=> {
  return (
    <>
      <Header/>
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <PageTitle>ページが見つかりません</PageTitle>
          </FirstHeadContainer>
          <WhiteBox>
            <PlainText>
              アクセス頂いたページはご利用できないか、変更・削除された可能性があります。ご不便をおかけして申し訳ございません。
            </PlainText>
          </WhiteBox>
          <ButtonContainer>
            <TransitionButton
              to="/"
            >
              トップページへ戻る
            </TransitionButton>
          </ButtonContainer>
        </ContentsContainer>
      </MiddleWrapper>
      <PortalFooter />
    </>
  );
};
