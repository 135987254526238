import * as React from "react";
import styled from "styled-components";
import {
  FaqLink,
  HomeIconAndroidLink,
  HomeIconAppleLink,
  QuitGuideLink,
  SubscriptionGuideLink,
} from "../../../../util/constant";
import { config } from "../../../config";
import {
  SubscriptionsQuery,
  CurrentUserFragment,
} from "../../generated/graphql";
import { OutsideTransitionButton } from "../atoms/button/outside_transition_button";
import { TransitionButton } from "../atoms/button/transition_button";
import { IconTitle } from "../atoms/heading/icon_title";
import { ListText, PlainText } from "../atoms/text";
import { ContentsTitleCard } from "../organisms/common/contents_title_card";
import { PortalFooter } from "../organisms/common/footer";
import Header from "../organisms/common/header";
import { NotificationLine } from "../organisms/top/notification_line";
import { UserItem } from "../organisms/top/user_item";
import { ContentsContainer, FirstHeadContainer, WhiteBox } from "./box";
import { ButtonContainer } from "./button_container";
import { MiddleWrapper } from "./wrapper";
import { BottomNavbar } from "../molecules/BottomNavbar";
import { useHistory } from "react-router";
import { BOOKLIST_USER_SESSIONSTORAGE_KEY } from "~/pages/BookList/container";

type TopTemplateProps = {
  data: SubscriptionsQuery;
  currentUser: CurrentUserFragment;
};

export const TopTemplate: React.FC<TopTemplateProps> = ({
  data,
  currentUser,
}) => {
  const history = useHistory();
  return (
    <>
      <Header />
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <AppLinkCon>
              <GrayLink
                href={config.USER_APP}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                お子さん用アプリはこちら
              </GrayLink>
            </AppLinkCon>
          </FirstHeadContainer>
        </ContentsContainer>
        <ContentsContainer id="children">
          <TitleContainer>
            <IconTitle iconPath="/img/smile-icon.svg" title="お子さん" />
            <PlainText>
              お子さんごとに登録情報の編集や決済情報の入力を行うことができます。
            </PlainText>
          </TitleContainer>
          <UserItemListCon>
            {data.parent.children.map((child) => (
              <UserItemCon key={child.id}>
                <UserItem to={"/child/" + child.id} user={child} />
              </UserItemCon>
            ))}
          </UserItemListCon>
          <ButtonContainer>
            <TransitionButton
              color="orange"
              to={"/register_additional_user"}
              disabled={currentUser && !currentUser.canAddChild}
            >
              <AddSomething />
              ごきょうだい様登録
            </TransitionButton>
            {currentUser && !currentUser.canAddChild && (
              <PlainText style={{ textAlign: "center", color: "red" }}>
                本ページでは
                {data.parent.children.length + 1}
                人目以降のご登録はできません。
                <br />
                ご登録されたい場合、LINEまたはメールにてお問い合わせください。
              </PlainText>
            )}
          </ButtonContainer>
        </ContentsContainer>
        <NotificationLine />
        <ContentsContainer>
          <TitleContainer>
            <IconTitle iconPath="/img/library-icon.svg" title="図書館" />
            <PlainText>
              本を借りる際に利用したい図書館をえらんでください。
              <br />
              えらんだ図書館で受け取れる本を優先しておすすめします。
            </PlainText>
          </TitleContainer>
          {currentUser && currentUser.myLibrary && (
            <WhiteBox>
              <ListText.Label>現在登録されている図書館</ListText.Label>
              <ListText.Value>
                {currentUser.myLibrary.libraryName}
              </ListText.Value>
            </WhiteBox>
          )}
          <ButtonContainer>
            <TransitionButton to={"/change_library"}>変更する</TransitionButton>
          </ButtonContainer>
        </ContentsContainer>
        <ContentsContainer>
          <TitleContainer>
            <IconTitle
              iconPath="/img/briefcase-icon.svg"
              title="よくある質問"
            />
          </TitleContainer>
          <ContentsTitleCard title={"料金の支払いについて"}>
            <PlainText>
              現在ヨンデミーの受講料はクレジットカード決済のみで対応しております。
              <br />
              クレジットカードのご登録がされていない状況ですと、無料体験期間が終了し次第、アプリへはログインすることができなくなります。
              <br />
              <br />
              <GrayLink
                href={SubscriptionGuideLink}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                👉詳しくはこちら
              </GrayLink>
            </PlainText>
          </ContentsTitleCard>
        </ContentsContainer>
        <ContentsContainer>
          <ContentsTitleCard title={"休会について"}>
            <PlainText>
              休会はこの保護者サイトから、お子さんごとに行うことができます。
              <br />
              <br />
              <GrayLink
                href={QuitGuideLink}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                👉詳しくはこちら
              </GrayLink>
            </PlainText>
          </ContentsTitleCard>
        </ContentsContainer>
        <ContentsContainer>
          <ContentsTitleCard
            title={"アプリのように、スマホのホーム画面からヨンデミーを開くには"}
          >
            <PlainText>
              スマートフォンのホーム画面から1タップで起動できるようになります。詳しくは下記のリンクをご覧ください。
              <br />
              <br />
              <GrayLink
                href={HomeIconAppleLink}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                🍎iPhone/iPad利用の方はこちら
              </GrayLink>
              <br />
              <GrayLink
                href={HomeIconAndroidLink}
                target={"_blank"}
                rel={"noreferrer noopener"}
              >
                🤖Android利用の方はこちら
              </GrayLink>
            </PlainText>
          </ContentsTitleCard>
        </ContentsContainer>
        <ContentsContainer>
          <ButtonContainer>
            <OutsideTransitionButton
              href={FaqLink}
              color={"orange"}
              target={"_blank"}
              rel={"noreferrer noopener"}
            >
              他の質問を見る
            </OutsideTransitionButton>
          </ButtonContainer>
        </ContentsContainer>
      </MiddleWrapper>
      <PortalFooter />
      {currentUser?.children.get.length > 0 && (
        <BottomNavbar
          currentTab={0}
          tabItems={[
            { page: 0, title: "ホーム", iconName: "home" },
            { page: 1, title: "本棚", iconName: "book" },
          ]}
          onChangeTab={(tabNumber: number) => {
            let userId = currentUser.children.get[0].id;
            const previousUserId = JSON.parse(
              sessionStorage.getItem(BOOKLIST_USER_SESSIONSTORAGE_KEY) || "{}"
            );
            if (
              typeof previousUserId === "number" &&
              currentUser.children.get.some(
                (child) => child.id === previousUserId
              )
            ) {
              userId = previousUserId;
            }
            switch (tabNumber) {
              case 0:
                history.push("/");
                return;
              case 1:
                history.push(`/booklist/${userId}`);
                return;
              default:
                return;
            }
          }}
        />
      )}
    </>
  );
};

const AddSomething = styled.span`
  transform: translate(-10px, -1px);
  &::before {
    content: "+";
    color: ${(props) => props.theme.colors.base.white};
    font-size: 25px;
    font-weight: bold;
  }
`;

const AppLinkCon = styled.div`
  padding: 12px 0;
  display: flex;
  flex-direction: row-reverse;
  @media screen and (min-width: 768px) {
    display: none;
  }
`;

const GrayLink = styled.a`
  font-size: 16px;
  color: ${(props) => props.theme.colors.tex.t300};
  text-decoration: underline;
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.tex.t700};
    text-decoration: none;
  }
`;

const TitleContainer = styled.div`
  margin-bottom: 12px;
`;

const UserItemCon = styled.div`
  margin: 12px 0;
  width: 100%;
  @media screen and (min-width: 768px) {
    width: 48%;
  }
`;

const UserItemListCon = styled.div`
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
`;
