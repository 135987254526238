import * as React from "react";
import styled from "styled-components";
import { ContentsContainer } from "~/component/templates/box";
import { MiddleWrapper } from "~/component/templates/wrapper";

type Props = {
  children: React.ReactNode;
};

export const SurveyWrapper: React.FC<Props> = ({ children }: Props) => (
  <_Wrapper>
    <_Content>{children}</_Content>
  </_Wrapper>
);

const _Wrapper = styled(MiddleWrapper)`
  box-sizing: border-box;
`;

const _Content = styled(ContentsContainer)`
  display: flex;
  flex-direction: column;
  row-gap: 32px;
`;
