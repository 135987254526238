import * as React from "react";

import {
  Auth0IDPasswordLoginConnectionRealm,
  auth0RedirectUri,
  auth0WebAuth,
} from "~/utils/auth0";
import { Auth0Error } from "auth0-js";

const stub = (): never => {
  throw new Error("You forgot to wrap your component in <Auth0Provider>.");
};

type Auth0ContextValue = {
  isAuthenticated: boolean;
  isLoading: boolean;
  getIdToken: () => Promise<IdToken>;
  getAccessToken: () => Promise<AccessToken>;
  changePassword: (email: string) => Promise<boolean>;
  logout: () => void;
  login: (
    email: string,
    password: string,
    options?: {
      redirectUri?: string;
      onLoginSuccess?: () => void;
      onLoginError?: (err: Auth0Error) => void;
    }
  ) => ReturnType<typeof auth0WebAuth.login>;
};

const initialContext: Auth0ContextValue = {
  isAuthenticated: false,
  isLoading: true,
  getIdToken: stub,
  getAccessToken: stub,
  changePassword: stub,
  logout: stub,
  login: stub,
};

export const Auth0Context =
  React.createContext<Auth0ContextValue>(initialContext);

type Auth0ProviderProps = { children: React.ReactNode };

type IdToken = {
  token: string;
  cacheExpireAt: Date;
  email: string;
};

type AccessToken = {
  token: string;
  cacheExpireAt: Date;
};

type CheckSessionResult = {
  idToken: string;
  idTokenPayload: {
    exp: number;
    iat: number;
    email: string;
  };
  accessToken: string;
  expiresIn: 7200;
};

const parseIdToken = (res: CheckSessionResult): IdToken => {
  const cacheLifetime = res.idTokenPayload.exp - res.idTokenPayload.iat; // トークン有効期間（秒）＝キャッシュの有効期間
  const cacheExpireAt = new Date();
  cacheExpireAt.setSeconds(new Date().getSeconds() + cacheLifetime);
  console.log("IdToken cache expired at", cacheExpireAt);

  return { token: res.idToken, cacheExpireAt, email: res.idTokenPayload.email };
};

const parseAccessToken = (res: CheckSessionResult): AccessToken => {
  const cacheExpireAt = new Date();
  cacheExpireAt.setSeconds(new Date().getSeconds() + res.expiresIn);
  console.log("AccessToken cache expired at", cacheExpireAt);

  return { token: res.accessToken, cacheExpireAt };
};

export const Auth0Provider: React.FC<Auth0ProviderProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = React.useState<boolean>(false);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);

  const idToken = React.useRef<IdToken>({
    cacheExpireAt: new Date(),
    token: "",
    email: "",
  });

  const accessToken = React.useRef<AccessToken>({
    cacheExpireAt: new Date(),
    token: "",
  });

  React.useEffect(() => {
    setIsLoading(true);
    auth0WebAuth.checkSession({}, (err, res: CheckSessionResult) => {
      console.log("checkSession", err, res);
      if (res) {
        setIsAuthenticated(true);
        idToken.current = parseIdToken(res);
        accessToken.current = parseAccessToken(res);
        setIsLoading(false);
      } else if (err) {
        setIsAuthenticated(false);
        setIsLoading(false);
      }
    });
  }, []);

  const getAccessToken = React.useCallback((): Promise<AccessToken> => {
    return new Promise((resolve, reject) => {
      // キャッシュの有効期限内はキャッシュされているトークンを返す
      const now = new Date();
      if (accessToken.current.cacheExpireAt > now) {
        resolve(accessToken.current);
        return;
      }

      console.log("token expired");
      auth0WebAuth.checkSession({}, (err, res) => {
        if (res) {
          const _accessToken = parseAccessToken(res);
          accessToken.current = _accessToken;
          resolve(_accessToken);
        } else if (err) {
          reject(err);
        }
      });
    });
  }, [accessToken]);

  const getIdToken = React.useCallback((): Promise<IdToken> => {
    return new Promise((resolve, reject) => {
      // キャッシュの有効期限内はキャッシュされているトークンを返す
      const now = new Date();
      if (idToken.current.cacheExpireAt > now) {
        resolve(idToken.current);
        return;
      }

      console.log("token expired");
      auth0WebAuth.checkSession({}, (err, res) => {
        if (res) {
          const _idToken = parseIdToken(res);
          idToken.current = _idToken;
          resolve(_idToken);
        } else if (err) {
          reject(err);
        }
      });
    });
  }, [idToken]);

  const changePassword = React.useCallback(
    (email: string): Promise<boolean> => {
      return new Promise((resolve) => {
        auth0WebAuth.changePassword(
          { email, connection: Auth0IDPasswordLoginConnectionRealm },
          (err, res) => {
            if (res) {
              console.log("change password", res);
              resolve(true);
            } else if (err) {
              resolve(false);
            }
          }
        );
      });
    },
    []
  );

  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        isLoading,
        getIdToken,
        getAccessToken,
        changePassword,
        logout: () => auth0WebAuth.logout({ returnTo: auth0RedirectUri }),
        login: (email, password, options = {}) =>
          auth0WebAuth.login(
            {
              email,
              password,
              realm: Auth0IDPasswordLoginConnectionRealm,
              redirectUri: options?.redirectUri,
            },
            (err, res) => {
              if (err) {
                options?.onLoginError && options.onLoginError(err);
              } else if (res) {
                options?.onLoginSuccess && options?.onLoginSuccess();
              }
            }
          ),
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
