import React from "react";
import styled from "styled-components";
import { NotifyToast } from "~/component/organisms/toasts/NotifyToast";
import { Text } from "~/component/atoms/text/Text";
import { Stack } from "~/component/atoms/layout/Stack";
import { TextButton } from "~/component/molecules/button/TextButton";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { PasswordInput } from "~/component/atoms/input/Input/PasswordInput";
import { Input } from "~/component/atoms/input/Input";
import { FormSlot } from "~/component/atoms/form/FormSlot";
import { useHistory } from "react-router";

export type LoginTemplateProps = {
  email: string;
  password: string;
  handleChangeEmail: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleChangePassword: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
  isOpenForgetPasswordToast: boolean;
  setIsOpenForgetPasswordToast: (b: boolean) => void;
};

export const LoginTemplate: React.FC<LoginTemplateProps> = ({
  email,
  password,
  handleChangeEmail,
  handleChangePassword,
  handleSubmit,
  isOpenForgetPasswordToast,
  setIsOpenForgetPasswordToast,
}) => {
  const history = useHistory();
  return (
    <_Wrapper>
      <Stack rowGap="24px" alignItems="center">
        <_LogoImg src="/img/login/yondemy_logo.svg" alt="ヨンデミー" />
        <img src="/img/login/parent_site_logo.svg" alt="保護者サイト" />
        <_WhiteBox onSubmit={handleSubmit}>
          <Stack rowGap="32px">
            <Stack rowGap="24px">
              <FormSlot label="メールアドレス" labelHtmlFor="email">
                <Input
                  id="email"
                  name="email"
                  autoComplete="email"
                  value={email}
                  onChange={handleChangeEmail}
                />
              </FormSlot>
              <FormSlot label="パスワード" labelHtmlFor="password">
                <PasswordInput
                  id="password"
                  name="password"
                  autoComplete="current-password"
                  value={password}
                  onChange={handleChangePassword}
                />
              </FormSlot>
            </Stack>
            <PrimaryButton noMarginTop>
              <Text
                fontSize={"LG"}
                lineHeight={"MD"}
                bold
                fontColor="semantic.layout.backgroundBox"
              >
                ログイン
              </Text>
            </PrimaryButton>
          </Stack>
        </_WhiteBox>
        <Stack rowGap="12px" alignItems="center">
          <TextButton
            fontSize="SM"
            lineHeight="EQ"
            fontColor="semantic.secondary.main"
            text="パスワードがわからない方"
            onClick={() => {
              history.push("/forget");
            }}
          />
          <TextButton
            fontSize="SM"
            lineHeight="EQ"
            fontColor="semantic.secondary.main"
            text="上記でログインができない方"
            onClick={() => {
              history.push("/cs_contact");
            }}
          />
        </Stack>
      </Stack>
      <NotifyToast
        color={"red"}
        stickPlace={"top"}
        icon={"exclamation"}
        isOpen={isOpenForgetPasswordToast}
        setIsOpen={setIsOpenForgetPasswordToast}
      >
        <Message fontSize={"SM"} lineHeight={"MD"}>
          メールアドレスまたはパスワードに間違いがあります
        </Message>
      </NotifyToast>
    </_Wrapper>
  );
};

const Message = styled(Text)`
  color: ${(props) => props.theme.colors.red.r500};
`;

const _WhiteBox = styled.form`
  width: 100%;
  padding: ${({ theme }) => theme.size.s6} ${({ theme }) => theme.size.s2};
  background-color: ${({ theme }) =>
    theme.colors.semantic.layout.backgroundBox};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  position: relative;
`;

const _Wrapper = styled.div`
  width: 100%;
  padding: ${({ theme }) => theme.size.s4} 7%;
  min-height: 100vh;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: ${({ theme }) => theme.size.s4} 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    width: 100%;
    padding: ${({ theme }) => theme.size.s4} 20%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 100%;
    padding: ${({ theme }) => theme.size.s4} 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;

const _LogoImg = styled.img`
  width: 131px;
  height: 26px;
`;
