import "styled-components";
import { defaultColors } from "./color";
import { defaultBreakPoints, defaultScreenSize } from "./screen_size";
import { defaultSize } from "./size";

export type ColorTheme =
  | "pri"
  | "sec"
  | "violet"
  | "green"
  | "red"
  | "sky"
  | "gray"
  | "lightGray"
  | "white"
  | "tex";

export type FontSize =
  | "SUPERXL"
  | "SUPERL"
  | "XXXXL"
  | "XXXL"
  | "XXL"
  | "XL"
  | "LG"
  | "MD"
  | "SM"
  | "XS"
  | "XXS";

export const defaultFontSize: Readonly<Record<FontSize, string>> = {
  SUPERXL: "3rem", //48px
  SUPERL: "2.5rem", //40px
  XXXXL: "2rem", //32px
  XXXL: "1.5rem", //24px
  XXL: "1.375rem", //22px
  XL: "1.25rem", //20px
  LG: "1.125rem", //18px
  MD: "1rem", //16px
  SM: "0.875rem", //14px
  XS: "0.75rem", //12px
  XXS: "0.625rem", //10px
};

export type FontLineHeight = "EQ" | "MD" | "LG";

export const defaultFontLineHight: Readonly<Record<FontLineHeight, number>> = {
  EQ: 1,
  MD: 1.6,
  LG: 1.6,
};

export const theme = {
  colors: defaultColors,
  zIndex: {
    base: 0,
    navbar: 100,
    blackBack: 800,
    element: 10,
    modal: 1000,
    popup: 500,
  },
  fontSize: defaultFontSize,
  screenSize: defaultScreenSize,
  breakPoints: defaultBreakPoints,
  borderRadius: {
    XS: "6px",
    S: "8px",
    SM: "12px",
    MD: "16px",
    LG: "24px",
  },
  lineHeight: defaultFontLineHight,
  size: defaultSize,
} as const;

type AppTheme = typeof theme;

declare module "styled-components" {
  interface DefaultTheme extends AppTheme {}  
}
