import * as React from "react";
import styled from "styled-components";
import Header from "../organisms/common/header";
import { MiddleWrapper } from "./wrapper";
import { ContentsContainer, FirstHeadContainer } from "./box";
import { PortalFooter } from "../organisms/common/footer";
import { Link } from "react-router-dom";
import { PlainText } from "../atoms/text";
import { IconTitle } from "../atoms/heading/icon_title";

export const EnqueteForRecommendTemplate: React.VFC<{ userId: number }> = ({
  userId,
}) => {
  return (
    <>
      <Header />
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <IconTitle
              iconPath={"/img/briefcase-icon.svg"}
              title={"受講前アンケート"}
            />
            <PlainText>
              ご回答はAI司書ヨンデミー先生に反映されます。よりお子さんに合った本をおすすめするために、アンケートにご協力ください。
            </PlainText>
            <SkipLinkBox>
              <GoNextLink to={`/user_registered/step1/${userId}`}>
                回答が終了したらこちら
              </GoNextLink>
            </SkipLinkBox>
          </FirstHeadContainer>
          <iframe
            src={
              "https://docs.google.com/forms/d/e/1FAIpQLSe_9QryCF67dbxteAT0ztVUaBHudN6mT2k1OF4mGKp6fc-Ocw/viewform?embedded=true&usp=pp_url&entry.614329155=" +
              String(userId)
            }
            width="100%"
            height="770"
            frameBorder="0"
            marginHeight={0}
            marginWidth={0}
          >
            読み込んでいます…
          </iframe>
        </ContentsContainer>
      </MiddleWrapper>
      <PortalFooter />
    </>
  );
};

const SkipLinkBox = styled.div`
  padding: 8px 0;
  display: flex;
  flex-direction: row-reverse;
`;

const SkipLink = styled(Link)`
  font-size: 14px;
  color: ${(props) => props.theme.colors.tex.t500};
  text-decoration: underline;
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.tex.t500};
    text-decoration: none;
  }
  &::after {
    content: ">";
    color: ${(props) => props.theme.colors.tex.t500};
  }
`;

const GoNextLink = styled(SkipLink)`
  font-size: 16px;
  font-weight: bold;
  color: ${(props) => props.theme.colors.pri.p600};
  &:hover,
  &:focus,
  &:active {
    color: ${(props) => props.theme.colors.pri.p600};
  }
  &::after {
    color: ${(props) => props.theme.colors.pri.p600};
  }
`;
