import { config } from "../../config";

export const setupGoogleTagManager = (): void => {
  if (config.GOOGLE_TAG_MANAGER_ID) {
    document.getElementById("gtm1")?.remove();

    const script = document.createElement("script");
    script.id = "gtm1";
    script.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${config.GOOGLE_TAG_MANAGER_ID}');`;
    document.head.appendChild(script);

    document.getElementById("gtm2")?.remove();

    const iframeScript = window.document.createElement("noscript");
    iframeScript.id = "gtm2";

    const bodyScript = window.document.createElement("iframe");
    bodyScript.src = `https://www.googletagmanager.com/ns.html?id=${config.GOOGLE_TAG_MANAGER_ID}`;
    bodyScript.height = "0";
    bodyScript.width = "0";
    iframeScript.appendChild(bodyScript);

    window.document.body.append(iframeScript);
  }
};
