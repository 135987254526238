import * as React from "react";
import { useHistory } from "react-router-dom";

import {
  RegisterWrapper,
  Title,
  InputCon,
  FLabel,
  Annot,
  FInput,
  ButtonCon,
} from "../component/form_style";
import { SubmitButton } from "../component/atoms/button/misc_button";
import { PortalFooter } from "../component/organisms/common/footer";
import { Auth0Context } from "~/contexts/Auth0Context";

export const Forget = (): React.ReactElement => {
  const history = useHistory();
  const { changePassword } = React.useContext(Auth0Context);

  const [email, setEmail] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(false);

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    setError(false);
    changePassword(email)
      .then(() => {
        history.push("/check_mail");
      })
      .catch(() => {
        setError(true);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  if (loading) {
    return <>Loading</>;
  }
  if (error) {
    return <>Error</>;
  }

  return (
    <>
      <section
        className="white-back"
        id="forget-pass"
        style={{ minHeight: "100vh" }}
      >
        <RegisterWrapper>
          <Title>パスワードをお忘れの方へ</Title>
          <form onSubmit={handleSubmit}>
            <InputCon>
              <FLabel>
                メールアドレス
                <Annot>
                  ご登録いただいたメールアドレスを入力してください。このメールアドレスにパスワード再設定用のURLを送らせていただきます。
                </Annot>
                <FInput
                  type="text"
                  value={email}
                  onChange={(event) => setEmail(event.target.value)}
                  isError={false}
                />
              </FLabel>
            </InputCon>
            <ButtonCon>
              <SubmitButton text="送信" />
            </ButtonCon>
          </form>
        </RegisterWrapper>
      </section>
      <PortalFooter />
    </>
  );
};
