import * as React from "react";
import styled from "styled-components";
import { ColorTheme } from "~/component/theme";
import { theme } from "~/component/theme";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";

export type PrimaryButtonProps = {
  children: React.ReactNode;
  onClick?: (e: React.MouseEvent) => void;
  disabled?: boolean;
  noMarginTop?: boolean;
  color?: ColorTheme;
};

type ButtonColor = {
  textColor: string;
  backgroundColor: string;
  borderColor: string;
  focusedColor: string;
};

const buttonColorList: Record<ColorTheme, ButtonColor> = {
  pri: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.pri.p400,
    borderColor: theme.colors.pri.p600,
    focusedColor: theme.colors.pri.p500,
  },
  sec: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.sec.s400,
    borderColor: theme.colors.sec.s600,
    focusedColor: theme.colors.sec.s500,
  },
  violet: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.violet.v400,
    borderColor: theme.colors.violet.v600,
    focusedColor: theme.colors.violet.v500,
  },
  green: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.green.g400,
    borderColor: theme.colors.green.g600,
    focusedColor: theme.colors.green.g500,
  },
  red: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.red.r400,
    borderColor: theme.colors.red.r600,
    focusedColor: theme.colors.red.r500,
  },
  sky: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.sky.s400,
    borderColor: theme.colors.sky.s600,
    focusedColor: theme.colors.sky.s500,
  },
  gray: {
    textColor: theme.colors.pri.p400,
    backgroundColor: theme.colors.base.lightGray,
    borderColor: theme.colors.base.gray,
    focusedColor: theme.colors.base.gray,
  },
  lightGray: {
    textColor: theme.colors.pri.p400,
    backgroundColor: theme.colors.base.lightGray,
    borderColor: theme.colors.base.gray,
    focusedColor: theme.colors.base.lightGray,
  },
  white: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.base.white,
    borderColor: theme.colors.pri.p600,
    focusedColor: theme.colors.base.white,
  },
  tex: {
    textColor: theme.colors.base.white,
    backgroundColor: theme.colors.pri.p400,
    borderColor: theme.colors.pri.p600,
    focusedColor: theme.colors.pri.p500,
  },
};

// FIXME styleをbox-shadowからborderに変更したい
export const PrimaryButton: React.VFC<PrimaryButtonProps> = ({
  children,
  onClick,
  disabled = false,
  noMarginTop,
  color = "pri",
}) => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick,
    label: children,
  });
  const ref = React.useRef<HTMLButtonElement>(null);
  const removeFocus = () => {
    if (ref.current) {
      ref.current.blur();
    }
  };
  React.useEffect(() => {
    removeFocus();
  }, []);
  return (
    <ButtonCon noMarginTop={noMarginTop}>
      <StyledButton
        onClick={onClickWithTracking}
        disabled={disabled}
        color={color}
        ref={ref}
      >
        {children}
      </StyledButton>
    </ButtonCon>
  );
};

const ButtonCon = styled.div<{ noMarginTop?: boolean }>`
  position: relative;
  width: 100%;
  margin-top: ${({ noMarginTop }) => (noMarginTop ? "0" : "24px")};
`;

const StyledButton = styled.button<{ disabled: boolean; color: ColorTheme }>`
  width: 100%;
  box-sizing: border-box;
  padding: 16px 8px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  border-radius: 16px;
  font-size: 20px;
  font-family: inherit;
  font-weight: bold;
  text-align: center;
  text-decoration: none;
  color: ${({ color }) => buttonColorList[color].textColor};
  background-color: ${({ color }) => buttonColorList[color].backgroundColor};
  box-shadow: ${({ color }) => `0 5px 0 ${buttonColorList[color].borderColor}`};
  border: none;
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
  @media (hover: hover) {
    &:hover {
      outline: none;
      background-color: ${({ disabled, color }) =>
        disabled
          ? buttonColorList[color].backgroundColor
          : buttonColorList[color].focusedColor};
    }
  }
  &:focus {
    outline: none;
    background-color: ${({ disabled, color }) =>
      disabled
        ? buttonColorList[color].backgroundColor
        : buttonColorList[color].focusedColor};
  }
  &:active {
    outline: none;
    box-shadow: ${({ disabled }) => !disabled && "none"};
    transform: ${({ disabled }) => !disabled && "translateY(5px)"};
  }
`;
