import React from "react";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import { MiddleWrapper as BaseMiddleWrapper } from "~/component/templates/wrapper";
import { ContentsContainer } from "~/component/templates/box";
import { PageTitle } from "~/component/atoms/heading/page_title";
import { ProgressBar } from "~/component/atoms/ProgressBar";
import { ContentsTitleCard } from "~/component/organisms/common/contents_title_card";
import { GraySpanText } from "~/component/atoms/text/styled_text";
import { Stack } from "~/component/atoms/layout/Stack";
import { ConnectLineSkipModal } from "~/component/organisms/modal/ConnectLineSkipModal/index";
import { PlainText } from "~/component/atoms/text";
import { ButtonContainer } from "~/component/templates/button_container";
import { OutsideTransitionButton } from "~/component/atoms/button/outside_transition_button";
import { config } from "../../../config";
export const TutorialLine: React.VFC = () => {
  const history = useHistory();

  const [addFriendClicked, setAddFriendClicked] =
    React.useState<boolean>(false);
  const [isOpenSkipModal, setIsOpenSkipModal] = React.useState<boolean>(false);
  const onSkipLineFriend = () => {
    setIsOpenSkipModal(false);
    history.push("/register_my_library");
  };
  const onCloseModal = () => {
    setIsOpenSkipModal(false);
  };
  return (
    <_Wrapper>
      <ContentsContainer>
        <_RegisteredFirstHeadContainer>
          <_ProgressBarContainer>
            <ProgressBar progress={50} />
          </_ProgressBarContainer>
          <PageTitle>LINEで友だち登録してください</PageTitle>
        </_RegisteredFirstHeadContainer>
        <Stack rowGap="0">
          <_ContentCard title="通知メッセージ">
            <PlainText>ヨンデミー先生からお子さんへのメッセージを</PlainText>
            <PlainText>保護者さまのLINEへとお送りします🤝</PlainText>
            <br />
            <_GrayText>🔔&nbsp;&nbsp;新しい本のおすすめが届いたとき</_GrayText>
            <_GrayText>🔔&nbsp;&nbsp;お子さんへレッスンが届いたとき</_GrayText>
          </_ContentCard>

          <_ContentCard title="保護者さま向けプチレッスン">
            <PlainText>
              ヨンデミーをご活用いただく上で、
              <br />
              必ず保護者さまに知っていただきたい情報をお送りします🙌
              <br />
              <br />
              全30回、1回3分で読める記事が 毎日配信されます🎉 <br />
              <br />
            </PlainText>
            <_GrayText>🔖&nbsp;&nbsp;3分でわかるヨンデミー活用法</_GrayText>
            <_GrayText>
              🔖&nbsp;&nbsp;少しの違いで子どもが本を手に取りやすくなる！
              その工夫とは？
            </_GrayText>
            <_GrayText>
              🔖&nbsp;&nbsp;何を聞いても「ふつう」と答えるお子さんには理由があった！{" "}
            </_GrayText>
            <_GrayText>など</_GrayText>
          </_ContentCard>

          <_ContentCard title="ヨンデミーなんでも相談窓口">
            <PlainText>
              LINEにて、保護者さまのお悩みをヨンデミー講師に直接ご相談いただけます🤝
              <br />
              <br />
              些細なことでも構いませんので、お気軽にご相談ください！
              <br />
              <br />
            </PlainText>
            <_GrayText>📚&nbsp;&nbsp;子どもが本に興味を持たない</_GrayText>
            <_GrayText>💬&nbsp;&nbsp;読み聞かせが卒業できない</_GrayText>
            <_GrayText>✏️&nbsp;&nbsp;感想がなかなか出てこない</_GrayText>
            <_GrayText>など</_GrayText>
          </_ContentCard>

          <_RedText>※ 宣伝等の配信は一切行いません。ご安心ください。</_RedText>

          <ButtonContainer>
            <OutsideTransitionButton
              href={config.NOTIFICATION_LINE_LINK}
              color="green"
              target={"_blank"}
              rel={"noreferrer noopener"}
              onClick={() => setAddFriendClicked(true)}
            >
              友だち追加する
            </OutsideTransitionButton>
          </ButtonContainer>
          <_SkipButtonContainer
            onClick={() => {
              if (addFriendClicked) {
                history.push("/register_my_library");
              } else {
                setIsOpenSkipModal(true);
              }
            }}
          >
            {addFriendClicked
              ? "友だち追加を完了された方・スキップ"
              : "LINEアカウントをお持ちでない方・スキップ"}
          </_SkipButtonContainer>
          <ConnectLineSkipModal
            isOpen={isOpenSkipModal}
            onSkip={onSkipLineFriend}
            onClose={onCloseModal}
          />
        </Stack>
      </ContentsContainer>
    </_Wrapper>
  );
};

const _RegisteredFirstHeadContainer = styled.div`
  padding-top: 20px;
  margin-bottom: 12px;
`;

const _Wrapper = styled(BaseMiddleWrapper)`
  min-height: 100vh;
  padding-bottom: 50px;
`;

const _ProgressBarContainer = styled.div`
  margin-bottom: 27px;
`;

const _ContentCard = styled(ContentsTitleCard)`
  margin-bottom: 20px;
  div:first-child {
    font-size: 18px;
  }
`;

const _GrayText = styled(GraySpanText)`
  display: block;
  font-size: 13px;
  line-height: 22px;
`;

const _RedText = styled.p`
  color: ${(props) => props.theme.colors.red.r400};
  font-size: 13px;
`;

const _SkipButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  font-size: 13px;
`;
