import React from "react";
import styled from "styled-components";
import { BaseModal } from "~/component/atoms/modal/base_modal";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
export type ConnectLineModalProps = {
  isOpen: boolean;
  onClose: () => void;
  isConnected: boolean;
};

const Title = styled.h2`
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 12px;
`;

const Contents = styled.div`
  white-space: pre-wrap;
`;

export const ConnectLineModal: React.VFC<ConnectLineModalProps> = ({
  isOpen,
  onClose,
  isConnected,
}) => {
  return (
    <BaseModal isOpen={isOpen} onClose={onClose} title={"LINEアカウントの連携"}>
      <Contents>
        <Title>LINEアカウントの連携</Title>
        <Para>
          {isConnected
            ? "LINEアカウントの連携ができました！\n続いて、ヨンデミーを便利に使うため、図書館を登録しましょう。"
            : "LINEアカウントの連携に失敗しました。復旧する場合は、cs@yondemy.comまでご連絡ください。"}
        </Para>
        {isConnected && (
          <PrimaryButton onClick={onClose} noMarginTop>
            次に進む
          </PrimaryButton>
        )}
      </Contents>
    </BaseModal>
  );
};

const Para = styled.p`
  font-size: 14px;
  color: ${(props) => props.theme.colors.tex.t700};
  margin-bottom: 12px;
`;
