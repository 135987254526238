import React, { useState, useEffect, useContext } from "react";
import styled from "styled-components";

import {
  UserProps,
  hasExpiredSubscription,
} from "../../../utils/subscription_config";
import {
  useCreateSubscriptionMutation,
  useRecreateSubscriptionMutation,
} from "../../../generated/graphql";
import { config } from "../../../../config";
import { Loading } from "../../atoms/progress_indicator/loading";
import { ButtonContainer } from "../../templates/button_container";
import { SubscriptionsClientContext } from "~/contexts/CustomApolloProvider";

declare global {
  interface Window {
    onTokenCreateCallback: any; //eslint-disable-line
    PayjpCheckout: any; //eslint-disable-line
  }
}

type BillingProps = {
  user: UserProps;
  onSuccess: () => void;
  onError: () => void;
};

const Billing: React.VFC<BillingProps> = ({ user, onSuccess, onError }) => {
  const resetButton = () => {
    // Reset values
    window.PayjpCheckout.button.value =
      window.PayjpCheckout.locale.defaultButton.partial;
    window.PayjpCheckout.button.classList.remove("has-token");
    window.PayjpCheckout.tokenBox.value = "";
  };

  const [token, setToken] = useState("");
  const { subscriptionsClient } = useContext(SubscriptionsClientContext);

  const mutationParams = {
    client: subscriptionsClient,
    async onCompleted() {
      resetButton();
      onSuccess();
      setToken("");
    },
    onError(e) {
      console.error("サーバーエラー:", e?.message);
      resetButton();
      onError();
    },
  };
  const [createSubscription, { loading: createLoading }] =
    useCreateSubscriptionMutation(mutationParams);
  const [recreateSubscription, { loading: recreateLoading }] =
    useRecreateSubscriptionMutation(mutationParams);
  const loading = createLoading || recreateLoading;

  // On card create
  useEffect(() => {
    if (!token) return;

    const params = {
      userId: user.id,
      payjpToken: token,
    };

    if (hasExpiredSubscription(user) && params.userId) {
      recreateSubscription({
        variables: {
          userId: params.userId,
          payjpToken: params.payjpToken,
        },
      });
    } else if (!hasExpiredSubscription(user) && user.planId && params.userId) {
      createSubscription({
        variables: {
          userId: params.userId,
          payjpToken: params.payjpToken,
          planId: user.planId,
        },
      });
    }
  }, [token, user]);

  // Callback
  useEffect(() => {
    // Note: It's sad, but this is the only way to catch callback from PayJP's checkout script
    window.onTokenCreateCallback = function () {
      const el = document.querySelector(
        "input[name=payjp-token]"
      ) as HTMLInputElement;

      setToken(el.value);
    };
  }, []);

  // PayJP Script
  useEffect(() => {
    const existingScript = document.getElementById("payJpScript");

    if (!existingScript) {
      const script = document.createElement("script");
      script.id = "payJpScript";
      script.src = "https://checkout.pay.jp/";
      script.className = "payjp-button";
      script.dataset.key = config.PAY_JP_PUBLIC_KEY;
      script.dataset.partial = "true";
      script.dataset.submitText = "カード情報を入力する";
      script.dataset.onCreated = "onTokenCreateCallback";

      document.getElementById("payjp-root")?.appendChild(script);
    }

    return () => {
      // PayJP checkout JS cleanup
      // Ref: https://tackeyy.com/blog/posts/implement-payjp-checkout-with-vue-spa
      const elem = document.getElementById("payjp-checkout-iframe");
      if (elem) {
        elem.parentElement?.remove();
      }
      window.PayjpCheckout = null;
      window.onTokenCreateCallback = null;
    };
  }, []);

  return (
    <>
      <ButtonContainer isShown={!loading}>
        <SubscriptionPayJpWrapper
          id="payjp-root"
          isPaid={loading}
          className="text-center"
        />
        {loading ? (
          <div className="text-center align-middle mt-3">
            <Loading />
            只今処理中です。。
          </div>
        ) : null}
      </ButtonContainer>
      {/* <AnnotWrapper isPaid={subscriptionExists || loading}>
        <TextLink to={"/about_edit_cardnumber"}>クレジットカードの番号を変更したい場合</TextLink>
      </AnnotWrapper> */}
    </>
  );
};

type PayJpWrapperProps = {
  isPaid: boolean;
};

/* const AnnotWrapper = styled.div<PayJpWrapperProps>`
  width: 100%;
  display: ${(props) => props.isPaid ? "flex" : "none"};
  flex-direction: row-reverse;
  margin: 10px 0 0 0;
`; */

const SubscriptionPayJpWrapper = styled.div<PayJpWrapperProps>`
  display: ${(props) => (props.isPaid ? "none" : "block")};

  #payjp_checkout_box input[type="button"] {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 1rem;
    height: 60px;
    width: 100%;
    font-size: 16px;
    font-weight: bold;
    border-radius: 15px;
    background-image: none;
    background-color: ${(props) => props.theme.colors.pri.p400};
    border: none;
    &:hover,
    &:focus,
    &:hover {
      background-color: ${(props) => props.theme.colors.pri.p500};
    }
  }
`;

export default Billing;
