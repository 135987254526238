import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useAudio } from "./useAudio";
import { Splash } from "./Splash";
import { StoryMessage } from "./StoryMessage";
import { TapHint } from "./TapHint";
import {
  endOfBookImage,
  lineImages,
  yondemyStories,
  YondemyStorySetting,
} from "./yondemyStory";
import { useScale } from "../ui/constants";

const CONTENT_WIDTH = 376;
const CONTENT_HEIGHT = 812;

type Props = {
  onCompleted: () => void;
  onBack?: () => void;
};

const allImages = yondemyStories.reduce<string[]>((prev, current) => {
  if (!prev.includes(current.image)) {
    return [...prev, current.image];
  }
  return [...prev];
}, []);

export const YondemyStory: React.FC<Props> = ({ onCompleted, onBack }) => {
  const [loaded, setLoaded] = useState<Record<string, boolean>>({});
  const [page, setPage] = useState<number>(0);
  const [closed, setClosed] = useState<boolean>(false);
  const [showSplash, setShowSplash] = useState<boolean>(true);
  const [showPrevPage, setShowPrevPage] = useState<boolean>(true);

  const { play } = useAudio({});

  const allImagesLoaded =
    Object.keys(loaded).length ===
    Object.values(loaded).filter((v) => v).length;

  console.log("loaded", allImagesLoaded);

  const previousPage = yondemyStories[page - 1];
  const currentPage = yondemyStories[page];
  const nextPage = yondemyStories[page + 1];

  const ref = useRef<HTMLDivElement>(null);

  const scale = useScale();

  useEffect(() => {
    [...lineImages, ...allImages, endOfBookImage].forEach((src) => {
      const img = document.createElement("img");
      img.onload = () => {
        setLoaded((l) => ({ ...l, [src]: true }));
      };
      img.src = src;
      setLoaded((l) => ({ ...l, [src]: false }));
      ref.current && ref.current.appendChild(img);
    });
  }, []);

  const endPageAudioStop = useRef<() => void>();

  useEffect(() => {
    return () => {
      endPageAudioStop.current && endPageAudioStop.current();
    };
  }, []);

  return (
    <_BG>
      <_YondemyStoryWrapper style={{ scale: `${scale}` }}>
        <_Preload ref={ref} />

        {!showSplash && (
          <Page
            key={`p_${page}`}
            stopped={closed}
            story={currentPage}
            onClick={() => {
              if (page >= yondemyStories.length - 1) {
                play("pageTurn");
                setClosed(true);
                endPageAudioStop.current = play("toBeContinued", 0.5);
                return;
              }

              if (currentPage.image !== nextPage?.image) {
                play("pageTurn");
              }

              setShowPrevPage(true);
              setPage((p) => {
                return p + 1;
              });
            }}
            onBack={() => {
              if (page === 0) {
                setShowSplash(true);
                return;
              }
              setShowPrevPage(false);
              setPage((p) => {
                return p - 1;
              });
            }}
          />
        )}
        {page === 0 && !showSplash && (
          <_TapHintWrapper>
            <TapHint />
          </_TapHintWrapper>
        )}

        {showPrevPage &&
          previousPage &&
          currentPage.image !== previousPage.image &&
          !closed && (
            <PreviousPage key={`pp_${page}`} image={previousPage.image} />
          )}
        {/* 最後のページでクリックしたら背表紙が←にワイプインする */}
        {closed && (
          <>
            <EndPage onClick={onCompleted} />
            <_TapHintWrapper>
              <TapHint />
            </_TapHintWrapper>
          </>
        )}
        {showSplash && (
          <Splash onClick={() => setShowSplash(false)} onBack={onBack} />
        )}
      </_YondemyStoryWrapper>
    </_BG>
  );
};

const _BG = styled.div`
  width: 100%;
  background-color: black;
  height: 100vh;
  max-height: 100dvh;
  display: flex;
  justify-content: center;
`;

const _TapHintWrapper = styled.div`
  position: absolute;
  bottom: 105px;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const _Preload = styled.div`
  display: none;
`;

const _YondemyStoryWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  transform-origin: 50% top;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  position: relative;
`;

type PageProps = {
  story: YondemyStorySetting;
  onClick: () => void;
  onBack: () => void;
  stopped?: boolean;
};

const Page: FC<PageProps> = ({
  story: { image, text, lineImage, sound },
  onClick,
  onBack,
  stopped = false,
}) => {
  const { play } = useAudio({ preload: [] });
  const stopPlaying = useRef<() => void>();

  const [messageFinished, setMessageFinished] = useState<boolean>(
    !text // textが空の場合はメッセージ送りが完了したものとして扱う
  );

  useEffect(() => {
    if (stopped && stopPlaying.current) {
      stopPlaying.current();
    }
  }, [stopped]);

  useEffect(() => {
    if (!sound) {
      return;
    }

    const stop = play(sound, 0.5);
    stopPlaying.current = stop;

    // unmoutするとき音を止める
    return () => {
      stop && stop();
    };
  }, []);

  const handleOnClick = useCallback(() => {
    if (!messageFinished) {
      setMessageFinished(true);
      return;
    }

    messageFinished && onClick();
  }, [onClick, messageFinished]);

  const handleBack = useCallback(() => {
    setMessageFinished(false);
    onBack();
  }, [onBack]);

  return (
    <>
      <_PageContainer image={image} onClick={handleOnClick}>
        {text && (
          <_MessageContainer>
            {!stopped && (
              <StoryMessage
                message={text}
                lineImage={lineImage}
                messageFinished={messageFinished}
                onFinished={() => setMessageFinished(true)}
              />
            )}
          </_MessageContainer>
        )}
      </_PageContainer>
      <_BackNavContainer>
        <div onClick={handleBack} style={{ cursor: "pointer" }}>
          <_LeftArrow />
        </div>
      </_BackNavContainer>
    </>
  );
};

const _LeftArrow: React.FC = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7465 23.8479L14.5904 25.004C14.1009 25.4935 13.3093 25.4935 12.825 25.004L2.70113 14.8854C2.2116 14.3958 2.2116 13.6042 2.70113 13.1199L12.825 2.99605C13.3145 2.50652 14.1061 2.50652 14.5904 2.99605L15.7465 4.15217C16.2413 4.64691 16.2309 5.45411 15.7257 5.93843L9.45038 11.9169H24.4175C25.1101 11.9169 25.6673 12.4742 25.6673 13.1668V14.8333C25.6673 15.5259 25.1101 16.0831 24.4175 16.0831H9.45038L15.7257 22.0616C16.2361 22.546 16.2465 23.3532 15.7465 23.8479Z"
      fill="#EE9F17"
    />
  </svg>
);

const _PageContainer = styled.div<{ image: string }>`
  cursor: pointer;
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;
  background-image: url(${({ image }) => image});
  background-repeat: no-repeat;
  background-size: cover;

  position: relative;
`;

const _BackNavContainer = styled.div`
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  height: 52px;
  background: linear-gradient(black, transparent);
  padding: 12px 20px;
`;

const _MessageContainer = styled.div`
  position: fixed;
  bottom: 0;
`;

type PreviousPageProps = {
  image: string;
};

const PreviousPage: FC<PreviousPageProps> = ({ image }) => {
  return (
    <_PreviousPageWrapper>
      <_PageContainer image={image} />
    </_PreviousPageWrapper>
  );
};

const _PreviousPageWrapper = styled.div`
  pointer-events: none;
  position: absolute;
  top: 0;
  left: 0;

  animation: wipe 0.5s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center left;

  @keyframes wipe {
    from {
      transform: perspective(2000px) rotateY(0);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.9);
      filter: brightness(1);
    }
    to {
      transform: perspective(2000px) rotateY(-90deg);
      box-shadow: 100px 0px 100px 100px rgba(0, 0, 0, 0);
      filter: brightness(0.5);
    }
  }
`;

type EndPageProps = {
  onClick: () => void;
};

const EndPage: FC<EndPageProps> = ({ onClick }) => {
  return (
    <_EndPageWrapper onClick={onClick}>
      <_PageContainer image={endOfBookImage} />
    </_EndPageWrapper>
  );
};

const _EndPageWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;

  animation: end_wipe 0.5s ease-out;
  animation-fill-mode: forwards;
  transform-origin: center right;

  @keyframes end_wipe {
    from {
      transform: perspective(2000px) rotateY(90deg);
      box-shadow: 100px 0px 100px 100px rgba(0, 0, 0, 0);
      filter: brightness(0.5);
    }
    to {
      transform: perspective(2000px) rotateY(0deg);
      box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.9);
      filter: brightness(1);
    }
  }
`;
