import * as React from "react";
import { Stack } from "../../layout/Stack";
import styled from "styled-components";
import { Icon } from "../../icon";
import { Row } from "../../layout/Row";

type Props = {
  label: string;
  labelHtmlFor?: string;
  children: React.ReactNode;
  helpText?: string;
  info?: string;
  error?: string;
  unit?: string;
};

export const FormSlot: React.FC<Props> = ({
  label,
  labelHtmlFor,
  children,
  helpText,
  info,
  error,
  unit,
}) => (
  <Stack rowGap="8px" style={{ width: "100%" }}>
    <_Label htmlFor={labelHtmlFor}>{label}</_Label>
    {helpText && <_HelpText>{helpText}</_HelpText>}
    <Row columnGap="8px">
      <_ChildrenWrapper>{children}</_ChildrenWrapper>
      {unit && <_Unit>{unit}</_Unit>}
    </Row>
    {info && (
      <_Info columnGap="4px">
        <Icon name="question" style={{ fontSize: 16 }} />
        {info}
      </_Info>
    )}
    {error && (
      <_Error columnGap="4px">
        <Icon name="exclamationCircle" style={{ fontSize: 16 }} />
        {error}
      </_Error>
    )}
  </Stack>
);

const _ChildrenWrapper = styled.div`
  flex-grow: 1;
`;

const _Unit = styled.div`
  color: ${({ theme }) => theme.colors.semantic.text.body};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  user-select: none;
`;

const _Label = styled.label`
  color: ${({ theme }) => theme.colors.semantic.text.label};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 100%;
`;

const _HelpText = styled.div`
  color: ${({ theme }) => theme.colors.semantic.text.label};
  font-size: ${({ theme }) => theme.fontSize.XXS};
  line-height: 160%;
`;

const _Info = styled(Row)`
  color: ${({ theme }) => theme.colors.semantic.text.label};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  align-items: flex-start;
  white-space: pre-wrap;

  svg {
    color: ${({ theme }) => theme.colors.semantic.text.label};
  }
`;

const _Error = styled(Row)`
  color: ${({ theme }) => theme.colors.semantic.alert.main};
  font-size: ${({ theme }) => theme.fontSize.SM};
  line-height: 160%;
  align-items: flex-start;
  white-space: pre-wrap;

  svg {
    color: ${({ theme }) => theme.colors.semantic.alert.main};
  }
`;
