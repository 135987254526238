import * as React from "react";
import { useParentProfileQuery } from "../generated/graphql";
import { Loading } from "../component/atoms/progress_indicator/loading";
import { ProfileTemplate } from "../component/templates/profile";
import { Auth0Context } from "~/contexts/Auth0Context";

export const Profile: React.VFC = () => {
  const { data, loading, error } = useParentProfileQuery();
  const { logout } = React.useContext(Auth0Context);

  const handleLogout = () => logout();
  if (loading || !data) return <Loading />;
  if (error) return <>エラーです。</>;
  return (
    <>
      <ProfileTemplate data={data} handleLogout={handleLogout} />
    </>
  );
};
