import * as React from "react";
import { Modal as ResponsiveModal } from "react-responsive-modal";
import styled, { useTheme } from "styled-components";
import "react-responsive-modal/styles.css";

type Padding = "0px" | "24px";

type Props = {
  open: boolean;
  children: React.ReactNode;
  closeOnOverlayClick?: boolean;
  onClose?: () => void;
  transparent?: boolean;
  header?: React.ReactNode;
  footer?: React.ReactNode;
  padding?: Padding;
};

export const BaseModal: React.FC<Props> = ({
  open,
  children,
  closeOnOverlayClick = true,
  onClose,
  transparent = false,
  header,
  footer,
  padding = "24px",
}) => {
  const { size, borderRadius } = useTheme();
  return (
    <ResponsiveModal
      center
      open={open}
      onClose={() => onClose && onClose()}
      closeOnOverlayClick={closeOnOverlayClick}
      showCloseIcon={false}
      styles={{
        modalContainer: {
          marginLeft: size.sideMargin,
          marginRight: size.sideMargin,
          marginBottom: size.sideMargin,
        },
        modal: {
          boxSizing: "border-box",
          maxWidth: `calc(496px + ${size.s3} * 2)`,
          margin: 0,
          padding: 0,
          borderRadius: borderRadius.MD,
          maxHeight: "80vh",
          position: "relative",
          verticalAlign: "middle",
          backgroundColor: transparent ? "transparent" : "white",
          boxShadow: transparent ? "none" : "0 12px 15px 0 rgba(0, 0, 0, 0.25)",
        },
      }}
    >
      <_Container>
        {header}
        <_Content padding={padding}>{children}</_Content>
        {footer}
      </_Container>
    </ResponsiveModal>
  );
};

const _Content = styled.div<{ padding: Padding }>`
  padding: ${({ padding }) => padding};
`;

const _Container = styled.div`
  position: relative;
`;

export const BaseModalHeader = styled.div`
  position: sticky;
  top: 0;
  width: 100%;

  box-sizing: border-box;
  padding: 16px;

  border-bottom: 1px solid #ccc;

  background-color: white;
`;

export const BaseModalFooter = styled.div`
  position: sticky;
  bottom: -1px;
  width: 100%;

  box-sizing: border-box;
  padding: 16px;

  border-top: 1px solid #ccc;

  background-color: white;
`;
