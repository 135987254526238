import React from "react";
import { SurveyWrapper } from "~/component/organisms/survey/SurveyWrapper";
import styled from "styled-components";
import { Stack } from "~/component/atoms/layout/Stack";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { useHistory } from "react-router";
import { TextButton } from "~/component/molecules/button/TextButton";
import { Row } from "~/component/atoms/layout/Row";

export type StartScreenProps = {
  onNext: () => void;
};

export const StartScreen: React.FC<StartScreenProps> = ({ onNext }) => {
  const history = useHistory();
  return (
    <_Wrapper>
      <_Box>
        <Stack rowGap="32px">
          <_Text>きょうだい登録までの流れ</_Text>
          <_SenseiImage src="/img/sensei/standing.svg" />
          <Stack>
            <_Text>
              紙芝居（音声つき）が始まります。
              <br />
              お子さんと一緒に見てください。
            </_Text>
            <_Text>
              もしお子さんが楽しんでいれば、
              <br />
              そのまま簡単な登録へとお進みいただけます。
            </_Text>
          </Stack>
        </Stack>
      </_Box>
      <Stack rowGap="12px">
        <PrimaryButton onClick={onNext} noMarginTop>
          紙芝居へすすむ
        </PrimaryButton>
        <_AutoChargeMessage>
          💳 無料体験後も、自動課金はありません！
        </_AutoChargeMessage>
      </Stack>
      <Row justifyContent="center">
        <TextButton
          text="前のページに戻る"
          onClick={() => history.goBack()}
          fontSize="MD"
          lineHeight="MD"
          fontColor="semantic.text.body"
        />
      </Row>
    </_Wrapper>
  );
};

const _Wrapper = styled(SurveyWrapper)`
  padding-top: 80px;
`;

const _Box = styled.div`
  padding: 32px 16px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;

  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 16px;
  line-height: 160%;
`;

const _SenseiImage = styled.img`
  max-width: 120px;
  width: 30%;
  margin: 0 auto;
`;

const _Text = styled.p`
  font-size: ${({ theme }) => theme.fontSize.MD};
  color: ${({ theme }) => theme.colors.tex.t800};
  line-height: 160%;
  font-weight: 700;
  text-align: justify;
  white-space: pre-wrap;
`;

const _AutoChargeMessage = styled.div`
  margin: 0 auto;
  font-size: ${({ theme }) => theme.fontSize.XS};
  color: ${({ theme }) => theme.colors.tex.t300};
  line-height: 160%;
`;
