import axios from "axios";
import * as React from "react";
import styled from "styled-components";
import { Form as BootstrapForm } from "react-bootstrap";
import { Stack } from "~/component/atoms/layout/Stack";
import { SurveyHeader } from "~/component/organisms/survey/SurveyHeader";
import { SurveyWrapper } from "~/component/organisms/survey/SurveyWrapper";
import { NavigationButton } from "./ui/NavigationButton";
import { QuestionBox, SubText } from "./ui/styles";
import { CalilLibrary } from "~/generated/graphql";
import { PrefectureList } from "./const";
import {
  ConfirmSkipRegisterModalProps,
  ConfirmSkipRegisterModal,
} from "./ConfirmSkipRegisterModal";

const { Control } = BootstrapForm;

type RegisterMyLibraryProps = {
  pref: string;
  setPref: (pref: string) => void;
  city: string;
  setCity: (city: string) => void;
  libraryList: CalilLibrary[];
  onSearchLibrary: () => void;
  onClearLibraryList: () => void;
  onSelectLibrary: (params: {
    calilLibraryKey: string;
    calilLibraryAreaID: string;
  }) => Promise<boolean>;
  onNext: () => void;
  loading: boolean;
  submitButtonText: string;
  confirmSkipRegisterModalProps?: ConfirmSkipRegisterModalProps & {
    setIsModalOpen: (isModalOpen: boolean) => void;
  };
};

export const RegisterMyLibrary: React.FC<RegisterMyLibraryProps> = ({
  pref,
  setPref,
  city,
  setCity,
  libraryList,
  onSearchLibrary,
  onClearLibraryList,
  onSelectLibrary,
  onNext,
  loading,
  submitButtonText,
  confirmSkipRegisterModalProps,
}) => {
  return (
    <SurveyWrapper>
      <SurveyHeader progress={25} />
      <_MainTitle>
        お近くの図書館を
        <br />
        登録してください
      </_MainTitle>
      <RegisterMyLibraryContent
        pref={pref}
        setPref={setPref}
        city={city}
        setCity={setCity}
        libraryList={libraryList}
        onSearchLibrary={onSearchLibrary}
        onClearLibraryList={onClearLibraryList}
        onSelectLibrary={onSelectLibrary}
        onNext={onNext}
        submitButtonText={submitButtonText}
        loading={loading}
        confirmSkipRegisterModalProps={confirmSkipRegisterModalProps}
      />
    </SurveyWrapper>
  );
};

// memo: change_libraryでも使っているがtemplateに切り出せていない
export const RegisterMyLibraryContent: React.FC<RegisterMyLibraryProps> = ({
  pref,
  setPref,
  city,
  setCity,
  libraryList,
  onSearchLibrary,
  onClearLibraryList,
  onSelectLibrary,
  onNext,
  submitButtonText,
  loading,
  confirmSkipRegisterModalProps,
}) => {
  const [cityList, setCityList] = React.useState<string[]>([]);

  const [library, setLibrary] = React.useState<CalilLibrary | undefined>(
    undefined
  );

  type CityListResponse = {
    あ: string[];
    か: string[];
    さ: string[];
    た: string[];
    な: string[];
    は: string[];
    ま: string[];
    や: string[];
    ら: string[];
    わ: string[];
    広域: string[];
  };

  const getCityList = async () => {
    const res = await axios.get<CityListResponse>(
      `https://calil.jp/api/citylist?pref=${pref}&callback=no`
    );
    let allCities: string[] = [];
    for (const key in res.data) {
      if (res.data[key] !== undefined && key !== "広域") {
        allCities = allCities.concat(res.data[key]);
      }
    }
    setCityList(allCities);
  };

  React.useEffect(() => {
    getCityList();
  }, [pref]);

  const [showLibraries, setShowLibraries] = React.useState<boolean>(false);

  const handleClickSkipButton = () => {
    if (confirmSkipRegisterModalProps?.setIsModalOpen) {
      confirmSkipRegisterModalProps.setIsModalOpen(true);
    } else {
      onNext();
    }
  };

  return (
    <>
      <QuestionBox>
        <Stack>
          本を借りる際に利用したい図書館をえらんでください。
          <br />
          えらんだ図書館で受け取れる本を優先しておすすめします。
          <SubText>
            ※図書館設定はお子様のアプリの設定からいつでも変更できます。
            <br />
            ※一部の図書館には対応していません。
          </SubText>
        </Stack>
      </QuestionBox>
      <QuestionBox>
        <Stack rowGap="8px">
          利用する図書館を地域で検索
          <StyledControl
            as="select"
            defaultValue={pref}
            onChange={(e) => {
              setPref(e.target.value);
              setCity("");
              onClearLibraryList();
              setLibrary(undefined);
              setShowLibraries(false);
            }}
          >
            <option value="">-----</option>
            {PrefectureList.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </StyledControl>
          <InvertedTriangleWrapper>
            <InvertedTriangle />
          </InvertedTriangleWrapper>
          <StyledControl
            as="select"
            defaultValue={city}
            onChange={(e) => {
              setCity(e.target.value);
              onClearLibraryList();
              setLibrary(undefined);
              setShowLibraries(false);
            }}
          >
            <option value="">-----</option>
            {cityList.map((option) => (
              <option key={option} value={option}>
                {option}
              </option>
            ))}
          </StyledControl>
          {!showLibraries && (
            <NavigationButton
              disabled={pref === "" || city === ""}
              onClick={() => {
                onSearchLibrary();
                setLibrary(undefined);
                setShowLibraries(true);
              }}
            >
              図書館を検索
            </NavigationButton>
          )}
          {showLibraries && (
            <>
              検索結果から利用したい図書館を1つ選んでください
              <StyledControl
                as="select"
                defaultValue={city}
                onChange={(e) => {
                  const lib = libraryList.find(
                    (l) => l.libraryName === e.target.value
                  );
                  setLibrary(lib);
                }}
              >
                <option value="">-----</option>
                {libraryList.map((option) => (
                  <option key={option.libraryName} value={option.libraryName}>
                    {option.libraryName}
                  </option>
                ))}
              </StyledControl>
            </>
          )}
        </Stack>
      </QuestionBox>
      <NavigationButton
        disabled={library === undefined || loading}
        onClick={() => {
          if (library) {
            onSelectLibrary({
              calilLibraryAreaID: library.calilLibraryAreaID,
              calilLibraryKey: library.calilLibraryKey,
            }).then(() => onNext());
          }
        }}
      >
        {submitButtonText}
      </NavigationButton>
      <SkipButtonContainer onClick={handleClickSkipButton}>
        スキップする
      </SkipButtonContainer>
      {confirmSkipRegisterModalProps?.isOpen && (
        <ConfirmSkipRegisterModal {...confirmSkipRegisterModalProps} />
      )}
    </>
  );
};

const StyledControl = styled(Control)`
  width: 100%;
  padding: 12px 12px;
  border: 1px solid #e6e6e6;
  border-radius: 8px;
  margin-top: 8px;
  margin-bottom: 8px;
`;

const InvertedTriangle: React.FC = () => (
  <svg
    width="17"
    height="14"
    viewBox="0 0 17 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2432 12.9011C9.47854 14.2604 7.52146 14.2604 6.75685 12.9011L1.17654 2.98052C0.426615 1.64731 1.39004 -4.98971e-07 2.91969 -6.32698e-07L14.0803 -1.60839e-06C15.61 -1.74212e-06 16.5734 1.64731 15.8235 2.98052L10.2432 12.9011Z"
      fill="#0068C9"
    />
  </svg>
);

const InvertedTriangleWrapper = styled.div`
  text-align: center;
`;

const SkipButtonContainer = styled.div`
  margin-top: 8px;
  text-align: center;
  cursor: pointer;
`;
const _MainTitle = styled.h1`
  font-size: ${({ theme }) => theme.fontSize.XXL};
  color: ${({ theme }) => theme.colors.tex.t800};
  line-height: 160%;
  font-weight: bold;
`;
