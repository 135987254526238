import React from "react";
import { Stack } from "~/component/atoms/layout/Stack";
import {
  AnnotationText,
  AnnotationTitle,
  AnnotationWrapper,
  CheckText,
  Img,
  PlainText,
  SectionTitle,
  Title,
} from "./styles";
import lib1Img from "../images/Manual/lib1.png";
import lib2Img from "../images/Manual/lib2.png";
import lib3Img from "../images/Manual/lib3.png";
import lib5Img from "../images/Manual/lib5.png";
import lib6Img from "../images/Manual/lib6.png";
import form1Img from "../images/Manual/form1.png";
import form2Img from "../images/Manual/form2.png";

/* eslint-disable no-irregular-whitespace */
export const Manual: React.FC = () => (
  <Stack rowGap="12px">
    <Title>これでOK！ おすすめ本の用意マニュアル</Title>
    <Stack rowGap="20px">
      <SectionTitle>おすすめ本の用意&お子さんへの渡し方！</SectionTitle>
      <Stack rowGap="28px">
        <Stack rowGap="8px">
          <PlainText>
            お子さんにとって、ヨンデミーは未知の体験😳
            <br />
            <span className="emphasized">本のスムーズな用意</span>
            と、
            <span className="emphasized">
              お子さんが1冊目を手に取るまでのステップ
            </span>
            を解説します！
          </PlainText>
          <AnnotationWrapper>
            <AnnotationTitle>ポイントはこの3つ！</AnnotationTitle>
            <AnnotationText>
              <b>
                ・おすすめ本は全部予約しよう！
                <br />
                ・本の定位置はおうちのリビングの机の上！
                <br />
                ・合わなくても大丈夫！　おすすめ本を再調整できます！
              </b>
            </AnnotationText>
          </AnnotationWrapper>
        </Stack>
        <PlainText>
          ヨンデミー先生からのおすすめ本は、お子さんに答えてもらったアンケートをもとにぴったりな本をお届けしています📚✨
          <br />
          お子さんとご一緒のときは、ぜひ<b>プレゼントボックス</b>
          をタップして見てください🎁
          <br />
          保護者さまだけが見たいときは、 <b>画面左上「おすすめの本」より一覧</b>
          で見ることができます！
          <br />
          おすすめ本は登録していただいた
          <span className="emphasized">最寄りの図書館で借りられる本を優先</span>
          しているんです🙌
          <br />
          さっそく<b>図書館で予約してみましょう！</b>
        </PlainText>
        <Stack rowGap="12px">
          <CheckText>図書館予約方法</CheckText>
          <Stack rowGap="8px">
            <PlainText>①ホーム画面左上「おすすめの本」をタップ</PlainText>
            <Img src={lib1Img} />
            <PlainText>②「図書館で予約・取り置きをする」をタップ</PlainText>
            <Img src={lib2Img} />
            <PlainText>③ご利用図書館での蔵書確認ページへ</PlainText>
            <Img src={lib3Img} />
            <PlainText>④ご利用図書館の「予約する」をタップ</PlainText>
            <PlainText>
              ⑤図書館のサイトに移動するので、「🧺予約かごへ」をタップ
            </PlainText>
            <Img src={lib5Img} />
            <PlainText>②〜⑤を繰り返す</PlainText>
            <PlainText>⑥予約かごに登録した本をすべて予約する🎉</PlainText>
            <Img src={lib6Img} />
            <PlainText>⑦受け取りに行く</PlainText>
            <PlainText>⑧おうちに置く</PlainText>
            <Img />
            <AnnotationWrapper>
              <AnnotationTitle>
                【注意！】「1冊だけ借りてみる」は△
              </AnnotationTitle>
              <AnnotationText>
                「なんとなく興味ありそうなのを1冊だけ……」ではなく
                <span className="emphasized">思い切って全部借りて</span>
                みてください！
              </AnnotationText>
              <Stack rowGap="4px">
                <AnnotationText>
                  <span className="underline">ポイント①</span>
                  <br />
                  「面白い」と思う本もあれば当然
                  <span className="emphasized">
                    「つまらなかった」と思うことも
                  </span>
                  。大事なのは再チャレンジできる本がたくさんあること。どんどんチャレンジして
                  <b>面白い本に1冊出会えれば当たり</b>なんです！
                </AnnotationText>
                <AnnotationText>
                  <span className="underline">ポイント②</span>
                  <br />
                  お子さんが
                  <span className="emphasized">
                    「読む本を選ぶ」体験もとても大事
                  </span>
                  。選べる本がたくさんあることで
                  <b>「読書の押し付け」感も減らす</b>ことができます☺️
                </AnnotationText>
                <AnnotationText>
                  <span className="underline">ポイント③</span>
                  <br />
                  「本って面白い！」そうお子さんが気づいたときに
                  <b>隣に本がもう1冊</b>
                  あると、
                  <span className="emphasized">
                    「次も読んでみようかな！」というきっかけ
                  </span>
                  に✨
                </AnnotationText>
                <AnnotationText>
                  <b>・合わなくても大丈夫！　おすすめ本を再調整できます！</b>
                </AnnotationText>
              </Stack>
              <AnnotationText>
                表紙だけで面白いかどうかを見極めるのは至難のワザ💦本をたくさん用意することでお子さんの
                <span className="emphasized">
                  「面白い！」を引き当てる確率がグッと上がる
                </span>
                んです💪
              </AnnotationText>
            </AnnotationWrapper>
            <AnnotationWrapper>
              <AnnotationTitle>
                【注意！】「いきなり本を購入」はプレッシャーになるかも😣
              </AnnotationTitle>
              <AnnotationText>
                <b>「買ってもらったんだから、絶対に最後まで読まなくちゃ……」</b>
                という
                <span className="emphasized">見えないプレッシャー</span>
                には気をつけたいものです。
              </AnnotationText>
              <Stack rowGap="4px">
                <AnnotationText>
                  <span className="underline">ポイント①</span>
                  <br />
                  本が合わなかったら
                  <span className="emphasized">すぐに読むのをやめて</span>
                  次の本を読むのがおすすめ📚✨
                </AnnotationText>
                <AnnotationText>
                  <span className="underline">ポイント②</span>
                  <br />
                  <span className="emphasized">選書の回数は無制限！</span>
                  どんどんチャレンジしてどんどん読むのをやめてください😉✨
                  たくさんの本と出会えば出会うほど、お子さんに
                  <b>ピッタリな本に出会える確率がアップ</b>します！
                </AnnotationText>
              </Stack>
            </AnnotationWrapper>
          </Stack>
        </Stack>
        <Stack rowGap="12px">
          <CheckText>家の中で本の定位置は目に入るところ！</CheckText>
          <Stack rowGap="8px">
            <PlainText>
              おうちに帰ったら
              <span className="emphasized">
                バッグから本を取り出して、目に見える場所
              </span>
              に置きましょう📚✨
            </PlainText>
            <PlainText>
              リビングの机やカーペットの上など、
              <b>自然と目に入ることで手に取りやすく</b>なります👀✨
            </PlainText>
          </Stack>
          <Stack rowGap="8px">
            <PlainText>
              読んだらヨンデミー先生に感想を出しましょう🎓
              <br />
              <span className="emphasized"> 選択式の質問に答えるだけ</span>
              なので、<b>たった30秒で簡単に</b>感想を送ることができます！
            </PlainText>
            <PlainText>
              感想を送れば送るほどよりお子さんにピッタリな本が選書されるんです📚✨
            </PlainText>
          </Stack>
        </Stack>
        <Stack rowGap="12px">
          <CheckText>もし本のレベルが合わなかったら……</CheckText>
          <Stack rowGap="8px">
            <PlainText>
              最初のアンケートでうまく答えられなかったとき、読んでみたら難しすぎた・簡単すぎたときは無理に読む必要はありません☺️
              <br />
              <b>
                「無理して読まなくてもいいよ！ヨンデミー先生に新しくおすすめしてもらおう！」
              </b>
              とお声がけください！✨
              <br />
              アプリの「YL相談所」から
              <span className="emphasized">おすすめ本の難しさの調整</span>
              ができます！
            </PlainText>
          </Stack>
        </Stack>
        <Stack rowGap="12px">
          <CheckText>YL相談所の使い方</CheckText>
          <Stack rowGap="8px">
            <PlainText>①ホーム画面左上「おすすめの本」をタップ</PlainText>
            <Img src={form1Img} />
            <PlainText>②「YL相談所に行く」をタップ</PlainText>
            <Img src={form2Img} />
            <PlainText>③フォームよりご希望をチェック！</PlainText>
            <PlainText className="emphasized">
              合わない本は最後まで読む必要はありません👍
            </PlainText>
            <PlainText>
              <b>「つまらない」も立派な感想です💪</b>
            </PlainText>
            <PlainText>
              本はどれも面白くてためになるもの……でも
              <b>お子さんにとって「面白い本」</b>に
              出会わなければ意味がないんです💦
            </PlainText>
            <PlainText>
              お子さんが「お気に入りの1冊」を見つけ、読書を通した素敵なご成長ができるようヨンデミーが全力でサポートさせていただきます！✨
            </PlainText>
          </Stack>
          <AnnotationWrapper>
            <AnnotationTitle>3行まとめ！</AnnotationTitle>
            <AnnotationText>
              <b>
                ・おすすめ本は全部予約しよう！
                <br />
                ・本の定位置はおうちのリビングの机の上！
                <br />
                ・本のレベルが合わないときは、YL相談所へ行こう！
              </b>
            </AnnotationText>
          </AnnotationWrapper>
        </Stack>
      </Stack>
    </Stack>
  </Stack>
);
