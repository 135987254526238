import React from "react";
import styled from "styled-components";

export const ArrowIcon: React.VFC = () => {
  return (
    <svg width={20} height={24} viewBox="0 0 20 24" fill="none">
      <Path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.476464 1.37773C1.37242 -0.0159836 3.22856 -0.419493 4.62227 0.476464L18.6223 9.47646C19.4809 10.0285 20 10.9792 20 12C20 13.0208 19.4809 13.9715 18.6223 14.5235L4.62227 23.5235C3.22856 24.4195 1.37242 24.016 0.476464 22.6223C-0.419493 21.2286 -0.0159836 19.3724 1.37773 18.4765L11.4522 12L1.37773 5.52353C-0.0159836 4.62758 -0.419493 2.77144 0.476464 1.37773Z"
      />
    </svg>
  );
};

const Path = styled.path`
  fill: ${(props) => props.theme.colors.pri.p500};
`;
