import React from "react";
import { AskFavoriteGenre, AskFavortiteGenreFlow } from "./AskFavoriteGenre";
import { SenseiDialog1 } from "./SenseiDialog_1";
import { YondemyStory } from "./YondemyStory";
import { BookSelectionContainer } from "./BookSelection/container";
import { FirstSurvey } from "./types";
import { RecoverModal } from "./RecoverModal";
import { RegisterAdditionalUserContainer as _RegisterUserContainer } from "~/pages/RegisterUser/registerAdditionalUserContainer";
import { SenseiDialog2 } from "./SenseiDialog_2";
import { useHistory } from "react-router";
import { useAudio } from "~/utils/hooks/useAudio";
import { StartScreen } from "./StartScreen";
import { PassageYL, DifficultyYL } from "./YLCheck/types";
import { SimpleYLCheck } from "./YLCheck/SimpleYLCheck";
import { DetailedYLCheck } from "./YLCheck/DetailedYLCheck";
import {
  DetailedFirstRecommendEnqueteDifficulties,
  DetailedFirstRecommendEnqueteDifficulty,
  SampleDifficultyPerception,
} from "~/generated/graphql";
import { SentenceOrder } from "./YLCheck/DetailedYLCheck/const";

//NOTE: 2人目以降の登録の場合のcontainer

export const ADDITIONAL_USER_DATA = "additionalUserData";

const pageFlows = [
  "StartScreen",
  "YondemyStory",
  "SenseiDialog1",
  "AskFavoriteGenre",
  "YLCheck",
  "SenseiDialog2",
  "BookSelection",
  "CreateUser",
] as const;

type PageFlow = typeof pageFlows[number];

//TODO: 名前をTempRegisterとかのいい感じの名前に変える
export const RegisterAdditionalUserContainer: React.FC = () => {
  const [pageFlow, setPageFlow] = React.useState<PageFlow>(pageFlows[0]);
  const [firstSurvey, setFirstSurvey] = React.useState<FirstSurvey>({});
  const [difficulties, setDifficulties] = React.useState<
    Record<PassageYL, DifficultyYL>
  >({
    15: undefined,
    20: undefined,
    25: undefined,
    30: undefined,
    35: undefined,
    40: undefined,
    45: undefined,
    50: undefined,
  });

  const defaultDetailDifficulties: Record<
    PassageYL,
    DetailedFirstRecommendEnqueteDifficulties
  > = {
    15: undefined,
    20: undefined,
    25: undefined,
    30: undefined,
    35: undefined,
    40: undefined,
    45: undefined,
    50: undefined,
  };

  const [detailDifficulties, setDetailDifficulties] = React.useState<
    Record<PassageYL, DetailedFirstRecommendEnqueteDifficulties>
  >(defaultDetailDifficulties);

  const [passageYL, setPassageYL] = React.useState<PassageYL>(undefined);
  const [YLCheckHistory, setYLCheckHistory] = React.useState<PassageYL[]>([]);
  const [YLCheckLastAnswer, setYLCheckLastAnswer] =
    React.useState<DifficultyYL>(undefined);
  const [
    detailSampleDifficultyPerception,
    setDetailSampleDifficultyPerception,
  ] = React.useState<SampleDifficultyPerception | undefined>(undefined);
  const [detailSuitableYL, setDetailSuitableYL] = React.useState<
    PassageYL | undefined
  >(undefined);
  const [isOpenRecoverModal, setIsOpenRecoverModal] = React.useState<boolean>(
    localStorage.getItem(ADDITIONAL_USER_DATA)
      ? JSON.parse(localStorage.getItem(ADDITIONAL_USER_DATA) || "{}")
          .pageFlow !== "StartScreen" &&
          JSON.parse(localStorage.getItem(ADDITIONAL_USER_DATA) || "{}")
            .pageFlow !== "YondemyStory"
      : false
  );
  const [senseiDialogIndex, setSenseiDialogIndex] = React.useState<number>(0);
  const [askFavoriteGenreflow, setAskFavoriteGenreFlow] =
    React.useState<AskFavortiteGenreFlow>("AskMostFavorite");

  const { play } = useAudio({
    preload: ["light", "searchBook", "initializeAudio"],
  });

  const current = pageFlows.indexOf(pageFlow);
  const history = useHistory();

  const onRestore = React.useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      play("initializeAudio");
      let _tmpAdditionalUserData: string | null = null;
      try {
        _tmpAdditionalUserData = localStorage.getItem(ADDITIONAL_USER_DATA);
      } catch (e) {
        console.error(e);
      }
      if (!_tmpAdditionalUserData) {
        return;
      }
      const _additionalUserData = JSON.parse(_tmpAdditionalUserData);
      setPageFlow(_additionalUserData.pageFlow);
      setFirstSurvey(_additionalUserData.firstSurvey);
      setDifficulties(_additionalUserData.difficulties);
      setDetailDifficulties(_additionalUserData.detailDifficulties);
      setPassageYL(_additionalUserData.passageYL);
      setYLCheckHistory(_additionalUserData.YLCheckHistory);
      setYLCheckLastAnswer(_additionalUserData.YLCheckLastAnswer);
      setDetailSampleDifficultyPerception(
        _additionalUserData.detailSampleDifficultyPerception
      );
      setDetailSuitableYL(_additionalUserData.detailSuitableYL);
      setIsOpenRecoverModal(false);
    },
    [
      play,
      setPageFlow,
      setFirstSurvey,
      setDifficulties,
      setDetailDifficulties,
      setPassageYL,
      setYLCheckHistory,
      setYLCheckLastAnswer,
      setDetailSampleDifficultyPerception,
      setDetailSuitableYL,
      setIsOpenRecoverModal,
    ]
  );

  React.useEffect(() => {
    if (isOpenRecoverModal) {
      return;
    }

    localStorage.setItem(
      ADDITIONAL_USER_DATA,
      JSON.stringify({
        pageFlow,
        firstSurvey,
        difficulties,
        detailDifficulties,
        passageYL,
        YLCheckHistory,
        YLCheckLastAnswer,
        detailSampleDifficultyPerception,
        detailSuitableYL,
      })
    );
  }, [
    pageFlow,
    firstSurvey,
    difficulties,
    detailDifficulties,
    passageYL,
    YLCheckHistory,
    YLCheckLastAnswer,
    isOpenRecoverModal,
    detailSampleDifficultyPerception,
    detailSuitableYL,
  ]);

  const handleNext = React.useCallback(() => {
    setPageFlow(pageFlows[current + 1]);
    window.gtag("event", "world_view_onboarding", {
      event_category: "additional_user",
      event_label: pageFlow,
      value: 1,
    });
  }, [setPageFlow, pageFlow, current]);

  const updateFirstSurvey = React.useCallback(
    (newFirstSurvey: FirstSurvey) => {
      setFirstSurvey({ ...firstSurvey, ...newFirstSurvey });
    },
    [firstSurvey, setFirstSurvey]
  );

  const onSelectDifficulty = React.useCallback(
    (level: PassageYL, difficulty: DifficultyYL) => {
      setDifficulties({ ...difficulties, [level]: difficulty });
    },
    [setDifficulties, difficulties]
  );

  const onSelectDetailDifficulty = React.useCallback(
    (
      level: PassageYL,
      difficulty: DetailedFirstRecommendEnqueteDifficulty,
      order?: SentenceOrder // 指定しない場合、firstとsecondの両方に値を入れる
    ) => {
      if (order) {
        setDetailDifficulties({
          ...detailDifficulties,
          [level]: detailDifficulties[level]
            ? { ...detailDifficulties[level], [order]: difficulty }
            : {
                first: order === "first" ? difficulty : undefined,
                second: order === "second" ? difficulty : undefined,
              },
        });
      } else {
        setDetailDifficulties({
          ...detailDifficulties,
          [level]: {
            first: difficulty,
            second: difficulty,
          },
        });
      }
    },
    [setDetailDifficulties, detailDifficulties]
  );

  const onSimpleYLCheckBack = React.useCallback(() => {
    const prevLevel: PassageYL = YLCheckHistory[YLCheckHistory.length - 1];
    const prevSelectedDifficulty: DifficultyYL | undefined =
      YLCheckHistory.length >= 2
        ? difficulties[YLCheckHistory[YLCheckHistory.length - 2]]
        : undefined;

    if (YLCheckHistory.length === 0) {
      return;
    }

    setPassageYL(prevLevel);
    setDifficulties((difficulties) => {
      return { ...difficulties, [prevLevel]: undefined };
    });
    setYLCheckHistory((currentHistory) =>
      currentHistory.slice(0, YLCheckHistory.length - 1)
    );
    setYLCheckLastAnswer(prevSelectedDifficulty);
  }, [YLCheckHistory, difficulties]);

  return (
    <>
      {isOpenRecoverModal ? (
        <RecoverModal
          onRestore={onRestore}
          onClose={() => setIsOpenRecoverModal(false)}
        />
      ) : (
        <>
          {pageFlow === "StartScreen" && <StartScreen onNext={handleNext} />}
          {pageFlow === "YondemyStory" && (
            <YondemyStory
              onCompleted={handleNext}
              onBack={() => setPageFlow("StartScreen")}
            />
          )}
          {pageFlow === "SenseiDialog1" && (
            <SenseiDialog1
              firstSurvey={firstSurvey}
              updateFirstSurvey={updateFirstSurvey}
              onNext={handleNext}
              index={senseiDialogIndex}
              setIndex={setSenseiDialogIndex}
              onBack={() => setPageFlow("YondemyStory")}
            />
          )}
          {pageFlow === "AskFavoriteGenre" &&
            (firstSurvey.userNickname ? (
              <AskFavoriteGenre
                onNextPage={handleNext}
                nickname={firstSurvey.userNickname}
                firstSurvey={firstSurvey}
                updateFirstSurvey={updateFirstSurvey}
                flow={askFavoriteGenreflow}
                setFlow={setAskFavoriteGenreFlow}
                onBack={() => setPageFlow("SenseiDialog1")}
              />
            ) : (
              setPageFlow("SenseiDialog1")
            ))}
          {pageFlow === "YLCheck" &&
            (firstSurvey.ylCheckType === "Simple" ? (
              <SimpleYLCheck
                onSelectDifficulty={onSelectDifficulty}
                onNext={handleNext}
                passageYL={passageYL}
                updatePassageYL={(passageYL: PassageYL) =>
                  setPassageYL(passageYL)
                }
                history={YLCheckHistory}
                updateHistory={(history: PassageYL[]) =>
                  setYLCheckHistory(history)
                }
                lastAnswer={YLCheckLastAnswer}
                updateLastAnswer={(lastAnswer: DifficultyYL) =>
                  setYLCheckLastAnswer(lastAnswer)
                }
                onSimpleYLCheckBack={onSimpleYLCheckBack}
                onBack={() => setPageFlow("AskFavoriteGenre")}
              />
            ) : (
              <DetailedYLCheck
                onResetDetailDifficulty={() =>
                  setDetailDifficulties(defaultDetailDifficulties)
                }
                onSelectDetailDifficulty={onSelectDetailDifficulty}
                onNext={handleNext}
                setDetailSampleDifficultyPerception={
                  setDetailSampleDifficultyPerception
                }
                setDetailSuitableYL={setDetailSuitableYL}
                onBack={() => setPageFlow("AskFavoriteGenre")}
              />
            ))}
          {pageFlow === "SenseiDialog2" && (
            <SenseiDialog2 firstSurvey={firstSurvey} onNext={handleNext} />
          )}
          {pageFlow === "BookSelection" && (
            <BookSelectionContainer onNext={handleNext} />
          )}
          {pageFlow === "CreateUser" && firstSurvey.ylCheckType && (
            <_RegisterUserContainer
              sampleDifficultyPerception={detailSampleDifficultyPerception}
              suitableYL={detailSuitableYL}
              firstSurvey={firstSurvey}
              difficulties={difficulties}
              detailDifficulties={detailDifficulties}
              onNext={(userId) =>
                history.push("/complete_register", {
                  userId: userId,
                })
              }
            />
          )}
        </>
      )}
    </>
  );
};
