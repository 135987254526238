import styled from "styled-components";

export const QuestionBox = styled.div`
  padding: 20px;
  background-color: ${({ theme }) => theme.colors.base.white};
  border-radius: 16px;

  color: ${({ theme }) => theme.colors.tex.t700};
  font-size: 16px;
  line-height: 160%;
`;

export const SubText = styled.div`
  color: ${({ theme }) => theme.colors.tex.t300};
  font-size: 12px;
  line-height: 160%;
`;
