import React from "react";
import styled from "styled-components";

export type ProgressBarProps = {
  progress: number;
};

export const ProgressBar = ({ progress }: ProgressBarProps): JSX.Element => {
  return (
    <ProgressWrapper>
      <Bar progress={progress} />
    </ProgressWrapper>
  );
};

const ProgressWrapper = styled.div`
  background: ${({ theme }) => theme.colors.pri.p300};
  width: 100%;
  height: 10px;
  border-radius: 10px;
`;

const Bar = styled.div<{ progress: number }>`
  background: ${({ theme }) => theme.colors.pri.p600};
  width: ${({ progress }) => progress}%;
  height: 10px;
  border-radius: 10px;
`;
