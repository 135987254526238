import React from "react";
import { Heart } from "./heart";
import { Row } from "~/component/atoms/layout/Row";

const heartNum = 6;

export const HeartLine: React.FC<{ filledHearts: number }> = ({
  filledHearts,
}) => {
  return (
    <Row justifyContent="center" columnGap="8px">
      {[...new Array(heartNum)].map((_, i) => (
        <Heart key={i} isFilled={i < filledHearts} />
      ))}
    </Row>
  );
};
