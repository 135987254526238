import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export type PopupColorTheme = "orange" | "red" | "green";

type PopupProps = {
  children: React.ReactNode;
  onClose: (event: React.MouseEvent) => void;
  color?: PopupColorTheme;
};

export const Popup: React.FC<PopupProps> = ({
  children,
  onClose,
  color = "orange",
}) => {
  return (
    <Container color={color}>
      {children}
      <Downer>
        <Button onClick={onClose}>
          <FontAwesomeIcon
            icon={"times-circle"}
            style={{ marginRight: "4px", width: "13px" }}
          />
          <CloseMessage>{"とじる"}</CloseMessage>
        </Button>
      </Downer>
    </Container>
  );
};

const Container = styled.div<{ color: PopupColorTheme }>`
  border-radius: 10px;
  padding: 10px;
  box-shadow: 0 0 12px 0 rgba(0, 0, 0, 0.2);
  background-color: ${({ color, theme }) => {
    switch (color) {
      case "red":
        return theme.colors.red.r300;
      case "green":
        return theme.colors.green.g300;
      default:
        return theme.colors.pri.p400;
    }
  }};
`;

const Downer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
`;

const Button = styled.button`
  padding: 0;
  border: none;
  background: none;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${(props) => props.theme.colors.base.white};
`;

const CloseMessage = styled.p`
  color: white;
  font-size: 10px;
  font-weight: bold;
  font-family: inherit;
  margin: 0;
`;
