export const extendedPassageYLs = [15, 20, 25, 30, 35, 40, 45, 50, 51] as const; // 表示の都合上51を追加
export type PassageYL = typeof extendedPassageYLs[number];

/* eslint-disable no-irregular-whitespace */

type TextAndCaption = {
  text: string;
  caption: string;
};

export const passageDict: Record<PassageYL, TextAndCaption> = {
  15: {
    text: `そこで、かあさんぐまは、
くまくんに、いいものを
こしらえてやりました。
「ほうら、いいものが
できましたよ。
かぶってごらん。」`,
    caption: "E・H・ミナリック『こぐまのくまくん』",
  },
  20: {
    text: `パパが　アイスの　ふくろを
うらがえしてみると、そこには
「アイスおうこく」と、
かいてありました。`,
    caption: "きたじま　ごうき『あたりかも』",
  },
  25: {
    text: `「やれやれ、あれは きつねの子
だったのかい、とんと
わからなかったよ。そういえば
みたことのない子だったなぁ。」
先生は めがねを ふきふきいいました。`,
    caption: "松谷みよ子『ジャムねこさん』",
  },
  30: {
    text: `ヤギのヤギマロ先生は、
山のてっぺんにひとりでくらしている、
お習字の先生です。
村の動物たちからすると、先生は、
まるでえらくて、ちょっとこわいかんじ
のするひとなのでした。`,
    caption: "たかどの　ほうこ『へろりのだいふく』",
  },
  35: {
    text: `トムは、だれの飼いネコでもありません
でした。だから、さむい夜に暖炉のまえで
ねたり、ミルクをお皿でもらうようなこと
は、いちどだってありませんでした。`,
    caption: "南部和也『ネコのタクシー』",
  },
  40: {
    text: `マジヒコ氏の席に、白い服を着た人が背中を向けて、プカンと浮いたような感じですわっていたのだ。マジヒコ氏は、びっくりしたあとはたいへん不愉快なきもちになった。`,
    caption: "たかどの　ほうこ『紳士とオバケ氏』",
  },
  45: {
    text: `学校で、創也と話すことは少ない。創也は図書室にいることが多いし、教室にいてもひとりで本を読んでいる。そして、ぼくはぼくで、達夫たちとテレビやマンガの話。`,
    caption: "はやみねかおる『都会のトムソーヤ』",
  },
  50: {
    text: `夜明け、メイ・タックが馬にのってトゥリーギャップ村のはずれにある森にむかった。息子のマイルズとジェシィに会うために、十年に一回だけそうしている。`,
    caption: "ナタリー・バビット『時をさまようタック』",
  },
  51: {
    text: `こんなふうに、朝から雲一つない文句なしの晴天に恵まれていると、それが最初から当たり前のように思えて、すぐにそのありがたみなど忘れてしまう。だが、もし今のお天気がどんよりした曇り空だったらどうだろう。`,
    caption: "恩田 陸『夜のピクニック』",
  },
};
/* eslint-enable no-irregular-whitespace */
