const primitiveColors = {
  orange: {
    o100: "#FEEAC6",
    o200: "#FAD189",
    o300: "#F9C364",
    o400: "#F3AE36",
    o500: "#EE9F17",
    o600: "#E8920A",
    o700: "#E28B02",
  },
  blue: {
    b100: "#CCE1F4",
    b200: "#80B3E4",
    b300: "#3386D4",
    b400: "#0068C9",
    b500: "#005EB5",
    b600: "#0053A1",
    b700: "#003E79",
  },
  green: {
    g100: "#C3E9C0",
    g200: "#9BDB95",
    g300: "#79CF72",
    g400: "#69C361",
    g500: "#5EBC56",
    g600: "#55B34D",
  },
  red: {
    r100: "#F7BCBC",
    r200: "#F39A9A",
    r300: "#ED6868",
    r400: "#EB5757",
    r500: "#D34E4E",
    r600: "#BE3E3E",
  },
  sky: {
    s100: "#A4D9F6",
    s200: "#86CCF3",
    s300: "#68BFF0",
    s400: "#5FB6E7",
    s500: "#4FA6D7",
    s600: "#459CCD",
  },
  violet: {
    v100: "#EDD4F5",
    v200: "#D9A5F7",
    v300: "#C17CF8",
    v400: "#AA57EB",
    v500: "#9E4BDF",
    v600: "#9643D7",
  },
  wood: {
    w100: "#E6DAD3",
    w200: "#CDB5A8",
    w300: "#B3907C",
    w400: "#A1745B",
    w500: "#814625",
    w600: "#723E21",
  },
  yellow: {
    y100: "#B4EECC",
    y200: "#82E3AA",
    y300: "#51D888",
    y400: "#06C755",
    y500: "#05B34C",
    y600: "#059F44",
  },
  line: {
    line100: "#B4EECC",
    line200: "#82E3AA",
    line300: "#51D888",
    line400: "#06C755",
    line500: "#05B34C",
    line600: "#059F44",
  },
  tex: {
    t100: "#CECECE",
    t200: "#B4B4B4",
    t300: "#828282",
    t400: "#6E6E6E",
    t500: "#505050",
    t600: "#464646",
    t700: "#323232",
    t800: "#1E1E1E",
    t900: "#000000",
  },
  monotone: {
    m0: "#FFFFFF",
    m20: "#F6F6F6",
    m40: "#EFEDEB",
    m90: "#D5D2CD",
    m100: "#CECECE",
    m200: "#B4B4B4",
    m300: "#828282",
    m400: "#6E6E6E",
    m500: "#505050",
    m600: "#464646",
    m700: "#323232",
    m800: "#1E1E1E",
    m900: "#000000",
  },
  transparent: {
    t45: "rgba(0, 0, 0, 0.45)",
  },
};

const semanticColors = {
  primary: {
    main: primitiveColors.orange.o500,
    plus1: primitiveColors.orange.o600,
    minus1: primitiveColors.orange.o400,
  },
  secondary: {
    main: primitiveColors.blue.b400,
    plus1: primitiveColors.blue.b500,
    plus2: primitiveColors.blue.b600,
    minus1: primitiveColors.blue.b300,
  },
  alert: {
    main: primitiveColors.red.r400,
    plus1: primitiveColors.red.r500,
    minus1: primitiveColors.red.r300,
  },
  text: {
    heading: primitiveColors.tex.t700,
    body: primitiveColors.tex.t500,
    label: primitiveColors.tex.t300,
    unselected: primitiveColors.tex.t300,
    placeholder: primitiveColors.tex.t200,
  },
  layout: {
    backgroundMain: primitiveColors.monotone.m20,
    backgroundBox: primitiveColors.monotone.m0,
    overlay: primitiveColors.transparent.t45,
    border: primitiveColors.monotone.m40,
    borderPlus1: primitiveColors.monotone.m90,
  },
  line: {
    main: primitiveColors.line.line400,
  },
};

export const defaultColors = {
  primitive: primitiveColors,
  semantic: semanticColors,

  /**
   * @deprecated use `semantic.*` instead
   **/
  base: {
    white: "#FFFFFF",
    paleOrange: "#FFFDFB",
    ashGray: "#F6F6F6",
    lightGray: "#EFEDEB",
    gray: "#D5D2CD",
    darkGray: "#7A7876",
    overlay: "rgba(0,0,0,0.45)",
    background: "#F6F6F6",
  },
  /**
   * @deprecated use `semantic.pri` instead
   **/
  pri: {
    p100: "#FEEAC6",
    p200: "#FAD189",
    p300: "#F9C364",
    p400: "#F3AE36",
    p500: "#EE9F17",
    p600: "#E8920A",
    p700: "#E28B02",
  },
  /**
   * @deprecated use `semantic.sec` instead
   **/
  sec: {
    s100: "#CCE1F4",
    s200: "#80B3E4",
    s300: "#3386D4",
    s400: "#0068C9",
    s500: "#005EB5",
    s600: "#0053A1",
    s700: "#003E79",
  },
  /**
   * @deprecated use `semantic.tex` instead
   **/
  tex: {
    t100: "#CECECE",
    t200: "#B4B4B4",
    t300: "#828282",
    t400: "#6E6E6E",
    t500: "#505050",
    t600: "#464646",
    t700: "#323232",
    t800: "#1E1E1E",
    t900: "#000000",
  },
  /**
   * @deprecated use `primitive.*` instead
   **/
  green: {
    g100: "#C3E9C0",
    g200: "#9BDB95",
    g300: "#79CF72",
    g400: "#69C361",
    g500: "#5EBC56",
    g600: "#55B34D",
  },
  /**
   * @deprecated use `primitive.*` instead
   **/
  red: {
    r100: "#F7BCBC",
    r200: "#F39A9A",
    r300: "#ED6868",
    r400: "#EB5757",
    r500: "#D34E4E",
    r600: "#BE3E3E",
  },
  /**
   * @deprecated use `primitive.*` instead
   **/
  sky: {
    s100: "#A4D9F6",
    s200: "#86CCF3",
    s300: "#68BFF0",
    s400: "#5FB6E7",
    s500: "#4FA6D7",
    s600: "#459CCD",
  },
  /**
   * @deprecated use `primitive.*` instead
   **/
  violet: {
    v100: "#EDD4F5",
    v200: "#D9A5F7",
    v300: "#C17CF8",
    v400: "#AA57EB",
    v500: "#9E4BDF",
    v600: "#9643D7",
  },
  /**
   * @deprecated use `primitive.*` instead
   **/
  wood: {
    w100: "#E6DAD3",
    w200: "#CDB5A8",
    w300: "#B3907C",
    w400: "#A1745B",
    w500: "#814625",
    w600: "#723E21",
  },
} as const;

type Primitive = string | number;

type GenericObject = Record<Primitive, unknown>;

type Union<
  L extends unknown | undefined,
  R extends unknown | undefined
> = L extends undefined
  ? R extends undefined
    ? undefined
    : R
  : R extends undefined
  ? L
  : L | R;

/**
 * NestedPaths
 * Get all the possible paths of an object
 * @example
 * type Keys = NestedPaths<{ a: { b: { c: string } }>
 * // 'a' | 'a.b' | 'a.b.c'
 */
type NestedPaths<
  T extends GenericObject,
  Prev extends string = "",
  Path extends string = ""
> = {
  [K in Extract<keyof T, string>]: T[K] extends GenericObject
    ? NestedPaths<T[K], Union<Prev, `${Path}${K}.`>, `${Path}${K}.`>
    : Union<Union<Prev, `${Path}${K}`>, `${Path}${K}`>;
}[Extract<keyof T, string>];

type TypeOfColor = typeof defaultColors;
export type AllColorTheme = Exclude<
  NestedPaths<TypeOfColor>,
  keyof TypeOfColor
>;
