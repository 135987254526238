import React, { useEffect, FC } from "react";
import { Redirect, useHistory } from "react-router";
import { CurrentUserContext } from "~/contexts/CurrentUserContext";
import { googleAnalyticsLogin } from "~/utils/googleAnalytics";
import { Loading } from "../component/atoms/progress_indicator/loading";
import { TopTemplate } from "../component/templates/top";
import { useSubscriptionsQuery } from "../generated/graphql";
import { SubscriptionsClientContext } from "~/contexts/CustomApolloProvider";
import { A2HSContext } from "~/contexts/A2HSContext";

export const Top: FC = () => {
  const { subscriptionsClient } = React.useContext(SubscriptionsClientContext);
  const { loading, error, data } = useSubscriptionsQuery({
    client: subscriptionsClient,
    fetchPolicy: "cache-and-network",
  });
  const { showPwaTutorial } = React.useContext(A2HSContext);

  const {
    currentUser,
    loading: loadingCurrentUser,
    error: errorCurrentUser,
  } = React.useContext(CurrentUserContext);

  const history = useHistory();

  useEffect(() => {
    if (showPwaTutorial) {
      history.push("/pwa_tutorial");
    }
  }, [showPwaTutorial, history]);

  useEffect(() => {
    if (data?.parent) {
      googleAnalyticsLogin(`${data.parent.id}`);
    }
  }, [data]);

  if (loading || loadingCurrentUser) return <Loading />;

  if (error || errorCurrentUser) return <>fail to fetch info</>;

  if (!data || !currentUser) return <Loading />;

  if (data && data.parent.children.length > 0) {
    return <TopTemplate data={data} currentUser={currentUser} />;
  } else {
    return <Redirect to={{ pathname: "/register_first_user" }} />;
  }
};
