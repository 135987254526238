import styled from "styled-components";

export const WhiteBox = styled.div`
  width: 100%;
  padding: 20px;
  border-radius: 14px;
  background-color: ${(props) => props.theme.colors.base.white};
`;

export const ContentsContainer = styled.div`
  padding: 15px 0;
  .emphasis {
    border: 2px solid ${({ theme }) => theme.colors.pri.p400};
  }
`;

export const HeadContainer = styled.div`
  margin-bottom: 12px;
`;

export const FirstHeadContainer = styled(HeadContainer)`
  padding-top: 100px;
`;
