import React from "react";
import styled from "styled-components";
import { Stack } from "~/component/atoms/layout/Stack";
import { PrimaryButtonWithSound } from "../../Button/PrimaryButtonWithSound";
import { CONTENT_HEIGHT, CONTENT_WIDTH } from "../../constants";
import { Conversation } from "../../Conversation";
import { Message, MessageProps } from "../../Message";
import { ButtonProps } from "../types";

export type SelectQuestionProps = {
  messageProps?: MessageProps;
  buttons: ButtonProps[];
  imgSrc: string;
  scale?: number;
};

export const SelectQuestion: React.FC<SelectQuestionProps> = ({
  messageProps,
  buttons,
  imgSrc,
  scale = 1.0,
}) => {
  const Dialog: React.ReactNode = React.useMemo(() => {
    return (
      <_Wrapper>
        <_Container>
          {messageProps && (
            <_MessageWrapper>
              <Message
                message={messageProps.message}
                speaker={messageProps.speaker}
                showTriangle={false}
              />
            </_MessageWrapper>
          )}
          <Stack rowGap="8px">
            {buttons.map((button, index) => (
              <_ButtonWrapper key={index}>
                <PrimaryButtonWithSound onClick={button.onClick}>
                  {button.label}
                </PrimaryButtonWithSound>
              </_ButtonWrapper>
            ))}
          </Stack>
        </_Container>
      </_Wrapper>
    );
  }, [messageProps, buttons]);

  return <Conversation scale={scale} imgSrc={imgSrc} dialog={Dialog} overlay />;
};

const _Wrapper = styled.div`
  position: absolute;
  top: 0;
`;

const _Container = styled.div`
  width: ${CONTENT_WIDTH}px;
  height: ${CONTENT_HEIGHT}px;

  margin: 0 auto;
  padding-bottom: 84px;

  display: flex;
  flex-direction: column;
  row-gap: 20px;

  padding-bottom: 80px;
  box-sizing: border-box;

  justify-content: flex-end;
`;

const _ButtonWrapper = styled.div`
  padding: 0 24px;
  width: 100%;
`;

const _MessageWrapper = styled.div`
  width: 100%;

  display: flex;
  justify-content: center;
`;
