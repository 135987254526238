import React from "react";
import { PagiableList } from "~/component/molecules/list/PagiableList";
import {
  ListItemProps,
  PagiableListProps,
} from "~/component/molecules/list/PagiableList/types";
import {
  BookListViewerItem,
  BookListViewerItemProps,
} from "./BookListViewerItem";

type BaseBookBuyLinkListProps = PagiableListProps<BookListViewerItemProps>;

export type BookBuyLinkListProps = Omit<
  BaseBookBuyLinkListProps,
  "renderListItem"
>;

export const BookBuyLinkList: React.VFC<BookBuyLinkListProps> = ({
  currentCount,
  items,
  itemTotal,
  itemsPerPage,
  onPageChange,
  renderEmptyComponent,
}) => {
  const getBookCoverLink = (link?: string): string => {
    return link ? link : "/img/read_log_noimage.jpg";
  };

  return (
    <PagiableList
      currentCount={currentCount}
      items={items}
      itemTotal={itemTotal}
      itemsPerPage={itemsPerPage}
      onPageChange={onPageChange}
      rowGap="16px"
      renderListItem={({ item }: ListItemProps<BookListViewerItemProps>) => {
        const {
          recommendId,
          title,
          imageUrl,
          isbn,
          affiliateLink,
          availableBookType,
          myLibraryName,
          isNew,
          procureStatusManager,
          handleEraseNewTag,
          handleRemoveItem,
          onClickDetail,
        } = item;
        return (
          <BookListViewerItem
            key={item.recommendId}
            recommendId={recommendId}
            title={title}
            imageUrl={getBookCoverLink(imageUrl)}
            isNew={isNew}
            procureStatusManager={procureStatusManager}
            isbn={isbn}
            affiliateLink={affiliateLink}
            availableBookType={availableBookType}
            myLibraryName={myLibraryName}
            handleEraseNewTag={handleEraseNewTag}
            handleRemoveItem={handleRemoveItem}
            onClickDetail={onClickDetail}
          />
        );
      }}
      renderEmptyComponent={renderEmptyComponent}
    />
  );
};
