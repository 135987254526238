import React, { FC } from "react";

export const VeryEasyIcon: FC = () => (
  <svg
    width="37"
    height="35"
    viewBox="0 0 37 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M16.5151 1.21751L11.9991 10.2672L1.89498 11.7231C0.0830234 11.9828 -0.643144 14.1905 0.670873 15.455L7.98095 22.4952L6.25199 32.4402C5.94077 34.2378 7.85647 35.5844 9.46095 34.7436L18.5 30.0479L27.5391 34.7436C29.1435 35.5775 31.0592 34.2378 30.748 32.4402L29.0191 22.4952L36.3291 15.455C37.6431 14.1905 36.917 11.9828 35.105 11.7231L25.0009 10.2672L20.4849 1.21751C19.6757 -0.395569 17.3312 -0.416074 16.5151 1.21751Z"
      fill="#EE9F17"
    />
  </svg>
);

export const EasyIcon: FC = () => (
  <svg
    width="38"
    height="38"
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M19 34C27.2843 34 34 27.2843 34 19C34 10.7157 27.2843 4 19 4C10.7157 4 4 10.7157 4 19C4 27.2843 10.7157 34 19 34ZM19 38C29.4934 38 38 29.4934 38 19C38 8.50659 29.4934 0 19 0C8.50659 0 0 8.50659 0 19C0 29.4934 8.50659 38 19 38Z"
      fill="#EE9F17"
    />
  </svg>
);

export const NormalIcon: FC = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="15.5" cy="15.5" r="15.5" fill="#69C461" />
  </svg>
);

export const DifficultIcon: FC = () => (
  <svg
    width="33"
    height="30"
    viewBox="0 0 33 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.9019 1.5C15.0566 -0.500003 17.9434 -0.499997 19.0981 1.5L32.5215 24.75C33.6762 26.75 32.2328 29.25 29.9234 29.25H3.07661C0.767213 29.25 -0.676157 26.75 0.478544 24.75L13.9019 1.5Z"
      fill="#3386D4"
    />
  </svg>
);

export const VeryDifficultIcon: FC = () => (
  <svg
    width="33"
    height="31"
    viewBox="0 0 33 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M22.5655 15.5L31.6627 6.68702C32.7791 5.60554 32.7791 3.8521 31.6627 2.76974L29.6409 0.811108C28.5245 -0.270369 26.7145 -0.270369 25.5973 0.811108L16.5 9.62409L7.40273 0.811108C6.28636 -0.270369 4.47636 -0.270369 3.35909 0.811108L1.33727 2.76974C0.220909 3.85122 0.220909 5.60466 1.33727 6.68702L10.4345 15.5L1.33727 24.313C0.220909 25.3945 0.220909 27.1479 1.33727 28.2303L3.35909 30.1889C4.47545 31.2704 6.28636 31.2704 7.40273 30.1889L16.5 21.3759L25.5973 30.1889C26.7136 31.2704 28.5245 31.2704 29.6409 30.1889L31.6627 28.2303C32.7791 27.1488 32.7791 25.3953 31.6627 24.313L22.5655 15.5Z"
      fill="#005EB5"
    />
  </svg>
);

export const VeryEasyIconSmall: FC = () => (
  <svg
    width="17"
    height="16"
    viewBox="0 0 17 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.58804 0.556577L5.51309 4.69357L0.870668 5.35911C0.0381459 5.47784 -0.295498 6.48709 0.308239 7.06515L3.66692 10.2835L2.87253 14.8298C2.72954 15.6516 3.60973 16.2671 4.34692 15.8828L8.5 13.7362L12.6531 15.8828C13.3903 16.264 14.2705 15.6516 14.1275 14.8298L13.3331 10.2835L16.6918 7.06515C17.2955 6.48709 16.9619 5.47784 16.1293 5.35911L11.4869 4.69357L9.41196 0.556577C9.04019 -0.180831 7.96299 -0.190205 7.58804 0.556577Z"
      fill="#EE9F17"
    />
  </svg>
);

export const EasyIconSmall: FC = () => (
  <svg
    width="17"
    height="17"
    viewBox="0 0 17 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.28652 15.0104C11.8996 15.0104 14.8285 12.0814 14.8285 8.4684C14.8285 4.85536 11.8996 1.92641 8.28652 1.92641C4.67348 1.92641 1.74453 4.85536 1.74453 8.4684C1.74453 12.0814 4.67348 15.0104 8.28652 15.0104ZM8.28652 16.7549C12.863 16.7549 16.573 13.0449 16.573 8.4684C16.573 3.89189 12.863 0.181885 8.28652 0.181885C3.71 0.181885 0 3.89189 0 8.4684C0 13.0449 3.71 16.7549 8.28652 16.7549Z"
      fill="#EE9F17"
    />
  </svg>
);

export const NormalIconSmall: FC = () => (
  <svg
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="6.5" cy="6.5" r="6.5" fill="#69C461" />
  </svg>
);

export const DifficultIconSmall: FC = () => (
  <svg
    width="15"
    height="13"
    viewBox="0 0 15 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.31387 0.666665C6.84104 -0.222223 8.15896 -0.222221 8.68613 0.666668L14.8145 11C15.3416 11.8889 14.6827 13 13.6283 13H1.37166C0.317328 13 -0.34163 11.8889 0.185539 11L6.31387 0.666665Z"
      fill="#3386D4"
    />
  </svg>
);

export const VeryDifficultIconSmall: FC = () => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.65364 7L13.6337 3.01994C14.1221 2.53153 14.1221 1.73966 13.6337 1.25085L12.7491 0.366307C12.2607 -0.122102 11.4689 -0.122102 10.9801 0.366307L7 4.34636L3.01994 0.366307C2.53153 -0.122102 1.73966 -0.122102 1.25085 0.366307L0.366307 1.25085C-0.122102 1.73926 -0.122102 2.53114 0.366307 3.01994L4.34636 7L0.366307 10.9801C-0.122102 11.4685 -0.122102 12.2603 0.366307 12.7491L1.25085 13.6337C1.73926 14.1221 2.53153 14.1221 3.01994 13.6337L7 9.65364L10.9801 13.6337C11.4685 14.1221 12.2607 14.1221 12.7491 13.6337L13.6337 12.7491C14.1221 12.2607 14.1221 11.4689 13.6337 10.9801L9.65364 7Z"
      fill="#005EB5"
    />
  </svg>
);
