import React, { FC } from "react";
import styled from "styled-components";
import { RoundedBox } from "~/component/atoms/surfaces/RoundedBox";
import { Heading3 } from "~/component/atoms/text/Heading3";
import { Book } from "~/generated/graphql";
import { Text } from "~/component/atoms/text/Text";

export type BookInfoProps = {
  book: Book;
};

export const BookInfo: FC<BookInfoProps> = ({ book }) => {
  return (
    <Wrapper>
      {book.synopsis && (
        <div>
          <Label fontSize={"SM"} lineHeight={"MD"}>
            あらすじ
          </Label>
          <ValueText fontSize={"SM"} lineHeight={"MD"}>
            {book.synopsis}
          </ValueText>
        </div>
      )}
      <table>
        <tbody>
          {book.yl != null && book.yl !== 0 && (
            <OneRow type="YL" data={Math.floor(book.yl / 100)} />
          )}
          {book.author && <OneRow type={"作者"} data={book.author} />}
          {book.painter && book.painter !== "**" && (
            <OneRow type={"作画者"} data={book.painter} />
          )}
          {book.publisher && <OneRow type={"出版社"} data={book.publisher} />}
          {book.wordCount != null && book.wordCount !== 0 && (
            <OneRow type={"文字数"} data={book.wordCount} />
          )}
          {book.pageCount != null && book.pageCount !== 0 && (
            <OneRow type={"ページ数"} data={book.pageCount} />
          )}
          {book.published && (
            <OneRow
              type={"出版年月日"}
              data={ConvertDateToYYYYMM(book.published)}
            />
          )}
          {book.isbn && (
            <OneRow
              type={"ISBN"}
              data={
                book.isbn.slice(0, 3) +
                "-" +
                book.isbn.slice(3, 4) +
                "-" +
                book.isbn.slice(4, 12) +
                "-" +
                book.isbn.slice(12, 13)
              }
            />
          )}
        </tbody>
      </table>
    </Wrapper>
  );
};

const Wrapper = styled(RoundedBox)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

type OneRowProps = {
  type: string;
  data: string | number;
};

const OneRow = ({ type, data }: OneRowProps): JSX.Element => {
  return (
    <tr>
      <KeyContainer>
        <Label fontSize="SM" lineHeight={"MD"}>
          {type}
        </Label>
      </KeyContainer>
      <ValueContainer>
        <ValueText fontSize={"SM"} lineHeight={"MD"}>
          {String(data)}
        </ValueText>
      </ValueContainer>
    </tr>
  );
};

//eslint-disable-next-line
const ConvertDateToYYYYMM = (any: any): string => {
  const date = new Date(any);
  return String(date.getFullYear()) + "年" + String(date.getMonth() + 1) + "月";
};

const Label = styled(Heading3)`
  color: ${(props) => props.theme.colors.semantic.text.placeholder};
`;

const KeyContainer = styled.td`
  width: 55%;
  max-width: 130px;
  padding: 5px 0;
`;

const ValueContainer = styled.td`
  padding: 5px 0;
`;

const ValueText = styled(Text)`
  margin: 0;
  color: ${({ theme }) => theme.colors.semantic.text.heading};
`;
