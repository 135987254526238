import * as React from "react";
import styled, { css } from "styled-components";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";

type ActionButtonProps = {
  children: React.ReactNode;
  onClick: () => void;
  disabled?: boolean;
};

type ActionOutsideLinkButtonProps = {
  children: React.ReactNode;
  href: string;
};

export const ActionButton: React.VFC<ActionButtonProps> = ({
  children,
  onClick,
  disabled = false,
}) => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick,
    label: children,
  });
  return (
    <ButtonContainer disabled={disabled} onClick={onClickWithTracking}>
      {children}
    </ButtonContainer>
  );
};

export const ActionOutsideLinkButton: React.VFC<
  ActionOutsideLinkButtonProps
> = ({ children, href }) => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick: () => {
      return;
    },
    label: children,
  });
  return (
    <OutsideLinkContainer
      href={href}
      disabled={false}
      onClick={onClickWithTracking}
      target="_blank"
      rel="noopener noreferrer"
    >
      {children}
    </OutsideLinkContainer>
  );
};

const Container = css<{ disabled: boolean }>`
  padding: 8px 20px;
  display: flex;
  align-items: center;
  border: none;
  border-radius: 30px;
  color: ${(props) => props.theme.colors.base.white};
  background-color: ${(props) => props.theme.colors.pri.p400};
  font-family: inherit;
  font-weight: bold;
  font-size: 16px;
  line-height: 160%;
  transition: all 0.2s;
  &:hover {
    cursor: pointer;
    background-color: ${(props) => props.theme.colors.pri.p500};
  }
  &:active {
    background-color: ${(props) => props.theme.colors.pri.p500};
  }
  ${(props) => props.disabled && DisabledButton}
`;

const ButtonContainer = styled.button`
  ${Container}
`;

const OutsideLinkContainer = styled.a<{ disabled: boolean }>`
  ${Container}
  &:hover, &:focus, &:active {
    color: ${(props) => props.theme.colors.base.white};
  }
`;

const DisabledButton = css`
  background-color: ${(props) => props.theme.colors.pri.p200};
  &:hover,
  &:active {
    background-color: ${(props) => props.theme.colors.pri.p200};
    cursor: initial;
  }
`;
