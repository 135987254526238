import * as React from "react";
import styled from "styled-components";
import { useButtonClickTracking } from "~/utils/googleAnalytics/useButtonClickTracking";
import {
  LargeButtonTemplateProps,
  LargeButtonTemplate,
  ButtonColors,
} from "./large_button_template";

type OutsideTransitionButtonProps = {
  children: React.ReactNode;
  href: string;
  target?: string;
  rel?: string;
  color?: ButtonColors;
  disabled?: boolean;
  onClick?: () => void;
};

export const OutsideTransitionButton: React.VFC<OutsideTransitionButtonProps> = ({
  children,
  href,
  target,
  rel,
  color = "orange",
  disabled = false,
  onClick,
}) => {
  const { onClickWithTracking } = useButtonClickTracking({
    onClick: onClick
      ? onClick
      : () => {
          return;
        },
    label: children,
  });
  const getHref = (): string => {
    if (disabled) {
      return "#";
    } else {
      return href;
    }
  };
  return (
    <OutsideLinkContainer
      href={getHref()}
      target={target}
      rel={rel}
      disabled={disabled}
      color={color}
      onClick={onClickWithTracking}
    >
      {children}
    </OutsideLinkContainer>
  );
};

const OutsideLinkContainer = styled.a<LargeButtonTemplateProps>`
  ${LargeButtonTemplate};
  &:hover,
  &:active,
  &:focus {
    color: ${(props) => props.theme.colors.base.white};
  }
`;
