import React from "react";

import {
  useChangeCreditCardMutation,
  useSubscriptionShowQuery,
} from "../../../generated/graphql";
import { UserProps } from "../../../utils/subscription_config";
import PayJpCheckoutButton, {
  resetPayjpButton,
} from "../../atoms/payjp_checkout_button";

import { PopupWrapper } from "../../templates/wrapper";
import { IndicateMutationState } from "../modal/indicate_mutation_state";
import { SubscriptionsClientContext } from "~/contexts/CustomApolloProvider";

type ChangeCreditCardProps = {
  user: UserProps;
};

const ChangeCreditCard: React.VFC<ChangeCreditCardProps> = ({ user }) => {
  const { subscriptionsClient } = React.useContext(SubscriptionsClientContext);

  const { refetch } = useSubscriptionShowQuery({
    client: subscriptionsClient,
    variables: { userId: user.id || 0 },
    skip: !user.id,
  });

  const buttonText = "お支払いカードを変更";

  const [mutationState, setMutationState] = React.useState("idle");
  const [mutationMessage, setMutationMessage] = React.useState("");

  const [changeCreditCard, { loading }] = useChangeCreditCardMutation({
    client: subscriptionsClient,
    async onCompleted() {
      await refetch();
      resetPayjpButton(buttonText);
      setMutationMessage("お支払いカードを変更しました。");
      setMutationState("success");
    },
    onError(e) {
      console.error("サーバーエラー:", e?.message);
      resetPayjpButton(buttonText);
      setMutationMessage("処理に失敗しました。もう一度お試しください。");
      setMutationState("fail");
    },
  });

  return (
    <>
      <PayJpCheckoutButton
        onTokenCreated={(payjpToken) => {
          if (!user.subscriptionId) return;
          changeCreditCard({
            variables: {
              subscriptionId: user.subscriptionId,
              payjpToken,
            },
          });
        }}
        buttonText={buttonText}
        isShown={!loading}
      />

      <PopupWrapper>
        <IndicateMutationState
          displayedMessage={mutationMessage}
          mutationState={mutationState}
          setMutationState={setMutationState}
        />
      </PopupWrapper>
    </>
  );
};

export default ChangeCreditCard;
