import React from "react";
import { BookSelectionAnimation } from "./Animation";
import { BookList } from "./flows/BookList";
import { BookInformation, BookInformationProps } from "./flows/BookInformation";

const ColorList = {
  green: "green",
  pri: "pri",
  red: "red",
  sky: "sky",
  sec: "sec",
  violet: "violet",
} as const;

export type BookColor = typeof ColorList[keyof typeof ColorList];

type Flow = "Animation" | "BookList" | "BookInformation";

export type BookSelectionProps = {
  onShowModal: () => void;
};

export const BookSelection: React.FC<BookSelectionProps> = ({
  onShowModal,
}) => {
  const [flow, setFlow] = React.useState<Flow>("Animation");
  const [bookSelectionProps, setBookSelectionProps] =
    React.useState<Pick<BookInformationProps, "bookNumber" | "color">>(
      undefined
    );

  const onNextBookList = (color: BookColor, bookNumber: number) => {
    setBookSelectionProps({ color: color, bookNumber: bookNumber });
    onShowModal();
    setFlow("BookInformation");
  };

  return (
    <>
      {flow === "Animation" && (
        <BookSelectionAnimation onNext={() => setFlow("BookList")} />
      )}
      {flow === "BookList" && <BookList onNext={onNextBookList} />}
      {flow === "BookInformation" && (
        <BookInformation
          color={bookSelectionProps.color}
          bookNumber={bookSelectionProps.bookNumber}
          onShowDetail={() => onShowModal()}
        />
      )}
    </>
  );
};
