import React from "react";
import { ParentProfileQuery } from "../../generated/graphql";
import Header from "../organisms/common/header";
import { MiddleWrapper } from "./wrapper";
import { FirstHeadContainer, ContentsContainer, HeadContainer } from "./box";
import { PageTitle } from "../atoms/heading/page_title";
import { BreadCrumb, Crumb } from "../atoms/bread_crumb";
import { ParentInfoList } from "../organisms/profile/parent_info_list";
import { TransitionButton } from "../atoms/button/transition_button";
import { ButtonContainer } from "./button_container";
import { ModalTransitionButton } from "../atoms/button/modal_transition_button";

type ProfileTemplateProps = {
  data: ParentProfileQuery;
  handleLogout: () => void;
};
export const ProfileTemplate: React.VFC<ProfileTemplateProps> = ({
  data,
  handleLogout,
}) => {
  return (
    <>
      <Header />
      <MiddleWrapper>
        <ContentsContainer>
          <FirstHeadContainer>
            <BreadCrumb>
              <Crumb.FirstList>
                <Crumb.Link to={"/"}>トップ</Crumb.Link>
              </Crumb.FirstList>
              <Crumb.List>
                <Crumb.Para>保護者さまの登録情報</Crumb.Para>
              </Crumb.List>
            </BreadCrumb>
            <PageTitle>保護者さまの登録情報</PageTitle>
          </FirstHeadContainer>
          <ParentInfoList email={data.me.email} nickname={data.me.nickname} />
          <ButtonContainer>
            <TransitionButton to={"#"} color={"orange"} disabled>
              編集機能は開発中です
            </TransitionButton>
          </ButtonContainer>
        </ContentsContainer>
        <ContentsContainer>
          <HeadContainer>
            <PageTitle>設定</PageTitle>
          </HeadContainer>
          <ButtonContainer>
            <ModalTransitionButton color={"red"} onClick={handleLogout}>
              ログアウト
            </ModalTransitionButton>
          </ButtonContainer>
        </ContentsContainer>
      </MiddleWrapper>
    </>
  );
};
