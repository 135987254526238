import React, { VFC } from "react";
import styled, { css } from "styled-components";
import { Heading1 } from "~/component/atoms/text/Heading1";
import { theme } from "~/component/theme";

type Color = "pri" | "sec";

export type BackNavbarProps = {
  titleName: string;
  onClick: (event: React.MouseEvent) => void;
  arrowColor?: Color;
};

export const BackNavbar: VFC<BackNavbarProps> = ({
  titleName,
  onClick,
  arrowColor = "pri",
}) => {
  return (
    <_Wrapper>
      <_Container>
        <_Arrow color={arrowColor} onClick={onClick} />
        <_PageTitle fontSize="LG" lineHeight="EQ" bold>
          {titleName}
        </_PageTitle>
        <_ActionContainer />
      </_Container>
    </_Wrapper>
  );
};

const _Wrapper = styled.div`
  ${({ theme }) => {
    return css`
      background: ${theme.colors.primitive.monotone.m0};
      width: 100%;
    `;
  }}
`;

const _Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${({ theme }) => theme.size.s2} 5%;
`;

const _ActionContainer = styled.div`
  min-width: 30px;
`;

const colorList: Record<Color, string> = {
  pri: theme.colors.semantic.primary.main,
  sec: theme.colors.semantic.secondary.main,
};

const _Arrow: React.FC<{
  color: Color;
  onClick: (event: React.MouseEvent) => void;
}> = ({ color, onClick }) => (
  <div onClick={onClick} style={{ cursor: "pointer" }}>
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.7458 21.8479L12.5897 23.004C12.1002 23.4935 11.3086 23.4935 10.8243 23.004L0.700398 12.8854C0.21087 12.3958 0.21087 11.6042 0.700398 11.1199L10.8243 0.996053C11.3138 0.506524 12.1054 0.506524 12.5897 0.996053L13.7458 2.15217C14.2406 2.64691 14.2301 3.45411 13.725 3.93843L7.44965 9.91693H22.4167C23.1094 9.91693 23.6666 10.4742 23.6666 11.1668V12.8333C23.6666 13.5259 23.1094 14.0831 22.4167 14.0831H7.44965L13.725 20.0616C14.2353 20.546 14.2458 21.3532 13.7458 21.8479Z"
        fill={colorList[color]}
      />
    </svg>
  </div>
);

const _PageTitle = styled(Heading1)`
  text-align: center;
  color: ${({ theme }) => theme.colors.tex.t800};
`;
