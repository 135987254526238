import React from "react";
import styled from "styled-components";
import { BaseModal } from "~/component/atoms/modal/base_modal";
import { Text } from "~/component/atoms/text/Text";
import { Stack } from "~/component/atoms/layout/Stack";
import { PrimaryButton } from "~/component/atoms/button/primary_button";

export type ConfirmRemoveRecommendModalProps = {
  onClickReturn: (e: React.MouseEvent) => void;
  onClickRemove: (e: React.MouseEvent) => void;
  isOpen: boolean;
  disabled?: boolean;
};

export const ConfirmRemoveRecommendModal: React.FC<
  ConfirmRemoveRecommendModalProps
> = ({ onClickRemove, onClickReturn, isOpen, disabled = false }) => {
  return (
    <BaseModal isOpen={isOpen} title="" onClose={onClickReturn}>
      <_ModalContainer>
        <_ModalWrapper>
          <Stack rowGap="16px">
            <Text fontColor="tex.t700" fontSize="LG" lineHeight="MD">
              本をおすすめ本リストから削除します
            </Text>
            <_ButtonsWrapper>
              <PrimaryButton
                color="lightGray"
                disabled={disabled}
                onClick={onClickReturn}
              >
                <Text fontSize="MD" lineHeight="EQ" fontColor="tex.t400" bold>
                  戻る
                </Text>
              </PrimaryButton>
              <PrimaryButton
                onClick={onClickRemove}
                disabled={disabled}
                color="red"
              >
                <Text fontSize="MD" lineHeight="EQ" fontColor="base.white" bold>
                  けす
                </Text>
              </PrimaryButton>
            </_ButtonsWrapper>
          </Stack>
        </_ModalWrapper>
      </_ModalContainer>
    </BaseModal>
  );
};

const _ModalContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
const _ModalWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.base.white};
  padding: 16px;
  border-radius: ${({ theme }) => theme.borderRadius.MD};
`;

const _ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  gap: 14px;
`;
