import React from "react";
import styled, { useTheme } from "styled-components";
import { Select } from "~/component/atoms/select";
import { BackNavbar } from "~/component/molecules/BackNavbar";
import { NavContainer } from "~/component/molecules/NavContainer";
import frequencyList from "./const";
import { Text } from "~/component/atoms/text/Text";
import { PrimaryButton } from "~/component/atoms/button/primary_button";
import { RecommendFrequency } from "~/generated/graphql";
import { NotifyTextToast } from "~/component/organisms/toasts/NotifyTextToast";
import { Stack } from "~/component/atoms/layout/Stack";

export type EditRecommendFrequencyTemplateProps = {
  handleBack: () => void;
  recommendFrequency: RecommendFrequency;
  onClick: (event: React.MouseEvent) => void;
  onChange: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  openModal: boolean;
  setOpenModal: (b: boolean) => void;
  modalMessage: string;
};

export const EditRecommendFrequencyTemplate: React.FC<
  EditRecommendFrequencyTemplateProps
> = ({
  recommendFrequency,
  onClick,
  handleBack,
  onChange,
  openModal,
  setOpenModal,
  modalMessage,
}) => {
  const theme = useTheme();
  return (
    <>
      <NavContainer>
        <BackNavbar titleName={"おすすめ冊数"} onClick={handleBack} />
      </NavContainer>
      <_Wrapper>
        <_TopWrapper>
          <_Container>
            <Select value={recommendFrequency} onChange={onChange}>
              {Object.keys(frequencyList).map((key, _) => (
                <option value={key as RecommendFrequency} key={_}>
                  {frequencyList[key].name}
                </option>
              ))}
            </Select>
            <Stack rowGap="32px">
              <_ContentsBox>
                <_Upper>
                  <_TitleText fontSize={"MD"} lineHeight={"EQ"} bold>
                    {frequencyList[recommendFrequency].emoji +
                      " " +
                      frequencyList[recommendFrequency].name}
                  </_TitleText>
                  <Stack rowGap="8px">
                    <_LeadText fontSize={"MD"} lineHeight={"MD"} bold>
                      {frequencyList[recommendFrequency].lead}
                    </_LeadText>
                    <_SubTextWrapper>
                      {frequencyList[recommendFrequency].sub && (
                        <Text
                          fontSize={"SM"}
                          lineHeight={"MD"}
                          style={{ color: theme.colors.primitive.tex.t500 }}
                        >
                          {frequencyList[recommendFrequency].sub}
                        </Text>
                      )}
                    </_SubTextWrapper>
                  </Stack>
                </_Upper>
                <_Border />
                <_Bottom>
                  <_ListItem>
                    <_ListKey fontSize={"SM"} lineHeight={"MD"}>
                      おすすめ本上限冊数
                    </_ListKey>
                    <_ListValue
                      fontSize={"MD"}
                      lineHeight={"EQ"}
                      bold
                    >{`${frequencyList[recommendFrequency].bookListMax}冊`}</_ListValue>
                  </_ListItem>
                  <_ListItem>
                    <_ListKey fontSize={"SM"} lineHeight={"MD"}>
                      おすすめの本リストが何冊になったら更新されるか
                    </_ListKey>
                    <_ListValue
                      fontSize={"MD"}
                      lineHeight={"EQ"}
                      bold
                    >{`${frequencyList[recommendFrequency].border}冊`}</_ListValue>
                  </_ListItem>
                </_Bottom>
              </_ContentsBox>
              <PrimaryButton onClick={onClick} noMarginTop>
                <Text
                  fontColor="primitive.monotone.m0"
                  fontSize="XL"
                  lineHeight="MD"
                  bold
                >
                  保存して完了
                </Text>
              </PrimaryButton>
            </Stack>
          </_Container>
        </_TopWrapper>
      </_Wrapper>
      <NotifyTextToast
        isOpen={openModal}
        setIsOpen={setOpenModal}
        text={modalMessage}
      />
    </>
  );
};

const _Wrapper = styled.div`
  width: 100%;
  padding: 0 7% 0 7%;
  min-height: 100vh;
  @media screen and ${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone} {
    padding: 0 10% 0 10%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmartPhone} {
    width: 100%;
    padding: 0 20% 0 20%;
  }
  @media ${({ theme }) => theme.breakPoints.minWidthSmallPC} {
    width: 100%;
    padding: 0 30% 0 30%;
  }
  background-color: ${({ theme }) => theme.colors.base.ashGray};
`;

const _TopWrapper = styled.div`
  padding: 74px 0 24px 0;
`;

const _Container = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
  width: 100%;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.primitive.monotone.m0};
  padding: ${({ theme }) => theme.size.s3};
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.s3};
  margin-bottom: 12px;
`;

const _ContentsBox = styled.div`
  border: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
  border-radius: ${({ theme }) => theme.borderRadius.MD};
  padding: 20px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.s4};
`;

const _TitleText = styled(Text)`
  color: ${({ theme }) => theme.colors.tex.t800};
`;

const _Upper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  row-gap: ${({ theme }) => theme.size.s1half};
`;

const _Border = styled.div`
  height: 0;
  width: 100%;
  border-bottom: 2px solid ${({ theme }) => theme.colors.semantic.layout.border};
`;

const _Bottom = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: ${({ theme }) => theme.size.s1half};
`;

const _LeadText = styled(Text)`
  color: ${({ theme }) => theme.colors.primitive.orange.o600};
`;

const _SubTextWrapper = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 4px;
`;

const _ListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const _ListKey = styled(Text)`
  color: ${({ theme }) => theme.colors.primitive.tex.t300};
  width: 80%;
`;

const _ListValue = styled(Text)`
  color: ${({ theme }) => theme.colors.primitive.tex.t800};
`;
