import React from "react";
import styled from "styled-components";
import { ReadingViewer, ReadingViewerProps } from "../ReadingViewer";
import { EnqueteFooterButton } from "../EnqueteFooterButton";

export type ReadAllSentenceProps = {
  sentence: ReadingViewerProps;
  onNext: () => void;
};

export const ReadAllSentence: React.FC<ReadAllSentenceProps> = ({
  sentence,
  onNext,
}) => {
  return (
    <_Wrapper>
      <_ContentWrapper>
        <_SurveyWrapper>
          <ReadingViewer {...sentence} />
        </_SurveyWrapper>
      </_ContentWrapper>
      <EnqueteFooterButton onClick={onNext} text="よんだ" />
    </_Wrapper>
  );
};

const _SurveyWrapper = styled.div`
  box-sizing: border-box;
  width: 100%;

  padding: 80px 6% 100px;

  @media screen and (${({ theme }) =>
      theme.breakPoints.minWidthSmallSmartPhone}) {
    padding: 80px 10% 100px;
  }
  @media screen and (${({ theme }) => theme.breakPoints.minWidthSmallPC}) {
    padding: 80px 20% 100px;
  }
`;

const _ContentWrapper = styled.div`
  width: 100%;
  max-width: 640px;
  height: 100%;
  max-height: 100%;
  margin: 0 auto;
  overflow: scroll;
`;

const _Wrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`;
