import React from "react";
import styled from "styled-components";
import { getNextPassageYL, passageDict } from "./const";
import { DifficultyYL, PassageYL } from "../types";
import { TransparentBackNavbar } from "~/component/molecules/TransparentBackNavbar";
import { NavContainer } from "~/component/molecules/NavContainer";
import { useScale } from "../../ui/constants";
import { Conversation } from "../../ui/Conversation";
import { ReadAllSentence } from "../ui/ReadAllSentence";
import { SelectDifficulty } from "../ui/SelectDifficulty";

type Props = {
  onNext: () => void;
  onSelectDifficulty: (level: PassageYL, difficulty: DifficultyYL) => void;
  passageYL: PassageYL;
  updatePassageYL: (passageYL: PassageYL) => void;
  history: PassageYL[];
  updateHistory: (history: PassageYL[]) => void;
  lastAnswer: DifficultyYL | undefined;
  updateLastAnswer: (lastAnswer: DifficultyYL) => void;
  onSimpleYLCheckBack: () => void;
  onBack: () => void;
};

type YLCheckFlow = "Conversation" | "YLCheck";
type EnqueteFlow = "reading" | "selectDifficulty";

export const SimpleYLCheck: React.FC<Props> = ({
  onNext,
  onSelectDifficulty,
  passageYL,
  updatePassageYL,
  history,
  updateHistory,
  lastAnswer,
  updateLastAnswer,
  onSimpleYLCheckBack,
  onBack,
}: Props) => {
  const [flow, setFlow] = React.useState<YLCheckFlow>("Conversation");
  const [enqueteFlow, setEnqueteFlow] = React.useState<EnqueteFlow>("reading");
  React.useEffect(() => {
    if (getNextPassageYL(lastAnswer, history) === undefined) {
      onNext();
    }
  }, []);
  const scale = useScale();

  const _onSelectDifficulty = React.useCallback(
    (difficulty: DifficultyYL) => {
      updateLastAnswer(difficulty);
      onSelectDifficulty(passageYL, difficulty);
      const nextLevel = getNextPassageYL(difficulty, [...history, passageYL]);
      updateHistory([...history, passageYL]);
      if (nextLevel === undefined) {
        onNext();
      } else {
        updatePassageYL(nextLevel);
        setEnqueteFlow("reading");
      }
    },
    [
      updateLastAnswer,
      onSelectDifficulty,
      passageYL,
      onNext,
      updatePassageYL,
      history,
      updateHistory,
    ]
  );

  const _handleBack = React.useCallback(() => {
    if (flow === "Conversation") {
      onBack();
      return;
    }
    if (enqueteFlow === "reading") {
      onSimpleYLCheckBack();
      if (history.length === 0) {
        setFlow("Conversation");
      } else {
        setEnqueteFlow("selectDifficulty");
      }
      return;
    }
    if (enqueteFlow === "selectDifficulty") {
      setEnqueteFlow("reading");
      return;
    }
  }, [enqueteFlow, history, onSimpleYLCheckBack, flow, onBack]);

  const content = React.useMemo(() => {
    console.log("passageYL:", passageYL);
    console.log("history:", history);
    console.log("lastAnswer:", lastAnswer);
    if (flow === "Conversation") {
      return (
        <>
          <NavContainer>
            <TransparentBackNavbar
              withBlur={true}
              leftBlock={{ arrowColor: "pri", onClick: _handleBack }}
            />
          </NavContainer>
          <Conversation
            imgSrc={"/img/sensei/standing.svg"}
            messageProps={{
              message:
                "つぎは、パッとぶんしょうを\nみて、かんたんか むずかしいか\nおしえてね！",
              speaker: "ヨンデミー先生",
            }}
            scale={scale}
            onNext={() => {
              updatePassageYL(getNextPassageYL(lastAnswer, history));
              setFlow("YLCheck");
            }}
          />
        </>
      );
    } else {
      const sentence = passageDict[passageYL];

      return (
        <_LibraryWrapper>
          {enqueteFlow === "reading" ? (
            <>
              <NavContainer>
                <TransparentBackNavbar
                  withBlur={true}
                  leftBlock={{ arrowColor: "pri", onClick: _handleBack }}
                />
              </NavContainer>
              <ReadAllSentence
                sentence={sentence}
                onNext={() => setEnqueteFlow("selectDifficulty")}
              />
            </>
          ) : (
            <>
              <NavContainer>
                <TransparentBackNavbar
                  withBlur={true}
                  leftBlock={{ arrowColor: "pri", onClick: _handleBack }}
                />
              </NavContainer>
              <SelectDifficulty
                sentence={sentence}
                onSelect={_onSelectDifficulty}
              />
            </>
          )}
        </_LibraryWrapper>
      );
    }
  }, [
    flow,
    passageYL,
    history,
    lastAnswer,
    scale,
    updatePassageYL,
    setFlow,
    setEnqueteFlow,
    _onSelectDifficulty,
    _handleBack,
    enqueteFlow,
  ]);

  return content;
};

const _LibraryWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  max-height: 100svh;
  overflow: hidden;
  background-image: url("/img/world_view_onboarding/bright-bookshelf.png");

  background-repeat: repeat-x;
  background-size: contain;

  position: relative;

  ::before {
    content: "";
    background-color: rgba(0, 0, 0, 0.45);

    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: block;
  }
`;
